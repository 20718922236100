<h6 class="my-3">Choose up to seven (7) categories below. Report will print in columns, sorted by wrestler.</h6>
<div class="row">
  <div class="col-3" *ngFor="let col of options">
    <div *ngFor="let row of col" class="form-check my-1">
      <input
        type="checkbox"
        [(ngModel)]="selectedOptions[row.value]"
        (ngModelChange)="onOptionChange()"
        class="form-check-input"
        id="cust-{{ row.value }}">
      <label
        class="form-check-label"
        for="cust-{{ row.value }}">{{ row.label }}</label>
    </div>
  </div>
</div>
<mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>
