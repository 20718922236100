<div [formGroup]="form"
  class="form-group"
  [ngClass]="{'mb-invalid': !isValid}">
  <input
    class="form-control"
    [ngClass]="{'mb-present': present}"
    type="{{ type }}"
    placeholder="{{ label }}"
    formControlName="input">
  <label class="mb-field-label">
    <span>{{ label }}</span>
  </label>
</div>
<mb-invalid
  [valid]="isValid"
  [control]="control"
  [messages]="messages"></mb-invalid>
