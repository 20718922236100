import { first, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { SchoolRecord } from '../models';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class SchoolRecordsService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    /**
     * find all records by teamID
     * @param teamID
     */
    findAll(teamID) {
        const params = { teamID };
        return this.apiService.get('school_records', { params })
            .pipe(first())
            .pipe(map((result) => result.result.map(r => new SchoolRecord(r))));
    }
    /**
     * add Record
     */
    addRecord(data) {
        return this.apiService.post('school_records', data)
            .pipe(first())
            .pipe(map(r => new SchoolRecord(r)));
    }
    /**
     * edit Record
     */
    editRecord(recordID, data) {
        return this.apiService.update(`school_records/${recordID}`, data);
    }
}
SchoolRecordsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SchoolRecordsService_Factory() { return new SchoolRecordsService(i0.ɵɵinject(i1.ApiService)); }, token: SchoolRecordsService, providedIn: "root" });
