import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, ErrorHandler } from "@angular/core";
import {
  LocationStrategy,
  HashLocationStrategy,
  APP_BASE_HREF,
} from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpXSRFInterceptor } from "./util/http-xsrf-interceptor";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CookieModule } from "ngx-cookie";
import { ToastrModule } from "ngx-toastr";
import { FileUploadModule } from "ng2-file-upload";
import { DatePipe } from "@angular/common";
import { routes } from "./routes";
import { AppComponent } from "./app.component";
import { AccountComponent, TagEditComponent } from "./account";
import {
  SettingsComponent,
  OrganizationComponent,
  UsersComponent,
  UserFilters,
  UserFiltersComponent,
  DevicesComponent,
  DeviceFilters,
  DeviceFiltersComponent,
  ProfileComponent,
  InviteUserComponent,
  UserEditComponent,
  UserInviteEditComponent,
  TrackWrestlingComponent,
} from "./settings";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { NotFoundComponent } from "./error/not-found.component";
import { HighlightsComponent } from "./highlights/highlights.component";
import { JumbotronComponent } from "./jumbotron/jumbotron.component";
import {
  FooterComponent,
  HeaderComponent,
  LayoutComponent,
  OnboardingComponent,
} from "./layout";
import {
  LoginComponent,
  PasswordComponent,
  PasswordResetComponent,
  RegisterComponent,
} from "./login";
import { LibraryComponent } from "./library/library.component";
import {
  EmailRosterComponent,
  RosterComponent,
  RosterFilters,
  RosterFiltersComponent,
  WrestlerEditComponent,
} from "./roster";
import {
  BoxscoreComponent,
  EventComponent,
  EventDetailComponent,
  EventEditComponent,
  FiltersComponent,
  MatchComponent,
  MatchEditComponent,
  ScorebookComponent,
  ScorebookTrashedComponent,
} from "./scorebook";

import { EditWtClassComponent } from "./video/edit-wt-class/edit-wt-class.component";
import { ScoringSymbolEditComponent } from "./video/scoring-symbol-edit/scoring-symbol-edit.component";
import { VideoHighlightsComponent } from "./video/video-highlights/video-highlights.component";
import { VideoListComponent } from "./video/video-list/video-list.component";
import { VideoListFiltersComponent } from "./video/video-list/video-list-filters.component";
import { VideoOptionsComponent } from "./video/video-options/video-options.component";
import { VideoPlaybackComponent } from "./video/video-playback/video-playback.component";
import { VideoPreviewComponent } from "./video/video-preview/video-preview.component";
import { VideoShortcutsComponent } from "./video/video-shortcuts/video-shortcuts.component";
import { VideoToolbarComponent } from "./video/video-toolbar/video-toolbar.component";

import { ApiService } from "./services/api.service";
import { AuthGuard } from "./services/auth-guard.service";
import { EventLevelsService } from "./services/event-levels.service";
import { LoginGuard } from "./services/login-guard.service";
import { MBEventService } from "./services/mbevent.service";
import { MBMatchService } from "./services/mbmatch.service";
import { NavService } from "./services/nav.service";
import { RosterService } from "./services/roster.service";
import { TeamPointAdjustmentService } from "./services/teampointadjustment.service";
import { UserService } from "./services/user.service";
import { AdministrativeService } from "./services/administrative.service";

import { ReportsComponent } from "./reports/reports.component";
import {
  AlertComponent,
  ConfirmComponent,
  MBError,
  SpinnerComponent,
  EllipsisLoaderComponent,
} from "./util";
import { InvalidComponent } from "./util/forms/invalid";
import { LongPressDirective } from "./util/long-touch.directive";
import { TextFieldComponent } from "./util/forms/text-field/text-field.component";
import { SetupWizardComponent } from "./setup-wizard/setup-wizard.component";
import { ReportResultComponent } from "./reports/report-result.component";
import { TeamReportsComponent } from "./reports/team-reports.component";
import { EventReportsComponent } from "./reports/event-reports.component";
import { IndividualReportsComponent } from "./reports/individual-reports.component";
import { CustomReportsComponent } from "./reports/custom-reports.component";
import { CumulativeReportsComponent } from "./reports/cumulative-reports.component";
import { Setup1Component } from "./setup-wizard/setup1.component";
import { Setup2Component } from "./setup-wizard/setup2.component";
import { Setup3Component } from "./setup-wizard/setup3.component";
import { Setup4Component } from "./setup-wizard/setup4.component";
import { Setup5Component } from "./setup-wizard/setup5.component";
import { LibraryItemEditComponent } from "./library/library-item-edit.component";
import { EditOurWrestlerComponent } from "./video/edit-our-wrestler/edit-our-wrestler.component";
import { EditOpponentWrestlerComponent } from "./video/edit-opponent-wrestler/edit-opponent-wrestler.component";
import { TwLoginComponent } from "./trackwrestling/tw-login/tw-login.component";
import { ImportRosterComponent } from "./roster/import-roster.component";
import { VideoWatchComponent } from "./video/video-watch/video-watch.component";
import { VideoListShareComponent } from "./video/video-list/video-list-share.component";
import { Setup20Component } from "./setup-wizard/setup20/setup20.component";
import { Setup21Component } from "./setup-wizard/setup21/setup21.component";
import { Setup22Component } from "./setup-wizard/setup22/setup22.component";
import { Setup23Component } from "./setup-wizard/setup23/setup23.component";
import { Setup24Component } from "./setup-wizard/setup24/setup24.component";
import { Setup25Component } from "./setup-wizard/setup25/setup25.component";
import { Setup31Component } from "./setup-wizard/setup31/setup31.component";
import { Setup41Component } from "./setup-wizard/setup41/setup41.component";
import { Setup252Component } from "./setup-wizard/setup252/setup252.component";
import { Setup253Component } from "./setup-wizard/setup253/setup253.component";
import { Setup262Component } from "./setup-wizard/setup262/setup262.component";
import { Setup33Component } from "./setup-wizard/setup33/setup33.component";
import { Setup12Component } from "./setup-wizard/setup12/setup12.component";
import { MatchAddComponent } from "./scorebook/match-add.component";
import { FileUploadComponent } from "./util/forms/file-upload/file-upload.component";
import { ImportEventsComponent } from "./scorebook/import-events.component";
import { SendToContactsComponent } from "./reports/send-to-contacts/send-to-contacts.component";
import { SchoolRecordsComponent } from "./school-records/school-records.component";
import { EventTrashedComponent } from "./scorebook/event-trashed.component";
import { AdjustTeamPointComponent } from "./scorebook/adjust-team-point.component";

@NgModule({
  declarations: [
    AccountComponent,
    SettingsComponent,
    OrganizationComponent,
    UsersComponent,
    UserFiltersComponent,
    DeviceFiltersComponent,
    InviteUserComponent,
    UserEditComponent,
    UserInviteEditComponent,
    TrackWrestlingComponent,
    DevicesComponent,
    ProfileComponent,
    AlertComponent,
    AppComponent,
    BoxscoreComponent,
    ConfirmComponent,
    RegisterComponent,
    CumulativeReportsComponent,
    CustomReportsComponent,
    DashboardComponent,
    EditWtClassComponent,
    EllipsisLoaderComponent,
    EmailRosterComponent,
    EventComponent,
    EventDetailComponent,
    EventEditComponent,
    EventReportsComponent,
    FiltersComponent,
    FooterComponent,
    HeaderComponent,
    HighlightsComponent,
    IndividualReportsComponent,
    InvalidComponent,
    JumbotronComponent,
    LayoutComponent,
    LibraryComponent,
    LoginComponent,
    LongPressDirective,
    MatchComponent,
    MatchEditComponent,
    NotFoundComponent,
    OnboardingComponent,
    PasswordComponent,
    PasswordResetComponent,
    ReportResultComponent,
    ReportsComponent,
    RosterComponent,
    RosterFiltersComponent,
    ScorebookComponent,
    ScoringSymbolEditComponent,
    Setup1Component,
    Setup2Component,
    Setup3Component,
    Setup4Component,
    Setup5Component,
    SetupWizardComponent,
    SpinnerComponent,
    TagEditComponent,
    TeamReportsComponent,
    TextFieldComponent,
    VideoPreviewComponent,
    WrestlerEditComponent,
    LibraryItemEditComponent,
    VideoToolbarComponent,
    VideoOptionsComponent,
    VideoShortcutsComponent,
    VideoHighlightsComponent,
    VideoPlaybackComponent,
    EditOurWrestlerComponent,
    EditOpponentWrestlerComponent,
    TwLoginComponent,
    VideoListComponent,
    VideoListFiltersComponent,
    ImportRosterComponent,
    VideoWatchComponent,
    VideoListShareComponent,
    Setup20Component,
    Setup21Component,
    Setup22Component,
    Setup23Component,
    Setup24Component,
    Setup25Component,
    Setup31Component,
    Setup41Component,
    Setup252Component,
    Setup253Component,
    Setup262Component,
    Setup33Component,
    Setup12Component,
    MatchAddComponent,
    FileUploadComponent,
    ImportEventsComponent,
    SendToContactsComponent,
    SchoolRecordsComponent,
    ScorebookTrashedComponent,
    EventTrashedComponent,
    AdjustTeamPointComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CookieModule.forRoot(),
    FileUploadModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { useHash: true }),
    ToastrModule.forRoot(),
  ],
  providers: [
    DatePipe,
    ApiService,
    AuthGuard,
    EventLevelsService,
    LoginGuard,
    MBEventService,
    MBMatchService,
    NavService,
    UserFilters,
    DeviceFilters,
    RosterFilters,
    RosterService,
    TeamPointAdjustmentService,
    UserService,
    AdministrativeService,
    {
      provide: ErrorHandler,
      useClass: MBError,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpXSRFInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: APP_BASE_HREF,
      useValue: "/",
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmComponent,
    EditWtClassComponent,
    EditOurWrestlerComponent,
    InviteUserComponent,
    UserEditComponent,
    UserInviteEditComponent,
    EditOpponentWrestlerComponent,
    EmailRosterComponent,
    EventEditComponent,
    LibraryItemEditComponent,
    MatchAddComponent,
    MatchEditComponent,
    ScoringSymbolEditComponent,
    SendToContactsComponent,
    TagEditComponent,
    TwLoginComponent,
    VideoListShareComponent,
    WrestlerEditComponent,
    AdjustTeamPointComponent,
  ],
})
export class AppModule {}
