import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'

import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})
export class HighlightsService {

  constructor(
    private apiService: ApiService,
    private http: HttpClient
  ) { }

  public findAll(data: {
    teamID: string,
    year: string,
    per_page?: number,
    page?: number
  }): Observable<any> {
    let params = new HttpParams()
      .set('teamID', data.teamID)
      .set('year', data.year)
      .set('per_page', (data.per_page || 100).toString())
      .set('page', (data.page || 0).toString())

    return this.apiService.get('highlights', { params: params })
  }

  public findArchived(data: {
    teamID: string
  }): Observable<any> {
    return this.apiService.get('highlightarchives', { params: data })
  }

  /**
   * add Highlight
   * required: matchID weightClass theDate wrestlerID description
   */
  public addHighlight(data: any): Observable<any> {
    let params = data || {}
    return this.apiService.post('highlights', params)
  }

  /**
   * edit Highlight
   */
  public editHighlight(highlightID: string, data: any): Observable<any> {
    let params = data || {}
    params.rowID = highlightID
    return this.apiService.update('highlights', params)
  }
	
  /**
   * delete Highlight
   */
  public deleteHighlight(highlightID: string, year: string): Observable<any> {
	return this.apiService.delete('highlights/' + encodeURIComponent(highlightID) + '?year=' + encodeURIComponent(year))
  }

  /**
   * "order" Highlights
   */
  public orderHighlights(body: {
    teamID: string,
    year: string,
    rowIDString: string,
    teamName?: string,
    state?: string,
    bucketName?: string,
  }): Observable<any> {
    return this.apiService.post('submitHighlightOrder', body)
  }
}
