import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil, first } from 'rxjs/operators'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { saveAs } from 'file-saver'
import * as PDFObject from 'pdfobject'

import { User, ReportParams, Report } from '../models'
import { UserService, ReportService } from '../services'
import { SendToContactsComponent } from './send-to-contacts/send-to-contacts.component'

@Component({
  selector: 'mb-report-result',
  templateUrl: './report-result.component.html'
})
export class ReportResultComponent implements OnInit, OnDestroy {
  public loaded = false
  public error = ''
  public success = ''
  public report: Report
  public reportType: string
  public urlParams: any
  public csvable = false
  public pdfable = false
  public emailable = false
  private user: User
  private reportParams: ReportParams
  private unsubscribe: Subject<any> = new Subject()

  constructor(
    private userService: UserService,
    private reportService: ReportService,
    private acRoute: ActivatedRoute,
    private modalService: NgbModal,
    private toastrService: ToastrService
  ) { }

  /**
   * set teamID from user.ID
   * @todo determin if wrestlers and coaches should see the same things
   */
  public ngOnInit() {
    this.acRoute.queryParams
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((params: any) => {
        this.urlParams = params
        this.reportParams = new ReportParams(params)
        this.reportType = this.transformReportType(params.reportType)
        this.pdfable = this._pdfable(this.reportType)
        this.emailable = this._emailable(this.reportType)
        this.csvable = this.reportType === 'customreport'
        this.getReport()
        this.getPDF()
      })

    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        this.user = user
        this.getReport()
        this.getPDF()
      })
  }

  public ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  public getCSV(): void {
    const data = this.report
    console.log(this.report);
    const section = data.sections[0]
    const header = section.columnHeadings

    const csv = section.rows.map(row => row.map(col =>
      JSON.stringify(col == null ? '' : col)).join(','))
    csv.unshift(header.join(','))
    const csvArray = csv.join('\r\n')

    const blob = new Blob([csvArray], {type: 'text/csv' })
    const filename = `${section.title.replace(' ', '-')}.csv`
    saveAs(blob, filename)
  }

  public getPDF(silent = true): void {
    if (!this.user) return
    if (!this.reportParams) return
    this.reportParams.teamID = this.user.teamID
    const url: string = this.reportService.getReportPDFUrl(this.reportType, this.reportParams)
    PDFObject.embed(
      url,
      "#pdf-container",
      {width: '100%', height: '100vh',
      fallbackLink: "<p>Please download the PDF to view it: <a href='[url]'>Download Report</a></p>"});
    this.loaded = true;
    if (!silent) window.open(url, '_blank')
  }

  public sendToContacts(): void {
    const modalRef = this.modalService.open(SendToContactsComponent, { size: 'lg' })
    modalRef.componentInstance.reportType = this.reportType
    modalRef.componentInstance.reportParams = this.reportParams
    modalRef.componentInstance.user = this.user
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.toastrService.success('The message was sent')
        } else {
          this.toastrService.error('There was an error sending the message (1).')
        }
      },
      (rejectedValue: any) => {
        if (rejectedValue === 'Cross click' || rejectedValue === 0 || rejectedValue === 1) return
        this.toastrService.error('There was an error sending the message (2).')
      }
    )
  }

  private getReport(): void {
    if (!this.user) return
    if (!this.reportParams) return
    this.reportParams.teamID = this.user.teamID
    this.reportService.getReport(this.reportType, this.reportParams)
      .pipe(first())
      .subscribe((data: any) => {
        // if (!data.success) this.error = 'Invalid report'
        this.report = new Report(data.result)
        this.loaded = true
      })
  }

  private transformReportType(type: string): string {
    if (type === 'dualeventanalysis') return 'eventanalysis'
    if (type === 'tourneyeventanalysis') return 'eventanalysis'
    return type
  }

  private _pdfable(type: string): boolean {
    return true

    // return [
    //   'teamduals',
    //   'categoryleaders',
    //   'categoryleaderscumulative',
    //   'seasonteamanalysis',
    //   'eventanalysis',
    //   'dualeventanalysis',
    //   'tourneyeventanalysis',
    //   'individualanalysis'
    // ].includes(type)
  }

  private _emailable(type: string): boolean {
      return [
        'teamduals',
        'seasonteamanalysis',
        'categoryleaders',
        'dualmeetsummary',
        'tournamentsummary',
        'customreport',
      ].includes(type)
  }
}
