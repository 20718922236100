import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core'
import { Subject } from 'rxjs'
import { first } from 'rxjs/operators'

import { MBEventService } from '../services'
import { MBEvent } from '../models'

@Component({
  selector: 'mb-event-reports',
  templateUrl: './event-reports.component.html',
  host: {'style': 'display: block', 'class': 'p-3'},
})
export class EventReportsComponent implements OnInit, OnDestroy {
  private _year: string
  private _levelID: string
  public loaded = false
  public dualEvents: MBEvent[]
  public tourneyEvents: MBEvent[]
  public eventType: string
  private unsubscribe: Subject<any> = new Subject()

  @Input() reportType: string
  @Output() reportTypeChange = new EventEmitter()
  @Input() eventID: string
  @Output() eventIDChange = new EventEmitter()
  @Input() teamID: string
  @Input()
  public set year(year: string) {
    this._year = year
    this.loadEvents()
  }

  @Input()
  public set levelID(levelID: string) {
    this._levelID = levelID
    this.loadEvents()
  }

  constructor(
    private eventService: MBEventService
  ) { }

  ngOnInit() {
    this.loadEvents()
  }

  private loadEvents(): void {
    if (!this._year) return
    this.loaded = false
    let params = {
      teamID: this.teamID,
      year: this._year,
      levelID: this._levelID,
      perPage: 200
    }
    this.eventService.findRecent(params)
      .pipe(first())
      .subscribe((data: any) => {
        this.dualEvents = []
        this.tourneyEvents = []
        if (data.result) {
          data.result.forEach(item => {
            const event: MBEvent = new MBEvent(item)
            if (event.isDualMeet) this.dualEvents.push(event)
            else if (event.isTournament) this.tourneyEvents.push(event)
          })
          this.setEventType()
          this.loaded = true
        }
        else throw new Error('Invalid response')
      })
  }

  public ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  public onReportTypeChange(): void {
    this.setEventType()
    this.reportTypeChange.emit(this.reportType)
  }

  public onEventIDChange(): void {
    this.eventIDChange.emit(this.eventID)
  }

  private setEventType(): void {
    const oldEventType = this.eventType
    if (this.reportType === 'dualmeetsummary') this.eventType = 'dual'
    else if (this.reportType === 'dualeventanalysis') this.eventType = 'dual'
    else if (this.reportType === 'tournamentsummary') this.eventType = 'tourney'
    else if (this.reportType === 'tourneyeventanalysis') this.eventType = 'tourney'
    if (oldEventType !== this.eventType) this.eventID = undefined
  }
}
