import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'

import { User } from '../../models'
import { UserService } from '../../services'

@Component({
  selector: 'mb-setup23',
  templateUrl: './setup23.component.html'
})
export class Setup23Component implements OnInit {

  @Input() account: User

  public form: FormGroup
  public loaded = false
  public submitting = false
  public validate = false

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.createForm()
  }

  public submitAnswer(theAnswer: string) {
	  this.submitting = true
	  this._submit(theAnswer)
  }

  private _submit(theAnswer: string): void {
    if (theAnswer === 'yes') {
      this.router.navigate(['/setup/7'])
    }
    else {
      this.router.navigate(['/setup/8'])
    }
  }

  public goBack() {
    window.history.back()
  }

  // private

  private createForm(): void {
    this.form = this.fb.group({

	})
    this.loaded = true
  }
}
