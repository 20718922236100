import { Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'mb-confirm',
  template: `
    <div class="mb-confirm">
      <div class="modal-header">
        <h4
          class="modal-title"
          *ngIf="title">{{ title }}</h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <p>{{ body }}</p>
      </div>

      <div class="modal-footer d-block text-right">
        <button
          type="button"
          class="btn"
          (click)="cancel()">
            {{ cancelLabel }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="confirm()">
            {{ confirmLabel }}
        </button>
      </div>
  `
})
export class ConfirmComponent {
  @Input() title: string
  @Input() body: string
  @Input() confirmLabel?: string = 'Confirm'
  @Input() cancelLabel?: string = 'Cancel'

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  public confirm(): void {
    this.activeModal.close({
      success: true, result: null
    })
  }

  public cancel(): void {
    this.activeModal.close({
      success: false, result: null
    })
  }
}
