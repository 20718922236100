export class TwEvent {
  eventDate: Date
  eventName: string
  eventType: string // 'Dual Meet' or 'Tournament'
  squadLevel: string // Var, etc
  twid: string
  location: string // home or away

  public constructor(data: any) {
    this.update(data)
  }

  public update(data: any): void {
    if (data.eventDate) data.eventDate = new Date(data.eventDate)
    Object.assign(this, data)
  }
}
