<div class="container container--inner">
  <h3 class="mb-page__head">
    <span class="mb-page__head-title">
      <img src="./assets/SVG/Page/Reports.svg" alt="Icon">
      Reports
    </span>
  </h3>

  <mb-spinner *ngIf="!loaded"
    label="Loading Reports..."
    class="my-5 p-5 text-center"></mb-spinner>

  <div *ngIf="loaded">
    <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>
    <div class="mb-report-heading">
      <span class="mb-report-heading__step">1</span>
      Select the year, squad, and season
    </div>
    <div class="pl-3">
      <div class="mb-2">
        <small>Note: Items below marked with an asterisk (*) will use the time period dropdown when compiling the report.</small>
      </div>
      <div class="row mb-2">
        <div class="col-2">
          Year
        </div>
        <div class="col-3">
          <select
            [(ngModel)]="currentYear"
            class="form-control">
            <option
              *ngFor="let year of yearOptions"
              [value]="year">{{ year }}</option>
          </select>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-2">
          Squad
        </div>
        <div class="col-3">
          <select
            [(ngModel)]="levelID"
            class="form-control">
            <option *ngFor="let level of levelOptions"
              [value]="level.levelID">{{ level.label }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          Time Period
        </div>
        <div class="col-3">
          <select
            [(ngModel)]="timePeriod"
            class="form-control">
            <option
              *ngFor="let tp of timePeriods"
              [value]="tp.value">{{ tp.label }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="mb-report-heading">
      <span class="mb-report-heading__step">2</span> Select a report type
    </div>
    <div class="mb-report-types">
      <nav class="mb-report-types__selector">
        <ul class="nav">
          <li
            *ngIf="!user.isWrestler"
            class="nav-item"
            [ngClass]="{active: reportGroup === 'team'}">
            <a class="nav-link mb-report-types__nav-item"
              (click)="setReportGroup('team')">Team</a>
          </li>
          <li
            *ngIf="!user.isWrestler"
            class="nav-item"
            [ngClass]="{active: reportGroup === 'event'}">
            <a class="nav-link mb-report-types__nav-item"
              (click)="setReportGroup('event')">Event</a>
          </li>
          <li class="nav-item"
            [ngClass]="{active: reportGroup === 'individual'}">
            <a class="nav-link mb-report-types__nav-item"
              (click)="setReportGroup('individual')">Individual</a>
          </li>
          <li
            *ngIf="!user.isWrestler"
            class="nav-item"
            [ngClass]="{active: reportGroup === 'custom'}">
            <a class="nav-link mb-report-types__nav-item"
              (click)="setReportGroup('custom')">Custom</a>
          </li>
          <li
            *ngIf="!user.isWrestler"
            class="nav-item"
            [ngClass]="{active: reportGroup === 'cumulative'}">
            <a class="nav-link mb-report-types__nav-item"
              (click)="setReportGroup('cumulative')">Cumulative</a>
          </li>
        </ul>
      </nav>
      <mb-team-reports
        *ngIf="reportGroup === 'team'"
        [(reportType)]="reportType"
        [(opponentName)]="opponentName"></mb-team-reports>

      <mb-event-reports
        *ngIf="reportGroup === 'event'"
        [year]="currentYear"
        [levelID]="levelID"
        [teamID]="user.teamID"
        [(reportType)]="reportType"
        [(eventID)]="eventID"></mb-event-reports>

      <mb-individual-reports
        *ngIf="reportGroup === 'individual'"
        [year]="currentYear"
        [teamID]="user.teamID"
        [user]="user"
        [(reportType)]="reportType"
        [(wrestlerID)]="wrestlerID"></mb-individual-reports>

      <mb-custom-reports
        *ngIf="reportGroup === 'custom'"
        [(catIndexArray)]="catIndexArray"></mb-custom-reports>

      <mb-cumulative-reports
        *ngIf="reportGroup === 'cumulative'"
        [teamID]="user.teamID"
        [(reportType)]="reportType"
        [(wrestlerID)]="wrestlerID"></mb-cumulative-reports>
    </div>

    <hr>
    <div class="mb-details-link">
      <button
        type="button"
        (click)="submit()"
        class="btn btn-lg btn-primary">Generate Report
      </button>
    </div>
  </div>
</div>
