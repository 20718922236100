import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { first } from 'rxjs/operators'

import { MBEvent, User } from '../../models'
import { MBEventService, UserService } from '../../services'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'mb-setup262',
  templateUrl: './setup262.component.html'
})
export class Setup262Component implements OnInit {

  @Input() account: User

  public YEAR = environment.currentYear

  public events: MBEvent[]
  public form: FormGroup
  public loaded = false
  public submitting = false
  public validate = false

  constructor(
    private fb: FormBuilder,
	private eventService: MBEventService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.findEvents()
  }

  public submitAnswer(theAnswer: string) {
	  this.submitting = true
	  this._submit(theAnswer)
  }

  private _submit(theAnswer: string): void {
    if (theAnswer === 'yes') {
      this.router.navigate(['/setup/14'])
    }
    else {
      window.history.back()
    }
  }

  public goBack() {
    window.history.back()
  }

  // private
  /**
   * get schedule from API
   */
  private findEvents(): void {
	const data: any = {
        teamID: this.account.teamID,
		levelID: '-1',
		year: this.YEAR
      }
    this.eventService.findAll(data)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.events = []
          if (data.success && data.result) {
            let ev: MBEvent
			for (var item of data.result) {
				ev = new MBEvent(item)
				this.events.push(ev)
			}
            this.createForm()
          }
          else throw new Error('Invalid response')
        }
      )
  }

  private createForm(): void {
    this.form = this.fb.group({

	})
    this.loaded = true
  }
}
