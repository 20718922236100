import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { first } from 'rxjs/operators'

import { User } from '../models'
import { UserService } from '../services'

@Component({
  selector: 'mb-setup5',
  templateUrl: './setup5.component.html'
})
export class Setup5Component implements OnInit {

  @Input() account: User

  public form: FormGroup
  public loaded = false
  public submitting = false

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.createForm()
  }
	
  public submitAnswer(theAnswer: string): void {
	  this.submitting = true
	  const data: any = {}
	  let updatedVersions: boolean = true
	  if (theAnswer == 'no') {
		  updatedVersions = false
		  alert("It is very IMPORTANT that you update your devices, so please take care of that soon.")
	  }
	  if (updatedVersions !== undefined) {
      	data.appUpdated = updatedVersions ? 1 : 'false'
      }
	  this.loaded = false
	  this.userService.update(this.account.userName, this.account.userType, data)
        .pipe(first())
        .subscribe(
          //(data: any) => this.setUser(data),
          //(e: any) => { console.warn('error', e) }
		  (data: any) => this._submit()
        )
  }

  public _submit(): void {
    this.router.navigate(['/setup/16'])
  }

  public goBack(): void {
    window.history.back()
  }

  private createForm(): void {
    this.form = this.fb.group({
      //appUpdated: ['', Validators.required],
      //disconnected: ['', Validators.required]
    })
    this.loaded = true
  }
}
