import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class TwService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    /**
     * Get TW roster
     * @param teamID
     */
    getRoster(teamID) {
        const params = { teamID: teamID, per_page: '500' };
        return this.apiService.get('trackwrestlingroster', { params: params });
    }
    /**
     * get TW info
     * @param teamID
     */
    getInfo(teamID) {
        const params = { teamID: teamID, per_page: '500' };
        return this.apiService.get('trackwrestling', { params: params });
    }
    /**
     * get TW shcedule
     * @param teamID
     */
    getSchedule(teamID) {
        const params = { teamID: teamID, per_page: '500' };
        return this.apiService.get('trackwrestlingschedule', { params: params });
    }
    /**
     * get TW States
     * @param none
     */
    getTWStates() {
        const params = { per_page: '500' };
        return this.apiService.post('trackwrestlingstates', { params: params });
    }
    /**
     * get TW States
     * @param none
     */
    getTWSeasons() {
        const params = { per_page: '500' };
        return this.apiService.post('trackwrestlingseasons', { params: params });
    }
    /**
     * get TW Team List
     * @param seasonID, teamID
     */
    getTWTeamList(seasonID, stateID) {
        const params = { seasonID: seasonID, stateID: stateID, per_page: '500' };
        return this.apiService.get('trackwrestlingseasonteams', { params: params });
    }
    /**
     * export (fka sync)
     * @param teamID
     */
    export(event, userName, password, otherTeamStateAbbreviation, exportExhibitions = true) {
        // eventID, userName (this is their TrackWrestling userName),
        // password (this is their TrackWrestling password),
        // (two-letter abbreviation for the opposing school)
        // exporttournament - eventID, userName, password
        if (event.isDualMeet)
            return this.exportDual(event, userName, password, otherTeamStateAbbreviation, exportExhibitions);
        else if (event.isTournament)
            return this.exportTournament(event, userName, password, exportExhibitions);
    }
    exportDual(event, userName, password, otherTeamStateAbbreviation, exportExhibitions = true) {
        return this.apiService.post('exportdual', {
            eventID: event.eventID,
            otherTeamStateAbbreviation: otherTeamStateAbbreviation,
            userName: userName,
            password: password,
            exportExhibitions: exportExhibitions
        });
    }
    exportTournament(event, userName, password, exportExhibitions = true) {
        return this.apiService.post('exporttournament', {
            eventID: event.eventID,
            userName: userName,
            password: password,
            exportExhibitions: exportExhibitions
        });
    }
}
TwService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TwService_Factory() { return new TwService(i0.ɵɵinject(i1.ApiService)); }, token: TwService, providedIn: "root" });
