<div class="container container--inner">
  <h3 class="mb-page__head">
    <span class="mb-page__head-title">
      <img src="./assets/SVG/Page/Roster.svg" alt="Icon">
      Your Roster
    </span>
    <span class="mb-page__head-links">
      <a (click)="addWrestler()" class="mx-4 mb-page__head-link">Add a wrestler</a>
      <a (click)="emailRoster()" class="mx-4 mb-page__head-link">Email your roster</a>
      <a routerLink="/roster/import" class="mx-4 mb-page__head-link">Trackwrestling</a>
    </span>
  </h3>

  <mb-spinner *ngIf="!loaded"
    label="Loading Roster..."
    class="my-5 p-5 text-center"></mb-spinner>

  <div *ngIf="loaded">
    <div class="mb-pagination">
      <strong>{{ wrestlers.length }} wrestlers</strong>
    </div>

    <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>
    <mb-alert *ngIf="success" [message]="success" (closed)="success = null"></mb-alert>

    <mb-roster-filters
      [search]="search"
      (submitted)="updateFilters($event)"></mb-roster-filters>

    <div class="table-responsive">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th class="d-none d-sm-table-cell text-nowrap"><a (click)="sort('Level')" class="mb-th__link">Squad</a></th>
            <th class="text-nowrap"><a (click)="sort('lastName')" class="mb-th__link">Last name</a></th>
            <th class="text-nowrap"><a (click)="sort('firstName')" class="mb-th__link">First name</a></th>
            <th class="d-none d-sm-table-cell text-nowrap"><a (click)="sort('Grade')" class="mb-th__link">Grade</a></th>
            <th class="d-none d-sm-table-cell text-nowrap"><a (click)="sort('WeightClass')" class="mb-th__link">Weight</a></th>
            <th class="d-none d-sm-table-cell text-nowrap">Years wrestled</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let wrestler of wrestlers">
            <td class="d-none d-sm-table-cell">{{ levels[wrestler.Level] || '' }}</td>
            <td>{{ wrestler.lastName }}</td>
            <td>{{ wrestler.firstName }}</td>
            <td class="d-none d-sm-table-cell">{{ wrestler.Grade }}</td>
            <td class="d-none d-sm-table-cell">{{ wrestler.WeightClass }}</td>
            <td class="d-none d-sm-table-cell">
              <span class="d-inline-block text-truncate" style="font-size: 80%; max-width: 150px;">
                {{ wrestler.yearsWrestled }}
              </span>
            </td>
            <td>
              <button *ngIf="wrestler.isEditable"
                (click)="editWrestler(wrestler)"
                class="btn btn-sm btn-outline-secondary"
                style="text-transform: uppercase">
                <img src="./assets/SVG/UI/Edit.svg" alt="Icon" style="width: 16px; height: 16px">
                Edit
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mb-details-link">
      <a (click)="addWrestler()"
        class="btn btn-lg btn-primary">Add Wrestler</a>
    </div>
  </div>
</div>
