import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'mb-alert',
  template: `<div [ngClass]="['alert', 'alert-' + type]" role="alert">
    <span [innerHTML]="message"></span>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>`
})
export class AlertComponent {
  @Input() message: string
  @Input() type: string
  @Output() closed: EventEmitter<string> = new EventEmitter<string>()

  constructor() {
    if (!this.type) this.type = 'success'
  }

  public close() {
    this.closed.emit(this.type)
  }

}
