import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'mb-team-reports',
  templateUrl: './team-reports.component.html',
  host: {'style': 'display: block', 'class': 'p-3'},
})
export class TeamReportsComponent implements OnInit {

  @Input() reportType: string
  @Output() reportTypeChange = new EventEmitter<string>()
  @Input() opponentName: string
  @Output() opponentNameChange = new EventEmitter<string>()


  constructor() { }

  ngOnInit() { }

  public onReportTypeChange(): void {
    this.reportTypeChange.emit(this.reportType)
  }

  public onOpponentNameChange(): void {
    this.opponentNameChange.emit(this.opponentName)
  }

}
