<div class="container container--inner">
  <div class="mb-breadcrumbs pb-3">
    <a preserveQueryParams routerLink="/settings">‹ Back To Settings</a>
  </div>

  <h3 class="mb-page__head">
    <span class="mb-page__head-title"> Organization Settings </span>
  </h3>

  <mb-spinner
    *ngIf="!loaded"
    label="Loading Settings..."
    class="my-5 p-5 text-center"
  ></mb-spinner>

  <div *ngIf="loaded">
    <mb-alert
      *ngIf="error"
      type="danger"
      [message]="error"
      (closed)="error = null"
    ></mb-alert>
    <mb-alert
      *ngIf="success"
      [message]="success"
      (closed)="success = null"
    ></mb-alert>

    <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
      <h3 class="mb-subhead my-3" *ngIf="!account.isWrestler">Account</h3>

      <div class="row" *ngIf="!account.isWrestler">
        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label">School name</label>
            <div class="form-value">{{ account.schoolName }}</div>
          </div>
        </div>

        <!-- <div class="col-md-6">
            <div class="form-group">
              <label class="mb-label">Team code</label>
              <div class="form-value">{{ account.teamCode }}</div>
            </div>
          </div> -->

        <!-- <div class="col-md-6">
            <div class="form-group">
              <label class="mb-label">Username</label>
              <div class="form-value">{{ account.userName }}</div>
            </div>
          </div> -->

        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label"
              >Schools in my conference/region
              <span class="mb-label__info"
                >- separate schools with commas</span
              ></label
            >
            <input
              type="text"
              class="form-control"
              formControlName="schoolList"
            />
          </div>
        </div>
      </div>

      <hr class="mb-hr" *ngIf="!account.isWrestler" />

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label">Name</label>
            <input type="text" class="form-control" formControlName="contact" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label">Phone</label>
            <input type="text" class="form-control" formControlName="phone" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label">Email</label>
            <input
              type="text"
              class="form-control"
              formControlName="emailAddress"
            />
          </div>
        </div>
        <div class="col-md-6" *ngIf="!account.isWrestler">
          <div class="form-group">
            <label class="mb-label"
              >Media contacts
              <span class="mb-label__info"
                >- separate email addresses with a comma</span
              ></label
            >
            <input
              type="text"
              class="form-control"
              formControlName="contactList"
            />
          </div>
        </div>
      </div>

      <hr class="mb-hr" />

      <!-- <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-label">New password <span class="mb-label__info">- must be 8-24 characters with 1 number</span></label>
              <input type="password"
                class="form-control"
                formControlName="newPassword"
                placeholder="Enter here">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-label">Recovery password</label>
              <input type="password"
                class="form-control"
                formControlName="recoveryPassword"
                placeholder="Mother's maiden name, etc.">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-label">Confirm password <span class="mb-label__info">- must be 8-24 characters with 1 number</span></label>
              <input type="password"
                class="form-control"
                formControlName="newPasswordConfirm"
                placeholder="Enter here">
            </div>
          </div>
        </div> -->

      <!-- <h3 class="mb-subhead" *ngIf="!account.isWrestler">TrackWrestling Integration</h3>
        <div class="row" *ngIf="!account.isWrestler">
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-label">TrackWrestling username</label>
              <input type="text"
                class="form-control"
                formControlName="twUserName"
                placeholder="Username">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-label">School name in TW</label>
              <input type="text"
                class="form-control"
                formControlName="twSchoolName"
                placeholder="TW School Name">
            </div>
          </div>
        </div> -->

      <h3 class="mb-subhead" *ngIf="!account.isWrestler">Team information</h3>
      <div class="row" *ngIf="!account.isWrestler">
        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label">Video playback</label>
            <div *ngFor="let opt of watchAllVideoOptions" class="form-check">
              <label class="mb-choice-label form-check-label">
                <input
                  class="form-check-input"
                  formControlName="wrestlersWatchAllVideo"
                  type="radio"
                  [value]="opt.value"
                />
                {{ opt.label }}
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label">Lead time for video tags</label>
            <select class="form-control" formControlName="leadTime">
              <option *ngFor="let seconds of leadTimeOptions" [value]="seconds">
                {{ seconds }} seconds
              </option>
            </select>
          </div>
        </div>
      </div>

      <hr class="mb-hr" *ngIf="!account.isWrestler" />

      <div class="row" *ngIf="!account.isWrestler">
        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label">A-Squad scorebook title</label>
            <input
              type="text"
              formControlName="level0Title"
              class="form-control"
              formControlName="level0Title"
              placeholder="A-Squad"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label">B-Squad scorebook title</label>
            <input
              type="text"
              formControlName="level1Title"
              class="form-control"
              formControlName="level1Title"
              placeholder="B-Squad"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label">C-Squad scorebook title</label>
            <input
              type="text"
              formControlName="level2Title"
              class="form-control"
              formControlName="level2Title"
              placeholder="C-Squad"
            />
          </div>
        </div>
      </div>

      <!-- SchoolRecords -->

      <!-- <h3 class="mb-subhead" *ngIf="!account.isWrestler">School Records</h3>
        <div class="row" *ngIf="!account.isWrestler">
          <div class="col-md-12">
            <div class="form-group">
              <p>
                Now you can use MatBoss to track your school records!
                Clicking the button below will take you to a page where you can enter your existing records.
                The system will then continue to track MatBoss data for all years you've been a customer and compare it against the data you've entered.
                As new marks are set, the records will be automatically updated.
                And, as a wrestler nears one of your records, the system will give you an alert so that you will be able to give him/her the recognition he/she deserves right when it happens!
              </p>
              <div>
                <a routerLink="/school-records" class="btn btn-lg btn-primary">School Records</a>
              </div>
            </div>
          </div>
        </div> -->

      <!-- end SchoolRecords -->

      <h3 class="mb-subhead" *ngIf="!account.isWrestler">
        <span style="float: right"
          ><a (click)="addTag()" class="mb-link">Add a tag</a></span
        >Advanced tags
      </h3>
      <div class="row" *ngIf="!account.isWrestler">
        <div class="col-md-4">
          <div class="mb-report-summary">
            <h5 class="mb-report-summary__title">Neutral</h5>
            <table class="table table-striped table-sm">
              <tbody>
                <tr *ngFor="let tag of account.neutralTags">
                  <td>{{ tag.name }} ({{ tag.abbrev }})</td>
                  <td>
                    <a
                      (click)="editTag(tag)"
                      class="btn btn-sm btn-outline-secondary text-uppercase"
                    >
                      <img
                        src="./assets/SVG/UI/Edit.svg"
                        alt="Icon"
                        style="width: 16px; height: 16px"
                      />
                      Edit
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-report-summary">
            <h5 class="mb-report-summary__title">Top</h5>
            <table class="table table-striped table-sm">
              <tbody>
                <tr *ngFor="let tag of account.topTags">
                  <td>{{ tag.name }} ({{ tag.abbrev }})</td>
                  <td>
                    <a
                      (click)="editTag(tag)"
                      class="btn btn-sm btn-outline-secondary text-uppercase"
                    >
                      <img
                        src="./assets/SVG/UI/Edit.svg"
                        alt="Icon"
                        style="width: 16px; height: 16px"
                      />
                      Edit
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-report-summary">
            <h5 class="mb-report-summary__title">Bottom</h5>
            <table class="table table-striped table-sm">
              <tbody>
                <tr *ngFor="let tag of account.bottomTags">
                  <td>{{ tag.name }} ({{ tag.abbrev }})</td>
                  <td>
                    <a
                      (click)="editTag(tag)"
                      class="btn btn-sm btn-outline-secondary text-uppercase"
                    >
                      <img
                        src="./assets/SVG/UI/Edit.svg"
                        alt="Icon"
                        style="width: 16px; height: 16px"
                      />
                      Edit
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <hr class="mb-hr" />

      <div>
        <button class="btn btn-lg btn-primary btn-full">Save changes</button>
      </div>
    </form>
  </div>
</div>
