/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../util/alert.ngfactory";
import * as i4 from "../util/alert";
import * as i5 from "../util/spinner.ngfactory";
import * as i6 from "../util/spinner";
import * as i7 from "./event.component.ngfactory";
import * as i8 from "./event.component";
import * as i9 from "../services/user.service";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
import * as i11 from "../services/mbevent.service";
import * as i12 from "ngx-toastr";
import * as i13 from "./filters.component.ngfactory";
import * as i14 from "./filters.component";
import * as i15 from "../services/event-levels.service";
import * as i16 from "./scorebook.component";
import * as i17 from "../services/nav.service";
var styles_ScorebookComponent = [];
var RenderType_ScorebookComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScorebookComponent, data: {} });
export { RenderType_ScorebookComponent as RenderType_ScorebookComponent };
function View_ScorebookComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "span", [["class", "mb-page__head-links"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "mx-4 mb-page__head-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Add an event"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [["class", "mx-4 mb-page__head-link"], ["preserveQueryParams", ""], ["routerLink", "/scorebook/import"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"], preserveQueryParams: [1, "preserveQueryParams"] }, null), (_l()(), i0.ɵted(-1, null, ["Trackwrestling"])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "a", [["class", "mx-4 mb-page__head-link"], ["preserveQueryParams", ""], ["routerLink", "/scorebook/trashed"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"], preserveQueryParams: [1, "preserveQueryParams"] }, null), (_l()(), i0.ɵted(-1, null, ["Deleted Events"]))], function (_ck, _v) { var currVal_2 = "/scorebook/import"; var currVal_3 = ""; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_6 = "/scorebook/trashed"; var currVal_7 = ""; _ck(_v, 7, 0, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).target; var currVal_1 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = i0.ɵnov(_v, 7).target; var currVal_5 = i0.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_4, currVal_5); }); }
function View_ScorebookComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-alert", [["type", "danger"]], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = ((_co.error = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertComponent_0, i3.RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i4.AlertComponent, [], { message: [0, "message"], type: [1, "type"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; var currVal_1 = "danger"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ScorebookComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-alert", [], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = ((_co.success = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertComponent_0, i3.RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i4.AlertComponent, [], { message: [0, "message"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.success; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ScorebookComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-spinner", [["class", "my-5 p-5 text-center"], ["label", "Loading Scorebook..."], ["style", "display: block; text-align: center;"]], null, null, null, i5.View_SpinnerComponent_0, i5.RenderType_SpinnerComponent)), i0.ɵdid(1, 49152, null, 0, i6.SpinnerComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Loading Scorebook..."; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ScorebookComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" There are no events in this scorebook. "]))], null, null); }
function View_ScorebookComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-event", [["class", "mb-event"]], null, [[null, "eventChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventChanged" === en)) {
        var pd_0 = (_co.onEventChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_EventComponent_0, i7.RenderType_EventComponent)), i0.ɵdid(1, 245760, null, 0, i8.EventComponent, [i9.UserService, i10.NgbModal, i11.MBEventService, i12.ToastrService], { event: [0, "event"] }, { eventChanged: "eventChanged" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ScorebookComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "mb-scorebook__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScorebookComponent_6)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScorebookComponent_7)), i0.ɵdid(4, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.events.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.events; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ScorebookComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "container container--inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "h3", [["class", "mb-page__head"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "mb-page__head-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["alt", "Icon"], ["src", "./assets/SVG/Page/Roster.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Scorebook "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScorebookComponent_1)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "mb-scorebook-filters", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.updateFilters($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_FiltersComponent_0, i13.RenderType_FiltersComponent)), i0.ɵdid(8, 114688, null, 0, i14.FiltersComponent, [i15.EventLevelsService], { user: [0, "user"], params: [1, "params"] }, { submitted: "submitted" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScorebookComponent_2)), i0.ɵdid(10, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScorebookComponent_3)), i0.ɵdid(12, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScorebookComponent_4)), i0.ɵdid(14, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScorebookComponent_5)), i0.ɵdid(16, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.user && _co.user.isMainContact); _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.user; var currVal_2 = _co.params; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = _co.error; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.success; _ck(_v, 12, 0, currVal_4); var currVal_5 = !_co.loaded; _ck(_v, 14, 0, currVal_5); var currVal_6 = _co.loaded; _ck(_v, 16, 0, currVal_6); }, null); }
export function View_ScorebookComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-scorebook", [["class", "mb-page mb-scorebook"]], null, null, null, View_ScorebookComponent_0, RenderType_ScorebookComponent)), i0.ɵdid(1, 245760, null, 0, i16.ScorebookComponent, [i1.ActivatedRoute, i1.Router, i11.MBEventService, i9.UserService, i17.NavService, i10.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScorebookComponentNgFactory = i0.ɵccf("mb-scorebook", i16.ScorebookComponent, View_ScorebookComponent_Host_0, {}, {}, []);
export { ScorebookComponentNgFactory as ScorebookComponentNgFactory };
