<div class="modal-header">
  <h4 class="modal-title">{{ this.scoringEvent.symbol ? 'Edit' : 'Add New' }} Scoring Symbol</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <p>
    To edit a scoring symbol, set the symbol text and color, then move the video playhead to the spot you want to mark.
  </p>

  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <div class="row">
      <div class="form-group col-sm-4">
        <label>Symbol</label>
        <input type="text"
          class="form-control"
          placeholder="T2"
          formControlName="symbol">
        <mb-invalid [form]="form"
          [validate]="validate"
          field="symbol"></mb-invalid>
      </div>
      <div class="form-group col-sm-4">
        <label>Preset Symbol</label>
        <select class="form-control"
          formControlName="presetSymbol">
          <option value=""></option>
          <option *ngFor="let sym of presetSymbolOpts"
            [value]="sym">{{ sym }}</option>
        </select>
        <mb-invalid [form]="form"
          [validate]="validate"
          field="presetSymbol"></mb-invalid>
      </div>
      <div class="form-group col-sm-4">
        <label>Advanced Tags</label>
        <select class="form-control" formControlName="advancedTags">
          <option value=""></option>
          <optgroup *ngFor="let tag of account.neutralTags"
            label="{{ tag.position }} - {{ tag.name }} ({{ tag.abbrev }})">
            <option value="{{ tag.abbrev + 'Succ' }}">{{ tag.abbrev + 'Succ' }}</option>
            <option value="{{ tag.abbrev + 'Fail' }}">{{ tag.abbrev + 'Fail' }}</option>
          </optgroup>
          <optgroup *ngFor="let tag of account.bottomTags"
            label="{{ tag.position }} - {{ tag.name }} ({{ tag.abbrev }})">
            <option value="{{ tag.abbrev + 'Succ' }}">{{ tag.abbrev + 'Succ' }}</option>
            <option value="{{ tag.abbrev + 'Fail' }}">{{ tag.abbrev + 'Fail' }}</option>
          </optgroup>
          <optgroup *ngFor="let tag of account.topTags"
            label="{{ tag.position }} - {{ tag.name }} ({{ tag.abbrev }})">
            <option value="{{ tag.abbrev + 'Succ' }}">{{ tag.abbrev + 'Succ' }}</option>
            <option value="{{ tag.abbrev + 'Fail' }}">{{ tag.abbrev + 'Fail' }}</option>
          </optgroup>
        </select>
        <mb-invalid [form]="form"
          [validate]="validate"
          field="advancedTags"></mb-invalid>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-4">
        <label>Color</label>
        <div class="btn-group btn-group-toggle"
          ngbRadioGroup
          formControlName="color">
          <label ngbButtonLabel class="btn btn-red-switch">
            <input type="radio"
              value="Red"
              ngbButton
              formControlName="color"> Red
          </label>
          <label ngbButtonLabel class="btn btn-green-switch">
            <input type="radio"
              value="Green"
              ngbButton
              formControlName="color"> Green
          </label>
        </div>
        <mb-invalid [form]="form"
          [validate]="validate"
          field="color"></mb-invalid>
      </div>
      <div class="form-group col-sm-4">
        <label>Time</label>
        <input type="text"
          class="form-control"
          placeholder="00:00"
          formControlName="time">
        <mb-invalid [form]="form"
          [validate]="validate"
          field="time"></mb-invalid>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-4">
        <label>Period</label>
        <div class="btn-group btn-group-toggle"
          ngbRadioGroup
          formControlName="period">
          <label *ngFor="let p of periodOptions"
            ngbButtonLabel class="btn btn-secondary">
            <input type="radio"
              [value]="p.value"
              ngbButton
              formControlName="period"> {{ p.title }}
          </label>
        </div>
        <mb-invalid [form]="form"
          [validate]="validate"
          field="period"></mb-invalid>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer d-block">
  <button type="button"
    class="btn btn-primary"
    (click)="submit()">
    Save
  </button>

  <button type="button"
    class="btn"
    (click)="cancel()">
    Cancel
  </button>

  <a (click)="delete()"
    class="mb-link float-right mt-2">Delete</a>
</div>
