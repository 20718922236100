import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core'
import { first } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'

import { Doodle } from '../doodle'
import { MBMatch, MBMatchVideo } from '../../models'
import { MBMatchService } from '../../services'

@Component({
  selector: 'mb-video-toolbar',
  templateUrl: './video-toolbar.component.html'
})
export class VideoToolbarComponent implements OnInit {

  private _doodle: Doodle
  public saving = false
  public switchingColors = false

  @Input() matchVideo: MBMatchVideo
  @Input() public set doodle(d: Doodle) {
    if (!d) return
    this._doodle = d
    if (this._colorEl) this._doodle.colorEl = this._colorEl
  }
  public get doodle(): Doodle {
    return this._doodle
  }

  public fileNotUploadedFlag = false
  public uploadInProgress = false

  private _colorEl: HTMLInputElement
  @ViewChild('colorElem', {static: false}) public set colorElem(el: ElementRef) {
    if (!el) return
    this._colorEl = el.nativeElement as HTMLInputElement
    if (this.doodle) this.doodle.colorEl = this._colorEl
  }

  constructor(
    private matchService: MBMatchService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.matchVideo.notes = this.match.notes
  }

  public get match(): MBMatch {
    return this.matchVideo.match
  }

  public switchColors(): void {
    this.switchingColors = true
    this.matchService.switchColorsByHash(this.match.matchIDHash, this.match.year)
      .pipe(first()).subscribe(
        (r: any) => {
          this.toastr.success('Changes Saved!')
          window.location.reload()
        },
        (e: any) => {
          console.error('Error saving match', e)
          this.toastr.error('Could not save changes')
          this.switchingColors = false
        }
      )
  }

  public saveChanges(): void {
    // playback speed?
    this.saving = true
    const doodleString = this.doodle.createSketchesString()
    this.match.doodle = doodleString
    const data: any = {
      doodle: doodleString
    }

    this.matchVideo.notes = (this.matchVideo.notes || '')
      .replace('&', 'and')
      .replace(/[^a-zA-Z0-9_ ,.-]/g, '')
    this.match.notes = this.matchVideo.notes
    data.notes = this.matchVideo.notes
    if (this.matchVideo.overrideTeamPoints && this.matchVideo.teamPoints) {
      this.match.teamPoints = this.matchVideo.teamPoints
      data.teamPoints = this.matchVideo.teamPoints
      data.overrideTeamPts = true
    }
    const fallTime: string = this.matchVideo.fallTime || ''
    if (fallTime.match(/[0-9]{1,2}:[0-9]{2}/)) {
      this.match.fallTime = fallTime
      data.theResult = this.match.theResult
    }
    this.matchService.editMatch(this.match.matchID, data)
      .pipe(first())
      .subscribe(
        (r: any) => {
          this.toastr.success('Changes Saved!')
          this.saving = false
        },
        (e: any) => {
          console.error('Error saving match', e)
          this.toastr.error('Could not save changes')
          this.saving = false
        })
  }
}
