<footer class="mb-footer">
  <div class="container">
    <!-- <h4 class="row mb-footer__head">
      More on MatBoss
    </h4>

    <div class="row">
      <div class="col-md-4">
        <div class="mb-footer__section">
          <h5>Matboss Blog</h5>
          <div *ngIf="blogs">
            <h6>{{ blogs[0].title }}</h6>
            <div style="max-height: 240px; overflow-y: auto;">{{ blogs[0].description }}</div>
            <a [href]="blogs[0].link" target="_blank">Read More ❯❯</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-footer__section">
          <h5>Matboss Podcast</h5>
          <div *ngIf="podcasts">
            <h6>{{ podcasts[0].title }}</h6>
            <p>{{ podcasts[0].description }}</p>
            <a [href]="podcasts[0].link" target="_blank">Listen Now ❯❯</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-footer__section">
          <h5>InterMatWrestle.com</h5>
          <a href="http://intermatwrestle.com"
            target="_blank"
            style="display: block; margin-bottom: 1rem;">
            <img src="./assets/Img/intermat-logo.png"
              class="logo"
              style="max-width: 100%;"
              alt="Powered By InterMat">
          </a>
          <a href="http://intermatwrestle.com"
            target="_blank"
            style="font-weight: bold">Read Now ❯❯</a>
        </div>
      </div>
    </div> -->

    <div class="row mb-footer__foot">
      <div class="mb-copyright col-md-4">
        <p>&copy; {{ currentYear }} MatBoss.</p>
      </div>
      <div class="col-md-8">
        <ul class="nav justify-content-end">
          <li class="nav-item">
            <a class="nav-link" routerLink="/settings">Settings</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" target="_blank" href="https://help.matboss.com/"
              >Help</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
