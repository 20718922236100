import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { environment } from "../../environments/environment";
import { Observable, of } from "rxjs";
import { map, first, catchError } from "rxjs/operators";
import { UserService } from "./user.service";
import { User } from "../models";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.userService.requestCurrent().pipe(
      first(),
      map((data: any) => {
        let validUser = false;
        let props = data.result;
        if (props) {
          // if we have a migrate flag on the current user response, set the same flag on the user account
          props.migrate = props.migrate || false;
          this.userService.setCurrentFromData(props);
          let user: User = new User(data);

          // console.log("auth guard props: ", props, user.isMainContact);

          // old accounts have the migrate flag:
          if (props.migrate) {
            // @todo find a better way to validate user
            validUser = props.userName || props.teamID;
            // new accounts will have an active flag, and will be assigned some role
          } else if (props.active && props.role) {
            validUser = true;
          }
          // if the user needs complete the season wizard
          const url = route.pathFromRoot
            .map((v) => v.url.map((segment) => segment.toString()).join("/"))
            .join("/");
          const needsSetupWizard =
            user.isMainContact &&
            validUser &&
            props.account.showSetupWizard &&
            !url.startsWith("//setup");
          if (needsSetupWizard) {
            window.location.href = environment.setupUrl;
          }

          // if the user needs to migrate to a new account, force them to the register page
          const needsMigrate = validUser && props.migrate;
          if (needsMigrate) this.router.navigate(["/register"]);
        }
        if (!validUser) this.router.navigate(["/login"]);
        return !!validUser;
      })
    );
  }
}
