import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core'
import { first } from 'rxjs/operators'

import { RosterService } from '../services'
import { Wrestler, User } from '../models'

@Component({
  selector: 'mb-individual-reports',
  templateUrl: './individual-reports.component.html',
})
export class IndividualReportsComponent implements OnInit {
  @HostBinding('attr.style') style = 'display: block'
  @HostBinding('attr.class') class = 'p-3'

  private _year: string
  public loaded = false
  public wrestlers: Wrestler[]
  public enableILReport = false

  @Input() reportType: string
  @Output() reportTypeChange = new EventEmitter()
  @Input() wrestlerID: string
  @Output() wrestlerIDChange = new EventEmitter()
  @Input() teamID: string
  @Input() user: User
  @Input()
  public set year(year: string) {
    this._year = year
    this.findRoster()
  }

  constructor(
    private rosterService: RosterService
  ) { }

  public ngOnInit() {
    this.initILReport()
    this.findRoster()
  }

  private initILReport(): void {
    if (this.user.state === 'IL' &&
      (this.user.ageLevel === 'High School Boys' || this.user.ageLevel === 'High School Girls')
    ) this.enableILReport = true
  }

  private findRoster() {
    if (!this._year) return
    this.loaded = false
    if (this.user && this.user.isWrestler) {
      this.wrestlers = [
        new Wrestler({
          wrestlerID: this.user.userWrestler.wrestlerID,
          firstName: this.user.userFullName
        })
      ]
    }
    else {
      this.rosterService.getRoster(this.teamID, { perPage: 500, year: this._year, status: 'all' })
        .pipe(first())
        .subscribe((data: any) => {
          this.wrestlers = []
          data.result.forEach(item => {
            this.wrestlers.push(new Wrestler(item))
          })
        })
    }
    this.loaded = true
  }

  public onReportTypeChange(): void {
    this.reportTypeChange.emit(this.reportType)
  }

  public onWrestlerIDChange(): void {
    this.wrestlerIDChange.emit(this.wrestlerID)
  }
}

