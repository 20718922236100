<div class="modal-header">
  <h4 class="modal-title">{{ item.ID ? 'Edit' : 'Add'}} Post</h4>
  <button type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<ngb-tabset>
  <!-- link tab -->
  <ngb-tab title="Link">
    <ng-template ngbTabContent>
      <div class="modal-body">
        <form [formGroup]="linkForm" (ngSubmit)="submitLink()" novalidate>
          <div class="form-group">
            <label>Title</label>
            <input
              type="text"
              class="form-control"
              placeholder="Title of post"
              formControlName="description">
            <mb-invalid
              [form]="itemForm"
              [validate]="validate"
              field="description"></mb-invalid>
          </div>
          <div class="form-group">
            <label>URL</label>
            <input
              type="text"
              class="form-control"
              placeholder="https://..."
              formControlName="url">
            <mb-invalid
              [form]="itemForm"
              [validate]="validate"
              message="A valid url is required"
              field="url"></mb-invalid>
          </div>
          <div class="form-group">
            <label>Category</label>
            <select
              class="form-control"
              formControlName="category">
              <option *ngFor="let cat of categories"
                [value]="cat">
                {{ cat }}
              </option>
            </select>
            <mb-invalid
              [form]="itemForm"
              [validate]="validate"
              field="category"></mb-invalid>
          </div>
          <div class="modal-footer d-block">
            <button
              type="button"
              class="btn btn-primary btn-block"
              *ngIf="!submitting"
              (click)="submitLink()">
              Save
            </button>

            <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>

            <div class="mt-4" *ngIf="item.ID && !submitting">
              <a class="mb-link" (click)="delete()">
                Delete Post
              </a>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </ngb-tab>

  <!-- upload tab -->
  <ngb-tab title="Upload" *ngIf="!item.ID">
    <ng-template ngbTabContent>
      <div class="modal-body">
        <form [formGroup]="uploadForm" (ngSubmit)="submitUpload()" novalidate>
          <div class="form-group">
            <label>Title</label>
            <input
              type="text"
              class="form-control"
              placeholder="Title of post"
              formControlName="description">
            <mb-invalid
              [form]="uploadForm"
              [validate]="validate"
              field="description"></mb-invalid>
          </div>

          <div *ngIf="user && !user.isWrestler"
            ng2FileDrop
            [ngClass]="{
              'mb-file-over': hasDropZoneOver && !uploadedFile,
              'mb-file-set': !!uploadedFile
            }"
            (fileOver)="fileDropOver($event)"
            (onFileDrop)="fileDropped($event)"
            [uploader]="uploader"
            class="mb-video__upload form-group">
              <div *ngIf="!uploadedFile">
                <label>File</label>
                <p>Drag and drop a file here, or use the button below:</p>
                <label for="videoFile" class="btn btn-light">Click to Select File</label>
                <mb-invalid
                  [form]="uploadForm"
                  [validate]="validate"
                  field="description"></mb-invalid>
              </div>

              <div *ngIf="!!uploadedFile">
                <label>File</label>
                <p>
                  Selected: {{ uploadedFile.name }}
                </p>
                <div *ngIf="!uploading">
                  <button
                    *ngIf="!uploading"
                    class="btn btn-light"
                    (click)="clearUploads()">
                    Clear selection
                  </button>
                </div>
                <div *ngIf="uploading">
                  <mb-ellipsis-loader *ngIf="uploading" type="inverse"></mb-ellipsis-loader>
                  <div class="progress">
                    <div class="indicator" [style.width]="formattedUploadProgress"></div>
                  </div>
                </div>
              </div>

              <input
                ng2FileSelect
                [uploader]="uploader"
                (onFileSelected)="fileSelected($event)"
                id="videoFile"
                type="file"
                style="display: none">
          </div>

          <div class="form-group">
            <label>Category</label>
            <select
              class="form-control"
              formControlName="category">
              <option *ngFor="let cat of categories"
                [value]="cat">
                {{ cat }}
              </option>
            </select>
            <mb-invalid
              [form]="uploadForm"
              [validate]="validate"
              field="category"></mb-invalid>
          </div>
          <div class="modal-footer d-block">
            <button
              type="button"
              class="btn btn-primary btn-block"
              (click)="submitUpload()">
              Save
            </button>

            <div class="mt-4" *ngIf="item.ID">
              <a class="mb-link" (click)="delete()">
                Delete Post
              </a>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
