<!-- STEP 2 -->
<mb-spinner *ngIf="!loaded" type="ripple-inverse"></mb-spinner>
<div *ngIf="loaded">
  <a (click)="goBack()" class="mb-link" style="color:gainsboro">&lt;&lt; Back</a>
  <h2 class="mb-heading">Step 2 of 3</h2>
  <p class="mb-text">
    Since your school name was not found in the TrackWrestling system, you'll have to import your TW roster and schedule manually later. Click the 'Skip TW' button below to skip the remainder of the TW portion of the setup wizard. An email will also be sent to a MatBoss staff member to let them know that your school name did not appear. They will be contacting you directly via email to remedy the issue.
  </p>
  <form [formGroup]="form">
	  <div class="form-group">
		  <button
			(click)="submitAnswer('next')"
			type="button"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg">
			  Skip TW
		  </button>
		  <mb-ellipsis-loader
			*ngIf="submitting"></mb-ellipsis-loader>
	   </div>
  </form>

</div>
