<!-- STEP 2 -->
<mb-spinner *ngIf="!loaded" type="ripple-inverse"></mb-spinner>
<div *ngIf="loaded">
  <a (click)="goBack()" class="mb-link" style="color:gainsboro">&lt;&lt; Back</a>
  <h2 class="mb-heading">Step 2 of 3</h2>
  <p class="mb-text">
    Confirm your schedule below or click 'Edit' to go back to the schedule import portion of the wizard.
  </p>
  <form [formGroup]="form">
	  <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>

		<table class="table">
		  <thead>
			<tr>
			  <th>Date</th>
			  <th>Level</th>
			  <th>Event Type</th>
			  <th>Event Name</th>
			</tr>
		  </thead>
		  <tbody>
			<tr *ngFor="let event of events">
			  <td>
				{{ event.eventDate | date:'mediumDate' }}
			  </td>
			  <td>
				{{ event.level }}
			  </td>
			  <td>
				{{ event.eventType }}
			  </td>
			  <td>
				{{ event.eventName }}
			  </td>
			</tr>
		  </tbody>
		</table>
	  <div class="form-group">
		  <button
			(click)="submitAnswer('yes')"
			type="button"
			style="margin-right:20px;"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg">
			  Confirm
		  </button>
		  <button
			(click)="submitAnswer('no')"
			type="button"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg">
			  Edit
		  </button>
		  <mb-ellipsis-loader
			*ngIf="submitting"></mb-ellipsis-loader>
	   </div>
  </form>

</div>
