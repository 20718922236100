<div class="row">
  <div class="col-4">
    <h6>Single season / single level</h6>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange()"
        value="teamduals"
        class="form-check-input"
        id="single-dual">
      <label
        class="form-check-label"
        for="single-dual">Dual meet record / results</label>
    </div>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange()"
        value="seasonteamanalysis"
        class="form-check-input"
        id="single-analysis">
      <label
        class="form-check-label"
        for="single-analysis">Team Analysis</label>
    </div>
  </div>
  <div class="col-4">
    <h6>Single season / multiple levels</h6>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange()"
        value="matchcounts"
        class="form-check-input"
        id="multi-matchcounts">
      <label
        class="form-check-label"
        for="multi-matchcounts">Match counts (all levels)</label>
    </div>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange()"
        value="logins"
        class="form-check-input"
        id="multi-logins">
      <label
        class="form-check-label"
        for="multi-logins">Login report</label>
    </div>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange()"
        value="matchlistingbyteam"
        class="form-check-input"
        id="multi-matchlistingbyteam">
      <label
        class="form-check-label"
        for="multi-matchlistingbyteam">Match listing against</label>
    </div>
    <div *ngIf="reportType === 'matchlistingbyteam'"
      class="mt-3">
      <input
        [(ngModel)]="opponentName"
        (ngModelChange)="onOpponentNameChange()"
        type="text"
        class="form-control"
        placeholder="Team name">
    </div>
  </div>
  <div class="col-4">
    <h6>Other</h6>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange()"
        value="highlights"
        class="form-check-input"
        id="other-highlights">
      <label
        class="form-check-label"
        for="other-highlights">Highlights</label>
    </div>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange()"
        value="advancedtagreportteam"
        class="form-check-input"
        id="other-advancedtagreportteam">
      <label
        class="form-check-label"
        for="other-advancedtagreportteam">Advanced tag report</label>
    </div>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange()"
        value="categoryleaders"
        class="form-check-input"
        id="other-categoryleaders">
      <label
        class="form-check-label"
        for="other-categoryleaders">Top 15 in categories</label>
    </div>
  </div>
</div>
