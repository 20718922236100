import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil, first } from 'rxjs/operators'

import { UserService } from '../services'
import { User, UserInvite } from '../models'
import { CustomValidators } from '../util/forms/custom-validators'

@Component({
  selector: 'mb-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit, OnDestroy {
  public form: FormGroup
  public submitting: boolean = false
  public error: string
  public success: string = ''
  public validate: boolean = false
  public passwordMessages = {
    minlength: 'Password must be at least 8 characters',
    pattern: 'Please use numbers and letters only'
  }
  private unsubscribe: Subject<any> = new Subject()
  public showIntro: boolean = false
  public isMigration: boolean = false
  public inviteInfo: UserInvite|null = null
  private inviteCode: string|null = null
  private user: User|null = null

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  public ngOnInit() {
    this.createForm()
    this.route.queryParams
    .subscribe(params => {
        if ( params.invite && params.invite !== '' ) {
          this.inviteCode = params.invite.trim()

          // pull in information from the invite code
          this.userService.requestInviteInfo(params.invite)
            .pipe(first())
            .subscribe(
              (data: any) => {
                if ( data.success && data.result ) {
                  this.inviteInfo = new UserInvite(data.result)
                  if ( this.form.get('first_name').value === '' ) {
                    this.form.patchValue({ first_name: data.result.first_name })
                  }
                  if ( this.form.get('last_name').value === '' ) {
                    this.form.patchValue({ last_name: data.result.last_name })
                  }
                  if ( this.form.get('email').value === '' ) {
                    this.form.patchValue({ email: data.result.email })
                  }
                } else {
                  this.router.navigate(['login'], { queryParams: { invalidInvite: 1 } });
                }
              },
              (err: any) => {
                this.router.navigate(['login'], { queryParams: { invalidInvite: 1 } });
              }
            )
        } else {
          this.router.navigate(['login'], { queryParams: { invalidInvite: 1 } });
        }

      }
    )

    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        // console.log('migrate', this.user)
        if ( user.migrate || this.router.url === '/migrate' ) {
          this.showIntro = true
          this.isMigration = true
          this.user = user
          
        }
      })
  }

  public ngOnDestroy() {
    this.destroy()
  }

  private destroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  public continue() {
    this.showIntro = false
  }

  public submit() {
    this.error = null

    if (!this.form.valid) {
      this.validate = true
      return false
    }
    this.submitting = true
    this.userService.createUser(this.formValue)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.submitting = false
          if (data.success) {
            this.setSuccess('Member profile created!')
            // redirect to login with a migration notice flag set
            this.router.navigate(['login'], { queryParams: { registered: 1 } });
          }
          else {
            this.setError('Unable to create new member profile: ' + (data || {}).message)
          }
        },
        (err: any) => {
          this.submitting = false
          if ( err.error ) {
            if ( err.error.errors && err.error.errors.email ) {
              this.setError('A profile with this email address already exists. Please <a href="/#/login" class="mb-link">login</a> with this email address to continue!')
            } else {
              this.setError(err.error.message + ' ' + Object.values(err.error.errors).map((v: any) => v.join(', ')))
            }
          } else {
            this.setError('An error was encountered while creating new member profile. Please try again!')
          }
        }
      )
  }

  private get formValue(): any {
    return {
      first_name: this.form.get('first_name').value.trim(),
      last_name: this.form.get('last_name').value.trim(),
      email: this.form.get('email').value.trim(),
      password: this.form.get('password').value.trim(),
      password_confirmation: this.form.get('confirmPassword').value.trim(),
      inviteCode: this.inviteCode,
    }
  }

  private createForm() {
    const confirmValidators: any[] = [Validators.required]
    this.form = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^[a-zA-Z0-9]+$/)
      ]],
      confirmPassword: [''],
      inviteCode: ['', [CustomValidators.inviteCodeValidator()]]
    })
    confirmValidators.push(
      CustomValidators.confirmValidator(this.form.get('password'))
    )
    this.form.get('confirmPassword')
      .setValidators(confirmValidators)
  }

  private setSuccess(msg: string): void {
    this.success = msg
    this.error = null
  }

  private setError(e: string): void {
    this.success = null
    this.error = e
    window.scroll(0,0)
  }

  public get isInvite(): boolean {
    return this.isMigration || (this.inviteCode !== '' && this.inviteInfo != null)
  }

  public get inviteOrganization(): string|null {
    if ( this.isMigration ) {
      return `${this.user.schoolName}, ${this.user.state}`
    } else if ( this.isInvite ) {
      return `${this.inviteInfo.account.schoolName}, ${this.inviteInfo.account.state}`
    }
    return null
  }

  public get inviteAgeLevel(): string|null {
    if ( this.isMigration ) {
      return this.user.ageLevel
    } else if ( this.isInvite ) {
      return this.inviteInfo.account.age
    }
    return null
  }

  public get inviteLevel(): string|null {
    if ( this.isMigration ) {
      return this.user.userType === 'main' ? 'Coach' : 'Wrestler'
    } else if ( this.isInvite ) {
      return this.inviteInfo.limitedRoleInfo ? this.inviteInfo.limitedRoleInfo.label : 'Unknown'
    }
    return null
  }
}
