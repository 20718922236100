import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { first } from 'rxjs/operators'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'

import { MBMatch } from '../../models'
import { MBMatchService } from '../../services'

@Component({
  selector: 'mb-edit-wt-class',
  templateUrl: './edit-wt-class.component.html'
})
export class EditWtClassComponent implements OnInit {

  @Input() match: MBMatch

  public form: FormGroup
  public validate = false
  public submitting = false

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public matchService: MBMatchService,
    private toastr: ToastrService
  ) { }

  public ngOnInit() {
    this.createForm()
  }

  public submit() {
    if (!this.form.valid) {
      this.validate = true
      return
    }

    this.submitting = true
    this.matchService.editMatch(this.match.matchID, {
        theWeightClass: this.form.get('weightClass').value
      })
      .pipe(first())
      .subscribe(
        (r: any) => {
          this.toastr.success('Weight Class Saved!')
          this.match.theWeightClass = this.form.get('weightClass').value
          this.activeModal.close({
            success: true,
            result: this.match
          })
        },
        (e: any) => {
          console.error('Error saving weight class', e)
          this.toastr.error('Could not save changes')
          this.submitting = false
        })
  }

  public cancel() {
    this.activeModal.dismiss('Cross click')
  }

  // PRIVATE

  private createForm() {
    this.form = this.fb.group({
      weightClass: [
        this.match.theWeightClass,
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z0-9- ]{1,10}$/)
        ]
      ]
    })
  }
}
