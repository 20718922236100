<div class="container">

  <div class="mb-breadcrumbs py-3">
    <a preserveQueryParams routerLink="/settings">‹ Back To Settings</a>
  </div>

  <h3 class="mb-page__head">
    <span class="mb-page__head-title">
      <img src="./assets/Img/Trophy.png" width="36" height="36" alt="Icon">
      School Records
    </span>
  </h3>

  <p>
    Enter your existing records below, the system will then continue to track MatBoss data for all years you've been a customer and compare it against the data you've entered.
    As new marks are set, the records will be automatically updated.
    And, as a wrestler nears one of your records, the system will give you an alert so that you will be able to give him/her the recognition he/she deserves right when it happens!
</p>

  <mb-spinner *ngIf="!loaded"
    label="Loading School Records..."
    class="my-5 p-5 text-center"></mb-spinner>

  <div *ngIf="loaded">
    <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>
    <div class="row">
      <div *ngFor="let category of categories; index as i" class="col-sm-6">
        <div class="mb-report-heading">
          <span class="mb-report-heading__step">{{ i + 1 }}</span>
          {{ category.title }}
        </div>
        <div class="table-responsive">
          <table class="table table-sm">
            <thead class="thead-dark">
              <tr>
                <th width="36">&nbsp;</th>
                <th width="36">Rank</th>
                <th>Wrestler</th>
                <th>{{ category.shortTitle }}</th>
                <th>Year</th>
                <th width="72">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let rank of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14]">
                <td>
                  <button *ngIf="!isEditing(category, rank)" class="btn" (click)="edit(category, rank)">+</button>
                  <button *ngIf="isEditing(category, rank)" class="btn" (click)="cancelEdit(category, rank)">x</button>
                </td>
                <td>{{ rank + 1 }}</td>
                <td>
                  <span *ngIf="!isEditing(category, rank)">
                    {{ rankName(category.key, rank) }}
                  </span>
                  <span *ngIf="isEditing(category, rank)">
                    <input class="form-control" type="text" style="padding-left: 6px"
                      [(ngModel)]="recordsByCategory[category.key][rank]['name']">
                  </span>
                </td>
                <td>
                  <span *ngIf="!isEditing(category, rank)">
                    {{ rankRecord(category.key, rank) }}
                  </span>
                  <span *ngIf="isEditing(category, rank)">
                    <input class="form-control" type="number"
                      style="padding-left: 6px; width: 80px;"
                      [(ngModel)]="recordsByCategory[category.key][rank]['record']"
                      step="{{ category.type === 'percent' ? 0.01 : 1 }}"
                      min="0" max="{{ category.type === 'percent' ? 100 : 9999 }}">
                  </span>
                </td>
                <td>
                  <span *ngIf="!isEditing(category, rank)">
                    {{ rankYear(category.key, rank) }}
                  </span>
                  <span *ngIf="isEditing(category, rank)">
                    <select class="form-control"
                      style="padding-left: 2px"
                      [(ngModel)]="recordsByCategory[category.key][rank]['year']"
                    >
                      <option *ngFor="let yr of years" [value]="yr.value">{{ yr.label }}</option>
                    </select>
                  </span>
                </td>
                <td>
                  <button
                    *ngIf="isEditing(category, rank)"
                    [disabled]="submitting"
                    class="btn btn-primary"
                    (click)="save(category, rank)">Save</button>
                  <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div *ngIf="!(i % 2)" class="w-100"></div>

      <div class="mb-details-link">
        <a [href]="pdfUrl" target="_blank"
          class="btn btn-lg btn-primary">PDF</a>
      </div>
    </div>
  </div>
</div>
