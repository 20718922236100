<!-- STEP 2 -->
<mb-spinner *ngIf="!loaded" type="ripple-inverse"></mb-spinner>
<div *ngIf="loaded">
  <a (click)="goBack()" class="mb-link" style="color:gainsboro">&lt;&lt; Back</a>
  <h2 class="mb-heading">Step 2 of 3</h2>
  <p class="mb-text">
    Confirm your roster below or click 'Edit' to go back to the roster import portion of the wizard.
  </p>
  <form [formGroup]="form">
	  <table class="table">
      <thead>
        <tr>
          <th>Last Name</th>
          <th>First Name</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let w of wrestlers">
          <td>{{ w.lastName }}</td>
          <td>{{ w.firstName }}</td>
        </tr>
      </tbody>
    </table>
	  <div class="form-group">
		  <button
			(click)="submitAnswer('yes')"
			type="button"
			style="margin-right: 20px;"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg">
			  Confirm
		  </button>
		  <button
			(click)="submitAnswer('no')"
			type="button"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg">
			  Edit
		  </button>
		  <mb-ellipsis-loader
			*ngIf="submitting"></mb-ellipsis-loader>
	   </div>
  </form>

</div>
