<div class="modal-header">
  <h4 class="modal-title">Add Match</h4>
  <button type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <div class="form-group">
      <label>Date <sup>*</sup></label>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          formControlName="theDate"
          ngbDatepicker
          placeholder="YYYY-MM-DD"
          (focus)="d.open()"
          #d="ngbDatepicker">
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="d.toggle()"
            type="button"></button>
        </div>
      </div>
      <mb-invalid [form]="form"
        [validate]="validate"
        field="theDate"></mb-invalid>
    </div>

    <div class="form-group">
      <label>Weight Class <sup>*</sup></label>
      <input type="text"
        class="form-control"
        placeholder="106"
        formControlName="theWeightClass">

      <mb-invalid [form]="form"
        [validate]="validate"
        field="theWeightClass"></mb-invalid>
    </div>

    <div class="form-group">
      <label>Our Wrestler <sup>*</sup></label>
      <div class="input-group">
        <div
          class="input-group-append"
          [ngClass]="{'mb-red': ourRed, 'mb-green': !ourRed}"
          style="width: 24px;">
          &nbsp;
        </div>
        <select
          class="form-control"
          formControlName="ourWrestlerID">
          <option
            [value]="undefined"
            disabled >- Choose Wrestler -</option>
          <option *ngFor="let w of wrestlers"
            [value]="w.wrestlerID.toString()">
            {{ w.fullName }}
          </option>
        </select>
      </div>
      <mb-invalid [form]="form"
        [validate]="validate"
        field="ourWrestlerID"></mb-invalid>
    </div>

    <div class="form-group">
      <label>Opponent Wrestler <sup>*</sup></label>
      <div class="input-group">
        <div
          class="input-group-append"
          [ngClass]="{'mb-red': !ourRed, 'mb-green': ourRed}"
          style="width: 24px;">
          &nbsp;
        </div>
        <input type="text"
          class="form-control"
          placeholder="Doe, John (opponent school)"
          formControlName="opponent">
      </div>
      <mb-invalid [form]="form"
        [validate]="validate"
        field="opponent"></mb-invalid>
    </div>

    <div class="form-group">
      <span
        class="mb-link"
        style="font-size: 80%"
        (click)="switchColors()">switch colors</span>
    </div>
  </form>
</div>

<div class="modal-footer d-block">
  <button *ngIf="!submitting"
    type="button"
    class="btn btn-primary btn-block"
    (click)="submit()">
    Save
  </button>
  <mb-ellipsis-loader
    *ngIf="submitting"></mb-ellipsis-loader>
</div>
