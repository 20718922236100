import { Component, Input, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { MBEvent, User } from '../models'
import { UserService, EventLevelsService } from '../services'
import { MBEventLevel } from '../util'

@Component({
  selector: 'mb-event-boxscore',
  templateUrl: './boxscore.component.html',
  host: { class: 'mb-event__boxscore', style: 'display: block' }
})
export class BoxscoreComponent implements OnInit {

  @Input() event:MBEvent

  public user: User
  public levelOptions: MBEventLevel[]
  public loaded = false
  private unsubscribe: Subject<any> = new Subject()

  constructor(
    private userService: UserService,
    private eventLevelsService: EventLevelsService
  ) { }

  /**
   * set teamID from user.ID
   * @todo determin if wrestlers and coaches should see the same things
   */
  public ngOnInit() {
    this.loadEventLevels()
    this.loadUser()
  }

  /**
   * unsubscribe to prevent memory leaks
   */
  public ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  public displayLevel(level: string): string {
    const lvs = this.levelOptions.filter(opt => opt.levelID === level)
    return lvs && lvs.length ? lvs[0].label : ''
  }

  private ready(): void {
    if (this.levelOptions && this.user) this.loaded = true
  }

  private loadUser(): void {
    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        this.user = user
        this.ready()
      })
  }

  private loadEventLevels(): void {
    this.eventLevelsService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(levels => {
        this.levelOptions = levels
        this.ready()
      })
  }
}
