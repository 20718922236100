import { Injectable } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'

import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  constructor(
    private apiService: ApiService
  ) { }

  public findAll(data: {
    teamID: string,
    per_page?: number,
    page?: number
  }): Observable<any> {
    let params = new HttpParams()
      .set('teamID', data.teamID)
      .set('per_page', (data.per_page || 100).toString())
      .set('page', (data.page || 0).toString())

    return this.apiService.get('library', { params: params })
  }

  public addItem(data: any): Observable<any> {
    return this.apiService.post('library', data)
  }

  public editItem(id: string, data: any): Observable<any> {
    data.rowID = id
    return this.apiService.update('library', data)
  }

  public deleteItem(id: string): Observable<any> {
    return this.apiService.delete('library?rowID=' + encodeURIComponent(id))
  }
}
