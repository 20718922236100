import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

export class NavItem {

  public ID: string
  public name: string
  public path: string

  constructor(data: any) {
    Object.assign(this, data)
  }
}

@Injectable()
export class NavService {
  private dummy: NavItem = new NavItem({})
  private subject: BehaviorSubject<NavItem> = new BehaviorSubject<NavItem>(this.dummy)
  public current: Observable<NavItem> = this.subject.asObservable()

  constructor() { }

  /**
   * sets current from plain javascript object
   * @param data
   */
  public setCurrentFromData(data: any) {
    const nav: NavItem = new NavItem(data)
    this.setCurrent(nav)
  }

  /**
   * sets current from obejct
   * @param nav
   */
  public setCurrent(nav: NavItem) {
    this.subject.next(nav)
  }
}
