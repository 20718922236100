import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { ApiService } from './api.service'
import { MBEvent } from '../models'

@Injectable({
  providedIn: 'root'
})
export class TwService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Get TW roster
   * @param teamID
   */
  public getRoster(teamID: string): Observable<any> {
    const params: any = { teamID: teamID, per_page: '500' }
    return this.apiService.get('trackwrestlingroster', { params: params })
  }

  /**
   * get TW info
   * @param teamID
   */
  public getInfo(teamID: string): Observable<any> {
    const params: any = { teamID: teamID, per_page: '500' }
    return this.apiService.get('trackwrestling', { params: params })
  }

  /**
   * get TW shcedule
   * @param teamID
   */
  public getSchedule(teamID: string): Observable<any> {
    const params: any = { teamID: teamID, per_page: '500' }
    return this.apiService.get('trackwrestlingschedule', { params: params })
  }

  /**
   * get TW States
   * @param none
   */
  public getTWStates(): Observable<any> {
    const params: any = { per_page: '500' }
    return this.apiService.post('trackwrestlingstates', { params: params })
  }

  /**
   * get TW States
   * @param none
   */
  public getTWSeasons(): Observable<any> {
    const params: any = { per_page: '500' }
    return this.apiService.post('trackwrestlingseasons', { params: params })
  }

  /**
   * get TW Team List
   * @param seasonID, teamID
   */
  public getTWTeamList(seasonID: string, stateID: string): Observable<any> {
    const params: any = { seasonID: seasonID, stateID: stateID, per_page: '500' }
    return this.apiService.get('trackwrestlingseasonteams', { params: params })
  }

  /**
   * export (fka sync)
   * @param teamID
   */
  public export(
    event: MBEvent,
    userName: string,
    password: string,
    otherTeamStateAbbreviation?: string,
    exportExhibitions: boolean = true
  ): Observable<any> {
    // eventID, userName (this is their TrackWrestling userName),
    // password (this is their TrackWrestling password),
    // (two-letter abbreviation for the opposing school)
    // exporttournament - eventID, userName, password
    if (event.isDualMeet) return this.exportDual(event, userName, password, otherTeamStateAbbreviation, exportExhibitions)
    else if (event.isTournament) return this.exportTournament(event, userName, password, exportExhibitions)
  }

  private exportDual(
    event: MBEvent,
    userName: string,
    password: string,
    otherTeamStateAbbreviation: string,
    exportExhibitions: boolean = true
  ): Observable<any> {
    return this.apiService.post('exportdual', {
      eventID: event.eventID,
      otherTeamStateAbbreviation: otherTeamStateAbbreviation,
      userName: userName,
      password: password,
      exportExhibitions: exportExhibitions
    })
  }

  private exportTournament(
    event: MBEvent,
    userName: string,
    password: string,
    exportExhibitions: boolean = true
  ): Observable<any> {
    return this.apiService.post('exporttournament', {
      eventID: event.eventID,
      userName: userName,
      password: password,
      exportExhibitions: exportExhibitions
    })
  }
  // The endpoints are done and tested. They are these two routes:
  // $router->post('exportdual', 'TrackWrestlingController@exportdual');
  // $router->post('exporttournament', 'TrackWrestlingController@exporttournament');
  // exportdual -
  //   eventID,
  //   userName (this is their TrackWrestling userName),
  //   password (this is their TrackWrestling password),
  //   otherTeamStateAbbreviation (two-letter abbreviation for the opposing school)
  // exporttournament - eventID, userName, password
}
