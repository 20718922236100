<mb-spinner *ngIf="!loaded"></mb-spinner>
<div *ngIf="loaded" class="row">
  <div class="col-4">
    <h6>Choose wrestler</h6>
    <select
      [(ngModel)]="wrestlerID"
      (ngModelChange)="onWrestlerIDChange()"
      class="form-control">
      <option
        [ngValue]="undefined"
        disabled selected> - Choose Wrestler - </option>
      <option
        value="all">ALL WRESTLERS</option>
      <option
        *ngFor="let wrestler of wrestlers"
        [value]="wrestler.wrestlerID">{{ wrestler.fullName }}</option>
    </select>
  </div>
  <div class="col-6">
    <h6>Choose report</h6>
    <div *ngIf="user && !user.isWrestler"
      class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange($event)"
        value="individualmatchsummary"
        class="form-check-input"
        id="indi-individualmatchsummary">
      <label
        class="form-check-label"
        for="indi-individualmatchsummary">Individual Match Summary</label>
    </div>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange($event)"
        value="individualanalysis"
        class="form-check-input"
        id="indi-individualanalysis">
      <label
        class="form-check-label"
        for="indi-individualanalysis">Individual Analysis <sup>*</sup></label>
    </div>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange($event)"
        value="advancedtagreportindividual"
        class="form-check-input"
        id="indi-advancedtagreportindividual">
      <label
        class="form-check-label"
        for="indi-advancedtagreportindividual">Individual Advanced Tag Report</label>
    </div>
    <div class="form-check my-1"
      *ngIf="enableILReport">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange($event)"
        value="individualseasonsummaryillinois"
        class="form-check-input"
        id="indi-individualseasonsummaryillinois">
      <label
        class="form-check-label"
        for="indi-individualseasonsummaryillinois">Season Match Summary (Illinois)</label>
    </div>
  </div>
</div>
