import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Subject } from 'rxjs'
import { first } from 'rxjs/operators'

import { UserService } from '../services'

@Component({
  selector: 'mb-password',
  templateUrl: './password.component.html'
})
export class PasswordComponent implements OnInit {
  public form: FormGroup
  public error: string
  public success: string
  public validate = false
  public submitting = false

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router
  ) { }

  public ngOnInit() {
    this.createForm()
  }

  public submit() {
    this.error = null
    if (!this.form.valid) {
      this.validate = true
      return false
    }
    this.submitting = true
    this.userService.recoverPassword(
      this.form.get('email').value
    )
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.submitting = false
          if (data.status) this.setSuccess(data.status)
          else this.setError()
        },
        (err: any) => {
          this.submitting = false
          if ( err.error ) {
            this.setError(err.error.message + ' ' + Object.values(err.error.errors).map((v: any) => v.join(', ')))
          } else {
            this.setError()
          }
        }
      )
  }

  private setSuccess(message:string = 'An email has been sent to you with instructions for resetting your password.') {
    this.error = null
    this.success = message
  }

  private setError(message:string = 'We could not find your account.') {
    this.success = null
    this.error = message
  }

  private createForm() {
    this.form = this.fb.group({
      email: ['', Validators.required]
    })
  }
}
