import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { first } from 'rxjs/operators'

import { User } from '../../models'
import { UserService } from '../../services';

@Component({
  selector: 'mb-setup12',
  templateUrl: './setup12.component.html'
})
export class Setup12Component implements OnInit {

  @Input() account: User

  public form: FormGroup
  public loaded = false
  public submitting = false
  public validate = false
  public messages = {
	required: 'This field is required',
    pattern: 'Must be 8-24 characters, and use letters, numbers or underscores only',
	'nomatch': 'Password and confirm password don\'t match'
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.createForm()
  }

  public submit(): void {
	  if (!this.form.valid) {
        this.validate = true
        return
      }

	  const pw: string = this.form.value.password
	  const data: any = {
        password: pw
      }
	  this.submitting = true

      this.loaded = false
      this.userService.update(this.account.userName, this.account.userType, data)
        .pipe(first())
        .subscribe(
          //(data: any) => this.setUser(data),
          //(e: any) => { console.warn('error', e) }
		  (data: any) => this._submit()
        )
  }

  private _submit(): void {
	if (this.account.ageLevel === 'Middle School' || this.account.ageLevel === 'Youth') {
		// navigate past the TW stuff automatically
		this.router.navigate(['/setup/13'])
	} else {
		this.router.navigate(['/setup/3'])
	}
  }

  public goBack() {
    window.history.back()
  }

  // private

  private createForm(): void {
    this.form = this.fb.group({
		userName: [this.account.userName],
		password: ['', [
          Validators.required,
		  Validators.pattern('^[A-Za-z0-9_]{8,24}$')
        ]],
		confirmpassword: ['', [
          Validators.required,
		  Validators.pattern('^[A-Za-z0-9_]{8,24}$')
        ]]
	}, {validator: this.checkPasswords})
    this.loaded = true
  }

  private checkPasswords(group: FormGroup): boolean { // here we have the 'passwords' group
  let pass = group.get('password').value
  let confirmPass = group.get('confirmpassword').value
  if (pass === confirmPass) {
	  group.controls['confirmpassword'].setErrors(null)
  } else {
	  group.controls['confirmpassword'].setErrors({'nomatch': true})
  }
  //return pass === confirmPass ? true : false
  return true
}
}
