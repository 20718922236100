export class ReportSection {
  title: string
  columnHeadings: string[]
  rows: string[][]

  public constructor(data: any) {
    this.title = data.title
    this.columnHeadings = data.columnHeadings
    this.rows = data.rows
  }
}

export class Report {
  public title: string
  public subtitle: string
  public sections: ReportSection[]
  public subreports: Report[]

  public constructor(data: any) {
    this.title = data.title
    this.subtitle = data.subtitle
    this.sections = []
    this.subreports = []
    if ( data.sections ) {
      data.sections.forEach(s => this.sections.push(new ReportSection(s)))
    }
    if ( data.subreports ) {
      data.subreports.forEach(s => this.subreports.push(new Report(s)))
    }
  }
}
