<div class="mb-video mb-video-preview">

  <div class="mb-breadcrumbs">
    <a style="cursor: pointer;"
      (click)="closePreview()">‹ Back</a>
  </div>

  <mb-spinner *ngIf="!loaded"
    label="Loading Video..."
    class="my-5 p-5 text-center"></mb-spinner>

  <div *ngIf="loaded">
    <div class="container-fluid">
      <div class="row justify-content-center">

        <div class="col-sm-7">
          <mb-video-playback
            [matchVideo]="matchVideo"
            [startTime]="startTime"
            [doodle]="doodle"></mb-video-playback>
        </div>

        <div class="col-sm-2">
          <mb-video-highlights
            [highlight]="highlight"
            [matchVideo]="matchVideo"></mb-video-highlights>

          <mb-video-options
            [matchVideo]="matchVideo"
            [doodle]="doodle"
            [user]="user"></mb-video-options>
        </div>
      </div>
    </div>
  </div>

</div>
