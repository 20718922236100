import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { first, takeUntil } from 'rxjs/operators'
import { MBEvent, User } from '../models';
import { MBEventIndexParams, MBEventService, UserService } from '../services';
import { EVENT_YEAR_OPTIONS } from '../util';
import { ScorebookFilters } from './'

@Component({
  selector: 'mb-scorebook-trashed',
  templateUrl: './scorebook-trashed.component.html',
  host: {'class': 'mb-page mb-scorebook'}
})
export class ScorebookTrashedComponent implements OnInit, OnDestroy {
  public trashedEvents: Array<MBEvent>
  public loaded = false
  public error = ''
  public success = ''
  public user: User
  private teamID: string
  private levelID: string
  public year: string = EVENT_YEAR_OPTIONS[0]
  private sort: string
  private timeRange: string
  public params: any
  private unsubscribe: Subject<any> = new Subject()

  constructor(
    private acRoute: ActivatedRoute,
    private router: Router,
    private eventService: MBEventService, 
    private userService: UserService) { }

  ngOnInit() {
    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        this.user = user
        this.teamID = user.teamID
      })
    this.initQueryParams()
  }

  /**
   * unsubscribe to prevent memory leaks
   */
   public ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  private initQueryParams(): void {
    this.acRoute.queryParams
      .pipe(first())
      .subscribe((params: any) => {
        this.params = params || { }
      })
  }

  public onEventRestored(e: boolean): void {
    if (e) {
      this.setSuccess('The event has been restored');
    } else {
      this.setError('Oops something went wrong and we could not restore the event. Please try again later.')
    }
    this.findTrashedEvents();
  }

  /**
   * This is a listener for the filters.submitted event
   * which is called from filters.component when
   * filters have changed. This triggers a new search.
   * @param filters
   */
   public updateFilters(filters: ScorebookFilters) {
    this.loaded = false
    this.levelID = filters.levelID
    this.year = filters.year
    this.timeRange = filters.timeRange
    this.sort = filters.sort
    this.findTrashedEvents()
  }

  private findTrashedEvents() {
    this.loaded = false
    let params: MBEventIndexParams = {
      teamID: this.teamID,
      levelID: this.levelID,
      year: this.year,
      sortDir: this.sort,
      includeDeletedEvents: 'true'
    }

    if (this.timeRange === 'past' || this.timeRange === 'upcoming') {
      params.filter = this.timeRange
    }

    const queryParams = Object.keys(params).reduce((c, i) => {
      if (i !== 'teamID' && !!params[i] && params[i] !== 'null') {
        c[i] = params[i]
      }
      return c
    }, { })

    this.eventService.findAll(params)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.trashedEvents = []
          if (data.result) {
            data.result.forEach((item: MBEvent) => {
              if (item.isDeleted == 1) {
                this.trashedEvents.push(new MBEvent(item))
              }
            })
            this.router.navigate([], {
              relativeTo: this.acRoute,
              queryParams,
            })
            this.loaded = true
          } else {
            throw new Error('Invalid response')
          }
        }
      )
  }

  private setError(message: string, obj?: any) {
    this.success = null
    this.error = message
    console.warn('Error: ' + message, obj)
    window.scroll(0, 0)
  }

  private setSuccess(message: string, obj?: any) {
    this.error = null
    this.success = message
    if (obj) console.warn('Success', obj)
    window.scroll(0, 0)
  }

}
