<div class="modal-header">
  <h4 class="modal-title">Email your roster</h4>
  <button type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <div class="form-group">
      <label>Recipients</label>
      <input class="form-control"
        formControlName="recipients"
        placeholder="user@example.com">
      <div class="mb-input-help">Separate email addresses with a comma</div>
      <mb-invalid
        [form]="form"
        [validate]="validate"
        field="recipients"></mb-invalid>
    </div>

    <div class="form-group">
      <label>Message - <span>Optional</span></label>
      <textarea class="form-control"
        formControlName="message"></textarea>
      <mb-invalid
        [form]="form"
        [validate]="validate"
        field="message"></mb-invalid>
    </div>
  </form>
</div>

<div class="modal-footer d-block">
  <button *ngIf="!submitting" type="button"
    class="btn btn-primary btn-block"
    (click)="submit()">
    Send
  </button>
  <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
</div>
