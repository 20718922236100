export const states: string[] = [
  'AL', 'AK', 'AR', 'AZ',
  'CA', 'CO', 'CT',
  'DC', 'DE',
  'FL',
  'GA',
  'HI',
  'IA', 'ID', 'IL', 'IN',
  'KS', 'KY',
  'LA',
  'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT',
  'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY',
  'OH', 'OK', 'OR',
  'PA', 'PR',
  'RI',
  'SC', 'SD',
  'TN', 'TX',
  'UT',
  'VA', 'VT',
  'WA', 'WI', 'WV', 'WY'
]
