import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { forkJoin } from 'rxjs'
import { first } from 'rxjs/operators'

import { MBEventService, TwService } from '../services'
import { MBEvent, TwEvent, User } from '../models'
import { environment } from '../../environments/environment'

@Component({
  selector: 'mb-setup4',
  templateUrl: './setup4.component.html'
})
export class Setup4Component implements OnInit {

  @Input() account: User
  @Input() next = '/setup/13'
  @Input() showSteps = true

  public YEAR = environment.currentYear

  public events: MBEvent[]
  public eventImport: any = {}
  public loaded: boolean = false
  public submitting = false
  public twEvents: TwEvent[]
  public validate = false

  constructor(
    private eventService: MBEventService,
    private router: Router,
    private twService: TwService
  ) { }

  ngOnInit() {
    this.findEvents()
  }

  public goBack(): void {
    window.history.back()
  }

  public submit(): void {
    this.submitting = true
    const stream: any[] = []
    Object.keys(this.eventImport).forEach(k => {
      if (!this.eventImport[k]) {
        return
      }
      const twevent: TwEvent = this.twEvents
        .filter(twe => twe.twid === k)
        .shift()

      const lvl = this.getLevelConstant(twevent)

      stream.push(
        this.eventService.addEvent(this.account.teamID, {
          eventType: twevent.eventType,
          eventName: twevent.eventName,
          eventDate: twevent.eventDate,
          scorebookID: this.makeScorebookID(twevent),
          location: twevent.location,
          twid: twevent.twid
        }, lvl)
      )
    })

    if (!stream.length) {
      this.router.navigate([this.next], { preserveQueryParams: true })
      return
    }

    forkJoin(stream)
      .subscribe((data: any[]) => {
        // @todo verify???
        this.router.navigate([this.next], { preserveQueryParams: true })
      },
      (err: any) => {
        // @todo
        console.warn('error', err)
        this.router.navigate([this.next], { preserveQueryParams: true })
      })
  }

  /**
   * find events from tw
   */
  private findEvents(): void {
    this.twService.getSchedule(this.account.teamID)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.twEvents = []
          if (data.success && data.result) {
            let twe: TwEvent
            data.result.forEach(item => {
              twe = new TwEvent(item.event)
              this.twEvents.push(twe)
              // preselect all events
              this.eventImport[twe.twid] = true
            })
            this.loaded = true
          }
          //else throw new Error('Invalid response')
		  else this.loaded = true
        }
      )
	this.loaded = true
  }

  private makeScorebookID(twevent: TwEvent): string {
    const twLevelMap: any = {
      Var: '0',
      JV: '1',
      Freshmen: '2',
      Soph: '2',
      Exhibition: '3'
    }
    const lvl: string = twLevelMap[twevent.squadLevel] || '0'
    return this.account.teamID + ':' + this.YEAR + ':' + lvl
  }

  private getLevelConstant(twevent: TwEvent): string {
    const twLevelMap: any = {
      Var: '0',
      JV: '1',
      Freshmen: '2',
      Soph: '2',
      Exhibition: '3'
    }
    const lvl: string = twLevelMap[twevent.squadLevel] || '0'
    return lvl
  }

  public checkAll(ev) {
    this.twEvents.forEach(x => this.eventImport[x.twid] = ev.target.checked)
  }
  
  public isAllChecked() {
    return this.twEvents && this.twEvents.every(_ => this.eventImport[_.twid]);
  }
}
