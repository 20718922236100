export const DEFAULT_TAGS: string[] = [
  'T2',
  'R2',
  'E1',
  'N2',
  'N3',
  'N4',
  'C',
  'WS',
  'S1',
  'S2',
  'P1',
  'P2',
  'RO1',
  'Defer',
  'Top',
  'Bottom',
  'Neutral',
  'Fall',
  'Tech Fall',
  'Default',
  'Disq',
  'Forfeit',
]
