import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MBEvent } from '../models'
import { ConfirmComponent } from '../util/confirm'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { MBEventService } from '../services/mbevent.service'
import { first } from 'rxjs/operators';

@Component({
  selector: 'mb-event-trashed',
  templateUrl: './event-trashed.component.html',
  host: { class: 'mb-event' }
})
export class EventTrashedComponent implements OnInit {
  @Input() event: MBEvent
  @Output() eventRestored = new EventEmitter<boolean>()

  public isRestoring = false;

  constructor(
    public modalService: NgbModal, 
    private eventService: MBEventService) { }

  ngOnInit() {
  }

  public confirmRestore(): void {
    const modalRef = this.modalService.open(ConfirmComponent, {  })
    modalRef.componentInstance.event = this.event
    modalRef.componentInstance.title = 'Confirm'
    modalRef.componentInstance.body = 'Are you sure you want to pull this event out of trash?'
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.isRestoring = true;
          this.restore()
        } else {
          // ignore
        }
      },
      (rejectedValue: any) => {
        console.log('rejected', rejectedValue)
      }
    )
  }

  private restore(): void {
    const data: any = {
      isDeleted: 0
    }
    this.eventService.editEvent(this.event.eventID, data)
      .pipe(first())
      .subscribe((r: any) => {
        this.event.update(r.result)
        this.isRestoring = false;
        this.eventRestored.emit(this.event.isDeleted == 0)
      }, (e: any) => {
        this.isRestoring = false;
        this.eventRestored.emit(false)
      })
  }
}
