
<ng-container *ngIf="showIntro">
  <div class="mb-onboarding__password">
    <h2 class="mb-heading">Create a New Member Profile!</h2>
    <p class="mb-text">MatBoss is updating our system to include member profiles for each individual user.</p>
    <p class="mb-text">Your new member profile will have all the same functionality of your old account, but with the additional security of your individual login credentials. Your organization can have an unlimited number of member profiles.</p>
    <p class="mb-text">Please continue to complete the upgrade process:</p>
    <div class="form-group">
      <button
          type="button"
          (click)="continue()"
          class="btn btn-primary btn-lg">
            Continue
        </button>
    </div>
  </div>
</ng-container>

<div class="mb-onboarding__password" *ngIf="!showIntro">
    <h2 class="mb-heading">Create Your Profile</h2>

    <mb-alert *ngIf="error"
      type="danger"
      [message]="error"
      (closed)="error = null"></mb-alert>

    <p class="mb-text mb-4">
      Create a new MatBoss profile by completing the form below.
    </p>

    <ng-container *ngIf="isInvite">
      <div class="mb-invite-info">
        <p>Your profile will be associated with the following organization:</p>
        <div>
          <h3>{{ inviteOrganization }}</h3>
          <div class="row">
            <div class="col-md-2">
              Type:
            </div>
            <div class="col-md-10">
              {{ inviteLevel }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              Age:
            </div>
            <div class="col-md-10">
              {{ inviteAgeLevel }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <form [formGroup]="form" (ngSubmit)="submit()" novalidate>

      <mb-text-field
        formControlName="first_name"
        label="Enter First Name"
        [validate]="validate"
        [control]="this.form.get('first_name')"
      ></mb-text-field>

      <mb-text-field
        formControlName="last_name"
        label="Enter Last Name"
        [validate]="validate"
        [control]="this.form.get('last_name')"
      ></mb-text-field>

      <mb-text-field
        formControlName="email"
        label="Enter Email"
        [validate]="validate"
        [control]="this.form.get('email')"
      ></mb-text-field>

      <mb-text-field
        formControlName="password"
        type="password"
        label="Enter Password"
        [validate]="validate"
        [control]="this.form.get('password')"
        [messages]="passwordMessages"
      ></mb-text-field>

      <mb-text-field
        formControlName="confirmPassword"
        type="password"
        label="Confirm Password"
        [validate]="validate"
        [control]="this.form.get('confirmPassword')"
      ></mb-text-field>

      <div class="form-group">
        <button
          *ngIf="!submitting"
          type="submit"
          class="btn btn-primary btn-lg">
            Continue
        </button>

        <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
        
      </div>
    </form>

    <div class="mb-onboarding__links">
      <a routerLink="/login" class="mb-link">Back to Log in</a>
    </div>
</div>
