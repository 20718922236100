import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { CustomValidators } from '../../util/forms/custom-validators'

@Component({
  selector: 'mb-video-list-share',
  templateUrl: './video-list-share.component.html',
  styleUrls: ['./video-list-share.component.scss']
})
export class VideoListShareComponent implements OnInit {
  public form: FormGroup
  public validate = false
  public loaded = false

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.createForm()
  }

  public submit(): void {
    if (!this.form.valid) {
      this.validate = true
      return
    }

    this.activeModal.close({
      success: true,
      emails: this.form.value.emails,
      message: this.form.value.message,
    })
  }

  public cancel(): void {
    this.activeModal.dismiss('Cross click')
  }

  // PRIVATE

  private createForm(): void {
    this.form = this.fb.group({
      emails: ['', [Validators.required, CustomValidators.emailListValidator()]],
      message: ['']
    })
    this.loaded = true
  }
}
