<div class="mb-roster-filter container">
  <div class="row">
    <div class="col-auto">
      <strong>View by: </strong>
    </div>
    <div class="col-auto">
      <div class="form-check form-check-inline">
        <input type="radio"
          class="form-check-input"
          id="device-filter-status"
          value="unused"
          [(ngModel)]="filters.status"
          (change)="submit()">
        <label class="form-check-label"
          for="user-filter-status-managers">Unused</label>
      </div>
    </div>
    <div class="col-auto">
      <div class="form-check form-check-inline">
        <input type="radio"
          class="form-check-input"
          id="device-filter-status"
          value="all"
          [(ngModel)]="filters.status"
          (change)="submit()">
        <label class="form-check-label"
          for="user-filter-status-all">All Devices</label>
      </div>
    </div>
    <div class="col-auto">
      <input type="text"
        placeholder="Search"
        (keyup)="submit()"
        [(ngModel)]="filters.search">
    </div>
  </div>
</div>
