export interface Period {
  title: string
  value: string
}

export const periodOptions: Period[] = [
  {title: 'Period 1', value: '0'},
  {title: 'Period 2', value: '1'},
  {title: 'Period 3', value: '2'},
  {title: 'OT 1', value: '3'},
  {title: 'OT 2', value: '4'},
  {title: 'OT 3', value: '5'},
  {title: 'OT 4', value: '6'},
]

export interface PlaybackSpeedOption {
  label: string
  value: string
}

export const playbackSpeedOptions: PlaybackSpeedOption[] = [
  {label: 'Normal', value: '1.0'},
  {label: 'Very Fast', value: '3.0'},
  {label: 'Fast', value: '2.0'},
  {label: 'Slow', value: '0.5'},
  {label: 'Super Slow', value: '0.25'},
]

export interface LeadTimeOption {
  label: string
  value: number
}

export const leadTimeOptions: LeadTimeOption[] = [
  {value: 0, label: '0 seconds'},
  {value: 1, label: '1 second'},
  {value: 2, label: '2 seconds'},
  {value: 3, label: '3 seconds'},
  {value: 4, label: '4 seconds'},
  {value: 5, label: '5 seconds'},
  {value: 6, label: '6 seconds'},
  {value: 7, label: '7 seconds'},
  {value: 8, label: '8 seconds'},
  {value: 9, label: '9 seconds'},
  {value: 10, label: '10 seconds'},
]
