import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr'

import { MBMatchVideo, Highlight, MBMatch } from '../../models'
import { HighlightsService } from '../../services/highlights.service'
import { CustomValidators } from '../../util/forms/custom-validators'
@Component({
  selector: 'mb-video-highlights',
  templateUrl: './video-highlights.component.html'
})
export class VideoHighlightsComponent implements OnInit {

  // @todo pass in reference to video element so we can get current time
  @Input() matchVideo: MBMatchVideo
  @Input() highlight?: Highlight

  public form: FormGroup
  public validate = false
  public error = ''
  public submitting = false
  public success = ''

  constructor(
    private highlightsService: HighlightsService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  public get match(): MBMatch {
    return this.matchVideo.match
  }

  ngOnInit() {
    this.createForm()
  }

  public submit(): void {
    this.error = null
    this.success = null
    if (!this.form.valid) {
      this.validate = true
      return
    }
    this.submitting = true
    const highlight: any = {
      matchID: this.match.matchID,
      weightClass: this.match.theWeightClass,
      theDate: this.match.theDate,
      year: this.match.year,
      wrestlerID: this.match.ourWrestlerID,
      description: this.form.value.description,
      move: this.form.value.move,
      startStop: this.form.value.start + '-' + this.form.value.stop
    }
    // this may take a while, we won't wait for a response
    if (this.highlight) {
      this.highlightsService.editHighlight(this.highlight.ID.toString(), highlight)
        .subscribe()
    }
    else {
      this.highlightsService.addHighlight(highlight)
        .subscribe()
    }

    this.toastr.success('Highlight Saved.')
    this.submitting = false
    this.form.reset()
    this.validate = false
  }

  private createForm(): void {
    const start = this.highlight && this.highlight.startStop ? this.highlight.startStop.split('-')[0] : ''
    const stop = this.highlight && this.highlight.startStop ? this.highlight.startStop.split('-')[1] : ''
    this.form = this.fb.group({
      description: [this.highlight ? this.highlight.description : '', Validators.required],
      move: [this.highlight ? this.highlight.move : '', Validators.required],
      start: [start, CustomValidators.timeValidator()],
      stop: [stop, CustomValidators.timeValidator()],
    })
  }
}
