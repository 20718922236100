import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'

import { User } from '../../models'
import { UserService } from '../../services';

@Component({
  selector: 'mb-setup25',
  templateUrl: './setup25.component.html'
})
export class Setup25Component implements OnInit {

  @Input() account: User

  public form: FormGroup
  public loaded = false
  public submitting = false
  public validate = false

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.createForm()
  }

  public submitAnswer(theAnswer: string) {
	  if (theAnswer === 'go_to_tw') {
		  var openTWButton = document.getElementById("opentwbutton") as HTMLSelectElement
		  const buttontext = openTWButton.innerHTML
		  if (buttontext === 'Done with TW') {
			  theAnswer = 'done'
		  } else {
			  window.open('https://www.trackwrestling.com/seasons/index.jsp', '_blank')
			  openTWButton.innerHTML = 'Done with TW'
		  	  return
		  }
	  }
	  this.submitting = true
	  this._submit(theAnswer)
  }

  private _submit(theAnswer: string): void {
    if (theAnswer === 'done') {
      this.router.navigate(['/setup/9'])
    }
    else {
      this.router.navigate(['/setup/11'])
    }
  }

  public goBack() {
    window.history.back()
  }

  // private

  private createForm(): void {
    this.form = this.fb.group({

	})
    this.loaded = true
  }
}
