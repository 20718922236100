import { MBMatch } from "./mbmatch";

export class MBEvent {
  public combinedEventID: string;
  public eventDate: string;
  public eventID: string;
  public eventIDList: string;
  public eventName: string;
  public eventType: string;
  public ID: number;
  public location: string;
  public match_count: number;
  public matches: MBMatch[];
  public official: string;
  public opponentScore: string;
  public ourScore: string;
  public scorebookID: string;
  public startingWeight: string;
  public twid: string;
  public isDeleted: number;

  public constructor(data) {
    this.update(data);
  }

  public update(data) {
    Object.assign(this, data);
  }

  public get isTournament(): boolean {
    return this.eventType === "Tournament";
  }

  public get isDualMeet(): boolean {
    return this.eventType === "Dual Meet";
  }

  public get opponentName(): string {
    return this.isDualMeet ? this.eventName : null;
  }

  public get encodedID(): string {
    return encodeURIComponent(this.eventID);
  }

  public get teamID(): string {
    if (!this.eventID) return null;
    return this.eventID.split(":")[0];
  }

  public get year(): string {
    if (!this.eventID) return null;
    return this.eventID.split(":")[1];
  }

  public get levelID(): string {
    if (!this.eventID) return null;
    return this.eventID.split(":")[2];
  }

  public get hasLocation(): boolean {
    return ["home", "away"].includes(this.location);
  }

  /**
   * @deprecated this translation should be handled outside this class
   * @todo levels can be customized ('A Sqwaad' etc...)
   */
  public get level(): string {
    if (!this.levelID) return null;
    if (this.levelID === "0") return "A-Squad";
    if (this.levelID === "1") return "B-Squad";
    if (this.levelID === "2") return "C-Squad";
    if (this.levelID === "3") return "Practice";
    if (this.levelID === "4") return "Scout";
  }
}
