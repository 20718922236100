
    <div class="mb-confirm">
      <div class="modal-header">
        <h4
          class="modal-title"
          *ngIf="title">{{ title }}</h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <p>{{ body }}</p>
      </div>

      <div class="modal-footer d-block text-right">
        <button
          type="button"
          class="btn"
          (click)="cancel()">
            {{ cancelLabel }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="confirm()">
            {{ confirmLabel }}
        </button>
      </div>
  