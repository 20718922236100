import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'

import { ApiService } from './api.service'
import { SchoolRecord } from '../models'

@Injectable({
  providedIn: 'root'
})
export class SchoolRecordsService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * find all records by teamID
   * @param teamID
   */
  public findAll(teamID: string): Observable<SchoolRecord[]> {
    const params: any = { teamID }
    return this.apiService.get('school_records', { params })
      .pipe(first())
      .pipe(map((result: {result: any[]}) => result.result.map(r => new SchoolRecord(r))))
  }

  /**
   * add Record
   */
  public addRecord(data: {
    teamID: string | number,
    category: string,
    year: number | string,
    record: number | string,
    name?: string,
    notes?: string,
  }): Observable<SchoolRecord> {

    return this.apiService.post('school_records', data)
      .pipe(first())
      .pipe(map(r => new SchoolRecord(r)))
  }

  /**
   * edit Record
   */
  public editRecord(recordID: number | string, data: {
    year?: number | string,
    record?: number | string,
    name?: string,
    notes?: string,
  }): Observable<any> {
    return this.apiService.update(`school_records/${recordID}`, data)
  }
}
