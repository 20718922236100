import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { parseString } from 'xml2js'

@Injectable({
  providedIn: 'root'
})
export class RssService {

  constructor(
    private http: HttpClient
  ) { }

  public loadFeed(url: string): Observable<any> {
    let headers = new HttpHeaders()
    headers.set('Accept', 'application/xml')
    headers.set('Content-Type', 'application/xml')
    return this.http.get(url, { headers: headers, responseType: 'text' })
      .pipe(
        map((r: any) => {
          let items: any[] = []
          parseString(r, (err, data) => {
            items = data.rss.channel[0].item
          })
          return items
        })
      )
  }
}
