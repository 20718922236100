<div class="container container--inner">
  <h3 class="mb-page__head">
    <span class="mb-page__head-title">
      <img src="./assets/SVG/Page/Roster.svg" alt="Icon">
      Deleted Events
    </span>
    <span
      class="mb-page__head-links">
      <a routerLink="/scorebook"
        class="mx-4 mb-page__head-link">Scorebook</a>
    </span>
  </h3>

  <mb-scorebook-filters
    [params]="params"
    [user]="user"
    [yearOptions]="[year]"
    (submitted)="updateFilters($event)">
  </mb-scorebook-filters>

  <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>
  <mb-alert *ngIf="success" [message]="success" (closed)="success = null"></mb-alert>

  <mb-spinner *ngIf="!loaded" label="Loading Deleted Events..." class="my-5 p-5 text-center"></mb-spinner>

  <div *ngIf="loaded" class="mb-scorebook__content">
    <div *ngIf="!trashedEvents.length">
      There are no deleted events in this scorebook.
    </div>
    <mb-event-trashed 
      *ngFor="let event of trashedEvents"
      (eventRestored)="onEventRestored($event)"
      [event]="event">
    </mb-event-trashed>
  </div>
</div>