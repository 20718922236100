<div *ngIf="loaded" class="mb-scorebook__filters d-md-flex">
  <label>
    Filter by:
  </label>
  <select [(ngModel)]="filters.levelID" (change)="levelChange()">
    <option *ngFor="let level of filters.levelOptions"
      [value]="level.levelID">
      {{ level.label }}
    </option>
  </select>
  <select [(ngModel)]="filters.eventID" (change)="eventChange()">
    <option [value]="null">By Event</option>
    <option *ngFor="let evt of filters.eventOptions"
      [value]="evt.eventID">
      {{ evt.eventName }}
    </option>
  </select>
  <select [(ngModel)]="filters.wrestlerID" (change)="submit()">
    <option [value]="null">By Wrestler</option>
    <option *ngFor="let wrestler of filters.wrestlerOptions"
      [value]="wrestler.wrestlerID">
      {{ wrestler.Name }}
    </option>
  </select>
  <select [(ngModel)]="filters.year" (change)="yearChange()">
    <option [value]="null">By Year</option>
    <option *ngFor="let yr of filters.yearOptions"
      [value]="yr"
      [selected]>
      {{ yr }}
    </option>
  </select>
  <select *ngIf="filters.wrestlerID || filters.eventID"
    [(ngModel)]="filters.move" (change)="submit()">
    <option [value]="null">By Move</option>
    <optgroup label="For">
      <option *ngFor="let move of filters.moveOptions"
        [value]="move + ':F'">
        {{ move }}
      </option>
    </optgroup>
    <optgroup label="Against">
      <option *ngFor="let move of filters.moveOptions"
        [value]="move + ':A'">
        {{ move }}
      </option>
    </optgroup>
  </select>
</div>
