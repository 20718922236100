<div class="mb-roster-filter container">
  <div class="row">
    <div class="col-auto">
      <strong>View by: </strong>
    </div>
    <div class="col-auto">
      <div class="form-check form-check-inline">
        <input
          type="radio"
          class="form-check-input"
          id="user-filter-status"
          value="managers"
          [(ngModel)]="filters.status"
          (change)="submit()"
        />
        <label class="form-check-label" for="user-filter-status-managers"
          >Coaches</label
        >
      </div>
    </div>
    <div class="col-auto">
      <div class="form-check form-check-inline">
        <input
          type="radio"
          class="form-check-input"
          id="user-filter-status"
          value="athletes"
          [(ngModel)]="filters.status"
          (change)="submit()"
        />
        <label class="form-check-label" for="user-filter-status-athletes"
          >Athletes</label
        >
      </div>
    </div>
    <div class="col-auto">
      <div class="form-check form-check-inline">
        <input
          type="radio"
          class="form-check-input"
          id="user-filter-status"
          value="former"
          [(ngModel)]="filters.status"
          (change)="submit()"
        />
        <label class="form-check-label" for="user-filter-status-former"
          >Former Athletes</label
        >
      </div>
    </div>
    <div class="col-auto">
      <div class="form-check form-check-inline">
        <input
          type="radio"
          class="form-check-input"
          id="user-filter-status"
          value="all"
          [(ngModel)]="filters.status"
          (change)="submit()"
        />
        <label class="form-check-label" for="user-filter-status-all"
          >All Members</label
        >
      </div>
    </div>
    <div class="col-auto">
      <input
        type="text"
        placeholder="Search"
        (keyup)="submit()"
        [(ngModel)]="filters.search"
      />
    </div>
  </div>
</div>
