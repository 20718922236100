import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { first, takeUntil } from 'rxjs/operators'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

import { MBEventService, MBEventIndexParams, NavService, UserService } from '../services'
import { MBEvent, User } from '../models'

import { ScorebookFilters } from './'
import { EventEditComponent } from './event-edit.component'

@Component({
  selector: 'mb-scorebook',
  templateUrl: './scorebook.component.html',
  host: {'class': 'mb-page mb-scorebook'}
})
export class ScorebookComponent implements OnInit, OnDestroy {

  public events: Array<MBEvent>
  public loaded = false
  public error = ''
  public success = ''
  public user: User
  private teamID: string
  private levelID: string
  private year: string
  public params: any
  private unsubscribe: Subject<any> = new Subject()
  private sort: string
  private timeRange: string
  private move: string

  /**
   * params are provided via dependency injection
   * @param eventService
   * @param userService
   */
  constructor(
    private acRoute: ActivatedRoute,
    private router: Router,
    private eventService: MBEventService,
    private userService: UserService,
    private navService: NavService,
    private modalService: NgbModal
  ) { }

  /**
   * set teamID from user.ID
   * @todo determin if wrestlers and coaches should see the same things
   */
  public ngOnInit() {
    this.navService.setCurrentFromData({
      name: 'scorebook page'
    })
    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        this.user = user
        this.teamID = user.teamID
      })
    this.initQueryParams()
  }

  /**
   * unsubscribe to prevent memory leaks
   */
  public ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  private initQueryParams(): void {
    this.acRoute.queryParams
      .pipe(first())
      .subscribe((params: any) => {
        this.params = params || { }
      })
  }

  /**
   * This is a listener for the filters.submitted event
   * which is called from filters.component when
   * filters have changed. This triggers a new search.
   * @param filters
   */
  public updateFilters(filters: ScorebookFilters) {
    this.loaded = false
    this.levelID = filters.levelID
    this.year = filters.year
    this.timeRange = filters.timeRange
    this.sort = filters.sort
    this.move = filters.move
    this.findEvents()
  }

  public addEvent() {
    let event: MBEvent = new MBEvent({})
    const modalRef = this.modalService.open(EventEditComponent, { size: 'sm' })
    modalRef.componentInstance.event = event
    modalRef.componentInstance.teamID = this.teamID
    if (this.levelID && this.levelID !== '-1') {
      modalRef.componentInstance.levelID = this.levelID
    }
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          if (fulfilledValue.deleted) {
            this.setSuccess('Event deleted!')
          } else {
            this.setSuccess('Event saved!')
          }
          this.findEvents()
        } else {
          this.setError('There was an error saving the event (1).', fulfilledValue)
        }
      },
      (rejectedValue: any) => {
        if (rejectedValue === 'Cross click' || rejectedValue === 0 || rejectedValue === 1) return
        this.setError('There was an error saving the event (2).', rejectedValue)
      }
    )
  }

  public onEventChanged(e: any): void {
    const msg: string = e.deleted ? 'The event has been deleted' : 'The event has been saved'
    this.setSuccess(msg, e)
    this.findEvents()
  }

  /**
   * finds events by teamID, levelID, year
   */
  private findEvents()
  {
    this.loaded = false
    let params: MBEventIndexParams = {
      teamID: this.teamID,
      levelID: this.levelID,
      year: this.year,
      sortDir: this.sort,
      per_page: 250 
    }
    if (this.timeRange === 'past' || this.timeRange === 'upcoming') params.filter = this.timeRange
    if ( this.move && this.move !== 'null' ) {
      params['move'] = this.move
    }

    const queryParams = Object.keys(params).reduce((c, i) => {
      if (i !== 'teamID' && !!params[i] && params[i] !== 'null') {
        c[i] = params[i]
      }
      return c
    }, { })

    this.eventService.findAll(params)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.events = []
          if (data.result) {
            data.result.forEach(item => {
              this.events.push(new MBEvent(item))
            })
            this.router.navigate([], {
              relativeTo: this.acRoute,
              queryParams,
            })
            this.loaded = true
          }
          else {
            throw new Error('Invalid response')
          }
        }
      )
  }

  private setError(message: string, obj?: any) {
    this.success = null
    this.error = message
    console.warn('Error: ' + message, obj)
    window.scroll(0, 0)
  }

  private setSuccess(message: string, obj?: any) {
    this.error = null
    this.success = message
    if (obj) console.warn('Success', obj)
    window.scroll(0, 0)
  }
}
