<div class="mb-roster-filter container">
  <div class="row">
    <div class="col-auto">
      <strong>View by: </strong>
    </div>
    <div class="col-auto">
      <div class="form-check form-check-inline">
        <input type="radio"
          class="form-check-input"
          id="roster-filter-status-current"
          value="current"
          [(ngModel)]="filters.status"
          (change)="submit()">
        <label class="form-check-label"
          for="roster-filter-status-current">Current Wrestlers</label>
      </div>
    </div>
    <div class="col-auto">
      <div class="form-check form-check-inline">
        <input type="radio"
          class="form-check-input"
          id="roster-filter-status-former"
          value="former"
          [(ngModel)]="filters.status"
          (change)="submit()">
        <label class="form-check-label"
          for="roster-filter-status-former">Former Wrestlers</label>
      </div>
    </div>
    <div class="col-auto">
      <div class="form-check form-check-inline">
        <input type="radio"
          class="form-check-input"
          id="roster-filter-status-all"
          value="all"
          [(ngModel)]="filters.status"
          (change)="submit()">
        <label class="form-check-label"
          for="roster-filter-status-all">All Wrestlers</label>
      </div>
    </div>
    <div class="col-auto">
      <input type="text"
        placeholder="Search"
        (keyup)="submit()"
        [(ngModel)]="filters.search">
    </div>
  </div>
</div>
