import { Component, OnInit, Input } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { first } from 'rxjs/operators'
import * as _ from 'lodash'

import { MBMatch, Wrestler } from '../../models'
import { RosterService } from '../../services/roster.service'

@Component({
  selector: 'mb-edit-our-wrestler',
  templateUrl: './edit-our-wrestler.component.html'
})
export class EditOurWrestlerComponent implements OnInit {

  @Input() match: MBMatch
  @Input() teamID: string

  public form: FormGroup
  public validate = false
  public loaded = false
  public wrestlers: Wrestler[]
  private _wrestlers: Wrestler[]

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private rosterService: RosterService
  ) { }

  ngOnInit() {
    this.findRoster()
  }

  private findRoster() {
    this.loaded = false
    this.rosterService.getRoster(this.match.accountID, {status: 'current'})
      .pipe(first())
      .subscribe((data: any) => {
        this.wrestlers = []
        this._wrestlers = undefined
        data.result.forEach(item => {
          this.wrestlers.push(new Wrestler(item))
        })
        this.sortWrestlers()
        this.createForm()
      })
  }

  private sortWrestlers(): void {
    this.wrestlers = _.orderBy(
      this.wrestlers,
      w => w['lastName'].toLowerCase(),
      'asc'
    )
      .map((w) => new Wrestler(w))
  }

  public submit(): void {
    if (!this.form.valid) {
      this.validate = true
      return
    }
    const wrestler: Wrestler = this.findWrestler(this.form.value.wrestlerID)
    this.match.ourWrestlerID = wrestler.wrestlerID
    this.match.ourWrestlerName = wrestler.fullName
    this.activeModal.close({
      success: true,
      result: this.match
    })
  }

  public cancel(): void {
    this.activeModal.dismiss('Cross click')
  }

  // PRIVATE

  private findWrestler(wrestlerID: string): Wrestler {
    const w: Wrestler[] = this.wrestlers.filter((w: Wrestler) => {
      return wrestlerID === w.wrestlerID
    })
    return w.length ? w[0] : null
  }

  private createForm(): void {
    this.form = this.fb.group({
      wrestlerID: [this.match.ourWrestlerID, Validators.required]
    })
    this.loaded = true
  }
}
