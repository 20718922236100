<div class="modal-header">
  <h4 class="modal-title">Edit Wrestler</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="!loaded">
    <mb-spinner></mb-spinner>
  </div>
  <form *ngIf="loaded"
    [formGroup]="form"
    (ngSubmit)="submit()"
    novalidate>
    <div class="row">
      <div class="col-sm-9">
        <div class="form-group">
          <label>Our Wrestler</label>
          <select
            class="form-control"
            formControlName="wrestlerID">
            <option *ngFor="let wrestler of wrestlers"
              [value]="wrestler.wrestlerID">
              {{ wrestler.Name }}
            </option>
          </select>
          <mb-invalid [form]="form"
            [validate]="validate"
            field="wrestlerID"></mb-invalid>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer d-block">
  <button type="button"
    class="btn btn-primary"
    (click)="submit()">
    Save
  </button>

  <button type="button"
    class="btn"
    (click)="cancel()">
    Cancel
  </button>
</div>
