/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./header/header.component.ngfactory";
import * as i2 from "./header/header.component";
import * as i3 from "../services/user.service";
import * as i4 from "@angular/router";
import * as i5 from "./footer/footer.component.ngfactory";
import * as i6 from "./footer/footer.component";
import * as i7 from "../services/rss.service";
import * as i8 from "./layout.component";
import * as i9 from "../services/nav.service";
var styles_LayoutComponent = [];
var RenderType_LayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
export function View_LayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-header", [], null, null, null, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(1, 245760, null, 0, i2.HeaderComponent, [i3.UserService, i4.Router, i0.Renderer2], null, null), (_l()(), i0.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "mb-footer", [], null, null, null, i5.View_FooterComponent_0, i5.RenderType_FooterComponent)), i0.ɵdid(5, 114688, null, 0, i6.FooterComponent, [i7.RssService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_LayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i0.ɵdid(1, 114688, null, 0, i8.LayoutComponent, [i9.NavService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i0.ɵccf("mb-layout", i8.LayoutComponent, View_LayoutComponent_Host_0, {}, {}, []);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
