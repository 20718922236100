<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="userForm" (ngSubmit)="submit()" novalidate>
    <div class="form-group">
      <label>First name</label>
      <input class="form-control" formControlName="first_name">
      <mb-invalid [form]="userForm" [validate]="validate" field="first_name"></mb-invalid>
    </div>
    <div class="form-group">
      <label>Last name</label>
      <input class="form-control" formControlName="last_name">
      <mb-invalid [form]="userForm" [validate]="validate" field="last_name"></mb-invalid>
    </div>
    <div class="form-group">
      <label>Email</label>
      <div class="">{{ user.userEmail }}</div>
    </div>
    <div class="form-group" *ngIf="isWrestler()">
      <label>Wrestler Roster Entry</label>
      <select
            class="form-control"
            formControlName="wrestlerID">
            <option *ngFor="let wrestler of wrestlers"
              [value]="wrestler.wrestlerID">
              {{ wrestler.Name }}
            </option>
          </select>
    </div>
    <div class="form-group">
      <label class="mb-label">Status</label>
      <div *ngFor="let opt of userActiveOptions" class="form-check">
        <label class="mb-choice-label form-check-label">
          <input class="form-check-input"
            formControlName="active"
            type="radio"
            [value]="opt.value">
          {{ opt.label }}
        </label>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer d-block">
  <button type="button"
    class="btn btn-primary btn-block"
    *ngIf="!submitting"
    (click)="submit()">
    Save
  </button>
  <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
</div>
