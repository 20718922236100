import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { parseString } from 'xml2js';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RssService {
    constructor(http) {
        this.http = http;
    }
    loadFeed(url) {
        let headers = new HttpHeaders();
        headers.set('Accept', 'application/xml');
        headers.set('Content-Type', 'application/xml');
        return this.http.get(url, { headers: headers, responseType: 'text' })
            .pipe(map((r) => {
            let items = [];
            parseString(r, (err, data) => {
                items = data.rss.channel[0].item;
            });
            return items;
        }));
    }
}
RssService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RssService_Factory() { return new RssService(i0.ɵɵinject(i1.HttpClient)); }, token: RssService, providedIn: "root" });
