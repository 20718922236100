import { Injectable } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'

import { ApiService } from './api.service'

@Injectable()
export class RosterService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * find all wrestlers by teamID
   * @param teamID
   * @param data?
   */
  public getRoster(
    teamID: string,
    data: { status?: string, perPage?: number, year?: string } = { }
  ): Observable<any> {
    let params: any = { teamID }
    params.per_page = data.perPage ? data.perPage.toString() : '500'
    if (data.status) params.status = data.status
    if (data.year) params.year = data.year
    return this.apiService.get('rosters', { params })
  }

  /**
   * emailRoster
   * @param teamID
   * @param message
   */
  public emailRoster(teamID: string, to: string, message: string): Observable<any> {
    return this.apiService.post('rosters/' + encodeURIComponent(teamID), {
      emailAddress: to,
      message: message
    })
  }

  /**
   * add Wrestler
   */
  public addWrestler(teamID: string, data: any): Observable<any> {
    let params = this.transform(data)
    params.TeamID = teamID
    return this.apiService.post('wrestlers', params)
  }

  /**
   * edit Wrestler
   */
  public editWrestler(wrestlerID: string, data: any): Observable<any> {
    return this.apiService.update('wrestlers/' + encodeURIComponent(wrestlerID), data)
  }

  /**
   * add current year to years wrestled (used when importing tw roster)
   */
  public addcurrentyeartoyearswrestled(wrestlerID: string): Observable<any> {
	const params = { wrestlerID: wrestlerID };
    return this.apiService.post('addcurrentyeartoyearswrestled', params)
  }

  /**
   * @todo is this Needed?
   * create HttpParams from js object
   * @param data
   */
  private makeParams(data: any): HttpParams {
    let params = new HttpParams()
    for (let k in data) {
      params = params.set(k, data[k])
    }
    return params
  }

  private transform(data: any): any {
    data = data || {}
    if (data.firstName) data.firstName = this.transformName(data.firstName)
    if (data.lastName) data.lastName = this.transformName(data.lastName)
    return data
  }

  private transformName(name: string): string {
    return name.replace("'", "`").trim()
  }
}
