export class ReportParams {
  //-----------------------
  // typically required params - but not always
  //-----------------------
  /**
   * accountNumber from users database
   */
  public teamID: string

  /**
   * scorebook in which to search
   */
  public year: string

  /**
   * scorebook in which to search
   */
  public levelID: string

  //-----------------------
  // misc params
  //-----------------------
  public eventID: string
  public wrestlerID: string
  public opponentName: string

  /**
   * empty or 0=entire season, 1=one week, 2=two weeks, 3=one month
   */
  public timePeriod: string

  /**
   * list of numbered indices, separated by tilde (~)
   */
  public catIndexArrayString: string

  //-----------------------
  // pagination
  //-----------------------
  public per_page: string
  public page: string

  public constructor(data) {
    this.update(data)
  }

  public update(data) {
    Object.assign(this, data)
  }
}
