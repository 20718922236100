export const environment = {
  env: "production",
  production: true,
  frontendUrl: "https://users.matbossapp.com/",
  analyticsUrl: "https://analytics.matbossapp.com/",
  viewerUrl: "https://viewer.matbossapp.com/",
  setupUrl: "https://users.matbossapp.com/#/setup",
  apiUrl: "https://backend.matbossapp.com/api/v2/",
  currentYear: "2023-2024",
  awsIdentityPoolId: "us-west-2:53e020f5-ec3a-4dc6-a932-ed7b5736f817",
  awsRegion: "us-west-2",
};
