import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Wrestler } from "../models";
import { RosterService } from "../services";
import {
  DEFAULT_EVENT_LEVELS,
  EVENT_LEVELS,
  EVENT_YEAR_OPTIONS,
  MBEventLevel,
} from "../util/mbevent-options";

@Component({
  selector: "mb-wrestler-edit",
  templateUrl: "./wrestler-edit.component.html",
})
export class WrestlerEditComponent implements OnInit {
  @Input() wrestler: Wrestler;
  @Input() teamCode?: string;
  @Input() title?: string = "Edit Wrestler";

  public wrestlerForm: FormGroup;
  public levels: MBEventLevel[] = EVENT_LEVELS.map((level) => {
    if (level.levelID === "-1") return { levelID: "", label: "" };
    const levelID: string = DEFAULT_EVENT_LEVELS[Number(level.levelID)];
    const label: string =
      level.levelID === "3" || level.levelID === "4" ? levelID : level.label;
    return { levelID: levelID, label: label };
  });

  public yearOptions: string[] = EVENT_YEAR_OPTIONS;
  public submitting = false;
  public validate = false;
  private NAME_PATTERN = /^[a-zA-Z'` -]+$/;
  private ALPHANUM_PATTERN = /^[a-zA-Z0-9.]+$/;

  public constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private rosterService: RosterService
  ) {}

  public ngOnInit() {
    this.createForm();
  }

  public inviteUrl() {
    return (
      "https://users.matbossapp.com/#/login/create-wrestler" +
      "?f=" +
      encodeURIComponent(this.wrestler.firstName) +
      "&l=" +
      encodeURIComponent(this.wrestler.lastName) +
      "&c=" +
      encodeURIComponent(this.teamCode)
    );
  }

  public goToOrgnizationSettings() {
    this.activeModal.close({
      success: true,
      result: this.wrestler,
    });
  }

  public submit() {
    if (!this.wrestlerForm.valid) {
      this.validate = true;
      return;
    }
    const data: any = {
      Name: this.NameValue,
      TeamID: this.wrestler.TeamID,
      // twid: @todo
      Grade: this.wrestlerForm.value.grade,
      WeightClass: this.wrestlerForm.value.weight_class,
      // Record: @todo
      Level: this.wrestlerForm.value.level,
      yearsWrestled: this.yearsWrestledValues.join(","),
      // twEligible:  @todo
      // userName:  don't allow changes, since it needs to be unique
      // emailAddress: this.wrestlerForm.value.email,
      // sendInvite: this.wrestlerForm.value.sendInvite,
    };
    const result: Observable<any> = this.wrestler.ID
      ? this.rosterService.editWrestler(this.wrestler.wrestlerID, data)
      : this.rosterService.addWrestler(this.wrestler.TeamID, data);

    this.submitting = true;
    result.pipe(first()).subscribe(
      (r: any) => {
        this.wrestler.update(r.result);
        this.activeModal.close({
          success: true,
          result: this.wrestler,
        });
      },
      (e: any) => {
        console.warn("wrestler edit error", e.error.message);
        this.activeModal.close({
          success: false,
          error: e,
        });
      }
    );
  }

  private createForm() {
    this.wrestlerForm = this.fb.group({
      first_name: [
        this.wrestler.firstName,
        [Validators.required, Validators.pattern(this.NAME_PATTERN)],
      ],
      last_name: [
        this.wrestler.lastName,
        [Validators.required, Validators.pattern(this.NAME_PATTERN)],
      ],
      // email: [this.wrestler.emailAddress, Validators.email],
      // username: [this.wrestler.userName],
      level: [this.wrestler.Level],
      weight_class: [
        this.wrestler.WeightClass,
        Validators.pattern(this.ALPHANUM_PATTERN),
      ],
      grade: [this.wrestler.Grade, Validators.pattern(this.ALPHANUM_PATTERN)],
      account: [this.wrestler.Cert],
      yearsWrestled: this.buildYearsWrestled(),
      // sendInvite: [false],
    });
  }

  private buildYearsWrestled(): FormArray {
    const yrs: string = this.wrestler.yearsWrestled || this.yearOptions[0];
    const yrsWrestled = yrs.split(",");
    const arr = this.yearOptions.map((yr) => {
      return this.fb.control(yrsWrestled.indexOf(yr) !== -1);
    });
    return this.fb.array(arr);
  }

  public get yearsWrestledArray(): FormArray {
    return this.wrestlerForm.get("yearsWrestled") as FormArray;
  }

  public get yearsWrestledValues(): string[] {
    const vals = [];
    this.yearOptions.forEach((yr, i) => {
      if (this.yearsWrestledArray.at(i).value) {
        vals.push(yr);
      }
    });
    return vals;
  }

  public get NameValue(): string {
    return [
      this.wrestlerForm.value.last_name.replace("'", "`"),
      this.wrestlerForm.value.first_name.replace("'", "`"),
    ].join(", ");
  }

  private buildSubmit(): any {
    const val: any = this.wrestlerForm.value;
    val.yearsWrestled = this.yearsWrestledValues;
    return val;
  }
}
