<!-- STEP 3 -->
<mb-spinner
  *ngIf="!loaded"
  type="ripple-inverse"></mb-spinner>
<div *ngIf="loaded">
  <a (click)="goBack()"
    class="mb-link" style="color:gainsboro">&lt;&lt; Back</a>
  <h2 class="mb-heading">Step 2 of 3</h2>
  <p class="mb-text">
    On the left side of the table, you'll find your TW roster listed. If a dropdown appears in a table row, it means the wrestler does not exist yet on your MatBoss roster. For each dropdown, choose either 'Do Not Import'
    or 'Create New Wrestler'.
  </p>

  <form (ngSubmit)="submit()" novalidate>
    <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>
    <table class="table">
      <thead>
        <tr>
          <th>Last Name</th>
          <th>First Name</th>
          <th>Grade</th>
          <th>Matboss Roster Match</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let twWrestler of twWrestlers">
          <td>{{ twWrestler.lastName }}</td>
          <td>{{ twWrestler.firstName }}</td>
          <td>{{ twWrestler.grade }}</td>
          <td>
            <select
              class="form-control"
              [(ngModel)]="import[twWrestler.twid]"
              (ngModelChange)="changeImport()"
              [ngModelOptions]="{standalone: true}"
			  >
              <option
                [value]="undefined"
                disabled >- Choose Wrestler -</option>
              <option value="new" selected>Create New Wrestler</option>
              <option [value]="null">Do Not Import</option>
              <option *ngFor="let w of wrestlers"
                [value]="w.wrestlerID.toString()">
                {{ w.fullName }}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>

    <p class="my-4 mb-text">
      The wrestlers listed below have not been matched up with
      anyone on your TrackWrestling roster.
      Most likely, this is due to graduation, etc.
      Place a checkmark next to those who are no longer with your team,
      and the system will change their status to 'Former' so they no
      longer show up in dropdown menus, etc.
    </p>

    <table class="table">
      <thead>
        <tr>
          <th>Select</th>
          <th>Last Name</th>
          <th>First Name</th>
        </tr>
      </thead>
      <tbody>
		<ng-container *ngFor="let w of unselectedWrestlers">
			<tr *ngIf="!w.lastName.toLowerCase().includes('forfeit') && !w.lastName.toLowerCase().includes('wrestler')">
			  <td>
				<div class="form-check">
				  <input
					class="form-check-input"
					type="checkbox"
					[(ngModel)]="former[w.wrestlerID.toString()]"
					[value]="true"
					[ngModelOptions]="{standalone: true}">
				</div>
			  </td>
			  <td>{{ w.lastName }}</td>
			  <td>{{ w.firstName }}</td>
			</tr>
		</ng-container>
      </tbody>
    </table>

    <div class="form-group">
      <button type="submit"
        *ngIf="!submitting"
        class="btn btn-primary btn-lg">
          Next
      </button>
      <mb-ellipsis-loader
        *ngIf="submitting"></mb-ellipsis-loader>
    </div>
  </form>
</div>
