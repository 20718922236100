import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { CustomValidators } from '../../util/forms/custom-validators'
import { ScoringEvent, Tag, User } from '../../models'
import { DEFAULT_TAGS } from '../../util'

@Component({
  selector: 'mb-scoring-symbol-edit',
  templateUrl: './scoring-symbol-edit.component.html'
})
export class ScoringSymbolEditComponent implements OnInit {

  @Input() account: User
  @Input() scoringEvent: ScoringEvent

  public form: FormGroup
  public validate = false
  public presetSymbolOpts: string[] = DEFAULT_TAGS
  public periodOptions: {title: string, value: string}[] = [
    {title: '1', value: '0'},
    {title: '2', value: '1'},
    {title: '3', value: '2'},
    {title: 'OT 1', value: '3'},
    {title: 'OT 2', value: '4'},
    {title: 'OT 3', value: '5'},
    {title: 'OT 4', value: '6'},
  ]

  public constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal
  ) { }

  public ngOnInit(): void {
    this.createForm()
  }

  public submit(): void {
    if (!this.form.valid) {
      this.validate = true
      return
    }
    this.setModelFromForm()
    this.activeModal.close({
      success: true,
      result: this.scoringEvent
    })
  }

  public cancel(): void {
    this.activeModal.dismiss('Cross click')
  }

  public delete(): void {
    this.activeModal.close({
      success: true,
      result: 'delete'
    })
  }

  // PRIVATE

  private createForm() {
    this.form = this.fb.group({
      symbol: [this.scoringEvent.symbol, Validators.required],
      presetSymbol: [],
      advancedTags: [],
      color: [this.scoringEvent.color, Validators.required],
      time: [this.scoringEvent.time, [Validators.required, CustomValidators.timeValidator()]],
      period: [this.scoringEvent.period, Validators.required]
    })
    this.form.get('presetSymbol').valueChanges.subscribe(val => {
      this.form.get('symbol').setValue(val || '')
    })
    this.form.get('advancedTags').valueChanges.subscribe(val => {
      this.form.get('symbol').setValue(val || '')
    })
  }

  private setModelFromForm() {
    const timeParts: string[] = this.form.get('time').value.split(':')
    const hr: number = parseInt(timeParts[timeParts.length - 3]) || 0
    const sec: number = parseInt(timeParts[timeParts.length - 1]) || 0
    let min: number = parseInt(timeParts[timeParts.length - 2]) || 0
    min += 60 * hr

    this.scoringEvent.seconds = sec.toString()
    this.scoringEvent.minutes = min.toString()
    this.scoringEvent.period = this.form.get('period').value
    this.scoringEvent.symbol = this.normalizeSymbol(this.form.get('symbol').value)
    this.scoringEvent.color = this.form.get('color').value
  }

  private normalizeSymbol(enteredValue:string):string {
    // special case for misspelled "Forfeit"
    if ( enteredValue.toLowerCase() === 'forfiet' ) {
      return 'Forfeit'
    }
    // also match up any custom entered symbol that doesn't match the case of the preset symbol
    for ( let i = 0; i < this.presetSymbolOpts.length; i++ ) {
      if ( this.presetSymbolOpts[i].toLowerCase() === enteredValue.toLowerCase() ) {
        return this.presetSymbolOpts[i]
      }
    }
    return enteredValue
  }
}
