import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject, Observable } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'

import { SchoolRecordsService, UserService } from '../services'
import { SchoolRecord, User } from '../models'
import { environment } from '../../environments/environment'

@Component({
  selector: 'mb-school-records',
  templateUrl: './school-records.component.html',
  styleUrls: ['./school-records.component.scss']
})
export class SchoolRecordsComponent implements OnInit, OnDestroy {

  private editing = {}

  public loaded = false
  public saving = false
  public categories = [
    { title: 'Single Season Wins', shortTitle: 'Wins', key: 'indSeasonWins', type: 'int' },
    { title: 'Career Wins', shortTitle: 'Wins', key: 'indCareerWins', type: 'int' },
    { title: 'Single Season Team Points', shortTitle: 'Pts', key: 'indSeasonTeamPoints', type: 'int' },
    { title: 'Career Team Points', shortTitle: 'Pts', key: 'indCareerTeamPoints', type: 'int' },
    { title: 'Single Season Falls', shortTitle: 'Falls', key: 'indSeasonFalls', type: 'int' },
    { title: 'Career Falls', shortTitle: 'Falls', key: 'indCareerFalls', type: 'int' },
    { title: 'Single Season Takedowns', shortTitle: 'Tkdwns', key: 'indSeasonTakedowns', type: 'int' },
    { title: 'Career Takedowns', shortTitle: 'Tkdwns', key: 'indCareerTakedowns', type: 'int' },
    { title: 'Single Season Winning %', shortTitle: 'Win %', key: 'indSeasonWinPercentage', type: 'percent' },
    { title: 'Career Winning %', shortTitle: 'Win %', key: 'indCareerWinPercentage', type: 'percent' },
  ]
  public years = []
  public teamID: string
  public allRecords: SchoolRecord[]
  public recordsByCategory: { [key: string]: SchoolRecord[] }
  public pdfUrl = `${environment.apiUrl}school_records/pdf`

  private unsubscribe: Subject<any> = new Subject()

  public constructor(
    private schoolRecordsService: SchoolRecordsService,
    private userService: UserService,
    private toastrService: ToastrService
  ) {
    const currYr = parseInt(environment.currentYear.split('-')[1], 10)
    for (let i = 0; i < 100; i++) {
      this.years.push({
        label: `${(currYr - i - 1)}-${(currYr - i)}`,
        value: (currYr - i)
      })
    }
  }

  public ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  public ngOnInit() {
    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        this.teamID = user.teamID
        this.loadRecords()
      })
  }

  public loadRecords(): void {
    this.loaded = false
    this.schoolRecordsService.findAll(this.teamID)
      .subscribe(result => {
        this.allRecords = result
        this.initRecords()
      })
  }

  private initRecords(): void {
    this.recordsByCategory = {}
    this.categories.forEach(cat => {
      this.recordsByCategory[cat.key] = this.allRecords.filter(r => {
        return r.category === cat.key
      }).splice(0, 15)
      // ensure 1 object at each row
      for (let i = 0; i < 15; i++) {
        if (!this.recordsByCategory[cat.key][i]) this.recordsByCategory[cat.key][i] = {} as SchoolRecord
      }
    })
    this.loaded = true
  }

  public edit(category: string, rank: number): void {
    this.editing[this.catRankStr(category, rank)] = true
  }

  public cancelEdit(category: string, rank: number): void {
    // @todo reset changes to record
    this.editing[this.catRankStr(category, rank)] = false
  }

  public save(category, rank): void {
    // @todo handle saving multiple edits at once?
    this.saving = true
    const record = this.catRank(category.key, rank)
    const data: any = {
      year: record.year,
      record: record.record,
      name: record.name,
    }
    //  @todo validate inputs
    let saver: Observable<SchoolRecord>
    if (record.ID) saver = this.schoolRecordsService.editRecord(record.ID, data)
    else {
      data.category = category.key
      data.teamID = this.teamID
      saver = this.schoolRecordsService.addRecord(data)
    }
    saver.subscribe(r => {
      this.editing[this.catRankStr(category, rank)] = false
      this.saving = false
      this.loadRecords()
    })
  }

  public isEditing(category, rank): boolean {
    return !!this.editing[this.catRankStr(category, rank)]
  }

  private catRankStr(category, rank): string {
    return `${category.key}-${rank}`
  }

  public rankName(catKey: string, rank: number): string {
    return (this.catRank(catKey, rank) || {} as SchoolRecord).name || '-'
  }

  public rankRecord(catKey: string, rank: number): string {
    const catRank = this.catRank(catKey, rank)
    if (!catRank || !catRank.record) return '-'
    const type = this.categories.find(c => c.key === catKey).type
    if (type === 'percent') return `${catRank.record.toFixed(2)}%`
    return catRank.record.toFixed()
  }

  public rankYear(catKey: string, rank: number): string {
    const catRank = this.catRank(catKey, rank)
    if (!catRank) return '-'
    const yr = parseInt(catRank.year, 10)
    if (!yr || yr < 1900 || yr > 2200) return '-'
    return `${(yr - 1).toFixed().substr(2)}-${yr.toFixed().substr(2)}`
  }

  private catRank(catKey: string, rank: number): SchoolRecord | undefined {
    return this.recordsByCategory[catKey][rank]
  }
}
