/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./confirm";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_ConfirmComponent = [];
var RenderType_ConfirmComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmComponent, data: {} });
export { RenderType_ConfirmComponent as RenderType_ConfirmComponent };
function View_ConfirmComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_ConfirmComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "mb-confirm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [["class", "modal-footer d-block text-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(12, null, [" ", " "])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(14, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.body; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.cancelLabel; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.confirmLabel; _ck(_v, 14, 0, currVal_3); }); }
export function View_ConfirmComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-confirm", [], null, null, null, View_ConfirmComponent_0, RenderType_ConfirmComponent)), i0.ɵdid(1, 49152, null, 0, i2.ConfirmComponent, [i3.NgbActiveModal], null, null)], null, null); }
var ConfirmComponentNgFactory = i0.ɵccf("mb-confirm", i2.ConfirmComponent, View_ConfirmComponent_Host_0, { title: "title", body: "body", confirmLabel: "confirmLabel", cancelLabel: "cancelLabel" }, {}, []);
export { ConfirmComponentNgFactory as ConfirmComponentNgFactory };
