import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { forkJoin } from 'rxjs'
import { first } from 'rxjs/operators'

import { User, Wrestler } from '../../models'
import { UserService, RosterService } from '../../services'

import { environment } from '../../../environments/environment'

@Component({
  selector: 'mb-setup253',
  templateUrl: './setup253.component.html'
})
export class Setup253Component implements OnInit {

  @Input() account: User

  public form: FormGroup
  public loaded = false
  public submitting = false
  public validate = false
  public wrestlers: Wrestler[]
  private wrestlersLoaded = false

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
	private rosterService: RosterService
  ) { }

  ngOnInit() {
	this.findRoster()
    //this.createForm()
  }

  public submitForm() {
	  this.submitting = true
	  this._submit()
  }

  private _submit(): void {
	const stream: any[] = []
	var numChanges = 0;
	var e = document.getElementsByTagName("select")
	for (var i = 0; i < e.length; i++){
	  var theValue = e[i].value
	  if (theValue.includes('#')) {
	      // add current year to years wrestled if necessary
		  var theParts = theValue.split('#')
		  var years = theParts[1]
		  var wrestlerID = theParts[0]
		  if (years.includes(environment.currentYear)) {
			  // do nothing
		  } else {
			  // add current year string and edit wrestler
			  var newYears = environment.currentYear + ',' + years
			  stream.push(
				this.rosterService.editWrestler(wrestlerID, { yearsWrestled: newYears })
		  	  )
			  numChanges++;
		  }
	  } else {
	     // change level to former
	     stream.push(
			this.rosterService.editWrestler(theValue, { Level: 'Former' })
		  )
		 numChanges++;
	  }
	}
	  
	if (numChanges < 1) {
		this.router.navigate(['/setup/14'])
	} else {
		forkJoin(stream)
		  .subscribe((data: any[]) => {
			this.router.navigate(['/setup/14'])
		  },
		  (err: any) => {
			console.warn('error', err)
			this.router.navigate(['/setup/14'])
		  })
	}
  }

  public goBack() {
    window.history.back()
  }

  // private

  private createForm(): void {
    this.form = this.fb.group({

	})
    this.loaded = true
  }

  private findRoster(): void {
    this.wrestlersLoaded = false
    this.rosterService.getRoster(this.account.teamID, {status: 'current'})
      .pipe(first())
      .subscribe((data: any) => {
        this.wrestlers = []
        data.result.forEach(item => {
          this.wrestlers.push(new Wrestler(item))
        })
        this.wrestlersLoaded = true
        this.ready()
      })
  }

  private ready(): void {
    if (!this.wrestlersLoaded) return
    //this.initImport()
    this.createForm()
  }
}
