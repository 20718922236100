import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subject } from 'rxjs'
import { first, takeUntil } from 'rxjs/operators'

import { MBMatchService } from '../../services'
import { MBMatch, MBMatchVideo, User } from '../../models'
import { periodOptions } from '../video-options'

@Component({
  selector: 'mb-video-watch',
  templateUrl: './video-watch.component.html',
  styleUrls: ['./video-watch.component.scss']
})
export class VideoWatchComponent implements OnInit {

  private unsubscribe: Subject<any> = new Subject()
  public error = ''
  public loaded = false
  public match: MBMatch
  public matchID: string = ''
  public matchVideo: MBMatchVideo
  public periodOptions = periodOptions
  public success = ''

  constructor(
    private acRoute: ActivatedRoute,
    private matchService: MBMatchService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initParams()
  }

  ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  private initParams(): void {
    this.acRoute.params
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((params: any) => {
        // @todo use atob btoa to convert data array of matchIDs
        this.matchID = decodeURIComponent(params.data)
        this.findMatch()
      })
  }

  private findMatch(): void {
    if (!this.matchID) return
    this.matchService.find(this.matchID)
      .pipe(first())
      .subscribe((data: any) => {
        this.match = new MBMatch(data.result)
        const USER: User = new User({}) // @Todo what should user be here?
        this.matchVideo = new MBMatchVideo(this.match, USER )
        this.loaded = true
      })
  }

}
