import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { first } from 'rxjs/operators'

import { User, ReportParams } from '../../models'
import { CustomValidators } from '../../util/forms/custom-validators'
import { ReportService } from '../../services'

@Component({
  selector: 'mb-send-to-contacts',
  templateUrl: './send-to-contacts.component.html',
  styleUrls: ['./send-to-contacts.component.scss']
})
export class SendToContactsComponent implements OnInit {

  @Input() reportType: string
  @Input() reportParams: ReportParams
  @Input() user: User

  public form: FormGroup
  public validate = false
  public submitting = false

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private reportService: ReportService
  ) { }

  public ngOnInit() {
    this.createForm()
  }

  public submit() {
    if (!this.form.valid) {
      this.validate = true
      return
    }
    this.submitting = true
    this.reportService.emailReport(
      this.reportType,
      this.reportParams,
      this.form.value.recipients,
      this.form.value.message
    ).pipe(first())
      .subscribe(
        (r: any) => {
          this.submitting = false
          this.activeModal.close({ success: true })
        },
        (e: any) => {
          this.submitting = false
          console.error('error sending email', e)
          this.activeModal.close({ success: false })
        }
      )
  }

  private createForm() {
    this.form = this.fb.group({
      recipients: [this.user.contactList || '', [
        Validators.required,
        CustomValidators.emailListValidator()
      ]],
      message: ['']
    })
  }
}
