<!-- TOOLS -->
<div class="mb-video-tools" *ngIf="doodle">
  <!-- color changer -->
  <div class="mb-color-changer"
    [ngClass]="{'mb-disabled': doodle.inInvisibleUI}">
    <h4 for="color" class="mb-color-changer__title">
      Sketch color:
    </h4>
    <input
      [disabled]="doodle.inInvisibleUI"
      (change)="doodle.changeColor()"
      type="color"
      title="change sketch color"
      value="#FF0000"
      class="mb-color-changer__input"
      [ngClass]="{'mb-disabled': doodle.inInvisibleUI}"
      #colorElem>
  </div>

  <div class="mb-video-tools__pallet">
    <div class="row no-gutters">
      <!-- circle tool -->
      <div class="col-sm-6 mb-video-tools__cell--br">
        <a class="drawingtools-item icon-circle"
          [ngClass]="{
            'drawingtools-item--disabled': !doodle.inCreateSketchMode,
            'drawingtools-item--active': doodle.inEllipseMode
          }"
          title="Add Oval"
          (click)="doodle.setEllipseMode()"></a>
      </div>
      <!-- eraser tool -->
      <div class="col-sm-6 mb-video-tools__cell--b">
        <a class="drawingtools-item icon-eraser"
          [ngClass]="{
            'drawingtools-item--disabled': !doodle.inCreateSketchMode
          }"
          title="Cancel New Sketch"
          (click)="doodle.cancelSketch()"></a>
      </div>
      <!-- arrow tool -->
      <div class="col-sm-6 mb-video-tools__cell--br">
        <a class="drawingtools-item icon-arrow"
          [ngClass]="{
            'drawingtools-item--disabled': !doodle.inCreateSketchMode,
            'drawingtools-item--active': doodle.inArrowMode
          }"
          title="Add Arrow"
          (click)="doodle.setArrowMode()"></a>
      </div>
      <!-- brush tool -->
      <div class="col-sm-6 mb-video-tools__cell--b">
        <a *ngIf="!doodle.inCreateSketchMode"
          class="drawingtools-item icon-brush"
          [ngClass]="{
            'drawingtools-item--disabled': !doodle.inDefaultUI
          }"
          title="Create New Freeform Sketch"
          (click)="doodle.createSketch()"></a>
        <a *ngIf="doodle.inCreateSketchMode"
          class="drawingtools-item icon-floppy"
          [ngClass]="{
            'drawingtools-item--disabled': !doodle.inDefaultUI
          }"
          (click)="doodle.commitSketch()"></a>
      </div>
      <!-- text tool -->
      <div class="col-sm-6 mb-video-tools__cell--r">
        <a class="drawingtools-item"
          [ngClass]="{
            'drawingtools-item--disabled': !doodle.inCreateSketchMode,
            'drawingtools-item--active': doodle.inTextMode
          }"
          title="Add Text"
          (click)="doodle.setTextMode()">AaBb</a>
      </div>
      <!-- toggle ui mode -->
      <div class="col-sm-6 mb-video-tools__cell">
        <a *ngIf="doodle.inDefaultUI"
          title="Watch Video Without Sketches"
          (click)="doodle.loadInvisibleUI()"
          class="drawingtools-item icon-ban"
          [ngClass]="{'drawingtools-item--disabled': doodle.inCreateSketchMode }"></a>
        <a *ngIf="doodle.inInvisibleUI"
          (click)="doodle.loadDefaultUI()"
          title="Watch Video With Sketches"
          class="drawingtools-item icon-OK"></a>
        <a *ngIf="doodle.inNoDoodleUI"
          (click)="doodle.noDoodleMode()"
          class="drawingtools-item icon-camera"></a>
      </div>
    </div><!-- .row -->
  </div><!-- pallet -->

  <!-- notes -->
  <div class="mb-video-tools__notes">
    <div class="notes-title">Notes</div>
    <textarea
      class="form-control"
      name="player-notes"
      [(ngModel)]="matchVideo.notes"
      rows="5"></textarea>
  </div>

  <!-- misc -->
  <div id="fileLoaderDiv" hidden>
    <h4 id="instructions"></h4>
    <input type="file"
      name="file"
      (change)="triggerFileNotUploadedFlag()">
    <input class="btn btn-primary"
      name="submit"
      type="submit"
      hidden>
  </div>

  <div style="margin-top: 5rem;">
    <a *ngIf="!saving"
      class="btn btn-full btn-primary"
      (click)="saveChanges()">Save Changes</a>
    <mb-ellipsis-loader
      *ngIf="saving"></mb-ellipsis-loader>
  </div>

  <div style="margin-top: 5rem;">
    <a *ngIf="!switchingColors"
      class="btn btn-full btn-light"
      (click)="switchColors()">Switch Colors</a>
    <mb-ellipsis-loader
      *ngIf="switchingColors"></mb-ellipsis-loader>
  </div>

</div><!-- tools -->
