import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import { first } from 'rxjs/operators'

import { User } from '../models'
import { UserService } from '../services/user.service'

@Component({
  selector: 'mb-settings',
  templateUrl: './settings.component.html',
  host: {'class': 'mb-page'}
})
export class SettingsComponent implements OnInit, OnDestroy {
  public account: User
  public paymentInfo: object
  public loaded = false
  private unsubscribe: Subject<any> = new Subject()

  public constructor(
    private userService: UserService
  ) { }

  public ngOnInit() {
    this.userService.requestCurrent()
      .pipe(first())
      .subscribe(
        (data: any) => {
            this.setUser(data)
        },
        (e: any) => { console.warn('error', e) }
      )
  }

  private getTeamPaymentStatus() {
    this.userService.teamPaymentStatus()
      .pipe(first())
      .subscribe(
        (data: any) => {
            this.paymentInfo = data.result
            this.loaded = true
        },
        (e: any) => { 
          console.warn('getTeamPaymentStatus, error', e) 
          this.paymentInfo = null
          this.loaded = true
        }
      )
  }

  public setUser(data: any) {
    if (data.success) {
      this.account = new User(data.result)
      this.userService.setCurrent(this.account)
      if ( this.account.isMainContact ) {
        this.getTeamPaymentStatus()
      } else {
        this.loaded = true
      }
      
    }
    else { console.warn('error', data.error) }
  }

  /**
   * unsubscribe
   */
  public ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

}
