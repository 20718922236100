<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <mb-alert
    *ngIf="error"
    type="danger"
    [message]="error"
    (closed)="error = null"
  ></mb-alert>
  <mb-alert
    *ngIf="resent"
    [message]="resent"
    (closed)="resent = null"
  ></mb-alert>
  <mb-alert
    *ngIf="copied"
    [message]="copied"
    (closed)="copied = null"
  ></mb-alert>
  <form [formGroup]="userForm" (ngSubmit)="submit()" novalidate>
    <div class="form-group">
      <label>First name</label>
      <input class="form-control" formControlName="first_name" />
      <mb-invalid
        [form]="userForm"
        [validate]="validate"
        field="first_name"
      ></mb-invalid>
    </div>
    <div class="form-group">
      <label>Last name</label>
      <input class="form-control" formControlName="last_name" />
      <mb-invalid
        [form]="userForm"
        [validate]="validate"
        field="last_name"
      ></mb-invalid>
    </div>
    <div class="form-group">
      <label>Email</label>
      <input class="form-control" formControlName="email" />
      <mb-invalid
        [form]="userForm"
        [validate]="validate"
        field="email"
      ></mb-invalid>
    </div>
    <div class="form-group" *ngIf="isWrestler()">
      <label>Wrestler Roster Entry</label>
      <select class="form-control" formControlName="wrestlerID">
        <option
          *ngFor="let wrestler of wrestlers"
          [value]="wrestler.wrestlerID"
        >
          {{ wrestler.Name }}
        </option>
      </select>
    </div>
  </form>
</div>

<div class="modal-footer d-block">
  <button
    type="button"
    class="btn btn-primary btn-block"
    *ngIf="!submitting"
    (click)="submit()"
  >
    Save
  </button>
  <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
  <div class="mt-4 text-center">
    <div class="mb-3">
      <button
        *ngIf="canResend(invite)"
        (click)="resendInvite(invite)"
        class="btn btn-sm btn-outline-secondary mr-2"
        style="text-transform: uppercase"
      >
        <img
          src="./assets/SVG/UI/Email.svg"
          alt="Icon"
          style="width: 16px; height: 16px"
        />
        Resend Invite Email
      </button>
    </div>
    <div class="mb-3">
      <button
        *ngIf="canCopy(invite)"
        (click)="copyInvite(invite)"
        class="btn btn-sm btn-outline-secondary"
        style="text-transform: uppercase"
      >
        <img
          src="./assets/SVG/UI/Duplicate.svg"
          alt="Icon"
          style="width: 16px; height: 16px"
        />
        Copy Invite Link URL
      </button>
    </div>
    <div>
      <button (click)="confirmDelete()" class="btn btn-outline-danger">
        Delete Invite
      </button>
    </div>
  </div>
</div>
