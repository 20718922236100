<div class="container container--inner">
  <h3 class="mb-page__head">
    <span class="mb-page__head-title">
      <img src="./assets/SVG/Page/Roster.svg" alt="Icon">
      Scorebook
    </span>
    <span
      *ngIf="user && user.isMainContact"
      class="mb-page__head-links">
      <a (click)="addEvent()"
        class="mx-4 mb-page__head-link">Add an event</a>
      <a 
        routerLink="/scorebook/import"
        preserveQueryParams
        class="mx-4 mb-page__head-link">Trackwrestling</a>
      <a 
        routerLink="/scorebook/trashed"
        preserveQueryParams
        class="mx-4 mb-page__head-link">Deleted Events</a>
    </span>
  </h3>

  <mb-scorebook-filters
    [params]="params"
    [user]="user"
    (submitted)="updateFilters($event)">
  </mb-scorebook-filters>

  <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>
  <mb-alert *ngIf="success" [message]="success" (closed)="success = null"></mb-alert>

  <mb-spinner *ngIf="!loaded"
    label="Loading Scorebook..."
    class="my-5 p-5 text-center"></mb-spinner>

  <div *ngIf="loaded" class="mb-scorebook__content">
    <div *ngIf="!events.length">
      There are no events in this scorebook.
    </div>
    <mb-event
      *ngFor="let event of events"
      (eventChanged)="onEventChanged($event)"
      [event]="event">
    </mb-event>
  </div>
</div>
