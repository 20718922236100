/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./ellipsis-loader";
var styles_EllipsisLoaderComponent = [];
var RenderType_EllipsisLoaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EllipsisLoaderComponent, data: {} });
export { RenderType_EllipsisLoaderComponent as RenderType_EllipsisLoaderComponent };
export function View_EllipsisLoaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "mb-ellipsis"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "inverse": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "mb-ellipsis"; var currVal_1 = _ck(_v, 3, 0, _co.isInverse); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_EllipsisLoaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-ellipsis-loader", [["style", "display: block; text-align: center;"]], null, null, null, View_EllipsisLoaderComponent_0, RenderType_EllipsisLoaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.EllipsisLoaderComponent, [], null, null)], null, null); }
var EllipsisLoaderComponentNgFactory = i0.ɵccf("mb-ellipsis-loader", i2.EllipsisLoaderComponent, View_EllipsisLoaderComponent_Host_0, { label: "label", type: "type" }, {}, []);
export { EllipsisLoaderComponentNgFactory as EllipsisLoaderComponentNgFactory };
