<p class="mb-event__summary">
  <span>{{ event.eventDate | date }}</span>
  <span class="px-2">•</span>
  <span>{{ event.eventType }}</span>
  <span *ngIf="loaded">
    <span class="px-2">•</span>
    <span>{{ displayLevel(event.levelID) }}</span>
  </span>
</p>
<div *ngIf="event.isDualMeet" class="mb-event__boxscore-row" test-id="dual-row1">
  <div class="mb-event__boxscore-team">
    {{ user.schoolName }}
  </div>
  <div class="mb-event__boxscore-score">
    {{ event.ourScore }}
  </div>
</div>
<div *ngIf="event.isDualMeet" class="mb-event__boxscore-row" test-id="dual-row2">
  <div class="mb-event__boxscore-team">
    {{ event.opponentName }}
  </div>
  <div class="mb-event__boxscore-score">
    {{ event.opponentScore }}
  </div>
</div>
<div *ngIf="event.isTournament" class="mb-event__boxscore-row" test-id="tournament-row">
  <div class="mb-event__boxscore-team">
    {{ event.eventName }}
  </div>
</div>
