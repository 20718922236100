<div class="modal-header">
    <h4 class="modal-title">Share Videos</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="!loaded">
      <mb-spinner></mb-spinner>
    </div>
    <form *ngIf="loaded"
      [formGroup]="form"
      (ngSubmit)="submit()"
      novalidate>
      <div class="row">
        <div class="col-sm-9">
          <div class="form-group">
            <label>Recipient Email Addresses</label>
            <input
              class="form-control"
              placeholder="email1@exmpale.com,email2@example.com,..."
              formControlName="emails">
            <mb-invalid
              [form]="form"
              [validate]="validate"
              field="emails"></mb-invalid>
          </div>
          <div class="form-group">
            <label>Enter a message</label>
            <textarea
              class="form-control"
              placeholder="optional"
              formControlName="message"></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer d-block">
    <button type="button"
      class="btn btn-primary"
      (click)="submit()">
      Share Matches
    </button>

    <button type="button"
      class="btn"
      (click)="cancel()">
      Cancel
    </button>
  </div>
