import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'

import { UserService } from './user.service'
import { User } from '../models/user'
import { EVENT_LEVELS, MBEventLevel } from '../util/mbevent-options'

@Injectable()
export class EventLevelsService {
  private subject: BehaviorSubject<MBEventLevel[]> = new BehaviorSubject<MBEventLevel[]>(EVENT_LEVELS)
  public current: Observable<MBEventLevel[]> = this.subject.asObservable()

  constructor(
    private userService: UserService
  ) {
    this.userService.current
      .subscribe((user: User) => {
        let changed = false
        if (user.level0Title && user.level0Title !== this.subject.value[1].label) {
          this.subject.value[1].label = user.level0Title
          changed = true
        }
        if (user.level1Title) {
          this.subject.value[2].label = user.level1Title
          changed = true
        }
        if (user.level2Title) {
          this.subject.value[3].label = user.level2Title
          changed = true
        }
        if (changed) this.setCurrent()
      })
  }

  private setCurrent() {
    this.subject.next(this.subject.value)
  }
}
