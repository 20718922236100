import { Component, OnInit, Input } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  FormControl,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";

import { Wrestler, UserRoleType, LimitedUserRoleList } from "../models";
import { RosterService, UserService } from "../services";

@Component({
  selector: "mb-invite-user",
  templateUrl: "./invite-user.component.html",
})
export class InviteUserComponent implements OnInit {
  @Input() teamID?: string;
  @Input() title?: string = "Invite Member";

  public error: string;
  public inviteForm: FormGroup;

  public roles: UserRoleType[] = LimitedUserRoleList;

  public wrestlers: Wrestler[];
  private _wrestlers: Wrestler[];

  public submitting = false;
  public validate = false;
  public loaded = false;
  private NAME_PATTERN = /^[a-zA-Z'` -]+$/;
  private ALPHANUM_PATTERN = /^[a-zA-Z0-9.]+$/;

  public constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private userService: UserService,
    private rosterService: RosterService
  ) {}

  public ngOnInit() {
    this.createForm();
    this.findRoster();
  }

  private findRoster() {
    this.loaded = false;
    this.rosterService
      .getRoster(this.teamID, { status: "current" })
      .pipe(first())
      .subscribe((data: any) => {
        this.wrestlers = [];
        this._wrestlers = undefined;
        data.result.forEach((item) => {
          this.wrestlers.push(new Wrestler(item));
        });
        this.sortWrestlers();
        this.createForm();
      });
  }

  private sortWrestlers(): void {
    this.wrestlers = _.orderBy(
      this.wrestlers,
      (w) => w["lastName"].toLowerCase(),
      "asc"
    ).map((w) => new Wrestler(w));
  }

  onWrestlerChange(wrestlerID: string) {
    // console.log(wrestlerID)
    const wrestler = this.wrestlers.find(
      (w: any) => w.wrestlerID === wrestlerID
    );
    // console.log(wrestler)
    this.inviteForm.patchValue({
      first_name: wrestler.firstName,
      last_name: wrestler.lastName,
      email: wrestler.emailAddress,
    });
  }

  public inviteWrestler() {
    // console.log('inviteWrestler', this.inviteForm.value.role)
    return this.inviteForm.value.role === "athlete-active";
  }

  public submit() {
    if (!this.inviteForm.valid) {
      this.validate = true;
      return;
    }
    const data: any = {
      first_name: this.inviteForm.value.first_name,
      last_name: this.inviteForm.value.last_name,
      email: this.inviteForm.value.email,
      role: this.inviteForm.value.role,
      wrestlerID:
        this.inviteForm.value.role === "athlete-active"
          ? this.inviteForm.value.wrestlerID
          : "",
    };
    this.submitting = true;
    this.userService
      .inviteTeamUser(data)
      .pipe(first())
      .subscribe(
        (r: any) => {
          // this.wrestler.update(r.result)
          console.log(r);
          this.activeModal.close({
            success: true,
            result: r.result,
          });
        },
        (e: any) => {
          this.submitting = false;
          console.warn("invite team user error: ", e.error.message);
          this.setError("Error saving invite: " + e.error.message);
          // this.activeModal.close({
          //   success: false,
          //   error: e,
          // });
        }
      );
  }

  private createForm() {
    this.inviteForm = this.fb.group(
      {
        first_name: [
          "",
          [Validators.required, Validators.pattern(this.NAME_PATTERN)],
        ],
        last_name: [
          "",
          [Validators.required, Validators.pattern(this.NAME_PATTERN)],
        ],
        email: ["", [Validators.required, Validators.email]],
        role: ["athlete-active", Validators.required],
        wrestlerID: [""],
      },
      { validator: this.wrestlerValidator() }
    );
  }

  private setError(msg: string): void {
    this.error = msg;
    // window.scroll(0, 0);
  }

  private wrestlerValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (
        control.get("role").value === "athlete-active" &&
        control.get("wrestlerID").value === ""
      ) {
        control.get("wrestlerID").setErrors({ required: true });
        return {
          wrestlerID: {
            value: control.get("wrestlerID").value,
            message: "Wrestler is required",
          },
        };
      }
      control.get("wrestlerID").setErrors(null);
      return null;
    };
  }
}
