import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { Tag } from '../models'

@Component({
  selector: 'mb-tag-edit',
  templateUrl: './tag-edit.component.html'
})
export class TagEditComponent implements OnInit {
  @Input() tag: Tag
  @Input() isNew: boolean
  public validate: boolean
  public form: FormGroup
  public positions: string[] = ['Neutral', 'Bottom', 'Top']

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.createForm()
  }

  public submit() {
    if (!this.form.valid) {
      this.validate = true
      return
    }
    this.tag.position = this.form.value.position
    this.tag.name = this.form.value.name
    this.tag.abbrev = this.form.value.abbrev
    this.activeModal.close({
      success: true,
      result: this.tag
    })
  }

  // PRIVATE

  private createForm() {
    this.form = this.fb.group({
      name: [this.tag.name, Validators.required],
      position: [this.tag.position, Validators.required],
      abbrev: [this.tag.abbrev, Validators.required]
    })
  }
}
