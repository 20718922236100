import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subject } from 'rxjs'
import { first } from 'rxjs/operators'

import { UserService } from '../services'
import { CustomValidators } from '../util/forms/custom-validators'

@Component({
  selector: 'mb-password-reset',
  templateUrl: './password-reset.component.html'
})
export class PasswordResetComponent implements OnInit {
  public form: FormGroup
  public error: string
  public success: string
  public validate = false
  public submitting = false
  public passwordMessages = {
    minlength: 'Password must be at least 8 characters',
    pattern: 'Please use numbers and letters only'
  }

  public email: string|null = null
  public token: string|null = null

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  public ngOnInit() {
    this.createForm()
    this.route.queryParams.subscribe(params => {
      if ( params.email ) {
        this.email = decodeURIComponent(params.email)
      }
      if ( params.token ) {
        this.token = decodeURIComponent(params.token)
      }
    })
  }

  public submit() {
    this.error = null
    if (!this.form.valid) {
      this.validate = true
      return false
    }
    this.submitting = true
    this.userService.resetPassword(
      this.formValue
    )
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.submitting = false
          if (data.status) this.setSuccess(data.status)
          else this.setError()
        },
        (err: any) => {
          console.log(err)
          this.submitting = false
          if ( err.error ) {
            this.setError(err.error.message + ' ' + Object.values(err.error.errors).map((v: any) => v.join(', ')))
          } else {
            this.setError()
          }
        }
      )
  }

  private setSuccess(message:string = 'Your email has been successfully changed, please try logging in once again.') {
    this.error = null
    this.success = message
  }

  private setError(message:string = 'An error occurred, we were unable to change your password.') {
    this.success = null
    this.error = message
  }

  private get formValue(): any {
    return {
      email: this.email,
      password: this.form.get('password').value.trim(),
      password_confirmation: this.form.get('confirmPassword').value.trim(),
      token: this.token
    }
  }

  private createForm() {
    const confirmValidators: any[] = [Validators.required]
    this.form = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^[a-zA-Z0-9]+$/)
      ]],
      confirmPassword: ['']
    })
    confirmValidators.push(
      CustomValidators.confirmValidator(this.form.get('password'))
    )
    this.form.get('confirmPassword')
      .setValidators(confirmValidators)
  }
}
