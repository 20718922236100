<div *ngIf="loaded" class="mb-scorebook__filters d-md-flex">
  <label>
    Filter by:
  </label>
  <select [(ngModel)]="filters.timeRange" (change)="submit()">
    <option *ngFor="let time of filters.timeRangeOptions"
      [value]="time.value">
      {{ time.label }}
    </option>
  </select>
  <select [(ngModel)]="filters.levelID" (change)="submit()">
    <option *ngFor="let level of filters.levelOptions"
      [value]="level.levelID">
      {{ level.label }}
    </option>
  </select>
  <select [(ngModel)]="filters.year" (change)="submit()">
    <option *ngFor="let yr of filters.yearOptions"
      [value]="yr">
      {{ yr }}
    </option>
  </select>
  <select [(ngModel)]="filters.sort" (change)="submit()">
    <option *ngFor="let s of filters.sortOptions"
      [value]="s.value">
      {{ s.label }}
    </option>
  </select>
  <!-- <select
  [(ngModel)]="filters.move" (change)="submit()">
  <option [value]="null">By Move</option>
  <optgroup label="For">
    <option *ngFor="let move of filters.moveOptions"
      [value]="move + ':F'">
      {{ move }}
    </option>
  </optgroup>
  <optgroup label="Against">
    <option *ngFor="let move of filters.moveOptions"
      [value]="move + ':A'">
      {{ move }}
    </option>
  </optgroup>
</select> -->
</div>
