/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../util/spinner.ngfactory";
import * as i2 from "../util/spinner";
import * as i3 from "../setup-wizard/setup4.component.ngfactory";
import * as i4 from "../setup-wizard/setup4.component";
import * as i5 from "../services/mbevent.service";
import * as i6 from "@angular/router";
import * as i7 from "../services/tw.service";
import * as i8 from "@angular/common";
import * as i9 from "./import-events.component";
import * as i10 from "../services/user.service";
var styles_ImportEventsComponent = [];
var RenderType_ImportEventsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImportEventsComponent, data: {} });
export { RenderType_ImportEventsComponent as RenderType_ImportEventsComponent };
function View_ImportEventsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-spinner", [["class", "my-5 p-5 text-center"], ["label", "Loading Scorebook..."], ["style", "display: block; text-align: center;"]], null, null, null, i1.View_SpinnerComponent_0, i1.RenderType_SpinnerComponent)), i0.ɵdid(1, 49152, null, 0, i2.SpinnerComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Loading Scorebook..."; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ImportEventsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "mb-scorebook__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "mb-setup4", [["next", "/scorebook"]], null, null, null, i3.View_Setup4Component_0, i3.RenderType_Setup4Component)), i0.ɵdid(2, 114688, null, 0, i4.Setup4Component, [i5.MBEventService, i6.Router, i7.TwService], { account: [0, "account"], next: [1, "next"], showSteps: [2, "showSteps"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.account; var currVal_1 = "/scorebook"; var currVal_2 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ImportEventsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 20, "div", [["class", "container container--inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "mb-breadcrumbs pb-3 mt-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "a", [["preserveQueryParams", ""], ["routerLink", "/scorebook"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"], preserveQueryParams: [1, "preserveQueryParams"] }, null), (_l()(), i0.ɵted(-1, null, ["\u2039 Back To Scorebook"])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "h3", [["class", "mb-page__head"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [["class", "mb-page__head-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "img", [["alt", "Icon"], ["src", "./assets/SVG/Page/Roster.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Import Your Scorebook from TrackWrestling "])), (_l()(), i0.ɵeld(9, 0, null, null, 6, "p", [["class", "alert alert-info"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" If you are not seeing the correct events listed below, please make sure your TrackWrestling settings are correct."])), (_l()(), i0.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(13, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(14, 2), (_l()(), i0.ɵted(-1, null, ["Go to the TrackWrestling settings page"])), (_l()(), i0.ɵeld(16, 0, null, null, 0, "hr", [["class", "mb-hr"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImportEventsComponent_1)), i0.ɵdid(18, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImportEventsComponent_2)), i0.ɵdid(20, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/scorebook"; var currVal_3 = ""; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_6 = _ck(_v, 14, 0, "/settings", "track-wrestling"); _ck(_v, 13, 0, currVal_6); var currVal_7 = !_co.loaded; _ck(_v, 18, 0, currVal_7); var currVal_8 = _co.loaded; _ck(_v, 20, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = i0.ɵnov(_v, 13).target; var currVal_5 = i0.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_4, currVal_5); }); }
export function View_ImportEventsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-import-events", [], null, null, null, View_ImportEventsComponent_0, RenderType_ImportEventsComponent)), i0.ɵdid(1, 114688, null, 0, i9.ImportEventsComponent, [i10.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImportEventsComponentNgFactory = i0.ɵccf("mb-import-events", i9.ImportEventsComponent, View_ImportEventsComponent_Host_0, {}, {}, []);
export { ImportEventsComponentNgFactory as ImportEventsComponentNgFactory };
