export class TeamPointAdjustment {
    public id: number
    public eventID: string
    public forOrAgainst: number    // for = 0, against = 1
    public type: string    // 'Win By Criteria', 'Unsportsmanlike Conduct'
    public teamPointDifference: number
    public matchWeight: string  // null for 'Win By Criteria'
    public deleted_at: string
    public created_at: string
    public updated_at: string

    public constructor(data: any) {
      Object.assign(this, data)
    }
}
