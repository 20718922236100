import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import * as _ from 'lodash'

import { User, Wrestler } from '../models'
import { UserService, RosterService } from '../services'

@Component({
  selector: 'mb-user-edit',
  templateUrl: './user-edit.component.html'
})
export class UserEditComponent implements OnInit {
  @Input() teamID?: string
  @Input() user: User
  @Input() title?: string = 'Edit Member Profile'

  public userActiveOptions = [
    {label: 'Profile is active.', value: 1},
    {label: 'Disabled, no login allowed.', value: 0}
  ]
  public userForm: FormGroup

  public wrestlers: Wrestler[]

  public submitting = false
  public validate = false
  public loaded = false
  private NAME_PATTERN = /^[a-zA-Z0-9'` -]+$/

  public constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private userService: UserService,
    private rosterService: RosterService
  ) { }

  public ngOnInit() {
    this.createForm()
    this.findRoster()
  }

  private findRoster() {
    this.loaded = false
    this.rosterService.getRoster(this.teamID, {status: 'current'})
      .pipe(first())
      .subscribe((data: any) => {
        this.wrestlers = []
        data.result.forEach(item => {
          this.wrestlers.push(new Wrestler(item))
        })
        this.sortWrestlers()
        this.createForm()
      })
  }

  private sortWrestlers(): void {
    this.wrestlers = _.orderBy(
        this.wrestlers,
        w => w['lastName'].toLowerCase(),
        'asc'
      )
      .map((w) => new Wrestler(w))
  }

  public isWrestler() {
    return this.user.userRole === 'athlete-active'
  }

  public submit() {
    if (!this.userForm.valid) {
      this.validate = true
      return
    }
    const data: any = {
      first_name: this.userForm.value.first_name,
      last_name: this.userForm.value.last_name,
      active: this.userForm.value.active,
      wrestlerID: this.user.userRole === 'athlete-active' ? this.userForm.value.wrestlerID : null
    }
    const result: Observable<any> = this.userService.updateTeamUser(this.user.userUID, data)
    this.submitting = true
    result.pipe(first())
      .subscribe(
        (r: any) => {
          this.user = new User(r.result)
          this.activeModal.close({
            success: true,
            result: this.user
          })
        },
        (e: any) => {
          console.warn('user edit error', e.error.message)
          this.activeModal.close({
            success: false,
            error: e
          })
        }
      )
  }

  private createForm() {
    this.userForm = this.fb.group({
      first_name: [this.user.userFirstName, [
        Validators.required,
        Validators.pattern(this.NAME_PATTERN)
      ]],
      last_name: [this.user.userLastName, [
        Validators.required,
        Validators.pattern(this.NAME_PATTERN)
      ]],
      active: [this.user.userActive],
      wrestlerID: [this.user.userRole === 'athlete-active' && this.user.userWrestler ? this.user.userWrestler.wrestlerID : null]
    })
  }

}
