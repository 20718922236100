<div class="container container--inner mb-settings">

  <div class="mb-breadcrumbs pb-3">
      <a preserveQueryParams routerLink="/settings">‹ Back To Settings</a>
  </div>
  
  <h3 class="mb-page__head">
    <span class="mb-page__head-title">
      Device Logins
    </span>
  </h3>

  <mb-spinner *ngIf="!loaded"
    label="Loading..."
    class="my-5 p-5 text-center"></mb-spinner>

  <div *ngIf="loaded">
      <div class="mb-pagination">
          <strong>{{ devices.length }} Devices</strong>
      </div>

      <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>
      <mb-alert *ngIf="success" [message]="success" (closed)="success = null"></mb-alert>

      <mb-device-filters
          [search]="search"
          (submitted)="updateFilters($event)"></mb-device-filters>

      <div class="table-responsive">
          <table class="table">
          <thead class="thead-dark">
              <tr>
                  <!-- <th class="d-none d-sm-table-cell text-nowrap"><a (click)="sort('Level')" class="mb-th__link">Squad</a></th> -->
                  <th class="text-nowrap"><a (click)="sort('name')" class="mb-th__link">Name</a></th>
                  <th class="d-none d-sm-table-cell text-nowrap"><a (click)="sort('created_at')" class="mb-th__link">Created</a></th>
                  <th class="d-none d-sm-table-cell text-nowrap"><a (click)="sort('last_used_at')" class="mb-th__link">Last Used</a></th>
                  <th></th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let device of filteredDevices">
                  <!-- <td class="d-none d-sm-table-cell">{{ levels[wrestler.Level] || '' }}</td> -->
                  <td>{{ device.name }}</td>
                  <td class="d-none d-sm-table-cell">{{ formatDate(device.created_at) }}</td>
                  <td class="d-none d-sm-table-cell">{{ formatDate(device.last_used_at) }}</td>
                  <td>
                      <button
                          (click)="revokeDevice(device)"
                          class="btn btn-sm btn-outline-secondary"
                          style="text-transform: uppercase">
                          <img src="./assets/SVG/UI/Remove.svg" alt="Icon" style="width: 16px; height: 16px">
                          Revoke
                      </button>
                  </td>
              </tr>
          </tbody>
          </table>
      </div>
  </div>

</div>