export class Tag {
  public TeamID: string
  public position: string
  public name: string
  public abbrev: string
  public objectID: string

  public constructor(data) {
    this.update(data)
    const s1: string = Date.now() + ''
    const s2: string = Math.random() + ''
    this.objectID = s1.substr(s1.length - 4, s1.length) + ':' + s2.substr(s2.length - 4, s2.length)
  }

  public update(data) {
    Object.assign(this, data)
  }
}
