export class Highlight {
  public ID: number
  public matchID: string
  public weightClass: string
  public theDate: Date
  public wrestlerID: string
  public description: string
  public move: string
  public startStop: string

  public ourWrestler: string
  public ourTeam: string
  public opponent: string
  public opposingTeam: string
  public winningWrestler: string
  public theResult: string // us | opponent
  public videoFileName: string

  public constructor(data: any) {
    this.update(data)
  }

  public get start(): string {
    if (!this.startStop) return ''
    return this.startStop.split('-')[0]
  }

  public get stop(): string {
    if (!this.startStop) return ''
    return this.startStop.split('-')[1]
  }

  public update(data: any): void {
    Object.assign(this, data)
    if (data.rowID) this.ID = data.rowID
  }
}
