import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable, Subject } from 'rxjs'
import { takeUntil, first } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'
import { FileUploader } from 'ng2-file-upload'
import * as S3 from 'aws-sdk/clients/s3';
import * as AWS from 'aws-sdk/global';

import { LibraryItem, User } from '../models'
import { LibraryService, UserService } from '../services'
import { toFormData } from '../util/forms/forms-util'
import { CustomValidators } from '../util/forms/custom-validators'
import { environment } from '../../environments/environment'

const MAX_UPLOAD_MB = 1024

@Component({
  selector: 'mb-library-item-edit',
  templateUrl: './library-item-edit.component.html'
})
export class LibraryItemEditComponent implements OnInit {

  @Input() teamID: string
  @Input() item: LibraryItem

  public user: User
  private unsubscribe: Subject<any> = new Subject()
  private uploader: FileUploader
  public validate: boolean
  public linkForm: FormGroup
  public uploadForm: FormGroup
  public categories: string[] = [
    'Neutral', 'Top', 'Bottom', 'Other'
  ]
  public submitting = false
  private URL_REG_EX: string = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'

  public hasDropZoneOver = false
  public uploadedFile: any
  public uploading = false
  public uploadProgress: number = 0

  constructor(
    private libService: LibraryService,
    private userService: UserService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService
  ) { }

  public ngOnInit(): void {
    this.createUploader()
    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        this.user = user
        this.createForms()
      })
  }

  public submitLink(): void {
    if (!this.linkForm.valid) {
      this.validate = true
      return
    }
    const data: any = {
      teamID: this.teamID,
      description: this.linkForm.value.description,
      url: this.linkForm.value.url,
      category: this.linkForm.value.category
    }
    const result: Observable<any> = this.item.ID ?
      this.libService.editItem(this.item.ID, data) :
      this.libService.addItem(data)

    this.submitting = true

    result.pipe(first())
      .subscribe(
        (r: any) => {
          console.log('item updated', r)
          this.submitting = false
          this.activeModal.close({
            success: true,
            result: this.item
          })
        },
        (e: any) => {
          console.error('library edit error', e.error.message)
          this.submitting = false
          this.activeModal.close({
            success: false,
            error: e
          })
        }
      )
  }

  public submitUploadData(finalFileName: string = null): void {
    if (!this.uploadForm.valid) {
      this.validate = true
      return
    }
    const formData = {
      ...this.uploadForm.value, 
      teamID: this.teamID,
      url: finalFileName
    }
    const data = toFormData(formData)

    const result: Observable<any> = this.item.ID ?
      this.libService.editItem(this.item.ID, data) :
      this.libService.addItem(data)

    this.submitting = true
    result.pipe(first())
      .subscribe(
        (r: any) => {
          this.item.update(r.result)
          this.submitting = false
          this.activeModal.close({
            success: true,
            result: this.item
          })
        },
        (e: any) => {
          console.warn('library item error', e.error.message)
          this.submitting = false
          this.activeModal.close({
            success: false,
            error: e
          })
        }
      )
  }

  public delete(): void {
    this.libService.deleteItem(this.item.ID)
      .pipe(first())
      .subscribe((r: any) => {
        this.submitting = false
        this.activeModal.close({
          success: true,
          result: 'deleted'
        })
      })
  }

  private createForms(): void {
    this.linkForm = this.fb.group({
      description: [this.item.description, Validators.required],
      url: [this.item.url, Validators.required],
      category: [this.item.category, Validators.required],
    })
    this.uploadForm = this.fb.group({
      category: [this.item.category, Validators.required],
      description: [this.item.description, Validators.required],
      // videoFile: [null, Validators.required]
    })
  }

  public fileDropOver(over: boolean): void {
    this.hasDropZoneOver = over
  }

  public fileDropped(files: any[]): void {
    this.setFile(files)
  }

  public fileSelected(files: any[]): void {
    this.setFile(files)
  }

  public submitUpload(): void {
    if (!this.uploadForm.valid) {
      this.validate = true
      console.log('invalid form')
      return
    }

    console.log(this.uploadedFile)
    if ( this.uploadedFile ) {
      this.uploading = true
      this.uploadProgress = 0
      this.uploadToS3(this.uploadedFile)
    } else {
      this.submitUploadData()
    }
  }

  private setFile(files: any[]): void {
    const file = files && files[0] || null
    if (!file) return

    // allow any file upload on library
    // if (file.type !== 'video/mp4' && file.type !== 'video/quicktime') {
    //   return this.setFileError('Please select an mp4 or mov video file')
    // }
    if (file.size > (MAX_UPLOAD_MB * 1024 * 1024)) {
      return this.setFileError(`This file exceeds the maximum size of ${MAX_UPLOAD_MB} MB`)
    }
    this.uploadedFile = file
  }

  private setFileError(msg: string): void {
    this.toastrService.error('Please select an mp4 or mov video file')
    this.uploadedFile = undefined
  }

  public clearUploads(): void {
    this.uploadedFile = undefined
  }

  private uploadToS3(file: any) {
    const contentType = file.type
    const extension = contentType !== "video/mp4" ? "." + file.name.split(".").pop() : ".mp4"
    const schoolName = this.user.schoolName.replace(/\s/g, "").toLowerCase()
    const finalFileName = "bulletinboard-internal-" + this.getUniqueId(4) + extension
    const key = schoolName + '.' + this.user.state.toLowerCase() + '/' + finalFileName
    const bucketName = this.user.bucketName + (environment.env !== "production" ? "-stage" : "")

    AWS.config.update({
      region: environment.awsRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: environment.awsIdentityPoolId,
      })
    })

    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    }
    // console.log(params)

    const upload = new S3.ManagedUpload({
      params: params
    })

    upload.on('httpUploadProgress', (progress:any) => {
      this.uploadProgress = (progress.loaded * 100) / progress.total
    })

    upload.promise().then(
      (data: any) => {
        // console.log('Successfully uploaded the file', data);
        this.submitUploadData(finalFileName)
      },
      (err: any) => {
        this.uploading = false;
        console.log('Error uploading the file', err);
        this.toastrService.error('There was an error uploading the file')
        return false;
      },
    )
  }

  private createUploader(): void {
    this.uploader = new FileUploader({
      url: `${environment.apiUrl}matches/library/`,
      itemAlias: 'videoFile',
      allowedMimeType: ['video/mp4', 'video/quicktime'],
      maxFileSize: 1024 * 1024 * MAX_UPLOAD_MB,
      method: 'post',
    })
  }

  /**
   * generate groups of 4 random characters
   * @example getUniqueId(1) : 607f
   * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
   */
   private getUniqueId(parts: number): string {
    const stringArr = [];
    for(let i = 0; i< parts; i++){
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

}
