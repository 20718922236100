import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'mb-jumbotron',
  templateUrl: './jumbotron.component.html',
  host: {'class': 'jumbotron'}
})
export class JumbotronComponent implements OnInit {

  @Input() messages: string[]

  constructor() { }

  ngOnInit() { }

}
