import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mb-video-shortcuts',
  templateUrl: './video-shortcuts.component.html'
})
export class VideoShortcutsComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
