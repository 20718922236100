<div class="mb-event__head">
  <mb-event-boxscore [event]="event"></mb-event-boxscore>

  <div *ngIf="user.isMainContact" class="mb-event__edit">
    <a (click)="edit()">
      <img src="./assets/SVG/UI/Edit.svg" alt="Edit">
      Edit
    </a>
  </div>
  <div *ngIf="user.isMainContact" class="mb-event__sync">
    <a (click)="export()">
      <img src="./assets/SVG/UI/Sync.svg" alt="Export">
      Export to<br>Trackwrestling {{ event.twid ? '' : '*' }}
    </a>
  </div>
</div>
<div class="mb-event__matches">
  <div class="mb-matches__counter">
    {{ event.match_count }} matches
  </div>
  <a 
    preserveQueryParams [routerLink]="['/event', event.encodedID]" 
    class="btn btn-lg btn-primary">
    Event details
  </a>
</div>
