/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../util/spinner.ngfactory";
import * as i2 from "../util/spinner";
import * as i3 from "../util/alert.ngfactory";
import * as i4 from "../util/alert";
import * as i5 from "@angular/common";
import * as i6 from "./library.component";
import * as i7 from "../services/library.service";
import * as i8 from "../services/user.service";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
var styles_LibraryComponent = [];
var RenderType_LibraryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LibraryComponent, data: {} });
export { RenderType_LibraryComponent as RenderType_LibraryComponent };
function View_LibraryComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "mx-4 mb-page__head-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Add a post"]))], null, null); }
function View_LibraryComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-spinner", [["class", "my-5 p-5 text-center"], ["label", "Loading Library..."], ["style", "display: block; text-align: center;"]], null, null, null, i1.View_SpinnerComponent_0, i1.RenderType_SpinnerComponent)), i0.ɵdid(1, 49152, null, 0, i2.SpinnerComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Loading Library..."; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LibraryComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-alert", [["type", "danger"]], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = ((_co.error = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertComponent_0, i3.RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i4.AlertComponent, [], { message: [0, "message"], type: [1, "type"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; var currVal_1 = "danger"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_LibraryComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-alert", [], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = ((_co.success = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertComponent_0, i3.RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i4.AlertComponent, [], { message: [0, "message"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.success; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LibraryComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "mb-link"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.url; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.description; _ck(_v, 1, 0, currVal_1); }); }
function View_LibraryComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "mb-link"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.internalUrl(_co.user); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.description; _ck(_v, 1, 0, currVal_1); }); }
function View_LibraryComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "mb-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editItem(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", "Edit"], ["src", "./assets/SVG/UI/Edit.svg"], ["style", "width: 24px;"], ["title", "Edit"]], null, null, null, null, null))], null, null); }
function View_LibraryComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "li", [["class", "list-group-item d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_10)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_11)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_12)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.isExternal; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.isExternal; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.user && _co.user.isMainContact); _ck(_v, 6, 0, currVal_2); }, null); }
function View_LibraryComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "mb-report-summary"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h5", [["class", "mb-report-summary__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_9)), i0.ɵdid(5, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.sections[_v.parent.context.$implicit].items; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sections[_v.parent.context.$implicit].title; _ck(_v, 2, 0, currVal_0); }); }
function View_LibraryComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_8)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.sections[_v.context.$implicit] && _co.sections[_v.context.$implicit].items) && _co.sections[_v.context.$implicit].items.length); _ck(_v, 2, 0, currVal_0); }, null); }
function View_LibraryComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "mb-link"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.url; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.description; _ck(_v, 1, 0, currVal_1); }); }
function View_LibraryComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "mb-link"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.internalUrl(_co.user); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.description; _ck(_v, 1, 0, currVal_1); }); }
function View_LibraryComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "mb-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editItem(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", "Edit"], ["src", "./assets/SVG/UI/Edit.svg"], ["style", "width: 24px;"], ["title", "Edit"]], null, null, null, null, null))], null, null); }
function View_LibraryComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "li", [["class", "list-group-item d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_15)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_16)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_17)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.isExternal; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.isExternal; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.user && _co.user.isMainContact); _ck(_v, 6, 0, currVal_2); }, null); }
function View_LibraryComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "row mt-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "mb-report-summary"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h5", [["class", "mb-report-summary__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_14)), i0.ɵdid(7, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.sections[2].items; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sections[2].title; _ck(_v, 4, 0, currVal_0); }); }
function View_LibraryComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_LibraryComponent_7)), i0.ɵdid(3, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(4, 3), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_13)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, 1, 3, 0); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.sections[2] && _co.sections[2].items) && _co.sections[2].items.length); _ck(_v, 6, 0, currVal_1); }, null); }
function View_LibraryComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["There are no items in the Library yet."]))], null, null); }
function View_LibraryComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_4)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_5)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_6)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_18)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.success; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.empty; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.empty; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_LibraryComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "container container--inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "h3", [["class", "mb-page__head"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "mb-page__head-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["alt", "Icon"], ["src", "./assets/SVG/Page/Messages.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Library "])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [["class", "mb-page__head-links"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_1)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_2)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibraryComponent_3)), i0.ɵdid(11, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.user && _co.user.isMainContact); _ck(_v, 7, 0, currVal_0); var currVal_1 = !_co.loaded; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.loaded; _ck(_v, 11, 0, currVal_2); }, null); }
export function View_LibraryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-library", [], null, null, null, View_LibraryComponent_0, RenderType_LibraryComponent)), i0.ɵdid(1, 245760, null, 0, i6.LibraryComponent, [i7.LibraryService, i8.UserService, i9.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LibraryComponentNgFactory = i0.ɵccf("mb-library", i6.LibraryComponent, View_LibraryComponent_Host_0, {}, {}, []);
export { LibraryComponentNgFactory as LibraryComponentNgFactory };
