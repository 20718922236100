<div class="container container--inner">
  <div class="mb-breadcrumbs pb-3 mt-3">
    <a preserveQueryParams routerLink="/scorebook">‹ Back To Scorebook</a>
  </div>

  <h3 class="mb-page__head">
    <span class="mb-page__head-title">
      <img src="./assets/SVG/Page/Roster.svg" alt="Icon" />
      Import Your Scorebook from TrackWrestling
    </span>
  </h3>

  <p class="alert alert-info">
    If you are not seeing the correct events listed below, please make sure your
    TrackWrestling settings are correct.<br />
    <a [routerLink]="['/settings', 'track-wrestling']"
      >Go to the TrackWrestling settings page</a
    >
  </p>

  <hr class="mb-hr" />

  <mb-spinner
    *ngIf="!loaded"
    label="Loading Scorebook..."
    class="my-5 p-5 text-center"
  ></mb-spinner>

  <div *ngIf="loaded" class="mb-scorebook__content">
    <mb-setup4
      [account]="account"
      next="/scorebook"
      [showSteps]="false"
    ></mb-setup4>
  </div>
</div>
