<mb-spinner *ngIf="!loaded"></mb-spinner>
<div *ngIf="loaded" class="row">
  <div class="col-4">
    <h6>Dual Meets</h6>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange($event)"
        value="dualmeetsummary"
        class="form-check-input"
        id="dual-dualmeetsummary"
      />
      <label class="form-check-label" for="dual-dualmeetsummary"
        >Dual meet summary</label
      >
    </div>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange($event)"
        value="dualeventanalysis"
        class="form-check-input"
        id="dual-eventanalysis"
      />
      <label class="form-check-label" for="dual-eventanalysis"
        >Side-by-side analysis</label
      >
    </div>
    <div *ngIf="eventType === 'dual'" class="mt-3">
      <select
        [(ngModel)]="eventID"
        (ngModelChange)="onEventIDChange()"
        class="form-control"
      >
        <option [ngValue]="undefined" disabled selected>
          - Choose Dual Meet -
        </option>
        <option *ngFor="let event of dualEvents" [value]="event.eventID">
          {{ event.eventName }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-2">&nbsp;</div>
  <div class="col-4">
    <h6>Tournaments</h6>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange()"
        value="tournamentsummary"
        class="form-check-input"
        id="tourney-tournamentsummary"
      />
      <label class="form-check-label" for="tourney-tournamentsummary"
        >Tournament analysis</label
      >
    </div>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange()"
        value="tourneyeventanalysis"
        class="form-check-input"
        id="tourney-eventanalysis"
      />
      <label class="form-check-label" for="tourney-eventanalysis"
        >Side-by-side analysis</label
      >
    </div>
    <div *ngIf="eventType === 'tourney'" class="mt-3">
      <select
        [(ngModel)]="eventID"
        (ngModelChange)="onEventIDChange()"
        class="form-control"
      >
        <option [ngValue]="undefined" disabled selected>
          - Choose Tournament -
        </option>
        <option *ngFor="let event of tourneyEvents" [value]="event.eventID">
          {{ event.eventName }}
        </option>
      </select>
    </div>
  </div>
</div>
