<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="wrestlerForm" (ngSubmit)="submit()" novalidate>
    <div class="form-group">
      <label>First name</label>
      <input class="form-control" formControlName="first_name" />
      <mb-invalid
        [form]="wrestlerForm"
        [validate]="validate"
        field="first_name"
      ></mb-invalid>
    </div>
    <div class="form-group">
      <label>Last name</label>
      <input class="form-control" formControlName="last_name" />
      <mb-invalid
        [form]="wrestlerForm"
        [validate]="validate"
        field="last_name"
      ></mb-invalid>
    </div>
    <!-- <div class="form-group">
      <label>Email</label>
      <input class="form-control" formControlName="email" />
      <mb-invalid
        [form]="wrestlerForm"
        [validate]="validate"
        field="email"
      ></mb-invalid>
    </div>
    <div class="form-group">
      <label class="d-block"
        ><input type="checkbox" formControlName="sendInvite" /> Send an invite
        email to this wrestler (if an invite does not already exist)</label
      >
    </div> -->
    <!-- <div class="form-group">
      <label>Username</label>
      <input class="form-control" formControlName="username" readonly>
    </div> -->
    <!-- <div class="form-group" *ngIf="!wrestler.userName && wrestler.ID">
      <label><a href="{{ inviteUrl() }}" target="_new">Create Wrestler Account Link <svg xmlns="http://www.w3.org/2000/svg" style="width: 16px; height: 16px;" viewBox="0 0 20 20" fill="currentColor">
        <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
        <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
      </svg></a></label>
    </div> -->
    <div class="form-group">
      <label>Level</label>
      <select class="form-control" formControlName="level">
        <option *ngFor="let level of levels" [value]="level.levelID">
          {{ level.label }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>Weight Class</label>
      <input class="form-control" formControlName="weight_class" />
      <mb-invalid
        [form]="wrestlerForm"
        [validate]="validate"
        field="weight_class"
      ></mb-invalid>
    </div>
    <div class="form-group">
      <label>Grade</label>
      <input class="form-control" formControlName="grade" />
      <mb-invalid
        [form]="wrestlerForm"
        [validate]="validate"
        field="grade"
      ></mb-invalid>
    </div>

    <div class="form-group">
      <label>Years Wrestled</label>
      <div class="form-row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="d-block" *ngFor="let i of [0, 1, 2]">
              <input
                type="checkbox"
                [formControl]="yearsWrestledArray.controls[i]"
              />
              {{ yearOptions[i] }}
            </label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="d-block" *ngFor="let i of [3, 4, 5]">
              <input
                type="checkbox"
                [formControl]="yearsWrestledArray.controls[i]"
              />
              {{ yearOptions[i] }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer d-block">
  <button
    type="button"
    class="btn btn-primary btn-block"
    *ngIf="!submitting"
    (click)="submit()"
  >
    Save
  </button>
  <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
</div>

<div class="modal-footer d-block">
  <div class="mb-subhead">
    * To invite this wrestler or to view their login profile, go to
    <a
      [routerLink]="['settings/users']"
      (click)="activeModal.dismiss('Cross click')"
      >Organization Members</a
    >
  </div>
</div>
