/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../util/spinner.ngfactory";
import * as i2 from "../../util/spinner";
import * as i3 from "../../util/ellipsis-loader.ngfactory";
import * as i4 from "../../util/ellipsis-loader";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "./setup33.component";
import * as i8 from "../../services/user.service";
import * as i9 from "@angular/router";
var styles_Setup33Component = [];
var RenderType_Setup33Component = i0.ɵcrt({ encapsulation: 2, styles: styles_Setup33Component, data: {} });
export { RenderType_Setup33Component as RenderType_Setup33Component };
function View_Setup33Component_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-spinner", [["style", "display: block; text-align: center;"], ["type", "ripple-inverse"]], null, null, null, i1.View_SpinnerComponent_0, i1.RenderType_SpinnerComponent)), i0.ɵdid(1, 49152, null, 0, i2.SpinnerComponent, [], { type: [0, "type"] }, null)], function (_ck, _v) { var currVal_0 = "ripple-inverse"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_Setup33Component_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary btn-lg"], ["style", "margin-right: 20px"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitAnswer("yes") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Yes "]))], null, null); }
function View_Setup33Component_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary btn-lg"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitAnswer("no") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" No "]))], null, null); }
function View_Setup33Component_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-ellipsis-loader", [["style", "display: block; text-align: center;"]], null, null, null, i3.View_EllipsisLoaderComponent_0, i3.RenderType_EllipsisLoaderComponent)), i0.ɵdid(1, 49152, null, 0, i4.EllipsisLoaderComponent, [], null, null)], null, null); }
function View_Setup33Component_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "mb-link"], ["style", "color:gainsboro"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["<< Back"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [["class", "mb-heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Step 3 of 3"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [["class", "mb-text"], ["style", "font-size: 20px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" App/device preparation: At the beginning of each season, you MUST disconnect/sign out of your account inside the MatBoss app and then sign back in again. This will generate new scorebooks for this season and clear your device's memory. Failure to do this will create problems. This needs to be done on EACH of your devices. Have you performed this task on each of your tablets? "])), (_l()(), i0.ɵeld(7, 0, null, null, 11, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 9).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 16384, null, 0, i5.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(9, 540672, null, 0, i5.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormGroupDirective]), i0.ɵdid(11, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i0.ɵeld(12, 0, null, null, 6, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Setup33Component_3)), i0.ɵdid(14, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Setup33Component_4)), i0.ɵdid(16, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Setup33Component_5)), i0.ɵdid(18, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 9, 0, currVal_7); var currVal_8 = !_co.submitting; _ck(_v, 14, 0, currVal_8); var currVal_9 = !_co.submitting; _ck(_v, 16, 0, currVal_9); var currVal_10 = _co.submitting; _ck(_v, 18, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 11).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 11).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 11).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 11).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 11).ngClassValid; var currVal_5 = i0.ɵnov(_v, 11).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 11).ngClassPending; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_Setup33Component_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_Setup33Component_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Setup33Component_2)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loaded; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.loaded; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_Setup33Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-setup33", [], null, null, null, View_Setup33Component_0, RenderType_Setup33Component)), i0.ɵdid(1, 114688, null, 0, i7.Setup33Component, [i5.FormBuilder, i8.UserService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Setup33ComponentNgFactory = i0.ɵccf("mb-setup33", i7.Setup33Component, View_Setup33Component_Host_0, { account: "account" }, {}, []);
export { Setup33ComponentNgFactory as Setup33ComponentNgFactory };
