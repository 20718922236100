import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { User, ReportParams } from '../models'
import { UserService, EventLevelsService, ReportService } from '../services'
import { MBEventLevel, EVENT_YEAR_OPTIONS } from '../util'
import { environment } from '../../environments/environment'

@Component({
  selector: 'mb-reports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent implements OnInit, OnDestroy {

  public loaded = false
  public user: User
  public error: string
  public reportGroup = 'team'
  public reportType: string
  private unsubscribe: Subject<any> = new Subject()
  private paramsLoaded = false

  public currentYear: string = environment.currentYear
  public yearOptions: string[] = EVENT_YEAR_OPTIONS
  public levelOptions: MBEventLevel[]
  public levelID = '0'

  public timePeriods: {label: string, value: string}[] = [
    {label: 'Entire Season', value: '0'},
    {label: 'Past Week', value: '1'},
    {label: 'Past Two Weeks', value: '2'},
    {label: 'Past Month', value: '3'},
  ]
  public timePeriod = '0'
  public opponentName: string
  public eventID: string
  public wrestlerID: string
  public catIndexArray: string[]

  constructor(
    private userService: UserService,
    private reportService: ReportService,
    private eventLevelsService: EventLevelsService,
    private acRoute: ActivatedRoute,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.initParams()
    this.initUser()
    this.initLevels()
  }

  private initParams(): void {
    this.acRoute.queryParams
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((params: any) => {
        if (params.year) this.currentYear = params.year
        if (params.levelID) this.levelID = params.levelID
        if (params.reportType) this.initReportType(params.reportType)
        if (params.timePeriod) this.timePeriod = params.timePeriod
        if (params.opponentName) this.opponentName = params.opponentName
        if (params.eventID) this.eventID = params.eventID
        if (params.wrestlerID) this.eventID = params.wrestlerID
        if (params.catIndexArray) {
          this.catIndexArray = params.catIndexArray
        }
        this.paramsLoaded = true
        this.ready()
      })
  }

  private initReportType(reportType: string): void {
    // ensure the correct report group tab is selected
    const groups: any = {
      // team
      teamduals: 'team',
      seasonteamanalysis: 'team',
      matchcounts: 'team',
      logins: 'team',
      matchlistingbyteam: 'team',
      categoryleaders: 'team',
      advancedtagreportteam: 'team',
      highlights: 'team',
      // event
      dualmeetsummary: 'event',
      eventanalysis: 'event',
      dualeventanalysis: 'event',
      tourneyeventanalysis: 'event',
      tournamentsummary: 'event',
      // individual
      individualmatchsummary: 'individual',
      individualanalysis: 'individual',
      advancedtagreportindividual: 'individual',
      // custom
      customreport: 'custom',
      // cumulative
      categoryleaderscumulative: 'cumulative',
      individualanalysiscareer: 'cumulative',
    }
    if (groups[reportType]) this.reportGroup = groups[reportType]
    this.reportType = reportType
  }

  private initUser(): void {
    this.userService.current
      .subscribe((user: User) => {
        if (!user || !user.loaded) return
        this.user = user
        if (this.user.isWrestler) this.reportGroup = 'individual'
        this.ready()
      })
  }

  private initLevels(): void {
    this.eventLevelsService.current
      .subscribe((levels: MBEventLevel[]) => {
        if (!levels || !levels.length) return
        // clone it
        this.levelOptions = levels.map(x => Object.assign({}, x))
        // remove ALL LEVELS option
        this.levelOptions.splice(0, 1)
        this.ready()
      })
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  public submit(): void {
    // @todo validate required extra params are included
    if (!this.reportType) {
      return this.setError('Choose a report type.')
    }
    if (this.reportType === 'individualseasonsummaryillinois') {
      return this.handleIllinoisReport()
    }
    const params = this.getReportParams()
    if (this.reportType === 'customreport' && params.catIndexArray && params.catIndexArray.length > 7) {
      return;
    } else {
      params.catIndexArrayString = params && params.catIndexArray && params.catIndexArray.join('~') || ""
    }
    this.router.navigate(['/reports/result'], { queryParams: params})
  }

  public setReportGroup(type: string): void {
    if (type === 'custom') this.reportType = 'customreport'
    // not sure about this logic, maybe should always set this to null on else?
    else if (this.reportType === 'custom') this.reportType = null
    this.reportGroup = type
  }

  private getReportParams(): any {
    const params: any = {
      year: this.currentYear,
      levelID: this.levelID,
      timePeriod: this.timePeriod,
      reportType: this.reportType
    }
    if (this.opponentName) params.opponentName = this.opponentName
    if (this.eventID) params.eventID = this.eventID
    if (this.wrestlerID) params.wrestlerID = this.wrestlerID
    if (this.catIndexArray) params.catIndexArray = this.catIndexArray

    return params
  }

  private handleIllinoisReport(): void {
    // @todo output as pdf
    const params = this.getReportParams()
    const reportParams = new ReportParams(params)
    reportParams.teamID = this.user.teamID
    const url: string = this.reportService.getReportPDFUrl(this.reportType, reportParams)
    window.open(url, '_blank')
  }

  private ready(): void {
    if (!this.user || !this.levelOptions || !this.paramsLoaded) return
    this.loaded = true
  }

  private setError(err: string): void {
    this.error = err
  }
}
