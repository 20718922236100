/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../util/spinner.ngfactory";
import * as i2 from "../util/spinner";
import * as i3 from "../util/alert.ngfactory";
import * as i4 from "../util/alert";
import * as i5 from "@angular/common";
import * as i6 from "./device-filters.component.ngfactory";
import * as i7 from "./device-filters.component";
import * as i8 from "@angular/router";
import * as i9 from "./devices.component";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
import * as i11 from "../services/user.service";
var styles_DevicesComponent = [];
var RenderType_DevicesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DevicesComponent, data: {} });
export { RenderType_DevicesComponent as RenderType_DevicesComponent };
function View_DevicesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-spinner", [["class", "my-5 p-5 text-center"], ["label", "Loading..."], ["style", "display: block; text-align: center;"]], null, null, null, i1.View_SpinnerComponent_0, i1.RenderType_SpinnerComponent)), i0.ɵdid(1, 49152, null, 0, i2.SpinnerComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Loading..."; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DevicesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-alert", [["type", "danger"]], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = ((_co.error = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertComponent_0, i3.RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i4.AlertComponent, [], { message: [0, "message"], type: [1, "type"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; var currVal_1 = "danger"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DevicesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-alert", [], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = ((_co.success = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertComponent_0, i3.RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i4.AlertComponent, [], { message: [0, "message"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.success; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DevicesComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "td", [["class", "d-none d-sm-table-cell"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "td", [["class", "d-none d-sm-table-cell"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "button", [["class", "btn btn-sm btn-outline-secondary"], ["style", "text-transform: uppercase"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.revokeDevice(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "img", [["alt", "Icon"], ["src", "./assets/SVG/UI/Remove.svg"], ["style", "width: 16px; height: 16px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Revoke "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.formatDate(_v.context.$implicit.created_at); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.formatDate(_v.context.$implicit.last_used_at); _ck(_v, 6, 0, currVal_2); }); }
function View_DevicesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 26, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "mb-pagination"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", " Devices"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DevicesComponent_3)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DevicesComponent_4)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "mb-device-filters", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.updateFilters($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DeviceFiltersComponent_0, i6.RenderType_DeviceFiltersComponent)), i0.ɵdid(9, 114688, null, 0, i7.DeviceFiltersComponent, [i7.DeviceFilters], { search: [0, "search"] }, { submitted: "submitted" }), (_l()(), i0.ɵeld(10, 0, null, null, 16, "div", [["class", "table-responsive"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 15, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 11, "thead", [["class", "thead-dark"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "th", [["class", "text-nowrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "a", [["class", "mb-th__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort("name") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Name"])), (_l()(), i0.ɵeld(17, 0, null, null, 2, "th", [["class", "d-none d-sm-table-cell text-nowrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "a", [["class", "mb-th__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort("created_at") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Created"])), (_l()(), i0.ɵeld(20, 0, null, null, 2, "th", [["class", "d-none d-sm-table-cell text-nowrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 1, "a", [["class", "mb-th__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort("last_used_at") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Last Used"])), (_l()(), i0.ɵeld(23, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DevicesComponent_5)), i0.ɵdid(26, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.error; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.success; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.search; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.filteredDevices; _ck(_v, 26, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.devices.length; _ck(_v, 3, 0, currVal_0); }); }
export function View_DevicesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "container container--inner mb-settings"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "mb-breadcrumbs pb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "a", [["preserveQueryParams", ""], ["routerLink", "/settings"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"], preserveQueryParams: [1, "preserveQueryParams"] }, null), (_l()(), i0.ɵted(-1, null, ["\u2039 Back To Settings"])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "h3", [["class", "mb-page__head"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "mb-page__head-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Device Logins "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DevicesComponent_1)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DevicesComponent_2)), i0.ɵdid(11, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/settings"; var currVal_3 = ""; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = !_co.loaded; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.loaded; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_DevicesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-devices", [["class", "mb-page"]], null, null, null, View_DevicesComponent_0, RenderType_DevicesComponent)), i0.ɵdid(1, 245760, null, 0, i9.DevicesComponent, [i10.NgbModal, i11.UserService, i8.Router, i5.DatePipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DevicesComponentNgFactory = i0.ɵccf("mb-devices", i9.DevicesComponent, View_DevicesComponent_Host_0, {}, {}, []);
export { DevicesComponentNgFactory as DevicesComponentNgFactory };
