import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";
import { CookieService, CookieOptions } from "ngx-cookie";

import { environment } from "../../environments/environment";
import { User } from "../models/user";

import { ApiService } from "./api.service";

@Injectable()
export class UserService {
  private dummy: User = new User({});
  private subject: BehaviorSubject<User> = new BehaviorSubject<User>(
    this.dummy
  );
  public current: Observable<User> = this.subject.asObservable();

  constructor(
    private apiService: ApiService,
    private cookieService: CookieService
  ) {}

  /**
   * sets current user from plain javascript object
   * @param data
   */
  public setCurrentFromData(data: any) {
    let user: User = new User(data);
    this.setCurrent(user);
  }

  /**
   * sets current user from User obejct
   * @param user
   */
  public setCurrent(user: User) {
    user.setAsLoaded();
    this.subject.next(user);
  }

  /**
   * finds currently logged in user or null
   * and sets that as userService.current
   */
  public findCurrent() {
    this.requestCurrent()
      .pipe(first())
      .subscribe((data: any) => {
        let props = data.result;
        // if we have a migrate flag on the current user response, set the same flag on the user account
        props.migrate = data.migrate || false;
        this.setCurrentFromData(props);
      });
  }

  /**
   * single api request for CSRF cookie
   */
  public requestInviteInfo(inviteCode: string): Observable<any> {
    return this.apiService.get(`auth/invite/${inviteCode}`);
  }
  /**
   * single api request for CSRF cookie
   */
  public requestCSRFToken(): Observable<any> {
    return this.apiService.get("csrf");
  }

  /**
   * single api request for current user
   */
  public requestCurrent(): Observable<any> {
    return this.apiService.get("auth/me");
  }

  /**
   * get full details of current user
   */
  public getInfo(): Observable<any> {
    return this.apiService.get("users", {
      params: this.currentUserParams,
    });
  }

  /**
   * update user info from account settings
   */
  public update(
    userName: string,
    userType: string,
    data: any
  ): Observable<any> {
    data.userType = userType;
    return this.apiService.update(
      "users/" + encodeURIComponent(userName),
      data
    );
  }

  /**
   * verify user info from setup wizard step 1
   */
  public verify(
    userName: string,
    userType: string,
    data: any
  ): Observable<any> {
    data.userType = userType;
    return this.apiService.update(
      "users/" + encodeURIComponent(userName) + "/verify",
      data
    );
  }

  /**
   * complete setup wizard
   */
  public completeSetup(
    userName: string,
    userType: string,
    data: any
  ): Observable<any> {
    data.userType = userType;
    return this.apiService.update(
      "users/" + encodeURIComponent(userName) + "/completesetup",
      data
    );
  }

  /**
   * get common user params
   */
  public get currentUserParams(): any {
    return {
      userName: this.subject.value.userName,
      userType: this.subject.value.userType,
    };
  }

  /**
   * send post to auth endpoint with user/pass info
   * @param data probably like: {userName: xxx, password: xxx}
   */
  public authenticate(data: any): Observable<any> {
    return this.apiService.post("auth/login", data);
  }

  /**
   * send post to auth endpoint with user/pass info
   * @param data probably like: {userName: xxx, password: xxx}
   */
  public authenticateWithNonce(nonce: string): Observable<any> {
    return this.apiService.get("auth/nonce/" + nonce);
  }

  /**
   * send post to recover pass endpoint
   * @param email
   * @todo create the endpoint to handle this
   */
  public recoverPassword(email: string): Observable<any> {
    return this.apiService.post("forgot-password", { email });
  }

  /**
   * send post to reset pass endpoint
   * @param email
   * @todo create the endpoint to handle this
   */
  public resetPassword(data: any): Observable<any> {
    return this.apiService.post("reset-password", data);
  }

  /**
   * send post to register a new account
   * @param data
   * @todo create endpoint
   */
  public createUser(data: any): Observable<any> {
    return this.apiService.post("users", data);
  }

  /**
   * signout - delete cookie - kill session
   * @todo create endpoint
   */
  public signOut(): Observable<any> {
    return this.apiService.post("auth/logout", {});
  }

  /**
   * update user info for a team member
   */
  public updateTeamUser(uid: string, data: any): Observable<any> {
    return this.apiService.update("team/user/" + encodeURIComponent(uid), data);
  }
  /**
   * update user info (self only)
   */
  public updateSelfUser(data: any): Observable<any> {
    return this.apiService.update("user", data);
  }

  /**
   * update user info for a team member
   */
  public updateTeamInvite(token: string, data: any): Observable<any> {
    return this.apiService.update(
      "team/user/invite/" + encodeURIComponent(token),
      data
    );
  }

  /**
   * team users, get list of users associated with the current user's account
   * @todo create endpoint
   */
  public teamUsers(status: string): Observable<any> {
    return this.apiService.get("team/users", {
      params: { status },
    });
  }

  /**
   * team user invites, get list of invites associated with the current user's account
   * @todo create endpoint
   */
  public teamInvites(status: string): Observable<any> {
    return this.apiService.get("team/user/invites", {
      params: { status },
    });
  }

  /**
   * Get the team's payment status for the previous and current season
   * @todo create endpoint
   */
  public teamPaymentStatus(): Observable<any> {
    return this.apiService.get("team/payment-status");
  }

  /**
   * user's device tokens
   * @todo create endpoint
   */
  public deviceTokens(): Observable<any> {
    return this.apiService.get("auth/tokens");
  }

  /**
   * delete a user's device token (thus logging them out)
   * @todo create endpoint
   */
  public deleteDeviceToken(id: number): Observable<any> {
    return this.apiService.delete("auth/token/" + encodeURIComponent(id + ""));
  }

  /**
   * delete a user's device token (thus logging them out)
   * @todo create endpoint
   */
  public deleteTeamInvite(token: string): Observable<any> {
    return this.apiService.delete(
      "team/user/invite/" + encodeURIComponent(token)
    );
  }

  /**
   * delete a user's device token (thus logging them out)
   * @todo create endpoint
   */
  public inviteTeamUser(data: any): Observable<any> {
    return this.apiService.post("team/user/invite", data);
  }
  /**
   * delete a user's device token (thus logging them out)
   * @todo create endpoint
   */
  public resendTeamInvite(data: any): Observable<any> {
    return this.apiService.post("team/invite/resend", data);
  }
}
