<!-- STEP 5 -->
<mb-spinner *ngIf="!loaded" type="ripple-inverse"></mb-spinner>
<div *ngIf="loaded">
  <a (click)="goBack()" class="mb-link" style="color:gainsboro">&lt;&lt; Back</a>
  <h2 class="mb-heading">Step 3 of 3</h2>
  <p class="mb-text" style="font-size: 20px">
    Have you updated the MatBoss app and iOS
        on your iPads to the most current versions? NOTE: It is best to delete the current version of the app on your iPad, install a fresh copy from the App Store, and then reconnect to your account.
  </p>
  <form [formGroup]="form">
	  <div class="form-group">
		  <button
			(click)="submitAnswer('yes')"
			type="button"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg"
			style="margin-right: 20px">
			  Yes
		  </button>
		  <button
			(click)="submitAnswer('no')"
			type="button"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg">
			  No
		  </button>
		  <mb-ellipsis-loader
			*ngIf="submitting"></mb-ellipsis-loader>
	   </div>
  </form>

</div>
