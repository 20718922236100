import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { first } from 'rxjs/operators'

import { User } from '../models'
import { UserService } from '../services'

@Component({
  selector: 'mb-setup1',
  templateUrl: './setup1.component.html'
})
export class Setup1Component implements OnInit {

  @Input() account: User

  public form: FormGroup
  public loaded = false
  public validate = false
  public submitting = false
  public messages = {
    pattern: 'Please use letters and spaces only'
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.createForm()
  }

  private createForm(): void {
    const alphaPattern = Validators.pattern(/^[a-zA-Z ]+$/)
    this.form = this.fb.group({
      mainContact: [this.account.contact, [
        Validators.required,
        alphaPattern
      ]],
      mainContactPhone: [this.account.contactPhone, [
        Validators.required
      ]],
      mainContactEmail: [this.account.contactEmail, [
        Validators.required,
        Validators.email
      ]],
	  secondaryContact: [this.account.secondaryContact, [
        Validators.required,
        alphaPattern
      ]],
	  secondaryContactPhone: [this.account.secondaryContactPhone, [
        Validators.required
      ]],
	  secondaryContactEmail: [this.account.secondaryContactEmail, [
        Validators.required,
        Validators.email
      ]]
    })
    this.loaded = true
  }

  public submit(): void {
    if (!this.form.valid) {
      this.validate = true
      return
    }
    this.submitting = true
    // if (this.form.pristine) {
    //   this.router.navigate(['/setup/2'])
    //   return
    // }
    const data: any = {
      contact: this.form.value.mainContact,
      contactEmail: this.form.value.mainContactEmail,
      contactPhone: this.form.value.mainContactPhone,
	    secondaryContact: this.form.value.secondaryContact,
      secondaryContactEmail: this.form.value.secondaryContactEmail,
      secondaryContactPhone: this.form.value.secondaryContactPhone
    }
    this.userService.verify(
      this.account.userName,
      this.account.userType,
      data
    )
    .pipe(first())
    .subscribe(
      (data: any): void => {
        if (data.result.ageLevel === 'Middle School' || data.result.ageLevel === 'Youth') {
          // navigate past the TW stuff automatically
          this.router.navigate(['/setup/13'])
        } else {
          this.router.navigate(['/setup/3'])
        }

      }
    )
  }
}
