/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../util/spinner.ngfactory";
import * as i2 from "../../util/spinner";
import * as i3 from "../video-playback/video-playback.component.ngfactory";
import * as i4 from "../video-playback/video-playback.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../services/mbmatch.service";
import * as i7 from "ngx-toastr";
import * as i8 from "../video-highlights/video-highlights.component.ngfactory";
import * as i9 from "../video-highlights/video-highlights.component";
import * as i10 from "../../services/highlights.service";
import * as i11 from "@angular/forms";
import * as i12 from "../video-options/video-options.component.ngfactory";
import * as i13 from "../video-options/video-options.component";
import * as i14 from "@angular/common";
import * as i15 from "./video-preview.component";
import * as i16 from "../../services/user.service";
var styles_VideoPreviewComponent = [];
var RenderType_VideoPreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VideoPreviewComponent, data: {} });
export { RenderType_VideoPreviewComponent as RenderType_VideoPreviewComponent };
function View_VideoPreviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-spinner", [["class", "my-5 p-5 text-center"], ["label", "Loading Video..."], ["style", "display: block; text-align: center;"]], null, null, null, i1.View_SpinnerComponent_0, i1.RenderType_SpinnerComponent)), i0.ɵdid(1, 49152, null, 0, i2.SpinnerComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Loading Video..."; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VideoPreviewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col-sm-7"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "mb-video-playback", [], null, [["window", "keypress"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keypress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).keypressEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).keydownEvent($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_VideoPlaybackComponent_0, i3.RenderType_VideoPlaybackComponent)), i0.ɵdid(5, 245760, null, 0, i4.VideoPlaybackComponent, [i5.NgbModal, i6.MBMatchService, i7.ToastrService], { matchVideo: [0, "matchVideo"], startTime: [1, "startTime"], doodle: [2, "doodle"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "col-sm-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "mb-video-highlights", [], null, null, null, i8.View_VideoHighlightsComponent_0, i8.RenderType_VideoHighlightsComponent)), i0.ɵdid(8, 114688, null, 0, i9.VideoHighlightsComponent, [i10.HighlightsService, i11.FormBuilder, i7.ToastrService], { matchVideo: [0, "matchVideo"], highlight: [1, "highlight"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "mb-video-options", [], null, null, null, i12.View_VideoOptionsComponent_0, i12.RenderType_VideoOptionsComponent)), i0.ɵdid(10, 114688, null, 0, i13.VideoOptionsComponent, [], { doodle: [0, "doodle"], matchVideo: [1, "matchVideo"], user: [2, "user"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.matchVideo; var currVal_1 = _co.startTime; var currVal_2 = _co.doodle; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.matchVideo; var currVal_4 = _co.highlight; _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = _co.doodle; var currVal_6 = _co.matchVideo; var currVal_7 = _co.user; _ck(_v, 10, 0, currVal_5, currVal_6, currVal_7); }, null); }
export function View_VideoPreviewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "mb-video mb-video-preview"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "mb-breadcrumbs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePreview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2039 Back"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoPreviewComponent_1)), i0.ɵdid(5, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoPreviewComponent_2)), i0.ɵdid(7, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loaded; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.loaded; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_VideoPreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-video-preview", [], null, null, null, View_VideoPreviewComponent_0, RenderType_VideoPreviewComponent)), i0.ɵdid(1, 245760, null, 0, i15.VideoPreviewComponent, [i16.UserService, i6.MBMatchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoPreviewComponentNgFactory = i0.ɵccf("mb-video-preview", i15.VideoPreviewComponent, View_VideoPreviewComponent_Host_0, { matchID: "matchID", highlight: "highlight", startTime: "startTime" }, { close: "close" }, []);
export { VideoPreviewComponentNgFactory as VideoPreviewComponentNgFactory };
