export class Wrestler {
  public ID: number;
  public wrestlerID: string;
  public twid: string;
  public Name: string;
  public Grade: string;
  public WeightClass: string;
  public Record: string;
  public Level: string;
  public TeamID: string;
  public timestamp: string;
  public Cert: string;
  public Seed: number;
  public userName: string;
  public emailAddress: string;
  public yearsWrestled: string;
  public twEligible: string;

  private _firstName: string;
  private _lastName: string;

  public users: any[];

  public constructor(data: any) {
    this.update(data);
    if (data.users) this.users = data.users;
  }

  private parseName() {
    if (!this.Name) return;
    let e = this.Name.split(",");
    this._firstName = e.length > 1 ? e[1].trim() : null;
    this._lastName = e[0].trim();
  }

  public update(data: any) {
    Object.assign(this, data);
    this.parseName();
  }

  get firstName(): string {
    if (this._firstName === undefined) this.parseName();
    return this._firstName;
  }

  set firstName(n: string) {
    this._firstName = n;
  }

  get lastName(): string {
    if (this._lastName === undefined) this.parseName();
    return this._lastName;
  }

  set lastName(n: string) {
    this._lastName = n;
  }

  get fullName(): string {
    return [this.firstName, this.lastName].filter((n) => n).join(" ");
  }

  get isEditable(): boolean {
    // If wrestler.last_name or first_name contains ‘Forfeit’ - do not allow editing
    // if wrestler.first_name == ‘Scout’ AND last_name == ‘Wrestler’ - do not allow editing
    if (this.lastName.toLowerCase().indexOf("forfeit") !== -1) return false;
    if (this.firstName) {
      if (this.firstName.toLowerCase().indexOf("forfeit") !== -1) return false;
      if (
        this.firstName.toLowerCase() === "scout" &&
        this.lastName.toLowerCase() === "wrestler"
      )
        return false;
    }
    return true;
  }
}
