/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../util/spinner.ngfactory";
import * as i2 from "../util/spinner";
import * as i3 from "../util/alert.ngfactory";
import * as i4 from "../util/alert";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./user-filters.component.ngfactory";
import * as i8 from "./user-filters.component";
import * as i9 from "./users.component";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
import * as i11 from "../services/user.service";
var styles_UsersComponent = [];
var RenderType_UsersComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UsersComponent, data: {} });
export { RenderType_UsersComponent as RenderType_UsersComponent };
function View_UsersComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-spinner", [["class", "my-5 p-5 text-center"], ["label", "Loading..."], ["style", "display: block; text-align: center;"]], null, null, null, i1.View_SpinnerComponent_0, i1.RenderType_SpinnerComponent)), i0.ɵdid(1, 49152, null, 0, i2.SpinnerComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Loading..."; _ck(_v, 1, 0, currVal_0); }, null); }
function View_UsersComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-alert", [["type", "danger"]], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = ((_co.error = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertComponent_0, i3.RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i4.AlertComponent, [], { message: [0, "message"], type: [1, "type"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; var currVal_1 = "danger"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_UsersComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-alert", [], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = ((_co.success = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertComponent_0, i3.RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i4.AlertComponent, [], { message: [0, "message"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.success; _ck(_v, 1, 0, currVal_0); }, null); }
function View_UsersComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-sm btn-outline-secondary"], ["style", "text-transform: uppercase"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editUser(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", "Icon"], ["src", "./assets/SVG/UI/Edit.svg"], ["style", "width: 16px; height: 16px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Edit "]))], null, null); }
function View_UsersComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "td", [["style", "border-top: 0 !important; padding-top: 0 !important"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "td", [["colspan", "5"], ["style", "border-top: 0 !important; padding-top: 0 !important"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Roster Entry: "])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "a", [["routerLink", "/roster"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i0.ɵpod(6, { search: 0 }), (_l()(), i0.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 6, 0, _v.parent.context.$implicit.formattedWrestler); var currVal_3 = "/roster"; _ck(_v, 5, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).target; var currVal_1 = i0.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.context.$implicit.formattedWrestler; _ck(_v, 7, 0, currVal_4); }); }
function View_UsersComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "td", [["class", "d-none d-sm-table-cell"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "td", [["class", "d-none d-sm-table-cell"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "td", [["class", "d-none d-sm-table-cell"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", " "])), (_l()(), i0.ɵeld(10, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersComponent_6)), i0.ɵdid(12, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersComponent_7)), i0.ɵdid(14, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.isEditable(_v.context.$implicit); _ck(_v, 12, 0, currVal_4); var currVal_5 = _v.context.$implicit.isWrestler; _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.formattedRole; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.userFullName; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.userEmail; _ck(_v, 7, 0, currVal_2); var currVal_3 = ((_v.context.$implicit.userActive === 1) ? "Active" : "Disabled"); _ck(_v, 9, 0, currVal_3); }); }
function View_UsersComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-sm btn-outline-secondary"], ["style", "text-transform: uppercase"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editInvite(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", "Icon"], ["src", "./assets/SVG/UI/Edit.svg"], ["style", "width: 16px; height: 16px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Edit "]))], null, null); }
function View_UsersComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["routerLink", "/roster"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i0.ɵpod(2, { search: 0 }), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, _v.parent.parent.context.$implicit.formattedWrestler); var currVal_3 = "/roster"; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.parent.context.$implicit.formattedWrestler; _ck(_v, 3, 0, currVal_4); }); }
function View_UsersComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Unknown "]))], null, null); }
function View_UsersComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "td", [["style", "border-top: 0 !important; padding-top: 0 !important"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "td", [["colspan", "5"], ["style", "border-top: 0 !important; padding-top: 0 !important"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Roster Entry: "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersComponent_12)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersComponent_13)), i0.ɵdid(7, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.formattedWrestler != ""); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.formattedWrestler == ""); _ck(_v, 7, 0, currVal_1); }, null); }
function View_UsersComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "td", [["class", "d-none d-sm-table-cell"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "td", [["class", "d-none d-sm-table-cell"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "td", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersComponent_10)), i0.ɵdid(10, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersComponent_11)), i0.ɵdid(12, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isInviteEditable(_v.context.$implicit); _ck(_v, 10, 0, currVal_3); var currVal_4 = _v.context.$implicit.isWrestler; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.formattedRole; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.fullName; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.email; _ck(_v, 7, 0, currVal_2); }); }
function View_UsersComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "table-responsive"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 15, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 11, "thead", [["class", "thead-dark"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "th", [["class", "d-none d-sm-table-cell text-nowrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["class", "mb-th__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort("userRole") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Type"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "th", [["class", "text-nowrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "a", [["class", "mb-th__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort("userFullName") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Name"])), (_l()(), i0.ɵeld(10, 0, null, null, 2, "th", [["class", "d-none d-sm-table-cell text-nowrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "a", [["class", "mb-th__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort("userEmail") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Email"])), (_l()(), i0.ɵeld(13, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersComponent_9)), i0.ɵdid(16, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filteredInvites; _ck(_v, 16, 0, currVal_0); }, null); }
function View_UsersComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 40, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "mb-pagination"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", " Member Profiles"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersComponent_3)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersComponent_4)), i0.ɵdid(7, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "mb-user-filters", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.updateFilters($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_UserFiltersComponent_0, i7.RenderType_UserFiltersComponent)), i0.ɵdid(9, 114688, null, 0, i8.UserFiltersComponent, [i8.UserFilters], { search: [0, "search"] }, { submitted: "submitted" }), (_l()(), i0.ɵeld(10, 0, null, null, 19, "div", [["class", "table-responsive"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 18, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 14, "thead", [["class", "thead-dark"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 13, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "th", [["class", "d-none d-sm-table-cell text-nowrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "a", [["class", "mb-th__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort("userRole") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Type"])), (_l()(), i0.ɵeld(17, 0, null, null, 2, "th", [["class", "text-nowrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "a", [["class", "mb-th__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort("userFullName") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Name"])), (_l()(), i0.ɵeld(20, 0, null, null, 2, "th", [["class", "d-none d-sm-table-cell text-nowrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 1, "a", [["class", "mb-th__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort("userEmail") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Email"])), (_l()(), i0.ɵeld(23, 0, null, null, 2, "th", [["class", "d-none d-sm-table-cell text-nowrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 1, "a", [["class", "mb-th__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort("userActive") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Status"])), (_l()(), i0.ɵeld(26, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersComponent_5)), i0.ɵdid(29, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(30, 0, null, null, 2, "h3", [["class", "mb-page__head"]], null, null, null, null, null)), (_l()(), i0.ɵeld(31, 0, null, null, 1, "span", [["class", "mb-page__head-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Pending Invites "])), (_l()(), i0.ɵeld(33, 0, null, null, 2, "div", [["class", "mb-pagination pb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(34, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(35, null, ["", " Invites"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersComponent_8)), i0.ɵdid(37, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(38, 0, null, null, 2, "div", [["class", "mb-details-link"]], null, null, null, null, null)), (_l()(), i0.ɵeld(39, 0, null, null, 1, "a", [["class", "btn btn-lg btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.inviteUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Invite New Member"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.error; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.success; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.search; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.filteredUsers; _ck(_v, 29, 0, currVal_4); var currVal_6 = (_co.filteredInvites.length > 0); _ck(_v, 37, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.users.length; _ck(_v, 3, 0, currVal_0); var currVal_5 = _co.filteredInvites.length; _ck(_v, 35, 0, currVal_5); }); }
export function View_UsersComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "container container--inner mb-settings"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "mb-breadcrumbs pb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "a", [["preserveQueryParams", ""], ["routerLink", "/settings"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"], preserveQueryParams: [1, "preserveQueryParams"] }, null), (_l()(), i0.ɵted(-1, null, ["\u2039 Back To Settings"])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "h3", [["class", "mb-page__head"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "mb-page__head-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Organization Members "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersComponent_1)), i0.ɵdid(9, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersComponent_2)), i0.ɵdid(11, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/settings"; var currVal_3 = ""; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = !_co.loaded; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.loaded; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_UsersComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-users", [["class", "mb-page"]], null, null, null, View_UsersComponent_0, RenderType_UsersComponent)), i0.ɵdid(1, 245760, null, 0, i9.UsersComponent, [i10.NgbModal, i11.UserService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UsersComponentNgFactory = i0.ɵccf("mb-users", i9.UsersComponent, View_UsersComponent_Host_0, {}, {}, []);
export { UsersComponentNgFactory as UsersComponentNgFactory };
