/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./boxscore.component";
import * as i3 from "../services/user.service";
import * as i4 from "../services/event-levels.service";
var styles_BoxscoreComponent = [];
var RenderType_BoxscoreComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BoxscoreComponent, data: {} });
export { RenderType_BoxscoreComponent as RenderType_BoxscoreComponent };
function View_BoxscoreComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "px-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2022"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayLevel(_co.event.levelID); _ck(_v, 4, 0, currVal_0); }); }
function View_BoxscoreComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "mb-event__boxscore-row"], ["test-id", "dual-row1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "mb-event__boxscore-team"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "mb-event__boxscore-score"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.schoolName; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.event.ourScore; _ck(_v, 4, 0, currVal_1); }); }
function View_BoxscoreComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "mb-event__boxscore-row"], ["test-id", "dual-row2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "mb-event__boxscore-team"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "mb-event__boxscore-score"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event.opponentName; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.event.opponentScore; _ck(_v, 4, 0, currVal_1); }); }
function View_BoxscoreComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "mb-event__boxscore-row"], ["test-id", "tournament-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "mb-event__boxscore-team"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event.eventName; _ck(_v, 2, 0, currVal_0); }); }
export function View_BoxscoreComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 9, "p", [["class", "mb-event__summary"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "px-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2022"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BoxscoreComponent_1)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BoxscoreComponent_2)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BoxscoreComponent_3)), i0.ɵdid(14, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BoxscoreComponent_4)), i0.ɵdid(16, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.loaded; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.event.isDualMeet; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.event.isDualMeet; _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.event.isTournament; _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v, 0), _co.event.eventDate)); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.event.eventType; _ck(_v, 8, 0, currVal_1); }); }
export function View_BoxscoreComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-event-boxscore", [["class", "mb-event__boxscore"], ["style", "display: block"]], null, null, null, View_BoxscoreComponent_0, RenderType_BoxscoreComponent)), i0.ɵdid(1, 245760, null, 0, i2.BoxscoreComponent, [i3.UserService, i4.EventLevelsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BoxscoreComponentNgFactory = i0.ɵccf("mb-event-boxscore", i2.BoxscoreComponent, View_BoxscoreComponent_Host_0, { event: "event" }, {}, []);
export { BoxscoreComponentNgFactory as BoxscoreComponentNgFactory };
