import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'

import { EventEditComponent } from './event-edit.component'
import { MBEvent, MBMatch, User } from '../models'
import { UserService, MBEventService } from '../services'
import { TwLoginComponent } from '../trackwrestling/tw-login/tw-login.component'

@Component({
  selector: 'mb-event',
  templateUrl: './event.component.html',
  host: { class: 'mb-event' }
})
export class EventComponent implements OnInit, OnDestroy {
  @Input() event:MBEvent
  @Output() eventChanged = new EventEmitter()

  public error: string = null
  public success: string = null
  public exporting = false
  public matches: MBMatch[]
  public user: User
  private unsubscribe: Subject<any> = new Subject()

  /**
   * params are provided via dependency injection
   */
  constructor(
    public userService: UserService,
    private modalService: NgbModal,
    private eventService: MBEventService,
    private toastrService: ToastrService
  ) {}

  /**
   * open edit modal
   */
  public edit(): void {
    const modalRef = this.modalService.open(EventEditComponent, { size: 'sm' })
    modalRef.componentInstance.event = this.event
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.eventChanged.emit(fulfilledValue)
        } else {
          // @todo show alert error
          console.warn('edit event failed', fulfilledValue.error)
        }
      },
      (rejectedValue: any) => {
        // @todo show alert error
        console.warn('edit event rejected', rejectedValue)
      }
    )
  }

  /**
   * init
   */
  public ngOnInit() {
    this.userService.current.pipe(takeUntil(this.unsubscribe))
      .subscribe((user: any) => this.user = user)
  }

  /**
   * unsubscribe
   */
  public ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  public export(): void {
    this.exporting = true
    const modalRef = this.modalService.open(TwLoginComponent, { size: 'sm' })
    modalRef.componentInstance.event = this.event
    modalRef.componentInstance.user = this.user
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.toastrService.success('The event has been exported')
        }
        else this.handleExportError(fulfilledValue)
      },
      (rejectedValue: any) => {
        if (rejectedValue === 'Cross click' || rejectedValue === 0 || rejectedValue === 1) return
        this.handleExportError(rejectedValue)
      }
    )
  }

  // @todo DRY this function into module
  private handleExportError(r: any): void {
    let msg: string = ''
    if (r && r.result) {
      if (r.result.error && r.result.error.message) msg = r.result.error.message
      else if (r.result.message) msg = r.result.message
      else if (r.result.statusText) msg = r.result.statusText
    }
    else if (r && r.message) msg = r.message

    this.toastrService.error('There was an error exporting the event: ' + msg)
  }

  private setError(e: string, o: any): void {
    this.error = e
  }

  private setSuccess(msg: string): void {
    this.success = msg
    window.scroll(0, 0)
  }
}
