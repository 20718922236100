import { Tag } from "./tag";
import { Wrestler } from "./wrestler";
import { MBMatch } from "./mbmatch";

export type UserRoleType = {
  label: string;
  description: string;
  value: string;
};

export const UserRoleList: Array<UserRoleType> = [
  {
    label: "Admin Superuser",
    description:
      "Has full control of the entire system, including system configuration",
    value: "admin-super-user",
  },
  {
    label: "Admin Support",
    description: "Has full control of client/customer information",
    value: "admin-support-user",
  },
  {
    label: "Account Owner",
    description:
      "Has full control of their account, including account configuration",
    value: "account-owner",
  },
  {
    label: "Account Admin",
    description: "Has full control of the roster, events and matches",
    value: "account-admin",
  },
  {
    label: "Account Manager",
    description: "Has control to add events and record matches",
    value: "account-manager",
  },
  {
    label: "Active Athlete",
    description:
      "A current athlete on the roster, can view all team matches (depending on account configuration)",
    value: "athlete-active",
  },
  {
    label: "Limited Athlete",
    description:
      "Often a former athelete that can only view matches they were part of",
    value: "athlete-limited",
  },
  {
    label: "Fan",
    description:
      "A fan (i.e. a parent or friend) of a specific athlete, can view matches of the associated athlete",
    value: "fan",
  },
  {
    label: "Local User",
    description:
      "A limited user, can only use the local features of the application (meaning no cloud/website features)",
    value: "local-user",
  },
];

export const LimitedUserRoleList: Array<UserRoleType> = [
  {
    label: "Coach",
    description:
      "Has full control of their account, including account configuration",
    value: "account-owner",
  },
  {
    label: "Wrestler",
    description:
      "A current athlete on the roster, can view all team matches (depending on account configuration)",
    value: "athlete-active",
  },
];

export class User {
  // these are the new User model properties, they should eventually be the only items on the User class
  // and the rest of the properties should be in an associated Account class
  userUID: string;
  userActive: boolean;
  userFullName: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userRole: string;
  userCreatedAt: string;
  userUpdatedAt: string;
  userAccount: object | null = null;
  userWrestler: Wrestler | null = null;

  // these are the old/historic properties
  advancedTags: string;
  appUpdated: number;
  bucketName: string;
  contact: string;
  contactPhone: string;
  contactEmail: string;
  contactList: string;
  secondaryContact: string;
  secondaryContactPhone: string;
  secondaryContactEmail: string;
  disconnected: number;
  emailAddress: string;
  leadTime: number;
  level0Title: string;
  level1Title: string;
  level2Title: string;
  name: string;
  phone: string;
  reportsToTW: number;
  rowID: number;
  schoolList: string;
  schoolName: string;
  twSchoolName: string;
  showSetupWizard: number;
  state: string;
  teamCode: string;
  teamID: string;
  // timestamp: ???
  twRosterImported: number;
  twScheduleImported: number;
  twSeason: string;
  twTeamID: string;
  twUserName: string;
  userName: string;
  userType: string;
  wrestlerID: string;
  wrestlersWatchAllVideo: number;
  migrate: boolean = false;

  // accountID: string
  // ID: string
  // year: string

  // accessKey: string
  // accountExpiration: Date
  // accountNumber: string
  accountType: string;
  ageLevel: string;
  age: string;
  // brandingBannerFilename: string
  // brandingHomepageInfo: string

  // contactPhone: string
  // keepResultsPrivate: number
  mothersMaidenName: string;
  // needsPasswordReset: number
  // notes: string
  // oldBucketName: string
  // rosterAndScheduleChecked: number
  // secretKey: string

  private _loaded = false;
  private _tags: Tag[];
  private _neutralTags: Tag[];
  private _topTags: Tag[];
  private _bottomTags: Tag[];
  public objectID: string;

  public constructor(data) {
    // if this is a new User model (api/v2), then parse the data assuming there is a
    // account and wrestler sub objects
    if (
      data &&
      data.hasOwnProperty("active") &&
      data.hasOwnProperty("role") &&
      data.hasOwnProperty("account") &&
      data.hasOwnProperty("wrestler")
    ) {
      this.userUID = data.uid;
      this.userActive = data.active;
      this.userFullName = data.name;
      this.userFirstName = data.first_name;
      this.userLastName = data.last_name;
      this.userEmail = data.email;
      this.userRole = data.role;
      this.userType = this.userTypeFromRole(this.userRole);
      this.userCreatedAt = data.created_at;
      this.userUpdatedAt = data.updated_at;
      this.userAccount = data.account;
      this.wrestlersWatchAllVideo =
        data && data.hasOwnProperty("wrestlersWatchAllVideo")
          ? data.wrestlersWatchAllVideo
            ? 1
            : 0
          : 0;

      if (data.wrestler) {
        this.userWrestler = new Wrestler(data.wrestler);
      }

      if (data.account) {
        Object.assign(this, data.account);
        this.teamID = data.account.accountNumber;
        this.ageLevel = data.account.age;
      }
    } else {
      Object.assign(this, data);
      this.wrestlersWatchAllVideo =
        data && data.hasOwnProperty("wrestlersWatchAllVideo")
          ? data.wrestlersWatchAllVideo
            ? 1
            : 0
          : 0;
    }

    const s1: string = Date.now() + "";
    const s2: string = Math.random() + "";
    this.objectID =
      s1.substr(s1.length - 4, s1.length) +
      ":" +
      s2.substr(s2.length - 4, s2.length);
  }

  public get statsOnly(): boolean {
    return this.accountType === "Stats";
  }

  public get loaded(): boolean {
    return this._loaded;
  }

  // you must intentioally call this to mark as loaded
  public setAsLoaded() {
    this._loaded = true;
  }

  public get tags(): Tag[] {
    if (this._tags === undefined) this.parseTags();
    return this._tags;
  }

  public get neutralTags(): Tag[] {
    if (this._neutralTags === undefined) {
      this._neutralTags = this.tagsByPosition("Neutral");
    }
    return this._neutralTags;
  }

  public get topTags(): Tag[] {
    if (this._topTags === undefined) {
      this._topTags = this.tagsByPosition("Top");
    }
    return this._topTags;
  }

  public get bottomTags(): Tag[] {
    if (this._bottomTags === undefined) {
      this._bottomTags = this.tagsByPosition("Bottom");
    }
    return this._bottomTags;
  }

  public get formattedRole(): string {
    let f = "Unknown";
    switch (this.userRole) {
      case "admin-super-user":
      case "admin-support-user":
        f = "Site Admin";
        break;
      case "account-owner":
      case "account-admin":
      case "account-manager":
        f = "Coach";
        break;
      default:
        f = "Athlete";
    }
    return f;
  }

  public canWatch(match: MBMatch): boolean {
    // if this account is a coach, we are good to go
    if (!this.isWrestler) {
      return true;
    }
    // if this is a former wrestler, then they can only watch their own matches
    if (this.userWrestler && this.userWrestler.Level === "Former") {
      return match.ourWrestlerID === this.userWrestler.wrestlerID;
    }
    // if wrestlers can all watch video, we are good to go
    if (this.wrestlersWatchAllVideo === 1) {
      return true;
    }
    // otherwise, wrestlers can only watch their own matches
    if (this.userWrestler) {
      return match.ourWrestlerID === this.userWrestler.wrestlerID;
    }
    // in all other cases, you can't watch!
    return false;
  }

  private userTypeFromRole(role: string): string {
    let u = "wrestler";
    switch (role) {
      case "admin-super-user":
      case "admin-support-user":
      case "account-owner":
      case "account-admin":
      case "account-manager":
        u = "main";
        break;
      default:
        u = "wrestler";
    }
    return u;
  }

  public get isWrestler(): boolean {
    const roles = ["athlete-active", "athlete-limited"];
    return roles.includes(this.userRole);
    // return (this.userType || '').toLowerCase() === 'wrestler'
  }

  public get isMainContact(): boolean {
    const roles = [
      // admin accounts are assumed to be "main contacts"
      // for purposes of the frontend
      "admin-super-user",
      "admin-support-user",
      "account-owner",
      "account-admin",
      "account-manager",
    ];
    return roles.includes(this.userRole);
    // return (this.userType || '').toLowerCase() === 'main'
  }

  // @todo verify what this value really is
  public get isCoachManager(): boolean {
    const roles = ["account-manager"];
    return roles.includes(this.userRole);
    // return (this.userType || '').toLowerCase() === 'coach_manager'
  }

  public get isPublicUser(): boolean {
    const roles = ["local-user", "fan"];
    return roles.includes(this.userRole);
    // return (this.userType || '').toLowerCase() === 'coach_manager'
  }

  public get formattedWrestler(): string {
    if (this.userWrestler) {
      if (this.userWrestler.Level === "Former") {
        return `[Former] ${this.userWrestler.firstName} ${this.userWrestler.lastName}`;
      }
      return `${this.userWrestler.firstName} ${this.userWrestler.lastName}`;
    }
    return "";
  }

  /**
   * reset the advancedTags string from existing tags []
   */
  public resetTags() {
    const tags: string[] = [];
    this.tags.forEach((t: Tag) => {
      tags.push(
        [
          t.position,
          t.name,
          t.abbrev,
          t.abbrev + "Succ",
          t.abbrev + "Fail",
        ].join("~")
      );
    });
    this.advancedTags = tags.join("!");
    this._neutralTags = undefined;
    this._topTags = undefined;
    this._bottomTags = undefined;
    this._tags = undefined;
  }

  private tagsByPosition(pos: string): Tag[] {
    const tags: Tag[] = this.tags;
    return tags.filter((t) => t.position === pos);
  }

  private parseTags() {
    // Bottom~Standup~SUp~SUpSucc~SUpFail!Neutral~Double Leg~Doub~DoubSucc~DoubFail!
    // Neutral~Front Head~FH~FHSucc~FHFail!Top~Far Side Cradle~FSC~FSCSucc~FSCFail!
    // Top~Near Side Cradle~NSC~NSCSucc~NSCFail
    const tagList: string[] = (this.advancedTags || "").split("!");
    this._tags = [];
    tagList.forEach((t) => {
      const parts: string[] = t.split("~");
      this._tags.push(
        new Tag({
          TeamID: this.teamID,
          position: parts[0],
          name: parts[1],
          abbrev: parts[2],
        })
      );
    });
  }
}
