/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./onboarding.component";
var styles_OnboardingComponent = [];
var RenderType_OnboardingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OnboardingComponent, data: {} });
export { RenderType_OnboardingComponent as RenderType_OnboardingComponent };
export function View_OnboardingComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "mb-onboarding"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "mb-onboarding__logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["alt", "Logo Alt Text"], ["class", "logo"], ["src", "./assets/Img/GrayedMBLogo.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(6, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "footer", [["class", "mb-onboarding__footer"], ["role", "contentinfo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "p", [["class", "mb-onboarding__copyright"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" \u00A9 ", ". All rights reserved. "]))], function (_ck, _v) { _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentYear; _ck(_v, 9, 0, currVal_0); }); }
export function View_OnboardingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-onboarding", [], null, null, null, View_OnboardingComponent_0, RenderType_OnboardingComponent)), i0.ɵdid(1, 114688, null, 0, i2.OnboardingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OnboardingComponentNgFactory = i0.ɵccf("mb-onboarding", i2.OnboardingComponent, View_OnboardingComponent_Host_0, {}, {}, []);
export { OnboardingComponentNgFactory as OnboardingComponentNgFactory };
