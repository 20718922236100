/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../util/alert.ngfactory";
import * as i2 from "../util/alert";
import * as i3 from "../util/spinner.ngfactory";
import * as i4 from "../util/spinner";
import * as i5 from "./event-trashed.component.ngfactory";
import * as i6 from "./event-trashed.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../services/mbevent.service";
import * as i9 from "@angular/common";
import * as i10 from "@angular/router";
import * as i11 from "./filters.component.ngfactory";
import * as i12 from "./filters.component";
import * as i13 from "../services/event-levels.service";
import * as i14 from "./scorebook-trashed.component";
import * as i15 from "../services/user.service";
var styles_ScorebookTrashedComponent = [];
var RenderType_ScorebookTrashedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScorebookTrashedComponent, data: {} });
export { RenderType_ScorebookTrashedComponent as RenderType_ScorebookTrashedComponent };
function View_ScorebookTrashedComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-alert", [["type", "danger"]], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = ((_co.error = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i2.AlertComponent, [], { message: [0, "message"], type: [1, "type"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; var currVal_1 = "danger"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ScorebookTrashedComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-alert", [], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = ((_co.success = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i2.AlertComponent, [], { message: [0, "message"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.success; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ScorebookTrashedComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-spinner", [["class", "my-5 p-5 text-center"], ["label", "Loading Deleted Events..."], ["style", "display: block; text-align: center;"]], null, null, null, i3.View_SpinnerComponent_0, i3.RenderType_SpinnerComponent)), i0.ɵdid(1, 49152, null, 0, i4.SpinnerComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Loading Deleted Events..."; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ScorebookTrashedComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" There are no deleted events in this scorebook. "]))], null, null); }
function View_ScorebookTrashedComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-event-trashed", [["class", "mb-event"]], null, [[null, "eventRestored"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventRestored" === en)) {
        var pd_0 = (_co.onEventRestored($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_EventTrashedComponent_0, i5.RenderType_EventTrashedComponent)), i0.ɵdid(1, 114688, null, 0, i6.EventTrashedComponent, [i7.NgbModal, i8.MBEventService], { event: [0, "event"] }, { eventRestored: "eventRestored" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ScorebookTrashedComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "mb-scorebook__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScorebookTrashedComponent_5)), i0.ɵdid(2, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScorebookTrashedComponent_6)), i0.ɵdid(4, 278528, null, 0, i9.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.trashedEvents.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.trashedEvents; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ScorebookTrashedComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "div", [["class", "container container--inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "h3", [["class", "mb-page__head"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "mb-page__head-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["alt", "Icon"], ["src", "./assets/SVG/Page/Roster.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Deleted Events "])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "span", [["class", "mb-page__head-links"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "a", [["class", "mx-4 mb-page__head-link"], ["routerLink", "/scorebook"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 671744, null, 0, i10.RouterLinkWithHref, [i10.Router, i10.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Scorebook"])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "mb-scorebook-filters", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.updateFilters($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_FiltersComponent_0, i11.RenderType_FiltersComponent)), i0.ɵdid(10, 114688, null, 0, i12.FiltersComponent, [i13.EventLevelsService], { user: [0, "user"], params: [1, "params"], yearOptions: [2, "yearOptions"] }, { submitted: "submitted" }), i0.ɵpad(11, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScorebookTrashedComponent_1)), i0.ɵdid(13, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScorebookTrashedComponent_2)), i0.ɵdid(15, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScorebookTrashedComponent_3)), i0.ɵdid(17, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScorebookTrashedComponent_4)), i0.ɵdid(19, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/scorebook"; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.user; var currVal_4 = _co.params; var currVal_5 = _ck(_v, 11, 0, _co.year); _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.error; _ck(_v, 13, 0, currVal_6); var currVal_7 = _co.success; _ck(_v, 15, 0, currVal_7); var currVal_8 = !_co.loaded; _ck(_v, 17, 0, currVal_8); var currVal_9 = _co.loaded; _ck(_v, 19, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 7).target; var currVal_1 = i0.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_0, currVal_1); }); }
export function View_ScorebookTrashedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-scorebook-trashed", [["class", "mb-page mb-scorebook"]], null, null, null, View_ScorebookTrashedComponent_0, RenderType_ScorebookTrashedComponent)), i0.ɵdid(1, 245760, null, 0, i14.ScorebookTrashedComponent, [i10.ActivatedRoute, i10.Router, i8.MBEventService, i15.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScorebookTrashedComponentNgFactory = i0.ɵccf("mb-scorebook-trashed", i14.ScorebookTrashedComponent, View_ScorebookTrashedComponent_Host_0, {}, {}, []);
export { ScorebookTrashedComponentNgFactory as ScorebookTrashedComponentNgFactory };
