import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin } from "rxjs";
import { first, delay } from "rxjs/operators";

import { User, Wrestler, TwWrestler } from "../models";
import { RosterService, TwService, UserService } from "../services";

/**
 * @see setup-wizard/setup2.component
 * @todo combine these 2 into a shared component with wrappers
 */
@Component({
  selector: "mb-import-roster",
  templateUrl: "./import-roster.component.html",
})
export class ImportRosterComponent implements OnInit {
  public account: User;
  public former: any = {};
  public import: any = {};
  public loaded = false;
  public submitting = false;
  public twWrestlers: TwWrestler[];
  public wrestlers: Wrestler[];
  private wrestlersLoaded = false;
  private twWrestlersLoaded = false;
  private unselectedWrestlers: Wrestler[] = [];

  constructor(
    private rosterService: RosterService,
    private router: Router,
    private twService: TwService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService.current.subscribe((user: User) => {
      this.account = user;
      this.findRosters();
    });
  }

  public submit(): void {
    this.submitting = true;

    // this.import tw => w {undefined, new, noimport, w.ID}
    const stream: any[] = [];

    const interval = 500;

    Object.keys(this.import).forEach((k, i) => {
      const tw: TwWrestler = this.twWrestlers
        .filter((w) => w.twid === k)
        .shift();
      const w: Wrestler = this.wrestlers
        .filter((w) => w.wrestlerID.toString() === this.import[k])
        .shift();
      if (!tw) return;
      if (this.import[k] === "new") {
        const theName = tw.lastName.trim() + ", " + tw.firstName.trim();
        stream.push(
          this.rosterService
            .addWrestler(this.account.teamID, {
              Name: theName,
              twEligible: tw.tweligible,
              Grade: tw.grade,
              twid: tw.twid,
            })
            .pipe(delay(i * interval))
        );
      } else if (this.import[k] && this.import[k] !== "null") {
        const theName = tw.lastName.trim() + ", " + tw.firstName.trim();
        let levelOption = {};
        if (w && w.Level === "Former") {
          levelOption = { Level: "" };
        }
        stream.push(
          this.rosterService
            .editWrestler(this.import[k], {
              twid: k,
              Grade: tw.grade,
              Name: theName,
              addCurrentYear: true,
              ...levelOption,
            })
            .pipe(delay(i * interval))
        );
      }
    });

    Object.keys(this.former).forEach((k, i) => {
      if (!this.former[k]) return;
      stream.push(
        this.rosterService
          .editWrestler(k, { Level: "Former" })
          .pipe(delay(i * interval))
      );
    });

    if (!stream.length) {
      this.submitting = false;
      return;
    }

    forkJoin(stream).subscribe(
      (data: any[]) => {
        this.router.navigate(["/roster"]);
      },
      (err: any) => {
        console.warn("error", err);
        this.router.navigate(["/roster"]);
      }
    );
  }

  public changeImport(): void {
    const selected: any[] = Object.values(this.import);
    this.unselectedWrestlers = this.wrestlers.filter((w) => {
      return (
        selected.indexOf(w.wrestlerID.toString()) === -1 && w.Level != "Former"
      );
    });
  }

  // private

  private findRosters(): void {
    this.loaded = false;
    this.findRoster();
    this.findTwRoster();
  }

  private findRoster(): void {
    this.wrestlersLoaded = false;
    this.rosterService
      .getRoster(this.account.teamID, { status: "all" })
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.wrestlers = [];
          data.result.forEach((item) => {
            this.wrestlers.push(new Wrestler(item));
          });
          this.wrestlersLoaded = true;
          this.ready();
        },
        (error) => {
          this.wrestlers = [];
          this.wrestlersLoaded = true;
          this.ready();
        }
      );
  }

  private findTwRoster(): void {
    this.twWrestlersLoaded = false;
    this.twService
      .getRoster(this.account.teamID)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (!data.success) return;
          this.twWrestlers = [];
          data.result.forEach((item) => {
            this.twWrestlers.push(new TwWrestler(item.wrestler));
          });
          this.twWrestlersLoaded = true;
          this.ready();
        },
        (error) => {
          this.twWrestlers = [];
          this.twWrestlersLoaded = true;
          this.ready();
        }
      );
  }

  private initImport(): void {
    this.twWrestlers.forEach((tw: TwWrestler) => {
      // first look for a wrestler that has users associated with it
      let found = this.wrestlers.find(
        (w: Wrestler) => this.wrestlerMatch(w, tw) && w.users.length
      );
      // if none found, just look for a name match
      if (!found) {
        found = this.wrestlers.find((w: Wrestler) => this.wrestlerMatch(w, tw));
      }
      if (found) {
        this.import[tw.twid] = found.wrestlerID.toString();
      }
    });
    this.changeImport();
  }

  private wrestlerMatch(wrestler: Wrestler, twWrestler: TwWrestler): boolean {
    if (
      wrestler.firstName.trim() === twWrestler.firstName.trim() &&
      wrestler.lastName.trim() === twWrestler.lastName.trim()
    )
      return true;

    // @todo make this smarter

    return false;
  }

  private ready(): void {
    if (!this.wrestlersLoaded) return;
    if (!this.twWrestlersLoaded) return;
    this.initImport();
    this.loaded = true;
  }
}
