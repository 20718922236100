<div class="container container--inner">
  <h3 class="mb-page__head">
    <span class="mb-page__head-title">
      <img src="./assets/SVG/Page/Reports.svg" alt="Icon">
      Reports
    </span>
  </h3>

  <div class="mb-breadcrumbs p-3">
    <a [routerLink]="['/reports']"
      queryParamsHandling="preserve">‹ Back</a>
  </div>

  <mb-spinner *ngIf="!loaded"
    label="Loading Report..."
    class="my-5 p-5 text-center"></mb-spinner>

  <div [hidden]="!loaded">

    <!-- <div class="d-flex justify-content-between">
      <div class="mb-details-link top" *ngIf="csvable">
        <button
          type="button"
          (click)="getCSV()"
          class="btn btn-lg btn-primary">Download CSV</button>
      </div>
      <div *ngIf="csvable && pdfable" class="px-2">&nbsp;</div>
      <div class="mb-details-link top" *ngIf="pdfable">
        <button
          type="button"
          (click)="getPDF()"
          class="btn btn-lg btn-primary">Download PDF</button>
      </div>
      <div *ngIf="pdfable && emailable" class="px-2">&nbsp;</div>
      <div class="mb-details-link top" *ngIf="emailable">
        <button
          type="button"
          (click)="sendToContacts()"
          class="btn btn-lg btn-primary">Email to Contacts</button>
      </div>
    </div>
    <hr class="top">-->

    <div id="pdf-container"></div>

    <!-- <h2>{{ report.title }}</h2>
    <h3 *ngIf="report.subtitle">{{ report.subtitle }}</h3>

    <div *ngFor="let section of report.sections"
      class="my-5">

      <h3 *ngIf="section.title">{{ section.title }}</h3>

      <div class="table-responsive">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th *ngFor="let heading of section.columnHeadings"
                class="text-nowrap">
                {{ heading }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of section.rows">
              <td *ngFor="let col of row">
                {{ col }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div *ngFor="let subreport of report.subreports"
      class="my-5">

      <hr/>

      <h3>{{ subreport.title }}</h3>
      <h4 *ngIf="subreport.subtitle">{{ subreport.subtitle }}</h4>

      <div *ngFor="let section of subreport.sections"
        class="my-5">

        <h3 *ngIf="section.title">{{ section.title }}</h3>

        <div class="table-responsive">
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th *ngFor="let heading of section.columnHeadings"
                  class="text-nowrap">
                  {{ heading }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of section.rows">
                <td *ngFor="let col of row">
                  {{ col }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div> -->

    <hr>
    <div class="d-flex justify-content-between">
      <div class="mb-details-link" *ngIf="emailable">
        <button
          type="button"
          (click)="sendToContacts()"
          class="btn btn-lg btn-primary">Email to Contacts</button>
      </div>
      <div *ngIf="pdfable && emailable" class="px-2">&nbsp;</div>
      <div class="mb-details-link" *ngIf="pdfable">
        <button
          type="button"
          (click)="getPDF(false)"
          class="btn btn-lg btn-primary">Download PDF</button>
      </div>
      <div *ngIf="pdfable && csvable" class="px-2">&nbsp;</div>
      <div class="mb-details-link" *ngIf="csvable">
        <button
          type="button"
          (click)="getCSV()"
          class="btn btn-lg btn-primary">Download CSV</button>
      </div>
  </div>
</div>
