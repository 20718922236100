<div class="modal-header">
  <h4 class="modal-title">TrackWrestling</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <mb-alert
    *ngIf="error"
    type="danger"
    [message]="error"
    (closed)="error = null"
  ></mb-alert>
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <!-- <div class="form-group">
      <label>Username</label>
      <input type="text" class="form-control" formControlName="userName" />
      <mb-invalid
        [form]="form"
        [validate]="validate"
        field="userName"
      ></mb-invalid>
    </div> -->
    <!-- <div class="form-group">
      <label>Password</label>
      <input type="password" class="form-control" formControlName="password" />
      <mb-invalid
        [form]="form"
        [validate]="validate"
        field="password"
      ></mb-invalid>
    </div> -->
    <div class="form-group">
      <label>Location</label>
      <select
        class="form-control"
        formControlName="location"
        [ngModel]="event.location"
      >
        <option value="">Choose Location</option>
        <option value="home">Home</option>
        <option value="away">Away</option>
      </select>
      <mb-invalid
        [form]="form"
        [validate]="validate"
        field="location"
      ></mb-invalid>
    </div>
    <div class="form-group" *ngIf="event.isDualMeet">
      <label>Opposing Team's State</label>
      <select
        id="stateDropdown"
        class="form-control"
        formControlName="opponentState"
      >
        <option>Choose State</option>
        <option *ngFor="let state of states" [value]="state">
          {{ state }}
        </option>
        "
      </select>
      <mb-invalid
        [form]="form"
        [validate]="validate"
        field="opponentState"
      ></mb-invalid>
    </div>
    <div class="form-group">
      <label>TrackWrestling Event:</label>
      <mb-ellipsis-loader *ngIf="loadingTwEvents"></mb-ellipsis-loader>
      <p *ngIf="!loadingTwEvents && twEvents && twEvents.length === 0">
        There are no events on your TrackWrestling schedule. Please create a
        corresponding event on TW first.
      </p>
      <select
        *ngIf="!loadingTwEvents && twEvents && twEvents.length > 0"
        id="twEventDropdown"
        class="form-control"
        formControlName="twid"
        [ngModel]="event.twid"
      >
        <option>Choose TrackWrestling Event</option>
        <option *ngFor="let evt of twEvents" [value]="evt.twid">
          {{ evt.squadLevel }} - {{ evt.eventName }} - Date:
          {{ evt.eventDate | date : "yyyy-MM-dd" : "UTC+0" }} -
          {{ evt.eventType }}
        </option>
      </select>
      <mb-invalid [form]="form" [validate]="validate" field="twid"></mb-invalid>
    </div>
    <div
      class="form-group"
      *ngIf="!loadingTwEvents && twEvents && twEvents.length > 0"
    >
      <label>
        <input
          type="checkbox"
          id="twExportExhibitions"
          formControlName="exportExhibitions"
          checked
        />
        &nbsp;Export Exhibition Matches
      </label>
    </div>
  </form>
</div>

<div class="modal-footer d-block">
  <button
    *ngIf="!submitting"
    type="button"
    class="btn btn-primary btn-block"
    (click)="submit()"
  >
    Submit
  </button>
  <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
</div>
