import { User } from './user'

export class LibrarySection {
  public title: string
  public items: LibraryItem[]

  public constructor(data: any) {
    this.update(data)
  }

  public update(data: any): void {
    if (data.title) this.title = data.title
    const headings: any[] = data.columnHeadings ? data.columnHeadings : ['rowID', 'Description', 'URL']
    if (data.rows) {
      this.items = []
      data.rows.forEach((row: any[]) => {
        const itemData: any = {
          category: data.title
        }
        for (let i: number = 0; i < row.length; i++) {
          itemData[headings[i]] = row[i]
        }
        this.items.push(new LibraryItem(itemData))
      })
    }
  }
}

export class LibraryItem {
  public ID: string
  public description: string
  public url: string
  public category: string
  public teamID: number

  public constructor(data: any) {
    this.update(data)
  }

  public update(data: any): void {
    if (data.rowID) this.ID = data.rowID.toString()
    if (data.Description) this.description = data.Description
    if (data.URL) this.url = data.URL
    if (data.category) this.category = data.category
    if (data.accountID) this.teamID = data.accountID
    if (data.teamID) this.teamID = data.teamID
  }

  public get isExternal(): boolean {
    return /^https?:\/\//.test(this.url)
  }

  public internalUrl(account: User): string {
    // To build the url, it looks like this:
    // <s3 endpoint base url>/<bucketname>/schoolname.state/<link>, where the 'bulletinboard_internal'
    // file name would be substituted for the <link> portion. Here's an example of a full url:
    // https://s3-us-west-2.amazonaws.com/jpchaos-wrestling-highschool/sample.mn/bulletinboard_internal_UploadTest.mp4
    let domain = 'https://s3-us-west-2.amazonaws.com/'

    const bucketName = account.bucketName
    const state = account.state.toLowerCase()
    const schoolName = account.schoolName.toLowerCase().replace(/ /g, '')
    return domain + bucketName + '/' + schoolName + '.' + state + '/' + this.url
  }
}
