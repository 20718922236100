export class TwWrestler {
  public twid: string
  public firstName: string
  public lastName: string
  public grade: string
  public tweligible: string

  public constructor(data: any) {
    this.update(data)
  }

  public update(data: any): void {
    Object.assign(this, data)
    if (this.grade === 'null') this.grade = null
  }
}
