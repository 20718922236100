import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Subject } from 'rxjs'
import { first } from 'rxjs/operators'

import { Tag, User } from '../models'
import { UserService } from '../services/user.service'
import { TagEditComponent } from '../account/tag-edit.component'

@Component({
  selector: 'mb-organization',
  templateUrl: './organization.component.html',
  host: {'class': 'mb-page'}
})
export class OrganizationComponent implements OnInit, OnDestroy {
  public account: User
  public loaded = false
  public form: FormGroup
  public leadTimeOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  public watchAllVideoOptions = [
    {label: 'Wrestlers can watch all videos', value: 1},
    {label: 'Wrestlers can only see their own', value: 0}
  ]
  public error: string
  public success: string
  private unsubscribe: Subject<any> = new Subject()

  public constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private modalService: NgbModal
  ) { }

  public ngOnInit() {
    this.userService.getInfo()
      .pipe(first())
      .subscribe(
        (data: any) => this.setUser(data),
        (e: any) => { console.warn('error', e) }
      )
  }

  public setUser(data: any) {
    if (data.success) {
      this.account = new User(data.result)
      this.userService.setCurrent(this.account)
      this.createForm()
      this.loaded = true
    }
    else { console.warn('error', data.error) }
  }

  /**
   * submit the form
   */
  public submit() {
    const data: any = {
      userType: 'main_contact',
      contact: this.form.value.contact,
      contactPhone: this.form.value.phone,
      contactEmail: this.form.value.emailAddress,
      contactList: this.form.value.contactList,
      schoolList: this.form.value.schoolList,
      twUserName: this.form.value.twUserName,
      // twSeason: this.form.value.twSeason,
      level0Title: this.form.value.level0Title,
      level1Title: this.form.value.level1Title,
      level2Title: this.form.value.level2Title,
      // advancedTags: this.form.value.advancedTags,
      wrestlersWatchAllVideo: this.form.value.wrestlersWatchAllVideo,
      leadTime: this.form.value.leadTime,
	  twSchoolName: this.form.value.twSchoolName
    }

    const pw: string = this.form.value.newPassword
    const pwConf: string = this.form.value.newPasswordConfirm
    const recoveryPw: string = this.form.value.recoveryPassword
    if (pw && pw === pwConf) data.password = pw
    if (recoveryPw) data.recoveryPassword = recoveryPw

    this.loaded = false
    this.userService.update(this.account.userName, this.account.userType, data)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.setUser(data)
          this.setSuccess('Organization Settings successfully updated!')
        },
        (e: any) => { 
          console.warn('error', e) 
          this.setError('An error was encountered while updating the organization settings. Please try again!')
        }
      )
  }

  /**
   * unsubscribe
   */
  public ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  public addTag() {
    const modalRef = this.modalService.open(TagEditComponent, { size: 'sm' })
    let tag: Tag = new Tag({ TeamID: this.account.teamID })
    modalRef.componentInstance.tag = tag
    modalRef.componentInstance.isNew = true
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.account.tags.push(fulfilledValue.result)
          this.account.resetTags()
          this.saveAccount({advancedTags: this.account.advancedTags}, 'Tag Saved!')
        } else {
          this.setError('There was an error saving the new tag (1).', fulfilledValue)
        }
      },
      (rejectedValue: any) => {
        if (rejectedValue === 'Cross click' || rejectedValue === 0 || rejectedValue === 1) return
        this.setError('There was an error saving the new tag (2).', rejectedValue)
      }
    )
  }

  private saveAccount(newData: any, successMsg?: string) {
    this.loaded = false
    window.scroll(0, 0)
    this.userService.update(
      this.account.userName,
      this.account.userType,
      newData
    )
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.setUser(data)
          this.setSuccess(successMsg)
        },
        (e: any) => { console.warn('error', e) }
      )
  }

  public editTag(tag: Tag) {
    const modalRef = this.modalService.open(TagEditComponent, { size: 'sm' })
    modalRef.componentInstance.tag = tag
    modalRef.componentInstance.isNew = false
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.account.resetTags()
          this.saveAccount({advancedTags: this.account.advancedTags}, 'Tag Saved!')
        } else {
          this.setError('There was an error saving the new tag (1).', fulfilledValue)
        }
      },
      (rejectedValue: any) => {
        if (rejectedValue === 'Cross click' || rejectedValue === 0 || rejectedValue === 1) return
        this.setError('There was an error saving the new tag (2).', rejectedValue)
      }
    )
  }

  private createForm() {
    this.form = this.fb.group({
      schoolList: [this.account.schoolList, Validators.required],
      contact: [this.account.contact, Validators.required],
      phone: [this.account.phone, Validators.required],
      emailAddress: [this.account.emailAddress, Validators.required],
      contactList: [this.account.contactList, Validators.required],
      newPassword: [''], // @todo validate 8-24 characters with 1 number
      newPasswordConfirm: [''], // @todo validate it matches newPassword
      recoveryPassword: [''], // @todo figure out what this is
      twUserName: [this.account.twUserName],
      leadTime: [this.account.leadTime],
      wrestlersWatchAllVideo: [this.account.wrestlersWatchAllVideo],
      level0Title: [this.account.level0Title],
      level1Title: [this.account.level1Title],
      level2Title: [this.account.level2Title],
	  twSchoolName: [this.account.twSchoolName],
    })
  }

  private setError(message: string, obj?: any) {
    this.success = null
    this.error = message
    console.warn('Error: ' + message, obj)
    window.scroll(0, 0)
  }

  private setSuccess(message: string, obj?: any) {
    this.error = null
    this.success = message
    if (obj) console.log('Success', obj)
    window.scroll(0, 0)
  }

  private clearAlerts() {
    this.error = null
    this.success = null
  }
}
