import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

/**
 * provides a data model to contain the roster filter options
 * and the selected filters
 */
export class RosterFilters {
  public status: string
  public search: string

  protected statusOptions: string[] = [
    'current', 'former', 'all'
  ]

  constructor() {
    this.status = 'current'
    this.search = ''
  }
}

@Component({
  selector: 'mb-roster-filters',
  templateUrl: './roster-filters.component.html'
})
export class RosterFiltersComponent implements OnInit {

  @Input()
  public set search(s: string) {
    if (s === this.filters.search) return
    this.filters.search = s
    this.submit()
  }

  @Output()
  submitted: EventEmitter<RosterFilters> = new EventEmitter<RosterFilters>()

  constructor(
    public filters: RosterFilters = new RosterFilters()
  ) { }

  /**
   * sync initial filters value on init
   */
  public ngOnInit() {}

  /**
   * emit the submitted event
   */
  public submit() {
    this.submitted.emit(this.filters)
  }
}
