<div class="mb-onboarding__setup{{ step === 9 || step === 11 || step === 12 || step === 13 || step === 14 ? '--wide' : '' }}">

  <mb-spinner *ngIf="!loaded"
    label="Loading..."
    class="my-5 p-5 text-center"
  ></mb-spinner>

  <div *ngIf="loaded">
    <mb-setup1 *ngIf="step === 1" [account]="account"></mb-setup1>
	<mb-setup12 *ngIf="step === 2" [account]="account"></mb-setup12>
    <mb-setup20 *ngIf="step === 3" [account]="account"></mb-setup20>
    <mb-setup21 *ngIf="step === 4" [account]="account"></mb-setup21>
    <mb-setup22 *ngIf="step === 5" [account]="account"></mb-setup22>
	<mb-setup23 *ngIf="step === 6" [account]="account"></mb-setup23>
	<mb-setup24 *ngIf="step === 7" [account]="account"></mb-setup24>
	<mb-setup25 *ngIf="step === 8" [account]="account"></mb-setup25>
	<mb-setup3 *ngIf="step === 9" [account]="account"></mb-setup3>
	<mb-setup252 *ngIf="step === 10" [account]="account"></mb-setup252>
	<mb-setup253 *ngIf="step === 11" [account]="account"></mb-setup253>
	<mb-setup4 *ngIf="step === 12" [account]="account"></mb-setup4>
	<mb-setup262 *ngIf="step === 13" [account]="account"></mb-setup262>
	<mb-setup31 *ngIf="step === 14" [account]="account"></mb-setup31>
    <mb-setup5 *ngIf="step === 15" [account]="account"></mb-setup5>
	<mb-setup33 *ngIf="step === 16" [account]="account"></mb-setup33>
	<mb-setup41 *ngIf="step === 17" [account]="account"></mb-setup41>
  </div>
</div>
