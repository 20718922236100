import { pipe } from 'rxjs'
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http'
import { filter, tap, map } from 'rxjs/operators'

// @see https://netbasal.com/how-to-implement-file-uploading-in-angular-reactive-forms-89a3fffa1a03

export function toFormData(formValue: any): FormData {
  const formData = new FormData()
  for (const key of Object.keys(formValue)) {
    const value = formValue[key]
    formData.append(key, value)
  }
  return formData
}

export const toResponseBody = <T>(): any => {
  return pipe(
    filter(( event: HttpEvent<T> ) => event.type === HttpEventType.Response),
    map((res: HttpResponse<T> ) => res.body)
  )
}

export const uploadProgress = <T>(cb: (progress: number) => void): any => {
  return tap((event: HttpEvent<T>) => {
    if (event.type === HttpEventType.UploadProgress) {
      cb(Math.round((100 * event.loaded) / event.total))
    }
  })
}
