import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { ApiService } from "./api.service";
import { MBMatch, MBEvent } from "../models";

@Injectable()
export class MBMatchService {
  constructor(public apiService: ApiService) {}

  /**
   * @see MBEventService.findMatches
   */
  public findAll(data: {
    teamID: string;
    levelID: string;
    year: string;
    eventID?: string;
    wrestlerID?: string;
    move?: string;
    uploadedVideoOnly?: string;
    per_page?: string;
    page?: string;
    search?: string;
  }): Observable<any> {
    let params = new HttpParams()
      .set("per_page", data.per_page || "100")
      .set("page", data.page || "1")
      .set("teamID", data.teamID)
      .set("levelID", data.levelID)
      .set("year", data.year);

    if (data.eventID) params = params.set("eventID", data.eventID);
    if (data.wrestlerID) params = params.set("wrestlerID", data.wrestlerID);
    if (data.uploadedVideoOnly)
      params = params.set("uploadedVideoOnly", data.uploadedVideoOnly);
    if (data.search && data.search !== "")
      params = params.set("search", data.search);
    if (data.move) {
      const parts = data.move.split(":");
      params = params
        .set("symbol", parts[0])
        .set("forAgainst", (parts[1] || "f").toLowerCase())
        .set("level", data.levelID);

      return this.apiService.get("matchesbymove", { params: params });
    } else return this.apiService.get("matches", { params: params });
  }

  public find(matchID: string): Observable<any> {
    const url: string = "matches/" + encodeURIComponent(matchID);
    return this.apiService.get(url);
  }

  public editMatch(matchID: string, data: any): Observable<any> {
    const url: string = "matches/" + encodeURIComponent(matchID);
    return this.apiService.update(url, data);
  }

  public matchUploaded(matchID: string, data: any): Observable<any> {
    const url: string = "matches/uploaded/" + matchID;
    return this.apiService.update(url, data);
  }

  public matchUploadStatus(matchID: string): Observable<any> {
    const url: string = "matches/upload-status/" + matchID;
    return this.apiService.get(url, { observe: "response" });
  }

  public switchColors(matchID: string): Observable<any> {
    const url: string = "matches/switchcolors/" + encodeURIComponent(matchID);
    return this.apiService.update(url);
  }
  public switchColorsByHash(
    matchIDHash: string,
    year: string
  ): Observable<any> {
    const url: string = "matches/switchcolors/" + year + "/" + matchIDHash;
    return this.apiService.update(url);
  }

  public editOurWrestler(
    match: MBMatch,
    wrestlerID: string,
    wrestlerName: string
  ): Observable<any> {
    const data: any = {
      ourWrestlerID: wrestlerID,
    };
    const field: string = match.ourRed ? "redWrestler" : "greenWrestler";
    const parts: string[] = match[field].split("#");
    parts[0] = wrestlerID;
    parts[1] = wrestlerName;
    data[field] = parts.join("#");
    match[field] = data[field];
    return this.editMatch(match.matchID, data);
  }

  public editTheirWrestler(
    match: MBMatch,
    name: string,
    school: string
  ): Observable<any> {
    const data: any = {
      opponentSchool: school,
    };
    const field: string = match.ourRed ? "greenWrestler" : "redWrestler";
    const parts: string[] = match[field].split("#");
    parts[1] = name;
    parts[3] = school;
    data[field] = parts.join("#");
    match[field] = data[field];
    return this.editMatch(match.matchID, data);
  }

  public share(data: {
    emails?: string[];
    matchIDs: string[];
    message: string;
  }): Observable<any> {
    return this.apiService.post("playlist", data);
  }

  public delete(match: MBMatch): Observable<any> {
    return this.apiService.delete("matches/" + match.matchID);
  }

  public clone(match: MBMatch): Observable<any> {
    return this.apiService.post("clonematch", {
      matchID: match.matchID,
    });
  }

  public move(match: MBMatch, newEventID: string): Observable<any> {
    const url: string = "matches/" + match.matchID;
    return this.apiService.post(url, {
      newEventID: newEventID,
    });
  }

  public create(data: any): Observable<any> {
    const url = "matches";
    return this.apiService.post(url, data);
  }

  public updateCloudStatus(teamID: string, year: string): Observable<any> {
    const url: string = "updatecloudstatus";
    const data: any = {
      teamID: teamID,
      year: year,
    };
    return this.apiService.update(url, data);
  }
}
