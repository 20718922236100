import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core'
import { Subject } from 'rxjs'
import { first, takeUntil } from 'rxjs/operators'

import { Doodle } from '../doodle'
import { MBMatchService } from '../../services/mbmatch.service'
import { UserService } from '../../services/user.service'
import { Highlight, MBMatch, MBMatchVideo, User } from '../../models'
import { periodOptions } from '../video-options'

@Component({
  selector: 'mb-video-preview',
  templateUrl: './video-preview.component.html'
})
export class VideoPreviewComponent implements OnInit, OnDestroy {

  @Input() matchID: string
  @Input() highlight?: Highlight
  @Input() startTime?: number = 0
  @Output() close = new EventEmitter()

  public doodle: Doodle = new Doodle()
  public error = ''
  public loaded = false
  public match: MBMatch
  public matchVideo: MBMatchVideo
  public periodOptions = periodOptions
  public success = ''
  public user: User
  public leadTime: number

  private teamID: string
  private unsubscribe: Subject<any> = new Subject()

  constructor(
    private userService: UserService,
    private matchService: MBMatchService
  ) { }

  public ngOnInit(): void {
    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((u: User) => {
        this.user = u
        this.teamID = u.teamID
        this.findMatch()
      })
  }

  public ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  public closePreview(): void {
    this.close.emit()
  }

  private findMatch(): void {
    this.matchService.find(this.matchID)
      .pipe(first())
      .subscribe((data: any) => {
        this.match = new MBMatch(data.result)
        this.matchVideo = new MBMatchVideo(this.match, this.user)
        this.loaded = true
      })
  }
}
