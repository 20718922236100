import { Component, Input, OnInit, forwardRef } from '@angular/core'
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  FormControl
} from '@angular/forms'

@Component({
  selector: 'mb-text-field',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextFieldComponent),
    multi: true,
  }],
  templateUrl: './text-field.component.html',
  host: {
    class: 'mb-text-field'
  }
})
export class TextFieldComponent implements OnInit, ControlValueAccessor {
  @Input() type?: string = 'text'
  @Input() label: string
  @Input() valid? = true // @todo deprecate
  @Input() validate?: boolean
  @Input() mbDisabled? = false
  @Input() messages?: any
  @Input() validationErrors?: any // @todo remove
  @Input() control?: FormControl // @todo use this everywhere

  public form: FormGroup
  public present = false

  constructor(
    private fb: FormBuilder
  ) { }

  public ngOnInit() {
    this.createForm()
  }

  public writeValue(value: string) {
    this.present = !!value
    if (value) {
      this.form.setValue({
        input: value
      })
    }
  }

  public registerOnChange(fn: (value: any) => void) {
    this.present = !!this.form.get('input').value
    this.form.get('input').valueChanges.subscribe(fn)
  }

  public registerOnTouched(fn) {

  }

  public get isValid(): boolean {
    // @todo make validate and control required inputs
    if (this.validate !== undefined && this.control) {
      if (!this.validate) return true
      return this.control.valid
    }
    // @todo remove this fallback
    return this.valid
  }

  private createForm() {
    this.form = this.fb.group({
      input: [{value: '', disabled: this.mbDisabled}]
    })
  }
}
