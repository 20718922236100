<div class="mb-video-shortcuts">
  <a>Keyboard Shortcuts</a>
  <p class="mb-text">
    Keyboard shortcuts:<br>
    Space bar - pause/play<br>
    Normal speed - N<br>
    Very fast speed (3x) - V<br>
    Fast speed(2x) - F<br>
    Slow speed (0.5x) - S<br>
    Ultra slow speed (0.25x) - U<br>
    Frame advance - Right arrow<br>
    Frame reverse - Left arrow
  </p>
</div><!-- shortcuts -->
