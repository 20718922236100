import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs/operators";

import { User, Highlight } from "../models";
import { UserService, HighlightsService } from "../services";
import { ConfirmComponent } from "../util/confirm";

import { environment } from "../../environments/environment";
import { EVENT_YEAR_OPTIONS } from "../util";

interface HighlightArchiveRow {
  date: string;
  numClips: number;
  url: string;
}

interface HighlightArchiveSection {
  title: string;
  columnHeadings: string[];
  rows: HighlightArchiveRow[];
}

interface HighlightArchiveResponse {
  title: string;
  subtitle: string;
  sections: HighlightArchiveSection[];
}

@Component({
  selector: "mb-highlights",
  templateUrl: "./highlights.component.html",
})
export class HighlightsComponent implements OnInit {
  public account: User;
  public enableSearch = false;
  public error: string;
  public yearOptions: string[];
  private _year: string;
  public highlights: Highlight[];
  public loaded = false;
  public previewHighlight: Highlight;
  public previewStartTime: number;
  public selected: any = {};
  public submitting = false;
  public success: string;
  public archive: HighlightArchiveResponse;
  public checkAll = false;

  public get year(): string {
    return this._year || null;
  }

  public set year(y: string) {
    this._year = y;
  }

  public yearChange(): void {
    this.reload();
  }

  constructor(
    private userService: UserService,
    private modalService: NgbModal,
    private highlightsService: HighlightsService
  ) {
    this.yearOptions = EVENT_YEAR_OPTIONS;
    this._year = this.yearOptions[0];
  }

  ngOnInit() {
    this.initUser();
  }

  public preview(evt: MouseEvent, h: Highlight): void {
    evt.stopPropagation();
    let parts: string[] = h.startStop.split("-");
    let startParts: string[] = (parts[0] || "").split(":");
    let min: number = parseInt(startParts[0] || "0", 10);
    let sec: number = parseInt(startParts[1] || "0", 10);
    this.previewStartTime = min * 60 + sec;
    this.previewHighlight = h;
  }

  public confirmDelete(evt: MouseEvent, h: Highlight): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.title = "Confirm";
    modalRef.componentInstance.body =
      "Are you sure you want to permanently delete this highlight?";
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) this.delete(evt, h);
      },
      (rejectedValue: any) => {
        console.log("rejected", rejectedValue);
      }
    );
  }

  public delete(evt: MouseEvent, h: Highlight): void {
    evt.stopPropagation();
    let rowID = h.ID.toString();
    this.submitting = true;
    this.highlightsService
      .deleteHighlight(rowID, this._year)
      .pipe(first())
      .subscribe(
        (r: any) => {
          this.submitting = false;
          this.setSuccess("Highlight has been deleted.");
          location.reload();
        },
        (e: any) => {
          this.submitting = false;
          this.setError(
            "Oops something went wrong and we could not delete the highlight. Please try again later."
          );
        }
      );
  }

  public toggleRow(h: Highlight): void {
    if (this.selected[h.ID]) delete this.selected[h.ID];
    else this.selected[h.ID] = true;
  }

  public toggleAll(): void {
    if (!this.checkAll) this.selected = {};
    else
      this.selected = this.highlights.reduce((s, h) => {
        s[h.ID] = true;
        return s;
      }, {});
  }

  public reload(): void {
    this.previewHighlight = undefined;
    this.loaded = false;
    this.loadHighlights();
  }

  public get numSelected(): number {
    return Object.keys(this.selected).filter((k) => !!this.selected[k]).length;
  }

  public placeOrder(): void {
    if (this.numSelected < 1) {
      console.log("numSelected", this.numSelected);
      this.setError("You must select at least one clip");
      return;
    }
    this.error = null;
    this.submitting = true;

    this.highlightsService
      .orderHighlights({
        teamID: this.account.teamID,
        year: this._year,
        rowIDString: Object.keys(this.selected).join("~"),
      })
      .subscribe();

    this.submitting = false;
    this.setSuccess(
      "Your clip order has been submitted. You will be receiving a link via email to your clip archive within 24 hours."
    );
  }

  private initUser(): void {
    this.userService.current.subscribe((user: User) => {
      if (!user || !user.loaded) return;
      this.account = user;
      this.loadHighlights();
      this.loadArchive();
    });
  }

  private loadHighlights(): void {
    this.highlightsService
      .findAll({
        teamID: this.account.teamID,
        year: this._year,
      })
      .pipe(first())
      .subscribe(
        (d: any) => {
          if (!d.success) {
            console.warn("error finding highlights");
            return;
          }
          this.initHighlights(d);
        },
        (e: any) => {
          console.warn("error finding highlights 2", e);
        }
      );
  }

  private loadArchive(): void {
    this.highlightsService
      .findArchived({ teamID: this.account.teamID })
      .pipe(first())
      .subscribe(
        (d: any) => {
          if (!d.success || !d.result) return;
          this.archive = d.result;
        },
        (e: any) => {
          console.warn("error finding highlights archive", e);
        }
      );
  }

  private initHighlights(data: any): void {
    this.highlights = [];
    const section = data.result.sections[0];
    const keys = section.columnHeadings;
    section.rows.forEach((row: any[]) => {
      const data: any = {};
      for (let i = 0; i < keys.length; i++) {
        data[keys[i]] = row[i];
      }
      this.highlights.push(new Highlight(data));
    });
    this.ready();
  }

  private ready(): void {
    this.loaded = true;
  }

  private setSuccess(msg: string): void {
    this.success = msg;
    window.scroll(0, 0);
  }

  private setError(msg: string): void {
    this.error = msg;
    window.scroll(0, 0);
  }
}
