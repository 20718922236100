import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { ApiService } from './api.service'
import { ReportParams, ReportSection, Report } from '../models'

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private apiService: ApiService
  ) { }

  public getReport(
    reportType: string,
    reportParams: ReportParams
  ): Observable<any> {
    const path = this.reportPath(reportType)
    if (!path) throw new Error('Invalid reportType')

    return this.apiService.get(path, { params: reportParams })
  }

  public getReportOutputUrl(reportType: string, reportParams: ReportParams, outputType: string): string {
    const path = this.reportPath(reportType)
    if (!path) throw new Error('Invalid reportType')
    let qs = '?'
    const keys = Object.keys(reportParams)
    const pairs = [`${outputType}=true`]
    keys.forEach((k) => {
      pairs.push(k + '=' + encodeURIComponent(reportParams[k]))
    })
    qs += pairs.join('&')

    return ApiService.API_URL + path + qs
  }

  // public getReportCSVUrl(reportType: string, reportParams: ReportParams): string {
  //   return this.getReportOutputUrl(reportType, reportParams, 'csv')
  // }

  public getReportPDFUrl(reportType: string, reportParams: ReportParams): string {
    return this.getReportOutputUrl(reportType, reportParams, 'pdf')
  }

  public emailReport(
    reportType: string,
    reportParams: ReportParams,
    recipients: string,
    message?: string
  ): Observable<any> {
    const path = this.reportPath(reportType)
    if (!path) throw new Error('Invalid reportType')
    const params = { pdf: true, email: true, recipients, ...reportParams }
    if (message) params['message'] = message

    return this.apiService.post(path, params)
  }

  private reportPath(reportType: string): string {
    const paths: any = {
      // team
      teamduals: 'reports',
      seasonteamanalysis: 'reports/seasonteamanalysis',
      matchcounts: 'reports/matchcounts',
      logins: 'reports/logins',
      matchlistingbyteam: 'reports/matchlistingbyteam',
      categoryleaders: 'reports/categoryleaders',
      advancedtagreportteam: 'reports/advancedtagreportteam',
      highlights: 'reports/highlights',
      // event
      dualmeetsummary: 'reports/dualmeetsummary',
      eventanalysis: 'reports/eventanalysis',
      dualeventanalysis: 'reports/eventanalysis',
      tourneyeventanalysis: 'reports/eventanalysis',
      tournamentsummary: 'reports/tournamentsummary',
      // individual
      individualmatchsummary: 'reports/individualmatchsummary',
      individualanalysis: 'reports/individualanalysis',
      advancedtagreportindividual: 'reports/advancedtagreportindividual',
      individualseasonsummaryillinois: 'reports/individualseasonsummaryillinois',
      // custom
      customreport: 'reports/customreport',
      // cumulative
      categoryleaderscumulative: 'reports/categoryleaderscumulative',
      individualanalysiscareer: 'reports/individualanalysiscareer',
    }

    return paths[reportType]
  }
}
