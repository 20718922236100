<!-- STEP 2 -->
<mb-spinner *ngIf="!loaded" type="ripple-inverse"></mb-spinner>
<div *ngIf="loaded">
  <a (click)="goBack()" class="mb-link" style="color:gainsboro">&lt;&lt; Back</a>
  <h2 class="mb-heading">Step 1 of 3</h2>
  <p class="mb-text" style="font-size: 20px">
    Your username is listed below.
  </p>
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
	  <mb-text-field
        formControlName="userName"
        label="Username"
        [validate]="validate"
        [control]="form.get('username')"
        [messages]="messages"
		[mbDisabled]="true"
      ></mb-text-field>
	  <p class="mb-text" style="font-size: 20px; margin-top: 48px;">
    	Enter a password betwen 8 and 24 characters (using ONLY letters, numbers and the underscore character).
  	  </p>
	  <mb-text-field
        formControlName="password"
        label="Password"
		type="password"
        [control]="form.get('password')"
        [messages]="messages"
		[valid]="!validate || form.get('password').valid"
      ></mb-text-field>
	  <mb-text-field  style="margin-bottom: 48px;"
        formControlName="confirmpassword"
        label="Confirm Password"
		type="password"
        [control]="form.get('confirmpassword')"
        [messages]="messages"
		[valid]="!validate || form.get('confirmpassword').valid"
      ></mb-text-field>
	  <div class="form-group">
      <button  *ngIf="!submitting"
        type="submit"
        class="btn btn-primary btn-lg">
          Next
      </button>
      <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
    </div>
  </form>

</div>
