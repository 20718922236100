<div class="mb-onboarding__login">
  <div *ngIf="!registered">
    <h2 class="mb-heading">Sign-in to your profile</h2>
  </div>

  <div *ngIf="registered">
    <h2 class="mb-heading">Congratulations!</h2>
    <p class="mb-text">
      You have successfully created your member profile. Please use your new
      credentials (email address and password) to log in below:
    </p>
  </div>

  <mb-alert
    *ngIf="invalidInvite"
    type="danger"
    [message]="invalidInviteMessage"
    (closed)="invalidInvite = false"
  ></mb-alert>

  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <mb-alert
      *ngIf="error"
      type="danger"
      [message]="error"
      (closed)="error = null"
    ></mb-alert>

    <mb-text-field
      formControlName="userName"
      label="Username or Email Address"
      [valid]="!validate || form.get('userName').valid"
    ></mb-text-field>

    <mb-text-field
      formControlName="password"
      type="password"
      label="Password"
      [valid]="!validate || form.get('password').valid"
    ></mb-text-field>

    <div class="form-group">
      <button type="submit" *ngIf="!submitting" class="btn btn-primary btn-lg">
        Log In
      </button>
      <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
    </div>
  </form>

  <div class="mb-onboarding__links">
    <p>
      <a routerLink="/login/password" class="mb-link mr-3">Forgot password?</a>
      <a href="https://help.matboss.com/" target="_blank" class="mb-link"
        >Help</a
      >
    </p>
  </div>
</div>
