<div class="mb-video mb-video-match">
  <div class="mb-breadcrumbs">
    <a class="mb-link" (click)="goBack()">‹ Back</a>
  </div>

  <ng-container *ngIf="betaViewer">
    <div class="jumbotron mb-match-viewer">
      <div class="text-center">
        <span class="mb-match-viewer-header">
          <img src="./assets/SVG/UI/Alert.svg" alt="Alert" /> View in the Modern
          Match Viewer:
        </span>
        <button class="btn btn-light" (click)="showBetaViewer()">
          Open Match
        </button>
      </div>
    </div>
  </ng-container>

  <mb-alert
    *ngIf="error"
    type="danger"
    [message]="error"
    (closed)="error = null"
  ></mb-alert>
  <mb-alert
    *ngIf="success"
    [message]="success"
    (closed)="success = null"
  ></mb-alert>
  <mb-alert
    *ngIf="info"
    type="primary"
    [message]="info"
    (closed)="info = null"
  ></mb-alert>

  <mb-spinner
    *ngIf="!loaded"
    label="Loading Match..."
    class="my-5 p-5 text-center"
  ></mb-spinner>

  <div *ngIf="loaded">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <h2>
          {{ match.theWeightClass }} - {{ match.ourWrestlerName }} vs.
          {{ match.opponentWrestlerLabel }}
        </h2>
      </div>
      <div class="row justify-content-center">
        <div class="d-none d-md-block col-md-2">
          <mb-video-toolbar
            *ngIf="user.isMainContact"
            [matchVideo]="matchVideo"
            [doodle]="doodle"
          ></mb-video-toolbar>
          <div *ngIf="user.isWrestler" class="mb-video-tools__notes">
            <div class="notes-title">Notes</div>
            <textarea
              disabled="disabled"
              class="form-control"
              name="player-notes"
              [(ngModel)]="match.notes"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="col-md-7">
          <mb-video-playback
            #videoPlayback
            [matchVideo]="matchVideo"
            [user]="user"
            [doodle]="doodle"
            [startTime]="startTime"
            [autoplay]="autoplay"
          ></mb-video-playback>
        </div>
        <div class="d-none d-md-block col-md-2">
          <div *ngIf="isTrackingPreviousAndNextMatch">
            <div class="form-group">Move: {{ formattedMove }}</div>
            <div class="form-group">
              <button
                class="btn btn-full btn-primary"
                (click)="goPreviousMove()"
                [disabled]="!hasPreviousMove"
              >
                Previous Move
              </button>
            </div>
            <div class="form-group">
              <button
                class="btn btn-full btn-primary"
                (click)="replayCurrentMove()"
              >
                Restart Current
              </button>
            </div>
            <div class="form-group">
              <button
                class="btn btn-full btn-primary"
                (click)="goNextMove()"
                [disabled]="!hasNextMove"
              >
                Next Move
              </button>
            </div>
          </div>
          <mb-video-highlights [matchVideo]="matchVideo"></mb-video-highlights>

          <mb-video-shortcuts></mb-video-shortcuts>

          <mb-video-options
            class="d-block"
            style="margin-top: 5rem"
            [matchVideo]="matchVideo"
            [doodle]="doodle"
            [user]="user"
          ></mb-video-options>
        </div>
      </div>
    </div>
  </div>
</div>
