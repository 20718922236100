<div class="container container--inner">
  <div class="mb-breadcrumbs pb-3 mt-3">
    <a preserveQueryParams routerLink="/roster">‹ Back To Roster</a>
  </div>

  <h3 class="mb-page__head">
    <span class="mb-page__head-title">
      <img src="./assets/SVG/Page/Roster.svg" alt="Icon" />
      Import Your Roster from Trackwrestling
    </span>
  </h3>

  <p class="alert alert-info">
    If you are not seeing the correct roster listed below, please make sure your
    TrackWrestling settings are correct.<br />
    <a [routerLink]="['/settings', 'track-wrestling']"
      >Go to the TrackWrestling settings page</a
    >
  </p>

  <hr class="mb-hr" />

  <mb-spinner
    *ngIf="!loaded"
    label="Loading Roster..."
    class="my-5 p-5 text-center"
  ></mb-spinner>

  <div *ngIf="loaded">
    <p class="mb-text">
      Match up each wrestler on your TrackWrestling roster with existing
      wrestlers on your MatBoss roster. If there is no match, choose either 'Do
      Not Import' or 'Create New Wrestler'. If you select a wrestler marked as
      "Former" on your MatBoss roster, that wrestler will be reactivated. The
      designation "Has Login Profile" means that this roster entry has a
      wrestler login profile associated with it.
    </p>

    <form (ngSubmit)="submit()" novalidate>
      <mb-alert
        *ngIf="error"
        type="danger"
        [message]="error"
        (closed)="error = null"
      ></mb-alert>
      <table class="table">
        <thead>
          <tr>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Grade</th>
            <th>Matboss Roster Match</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let twWrestler of twWrestlers">
            <td>{{ twWrestler.lastName }}</td>
            <td>{{ twWrestler.firstName }}</td>
            <td>{{ twWrestler.grade }}</td>
            <td>
              <select
                class="form-control"
                [(ngModel)]="import[twWrestler.twid]"
                (ngModelChange)="changeImport()"
                [ngModelOptions]="{ standalone: true }"
              >
                <option [value]="undefined" disabled>
                  - Choose Wrestler -
                </option>
                <option value="new" selected>Create New Wrestler</option>
                <option [value]="null">Do Not Import</option>
                <option
                  *ngFor="let w of wrestlers"
                  [value]="w.wrestlerID.toString()"
                >
                  <span *ngIf="w.Level == 'Former'">[Former] </span>
                  {{ w.fullName }}
                  <span *ngIf="w.users.length > 0"> (Has Login Profile)</span>
                </option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>

      <p class="my-4 mb-text">
        The wrestlers listed below have not been matched up with anyone on your
        TrackWrestling roster. Most likely, this is due to graduation, etc.
        Place a checkmark next to those who are no longer with your team, and
        the system will change their status to 'Former' so they no longer show
        up in dropdown menus, etc.
      </p>

      <table class="table">
        <thead>
          <tr>
            <th>Select</th>
            <th>Last Name</th>
            <th>First Name</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let w of unselectedWrestlers">
            <tr
              *ngIf="
                !w.lastName.toLowerCase().includes('forfeit') &&
                !w.lastName.toLowerCase().includes('wrestler')
              "
            >
              <td>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [(ngModel)]="former[w.wrestlerID.toString()]"
                    [value]="true"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </td>
              <td>{{ w.lastName }}</td>
              <td>{{ w.firstName }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <div class="form-group">
        <button
          type="submit"
          *ngIf="!submitting"
          class="btn btn-primary btn-lg"
        >
          Import
        </button>
        <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
      </div>
    </form>
  </div>
</div>
