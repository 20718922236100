<!-- STEP 4 -->
<mb-spinner
  *ngIf="!loaded"
  type="ripple-inverse"></mb-spinner>
<div *ngIf="loaded">
  <div *ngIf="showSteps">
    <a (click)="goBack()" class="mb-link" style="color:gainsboro">&lt;&lt; Back</a>
    <h2 class="mb-heading">Step 2 of 3</h2>
  </div>
  <p class="mb-text">
    Choose which events to import and then click the
    'Import' button at the bottom of the table.
  </p>

  <form (ngSubmit)="submit()" novalidate>
    <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>

    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th><input 
              type="checkbox" 
              name="all" 
              [checked]="isAllChecked()" 
              (change)="checkAll($event)"></th>
          <th>TW Level</th>
          <th>Event Type</th>
          <th>Event Name</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let event of twEvents">
          <td>
            <div class="form-check">
              <input
                [(ngModel)]="eventImport[event.twid]"
                [ngModelOptions]="{standalone: true}"
                type="checkbox"
                class="form-check-input">
            </div>
          </td>
          <td>
            {{ event.squadLevel }}
          </td>
          <td>
            {{ event.eventType }}
          </td>
          <td>
            {{ event.eventName }}
          </td>
          <td>
            {{ event.eventDate | date:'yyyy-MM-dd':'UTC+0' }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="form-group">
      <button type="submit"
        *ngIf="!submitting"
        class="btn btn-primary btn-lg">
          Import
      </button>
      <mb-ellipsis-loader
        *ngIf="submitting"></mb-ellipsis-loader>
    </div>
  </form>
</div>
