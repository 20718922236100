<div class="container container--inner mb-settings">
  <div class="mb-breadcrumbs pb-3">
    <a preserveQueryParams routerLink="/settings">‹ Back To Settings</a>
  </div>

  <h3 class="mb-page__head">
    <span class="mb-page__head-title"> Organization Members </span>
  </h3>

  <mb-spinner
    *ngIf="!loaded"
    label="Loading..."
    class="my-5 p-5 text-center"
  ></mb-spinner>

  <div *ngIf="loaded">
    <div class="mb-pagination">
      <strong>{{ users.length }} Member Profiles</strong>
    </div>

    <mb-alert
      *ngIf="error"
      type="danger"
      [message]="error"
      (closed)="error = null"
    ></mb-alert>
    <mb-alert
      *ngIf="success"
      [message]="success"
      (closed)="success = null"
    ></mb-alert>

    <mb-user-filters
      [search]="search"
      (submitted)="updateFilters($event)"
    ></mb-user-filters>

    <div class="table-responsive">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th class="d-none d-sm-table-cell text-nowrap">
              <a (click)="sort('userRole')" class="mb-th__link">Type</a>
            </th>
            <th class="text-nowrap">
              <a (click)="sort('userFullName')" class="mb-th__link">Name</a>
            </th>
            <th class="d-none d-sm-table-cell text-nowrap">
              <a (click)="sort('userEmail')" class="mb-th__link">Email</a>
            </th>
            <th class="d-none d-sm-table-cell text-nowrap">
              <a (click)="sort('userActive')" class="mb-th__link">Status</a>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let user of filteredUsers">
            <tr>
              <td class="d-none d-sm-table-cell">{{ user.formattedRole }}</td>
              <td>{{ user.userFullName }}</td>
              <td class="d-none d-sm-table-cell">{{ user.userEmail }}</td>
              <td class="d-none d-sm-table-cell">
                {{ user.userActive === 1 ? "Active" : "Disabled" }}
              </td>
              <td>
                <button
                  *ngIf="isEditable(user)"
                  (click)="editUser(user)"
                  class="btn btn-sm btn-outline-secondary"
                  style="text-transform: uppercase"
                >
                  <img
                    src="./assets/SVG/UI/Edit.svg"
                    alt="Icon"
                    style="width: 16px; height: 16px"
                  />
                  Edit
                </button>
              </td>
            </tr>
            <tr *ngIf="user.isWrestler">
              <td
                style="border-top: 0 !important; padding-top: 0 !important"
              ></td>
              <td
                colspan="5"
                style="border-top: 0 !important; padding-top: 0 !important"
              >
                Roster Entry:
                <a
                  routerLink="/roster"
                  [queryParams]="{ search: user.formattedWrestler }"
                >
                  {{ user.formattedWrestler }}
                </a>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <h3 class="mb-page__head">
      <span class="mb-page__head-title"> Pending Invites </span>
    </h3>

    <div class="mb-pagination pb-2">
      <strong>{{ filteredInvites.length }} Invites</strong>
    </div>

    <div class="table-responsive" *ngIf="filteredInvites.length > 0">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th class="d-none d-sm-table-cell text-nowrap">
              <a (click)="sort('userRole')" class="mb-th__link">Type</a>
            </th>
            <th class="text-nowrap">
              <a (click)="sort('userFullName')" class="mb-th__link">Name</a>
            </th>
            <th class="d-none d-sm-table-cell text-nowrap">
              <a (click)="sort('userEmail')" class="mb-th__link">Email</a>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let invite of filteredInvites">
            <tr>
              <td class="d-none d-sm-table-cell">{{ invite.formattedRole }}</td>
              <td>{{ invite.fullName }}</td>
              <td class="d-none d-sm-table-cell">{{ invite.email }}</td>
              <td class="text-right">
                <button
                  *ngIf="isInviteEditable(invite)"
                  (click)="editInvite(invite)"
                  class="btn btn-sm btn-outline-secondary"
                  style="text-transform: uppercase"
                >
                  <img
                    src="./assets/SVG/UI/Edit.svg"
                    alt="Icon"
                    style="width: 16px; height: 16px"
                  />
                  Edit
                </button>
              </td>
            </tr>

            <tr *ngIf="invite.isWrestler">
              <td
                style="border-top: 0 !important; padding-top: 0 !important"
              ></td>
              <td
                colspan="5"
                style="border-top: 0 !important; padding-top: 0 !important"
              >
                Roster Entry:
                <a
                  *ngIf="invite.formattedWrestler != ''"
                  routerLink="/roster"
                  [queryParams]="{ search: invite.formattedWrestler }"
                >
                  {{ invite.formattedWrestler }}
                </a>
                <span *ngIf="invite.formattedWrestler == ''"> Unknown </span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="mb-details-link">
      <a (click)="inviteUser()" class="btn btn-lg btn-primary"
        >Invite New Member</a
      >
    </div>
  </div>
</div>
