<svg style="display: none">
  <symbol id="volume-on"
    viewBox="0 0 48 48">
    <path fill="white" d="M6 18v12h8l10 10V8L14 18H6zm27 6c0-3.53-2.04-6.58-5-8.05v16.11c2.96-1.48 5-4.53 5-8.06zM28 6.46v4.13c5.78 1.72 10 7.07 10 13.41s-4.22 11.69-10 13.41v4.13c8.01-1.82 14-8.97 14-17.54S36.01 8.28 28 6.46z"/>
    <path fill="none" d="M0 0h48v48H0z"/>
  </symbol>
  <symbol id="volume-off"
    viewBox="0 0 48 48">
    <path fill="white" d="M33 24c0-3.53-2.04-6.58-5-8.05v4.42l4.91 4.91c.06-.42.09-.85.09-1.28zm5 0c0 1.88-.41 3.65-1.08 5.28l3.03 3.03C41.25 29.82 42 27 42 24c0-8.56-5.99-15.72-14-17.54v4.13c5.78 1.72 10 7.07 10 13.41zM8.55 6L6 8.55 15.45 18H6v12h8l10 10V26.55l8.51 8.51c-1.34 1.03-2.85 1.86-4.51 2.36v4.13c2.75-.63 5.26-1.89 7.37-3.62L39.45 42 42 39.45l-18-18L8.55 6zM24 8l-4.18 4.18L24 16.36V8z"/>
    <path fill="none" d="M0 0h48v48H0z"/>
  </symbol>
</svg>

<div class="mb-video-playback" id="mb-video-playback">
  <div class="mb-video__score-panel">
    <div
      class="mb-video__playback-time"
      *ngIf="playbackTime && !!matchVideo.src">
      {{ playbackTime }}
    </div>
    <div class="mb-video__fullscreen"
      (click)="toggleFullscreen()">
      <img src="./assets/SVG/UI/Fullscreen.svg"
        alt="Fullscreen" title="Fullscreen Video"
        style="width: 100%; height: 100%">
    </div>
    <div *ngIf="!!matchVideo.src"
      class="mb-video__volume"
      title="Toggle Volume"
      (click)="toggleVolume()">
      <svg *ngIf="volume"><use xlink:href="#volume-on" /></svg>
      <svg *ngIf="!volume"><use xlink:href="#volume-off" /></svg>
    </div>
    <canvas *ngIf="!!matchVideo.src"
      #canvasElem></canvas>
    <video *ngIf="!!matchVideo.src"
      playsinline
      preload="auto"
      [poster]="matchVideo.thumbnail"
      (ended)="stopVideo()"
      #videoElem>
      <source [src]="matchVideo.src" type="video/mp4">
      <img src="" title="No video playback capabilities">
    </video>
    <!-- no video -->
    <div *ngIf="!matchVideo.src"
      class="mb-video__empty">
      <h3 *ngIf="!user || user.isWrestler">No video file for this match</h3>
      <div *ngIf="user && !user.isWrestler"
        ng2FileDrop
        [ngClass]="{
          'mb-file-over': hasDropZoneOver && !uploadedFile,
          'mb-file-set': !!uploadedFile
        }"
        (fileOver)="fileDropOver($event)"
        (onFileDrop)="fileDropped($event)"
        [uploader]="uploader"
        class="mb-video__upload">
          <div *ngIf="!uploadedFile">
            <h3>No video file for this match</h3>
            <p>Drag and drop an .mp4 or .mov video file here</p>
            <p>or</p>
            <label
              for="videoFile"
              class="btn btn-light">Click to Select File</label>
          </div>

          <div *ngIf="!!uploadedFile">
            <h3>Video Selected</h3>
            <p>
              File Selected: {{ uploadedFile.name }}
            </p>
            <div *ngIf="!uploading && !converting" class="pt-4">
              <p>
                <button
                  class="btn btn-primary"
                  (click)="submitUpload()">
                  Upload Video File
                </button>
              </p>
              <p>or</p>
              <button
                *ngIf="!uploading"
                class="mb-link--light"
                (click)="clearUploads()">
                Clear selection
              </button>
            </div>
            <div *ngIf="uploading" class="pt-4">
              <mb-ellipsis-loader *ngIf="uploading" type="inverse"></mb-ellipsis-loader>
              <div class="progress">
                <div class="indicator" [style.width]="formattedUploadProgress"></div>
              </div>
              <div>
                <span class="mb-text--light text-left">
                  {{ formattedUploadProgress }}
                </span>
              </div>
            </div>
            <div *ngIf="converting" class="pt-4">
              <mb-ellipsis-loader *ngIf="converting" type="inverse"></mb-ellipsis-loader>
              <div class="progress">
                <div class="indicator" [style.width]="formattedConversionProgress"></div>
              </div>
              <div>
                <span class="mb-text--light text-left">
                  {{ formattedConversionProgress }}
                </span>
                <span class="mb-text--light text-right">
                  {{ formattedConversionStatus }}
                </span>
              </div>
            </div>
          </div>

          <input
            ng2FileSelect
            [uploader]="uploader"
            (onFileSelected)="fileSelected($event)"
            id="videoFile"
            type="file"
            style="display: none">
      </div>
    </div>
  </div>
  <div class="mb-video__playback-controls">
    <div class="mb-video-timeline">

      <!-- CONTROLS -->
      <div *ngIf="!!matchVideo.src" class="mb-video-timeline__controlbar">
        <div class="mb-video-timeline__buttons">
          <button
            [ngClass]="{ 'mb-pause': !isPaused }"
            style="background: transparent"
            class="mb-video-timeline__play-button"
            (click)="toggleVideoPlayback()"></button>
        </div>
        <div class="mb-video-timeline__scrubber" #scrubber>
          <div class="mb-video-timeline__progressbar"
            (click)="progressbarClick($event)">
            <div class="mb-video-timeline__progress"
              [ngStyle]="{'width.px': progressAmount }"></div>
          </div>
          <div class="mb-video-timeline__knob"
            (mousedown)="knobDragStart($event)"
            [ngStyle]="{'left.px': progressAmount }">
            <div class="mb-video-timeline__knob-inner"></div>
          </div>
        </div>
      </div>

      <!-- SCORING -->
      <div class="mb-video-timeline__scoring">
        <!-- player ids -->
        <div class="mb-video-timeline__player-ids">
          <div title="Click to edit"
            (click)="editWeightClass()"
            class="player weight-class mb-weight-class-edit"
            id="weightClassLabel">
            {{ match.theWeightClass }}
          </div>
          <div class="player highlight-{{ match.ourColor }}"
            (click)="editOurWrestler($event)">
            {{ match.ourWrestlerName }}
          </div>
          <div class="player highlight-{{ match.theirColor }}"
            (click)="editOpponentWrestler($event)">
            {{ match.opponentName }}
          </div>
        </div>

        <!-- timeline -->
        <div class="mb-video-timeline__player-timeline">
          <div class="mb-video-timeline__player-timeline-inner">
            <div *ngFor="let period of periodOptions"
              class="mb-video-timeline__period-container">
              <div class="title">{{ period.title }}</div>
              <div class="timeline highlight-{{ match.ourColor }}">
                <ng-container *ngFor="let se of match.ourScoringEvents; index as i">
                  <span
                    *ngIf="se.period === period.value"
                    (click)="movePlayhead(se, $event)"
                    (contextmenu)="editScoringSymbol(se, $event)"
                    mb-long-press
                    (onLongPress)="editScoringSymbol(se, $event)"
                    class="marker"
                    [ngStyle]="{'left.px': (20*i) + 5}"
                  >{{ se.symbol }}</span>
                </ng-container>
              </div>
              <div class="timeline highlight-{{ match.theirColor }}">
                <ng-container *ngFor="let se of match.theirScoringEvents; index as i">
                  <span
                    *ngIf="se.period === period.value"
                    (click)="movePlayhead(se, $event)"
                    (contextmenu)="editScoringSymbol(se, $event)"
                    mb-long-press
                    (onLongPress)="editScoringSymbol(se, $event)"
                    class="marker"
                    [ngStyle]="{'left.px': (20*i) + 5}"
                  >{{ se.symbol }}</span>
                </ng-container>
              </div>
            </div><!-- .period-container -->
          </div><!-- .player-timeline-inner -->
        </div><!-- .player-timeline -->

        <!-- add scoring -->
        <div class="mb-video-timeline__scoring">
          <div class="mb-video-timeline__scores">
            <div class="mb-video-timeline__scores--top">&nbsp;</div>
            <div class="mb-video-timeline__scores--score">
              {{ match.ourWrestlerScore }}
            </div>
            <div class="mb-video-timeline__scores--score">
              {{ match.theirWrestlerScore }}
            </div>
          </div>
          <div *ngIf="user && !user.isWrestler"
            class="mb-video-timeline__add-scoring">
            <a (click)="addNewScoringSymbol()">Scoring Event</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
