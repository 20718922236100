export class SchoolRecord {
  public ID: number
  public accountNumber: string
  public category: string
  public name: string
  public year: string
  public record: number
  public notes: string

  public constructor(data) {
    this.update(data)
  }

  public update(data) {
    Object.assign(this, data)
    if (data.record) this.record = parseFloat(data.record.toString())
    if (this.record && this.category.indexOf('Percentage') === -1) this.record = Math.floor(this.record)
  }
}
