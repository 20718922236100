import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { first } from 'rxjs/operators'

import { User, Wrestler } from '../../models'
import { UserService, RosterService } from '../../services'

@Component({
  selector: 'mb-setup252',
  templateUrl: './setup252.component.html'
})
export class Setup252Component implements OnInit {

  @Input() account: User

  public form: FormGroup
  public loaded = false
  public submitting = false
  public validate = false
  public wrestlers: Wrestler[]
  private wrestlersLoaded = false

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
	private rosterService: RosterService
  ) { }

  ngOnInit() {
	this.findRoster()
    //this.createForm()
  }

  public submitAnswer(theAnswer: string) {
	  this.submitting = true
	  this._submit(theAnswer)
  }

  private _submit(theAnswer: string): void {
    if (theAnswer === 'yes') {
      this.router.navigate(['/setup/12'])
    }
    else {
      window.history.back()
    }
  }

  public goBack() {
    window.history.back()
  }

  // private

  private createForm(): void {
    this.form = this.fb.group({

	})
    this.loaded = true
  }

  private findRoster(): void {
    this.wrestlersLoaded = false
    this.rosterService.getRoster(this.account.teamID, {status: 'current'})
      .pipe(first())
      .subscribe((data: any) => {
        this.wrestlers = []
        data.result.forEach(item => {
          this.wrestlers.push(new Wrestler(item))
        })
        this.wrestlersLoaded = true
        this.ready()
      })
  }

  private ready(): void {
    if (!this.wrestlersLoaded) return
    //this.initImport()
    this.createForm()
  }
}
