import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { first } from 'rxjs/operators'

import { User } from '../../models'
import { UserService, AdministrativeService } from '../../services';

@Component({
  selector: 'mb-setup41',
  templateUrl: './setup41.component.html'
})
export class Setup41Component implements OnInit {

  @Input() account: User

  public form: FormGroup
  public loaded = false
  public submitting = false
  public validate = false

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
	private administrativeService: AdministrativeService
  ) { }

  ngOnInit() {
    this.createForm()
  }

  public submitAnswer(theAnswer: string) {
	  this.submitting = true
	  const data: any = {
        showSetupWizard: 'false'
      }

	  const schoolName = this.account.schoolName
	  //const userName = this.account.userName
	  //const teamCode = this.account.teamCode
	  const emailOfMainContact = this.account.emailAddress
	  const state = this.account.state
	  const partone = 'Someone has just completed the setup wizard. Look below for info on the account. <br>School name: '
	  const parttwo = '<br>State: '
	  const partthree = '<br>Email: '

	  const message = partone.concat(schoolName, parttwo, state, partthree, emailOfMainContact)
	  /*this.administrativeService.emailSomeone(
		  'john@matbossapp.com',
		  message,
		  'Setup wizard completed'
		  )
			.pipe(first())
			.subscribe((r) => null)*/

	  this.administrativeService.setwizardcompleted(
		this.account.contactEmail
		  )
			.pipe(first())
			.subscribe((r) => null)

	  let update: boolean = true

	  if (update) {
		  // set showSetupWizard flag
		  this.userService.update(
			this.account.userName,
			this.account.userType,
			data
		  )
		  .pipe(first())
		  .subscribe((r) => this._submit())
		}
		else this._submit()
  }

  private _submit(): void {
    this.router.navigate(['/'])
  }

  public goBack() {
    window.history.back()
  }

  // private

  private createForm(): void {
    this.form = this.fb.group({
	  //username: [this.account.userName],
      //school: [this.account.schoolName],
      //teamcode: [this.account.teamCode]
	})
	//var usernametext = document.getElementById("usernametext") as HTMLSelectElement
	//usernametext.innerHTML = "Username: " + this.account.userName;
    this.loaded = true
  }
}
