import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { EventEditComponent, MatchEditComponent } from "./";
import { MBEvent, MBMatch, MBMatchVideo, User } from "../models";
import { MBEventService, UserService } from "../services";
import { TwLoginComponent } from "../trackwrestling/tw-login/tw-login.component";
import { MatchAddComponent } from "./match-add.component";
import { AdjustTeamPointComponent } from "./adjust-team-point.component";
import { environment } from "../../environments/environment";
import { TeamPointAdjustmentService } from "../services/teampointadjustment.service";
import { TeamPointAdjustment } from "../models/teampointadjustment";

@Component({
  selector: "mb-event-detail",
  templateUrl: "./event-detail.component.html",
})
export class EventDetailComponent implements OnInit, OnDestroy {
  public event: MBEvent;
  public matches: MBMatch[];
  public teamPointAdjustments: TeamPointAdjustment[];
  public loaded = false;
  public error = "";
  public success = "";
  public user: User;
  public exporting = false;
  public params: any;
  private eventID: string;
  private teamID: string;
  private unsubscribe: Subject<any> = new Subject();
  private thumbnails: any = {};

  /**
   * params are provided via dependency injection
   * @param apiService
   * @param userService
   */
  constructor(
    private eventService: MBEventService,
    private userService: UserService,
    private acRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private toastrService: ToastrService,
    private teamPointAdjustmentService: TeamPointAdjustmentService
  ) {}

  /**
   * set teamID from user.ID
   * @todo determine if wrestlers and coaches should see the same things
   */
  public ngOnInit() {
    this.acRoute.params
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((params: any) => {
        // console.log("params1: ", params);
        this.params = params || {};
        this.eventID = decodeURIComponent(params.id);
        this.findEventOnInit();
      });

    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        this.user = user;
        this.teamID = user.teamID;
      });
  }

  /**
   * unsubscribe
   */
  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * find the event
   */
  private findEventOnInit() {
    this.eventService
      .find(this.eventID)
      .pipe(first())
      .subscribe((data: any) => {
        this.event = new MBEvent(data.result);
        this.findMatches();
        this.findTeamPointAdjustments();
      });
  }

  private findEvent() {
    this.eventService
      .find(this.eventID)
      .pipe(first())
      .subscribe((data: any) => {
        this.event = new MBEvent(data.result);
      });
  }

  /**
   * find the matches
   */
  private findMatches() {
    this.loaded = false;
    this.eventService
      .findMatches({
        teamID: this.teamID,
        year: this.event.year,
        levelID: "-1",
        eventID: this.eventID,
      })
      .pipe(first())
      .subscribe((data: any) => {
        this.matches = [];
        data.result.forEach((r) => {
          this.matches.push(new MBMatch(r));
        });
        this.loaded = true;
      });
  }

  private findTeamPointAdjustments() {
    this.teamPointAdjustmentService
      .findTeamPointAdjustmentForEvent(this.eventID)
      .pipe(first())
      .subscribe((data: any) => {
        this.teamPointAdjustments = [];
        data.result.forEach((result) => {
          this.teamPointAdjustments.push(new TeamPointAdjustment(result));
        });
      });
  }

  public thumbnailForMatch(match: MBMatch): string {
    if (!this.thumbnails[match.matchID]) {
      this.thumbnails[match.matchID] = new MBMatchVideo(
        match,
        this.user
      ).thumbnail;
    }

    return this.thumbnails[match.matchID];
  }

  public defaultThumbnail(event: Event): void {
    (event.target as HTMLImageElement).src = `./assets/Img/mb-video.png`;
  }

  public watch(m: MBMatch): string {
    let canWatch = this.user.canWatch(m);
    if (canWatch) {
      let q: any = { ...this.params };
      q.back = "scorebook";
      return `${environment.viewerUrl}#/match/${
        m.matchID
      }?${new URLSearchParams(q).toString()}`;
    }
    return "#";
  }

  /**
   * open edit modal
   */
  public edit(): void {
    const modalRef = this.modalService.open(EventEditComponent, { size: "sm" });
    modalRef.componentInstance.event = this.event;
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          if (fulfilledValue.deleted) {
            this.router.navigate(["/scorebook"]);
            return;
          }
          this.event = new MBEvent(fulfilledValue.result);
          this.setSuccess("Event saved!");
        } else {
          this.setError(
            "There was an error saving the event (1).",
            fulfilledValue
          );
        }
      },
      (rejectedValue: any) => {
        if (
          rejectedValue === "Cross click" ||
          rejectedValue === 0 ||
          rejectedValue === 1
        )
          return;
        this.setError(
          "There was an error saving the event (2).",
          rejectedValue
        );
      }
    );
  }

  public editMatch(match: MBMatch): void {
    const modalRef = this.modalService.open(MatchEditComponent, { size: "sm" });
    modalRef.componentInstance.match = match;
    modalRef.componentInstance.teamID = this.teamID;
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          if (fulfilledValue.deleted) {
            this.router.navigate(["/scorebook"]);
            return;
          }
          this.findMatches();
          this.setSuccess("Match saved!");
        } else {
          this.setError(
            "There was an error saving the match (1).",
            fulfilledValue
          );
        }
      },
      (rejectedValue: any) => {
        if (
          rejectedValue === "Cross click" ||
          rejectedValue === 0 ||
          rejectedValue === 1
        )
          return;
        if (rejectedValue === "View Match") {
          this.router.navigateByUrl("/match/" + match.matchID);
        }
        this.setError(
          "There was an error saving the match (2).",
          rejectedValue
        );
      }
    );
  }

  public adjustTeamPoint(): void {
    const modalRef = this.modalService.open(AdjustTeamPointComponent, {
      size: "sm",
    });
    modalRef.componentInstance.eventID = this.eventID;
    modalRef.componentInstance.weightClassList = this.getWeightClassOfMatches();
    modalRef.result.then((result: any) => {
      if (result.success) {
        this.findTeamPointAdjustments();
        this.findEvent();
      } else {
        this.setError("There was an error saving the team point adjustment.");
      }
    });
  }

  public editTeamPoint(teamPointAdjustment: TeamPointAdjustment): void {
    const modalRef = this.modalService.open(AdjustTeamPointComponent, {
      size: "sm",
    });
    modalRef.componentInstance.eventID = this.eventID;
    modalRef.componentInstance.weightClassList = this.getWeightClassOfMatches();
    modalRef.componentInstance.teamPointAdjustment = teamPointAdjustment;
    modalRef.result.then((result: any) => {
      if (result.success) {
        this.findTeamPointAdjustments();
        this.findEvent();
      } else {
        this.setError("There was an error updating the team point adjustment.");
      }
    });
  }

  private getWeightClassOfMatches() {
    let weightClassList = new Set();
    if (this.matches) {
      for (let match of this.matches) {
        weightClassList.add(match.theWeightClass);
      }
    }
    return weightClassList;
  }

  public addMatch(): void {
    const modalRef = this.modalService.open(MatchAddComponent, { size: "sm" });
    modalRef.componentInstance.teamID = this.teamID;
    modalRef.componentInstance.event = this.event;

    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.findMatches();
          (location.href = `${environment.viewerUrl}#/match/${fulfilledValue.result.matchID}?id=${this.event.eventID}&back=scorebook`),
            "_self";
        } else {
          this.setError(
            "There was an error saving the match (1).",
            fulfilledValue
          );
        }
      },
      (rejectedValue: any) => {
        if (
          rejectedValue === "Cross click" ||
          rejectedValue === 0 ||
          rejectedValue === 1
        )
          return;
        this.setError(
          "There was an error saving the match (2).",
          rejectedValue
        );
      }
    );
  }

  public export(): void {
    const modalRef = this.modalService.open(TwLoginComponent, { size: "sm" });
    modalRef.componentInstance.event = this.event;
    modalRef.componentInstance.user = this.user;
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.toastrService.success("The event has been exported");
        } else this.handleExportError(fulfilledValue);
      },
      (rejectedValue: any) => {
        if (
          rejectedValue === "Cross click" ||
          rejectedValue === 0 ||
          rejectedValue === 1
        )
          return;
        this.handleExportError(rejectedValue);
      }
    );
  }

  // @todo DRY this function into module
  private handleExportError(r: any): void {
    let msg: string = "";
    if (r && r.result) {
      if (r.result.error && r.result.error.message)
        msg = r.result.error.message;
      else if (r.result.message) msg = r.result.message;
      else if (r.result.statusText) msg = r.result.statusText;
    } else if (r && r.message) msg = r.message;

    this.toastrService.error("There was an error exporting the event: " + msg);
  }

  private setError(message: string, obj?: any) {
    this.success = null;
    this.error = message;
    console.warn("Error: " + message, obj);
    window.scroll(0, 0);
  }

  private setSuccess(message: string, obj?: any) {
    this.error = null;
    this.success = message;
    if (obj) console.warn("Success", obj);
    window.scroll(0, 0);
  }
}
