import { Component, Input } from '@angular/core'

@Component({
  selector: 'mb-spinner',
  host: {'style': 'display: block; text-align: center;'},
  template: `
    <img *ngIf="isSpinner" src="./assets/loaders/spinner-gray.gif" alt="loading">
    <div *ngIf="isRipple" class="mb-ripple" [ngClass]="{ 'inverse': isInverse }"><div></div><div></div></div>
    <p *ngIf="label" class="text-muted mt-5">
      {{ label }}
    </p>
  `
})
export class SpinnerComponent {
  @Input() label: string
  @Input() type: string = 'ripple'

  constructor() { }

  public get isSpinner(): boolean {
    return this.type === 'spinner'
  }

  public get isRipple(): boolean {
    return this.type === 'ripple' || this.type === 'ripple-inverse'
  }

  public get isInverse(): boolean {
    return this.type === 'ripple-inverse'
  }
}
