import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { first } from "rxjs/operators";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";

import { User, UserInvite } from "../models";
import { UserService } from "../services/user.service";
import { Pagination, MBEventLevel } from "../util";
import {
  UserFilters,
  InviteUserComponent,
  UserEditComponent,
  UserInviteEditComponent,
} from "./";

@Component({
  selector: "mb-users",
  templateUrl: "./users.component.html",
  host: { class: "mb-page" },
})
export class UsersComponent implements OnInit, OnDestroy {
  public account: User;
  public loaded = false;
  private unsubscribe: Subject<any> = new Subject();
  public error: string;
  public success: string;

  public users: User[];
  public filteredUsers: User[];
  public invites: UserInvite[];
  public filteredInvites: UserInvite[] = [];
  public pagination: Pagination;
  public levelOptions: MBEventLevel[];
  public sortBy = "lastName";
  public status = "current";
  public search = "";
  private _sortAsc = true;

  public constructor(
    private modalService: NgbModal,
    private userService: UserService,
    public router: Router
  ) {}

  public ngOnInit() {
    this.userService
      .requestCurrent()
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.setUser(data);
          this.findUsers();
        },
        (e: any) => {
          console.warn("error", e);
        }
      );
  }

  public setUser(data: any) {
    if (data.success) {
      this.account = new User(data.result);
      this.userService.setCurrent(this.account);
      this.loaded = true;
    } else {
      console.warn("error", data.error);
    }
  }

  /**
   * unsubscribe
   */
  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public isEditable(user: User) {
    // return false
    return this.account.userUID !== user.userUID;
  }

  public isInviteEditable(user: User) {
    // return false
    return this.account.userUID !== user.userUID;
  }

  /**
   * This is a listener for the filters.submitted event
   * which is called from roster-filters.component when
   * filters have changed. This triggers a new search.
   * @param filters
   */
  public updateFilters(filters: UserFilters): void {
    if (this.status !== filters.status) {
      this.loaded = false;
      this.status = filters.status;
      this.findUsers();
    } else if (this.search !== filters.search) {
      this.search = filters.search;
      this.applySearch();
    }
  }

  /**
   * roster lookup
   */
  private findUsers(): void {
    this.loaded = false;

    this.userService
      .teamUsers(this.status)
      .pipe(first())
      .subscribe((data: any) => {
        this.users = [];
        data.result.forEach((item) => {
          this.users.push(new User(item));
        });
        this.filteredUsers = this.users;
        this.pagination = data.pagination as Pagination;
        this.loaded = true;
      });
    this.userService
      .teamInvites(this.status)
      .pipe(first())
      .subscribe((data: any) => {
        this.invites = [];
        data.result.forEach((item) => {
          this.invites.push(new UserInvite(item));
        });
        this.filteredInvites = this.invites;
      });
  }

  private applySearch(): void {
    this.filteredUsers = [];
    this.filteredUsers = this.users.filter((u: User) => {
      return (
        [u.userFirstName, u.userLastName]
          .join(" ")
          .toLocaleLowerCase()
          .indexOf(this.search.toLocaleLowerCase()) !== -1
      );
    });
    this.filteredInvites = [];
    this.filteredInvites = this.invites.filter((ui: UserInvite) => {
      return (
        [ui.first_name, ui.last_name]
          .join(" ")
          .toLocaleLowerCase()
          .indexOf(this.search.toLocaleLowerCase()) !== -1
      );
    });
  }

  /**
   * the sort direction
   */
  public get sortDir(): _.Many<"asc" | "desc"> {
    return this._sortAsc ? "asc" : "desc";
  }

  /**
   * reverse sort direction if field is same, or default to asc
   * @param field
   */
  private newSortDir(field: string): _.Many<boolean | "asc" | "desc"> {
    if (field === this.sortBy) {
      this._sortAsc = !this._sortAsc;
    } else {
      this._sortAsc = true;
    }
    return this.sortDir;
  }

  /**
   * sort wrestlers by a field
   * @param field
   */
  public sort(field): void {
    const dir: _.Many<boolean | "asc" | "desc"> = this.newSortDir(field);
    this.sortBy = field;
    this.filteredUsers = _.orderBy(
      this.users,
      [(w) => w[this.sortBy].toLowerCase()],
      dir
    ).map((w) => new User(w));
    this.filteredInvites = _.orderBy(
      this.invites,
      [(w) => w[this.convertUserFieldToInviteField(this.sortBy)].toLowerCase()],
      dir
    ).map((w) => new UserInvite(w));
  }
  private convertUserFieldToInviteField(field: string): string {
    switch (field) {
      case "userFullName":
        return "fullName";
      case "userEmail":
        return "email";
      case "userWrestler":
        return "wrestler";
      case "userRole":
        return "role";
      case "userActive":
        return "completed";
      default:
        return field;
    }
  }

  /**
   * add a new wrestler to the roster
   */
  public inviteUser(): void {
    const modalRef = this.modalService.open(InviteUserComponent, {
      size: "sm",
    });
    modalRef.componentInstance.teamID = this.account.teamID;
    modalRef.componentInstance.title = "Invite Member";
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.setSuccess("User invite sent!");
          this.search = "";
          this.findUsers();
        } else {
          this.setError("There was an error inviting the user", fulfilledValue);
        }
      },
      (rejectedValue: any) => {
        if (
          rejectedValue === "Cross click" ||
          rejectedValue === 0 ||
          rejectedValue === 1
        )
          return;
        this.setError("There was an error inviting the user.", rejectedValue);
      }
    );
  }

  /**
   * edit profile
   * @param Profile
   */
  public editUser(user: User): void {
    const modalRef = this.modalService.open(UserEditComponent, { size: "sm" });
    modalRef.componentInstance.teamID = this.account.teamID;
    modalRef.componentInstance.user = user;
    modalRef.result.then(
      (fulfilledValue) => {
        if (fulfilledValue.success) {
          this.setSuccess(
            fulfilledValue.result.first_name +
              " " +
              fulfilledValue.result.last_name +
              " updated!"
          );
          this.search = "";
          this.findUsers();
        } else {
          this.setError(
            "There was an error saving the profile (1).",
            fulfilledValue
          );
        }
      },
      (rejectedValue) => {
        if (
          rejectedValue === "Cross click" ||
          rejectedValue === 0 ||
          rejectedValue === 1
        )
          return;
        this.setError(
          "There was an error saving the profile (2).",
          rejectedValue
        );
      }
    );
  }

  /**
   * edit profile
   * @param Profile
   */
  public editInvite(invite: UserInvite): void {
    const modalRef = this.modalService.open(UserInviteEditComponent, {
      size: "sm",
    });
    modalRef.componentInstance.teamID = this.account.teamID;
    modalRef.componentInstance.invite = invite;
    modalRef.result.then(
      (fulfilledValue) => {
        console.log("modal result for edit invite:", fulfilledValue);
        if (fulfilledValue.success) {
          if (fulfilledValue.deleted) {
            this.setSuccess("Invite successfully deleted!");
          } else {
            this.setSuccess(
              "Invite for " +
                fulfilledValue.result.first_name +
                " " +
                fulfilledValue.result.last_name +
                " updated!"
            );
          }
          this.search = "";
          this.findUsers();
        } else {
          this.setError(
            "There was an error saving the invite: " +
              fulfilledValue.error.error.message,
            fulfilledValue
          );
        }
      },
      (rejectedValue) => {
        if (
          rejectedValue === "Cross click" ||
          rejectedValue === 0 ||
          rejectedValue === 1
        )
          return;
        this.setError(
          "There was an error saving the invite (2).",
          rejectedValue
        );
      }
    );
  }

  private setError(message: string, obj?: any): void {
    this.success = null;
    this.error = message;
    console.warn("Error: " + message, obj);
    window.scroll(0, 0);
  }

  private setSuccess(message: string, obj?: any): void {
    this.error = null;
    this.success = message;
    if (obj) console.warn("Success", obj);
    window.scroll(0, 0);
  }

  private clearAlerts(): void {
    this.error = null;
    this.success = null;
  }
}
