import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { UserService } from '../services'
import { User } from '../models'

@Component({
  selector: 'mb-setup-wizard',
  templateUrl: './setup-wizard.component.html',
  styleUrls: ['./setup-wizard.component.scss']
})
export class SetupWizardComponent implements OnInit, OnDestroy {
  public account: User
  public step: number = 1
  public loaded: boolean = false
  public error: string

  private unsubscribe: Subject<any> = new Subject()

  constructor(
    private userService: UserService,
    private router: Router,
    private acRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.initUser()
	  this.initRoute()
  }

  public ngOnDestroy() {
    this.destroy()
  }

  private initRoute() {
    this.acRoute.params
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((params: any) => {
        const s: number = parseInt(params.step, 10)
        if ([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17].includes(s)) {
          this.step = s
        }
      })
  }

  private initUser() {
    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        // check to ensure this user is a coach!
        if ( user.isMainContact ) {
          this.account = user
          this.loaded = true
        } else {
          this.destroy()
          this.router.navigate(['/'])
        }
        
      })
  }

  private destroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
}
