/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./jumbotron.component";
var styles_JumbotronComponent = [];
var RenderType_JumbotronComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_JumbotronComponent, data: {} });
export { RenderType_JumbotronComponent as RenderType_JumbotronComponent };
function View_JumbotronComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "h4", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_JumbotronComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "media"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "Alert"], ["class", "mr-3 d-none d-md-block"], ["src", "./assets/SVG/UI/Alert.svg"], ["style", "width: 100px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "media-body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_JumbotronComponent_1)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_JumbotronComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-jumbotron", [["class", "jumbotron"]], null, null, null, View_JumbotronComponent_0, RenderType_JumbotronComponent)), i0.ɵdid(1, 114688, null, 0, i2.JumbotronComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JumbotronComponentNgFactory = i0.ɵccf("mb-jumbotron", i2.JumbotronComponent, View_JumbotronComponent_Host_0, { messages: "messages" }, {}, []);
export { JumbotronComponentNgFactory as JumbotronComponentNgFactory };
