import { AbstractControl, Validators, ValidatorFn, FormControl } from '@angular/forms'

export const TIME_PATTERNS = {
  minSec: /^([0-5][0-9]):([0-5][0-9])$/,
  hrMin: /^([0-1][0-9]):([0-5][0-9])$/,
  hrMinSec: /^([0-1][0-9]):([0-5][0-9]):([0-5][0-9])$/
}

export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export class CustomValidators extends Validators {

  static timeValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      if (TIME_PATTERNS.minSec.test(control.value)) return null
      if (TIME_PATTERNS.hrMin.test(control.value)) return null
      if (TIME_PATTERNS.hrMinSec.test(control.value)) return null
      return {
        timeValidator: {
          value: control.value,
          message: 'Invalid time'
        }
      }
    }
  }

  static emailListValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      let valid = 0
      const parts = control.value.split(',')
      parts.forEach((email: string) => {
        if (EMAIL_PATTERN.test(email.trim())) valid++
      })
      if (valid === parts.length) return null

      return {
        emailListValidator: {
          value: control.value,
          message: 'Please provide valid emails, separate each with a comma'
        }
      }
    }
  }

  static inviteCodeValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      return null
    }
  }

  static confirmValidator(confirmControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      if (control.value === confirmControl.value) return null
      return {
        confirmValidator: {
          value: control.value,
          message: 'Password confirmation does not match!'
        }
      }
    }
  }

  static datepickerValidator(settings?: any): ValidatorFn {
    if (!settings) settings = {}
    return (control: AbstractControl): {[key: string]: any} => {
      const minDate: Date = settings.minDate || new Date(2010, 0, 1)
      const maxDate: Date = settings.maxDate || new Date(2050, 0, 1)
      const val: any = control.value || {}
      if (!val.year || !val.month || !val.day) {
        return { dateValidator: {
          value: control.value,
          message: 'Invalid date'
        }}
      }
      const d: Date = new Date(
        val.year,
        val.month - 1,
        val.day
      )
      if (!d || (d < minDate) || (d > maxDate)) {
        return { dateValidator: {
          value: control.value,
          message: 'Invalid date'
        }}
      }
      return null
    }
  }

  static dateValidator(settings?: any): ValidatorFn {
    if (!settings) settings = {}
    return (control: AbstractControl): {[key: string]: any} => {
      const minDate: Date = settings.minDate || new Date(2010, 0, 1)
      const maxDate: Date = settings.maxDate || new Date(2050, 0, 1)
      // const FORMAT: string = 'YYYY-MM-DD' // @todo maybe allow other formats
      const pieces: string[] = (control.value || '').split('-')
      if (pieces.length !== 3) {
        return { dateValidator: {
          value: control.value,
          message: 'Invalid date format'
        }}
      }
      const d: Date = new Date(
        parseInt(pieces[0]) || 1,
        parseInt(pieces[1]) || 1,
        parseInt(pieces[2]) || 1
      )
      if (!d || (d < minDate) || (d > maxDate)) {
        return { dateValidator: {
          value: control.value,
          message: 'Invalid date'
        }}
      }
      return null
    }
  }

  static requiredFileType(types: string[]): ValidatorFn {
    return (control: FormControl): {[key: string]: any} => {
      const file = control.value
      if (file) {
        const ext = file.name.split('.').pop().toLowerCase()
        if (!types.map(v => v.toLowerCase()).includes(ext)) {
          return { requiredFileType: true }
        }
      }
      return null
    }
  }
}
