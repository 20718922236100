import { Component, OnInit, Inject } from '@angular/core'
import { Location, PopStateEvent, DOCUMENT } from '@angular/common'
import { Router, NavigationStart, NavigationEnd } from '@angular/router'

import { UserService } from './services'
import { User } from './models'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public loaded = false
  public user: User
  private lastPoppedUrl: string
  private yScrollStack: number[] = []

  public constructor(
    private router: Router,
    private location: Location,
    private userService: UserService,
    @Inject(DOCUMENT) private doc: any
  ) { }

  public ngOnInit() {
    this.initUser()
    this.initScroller()
  }

  private initUser(): void {
    this.userService.current.subscribe((user) => {
      this.user = user
    })
  }

  private initScroller(): void {
    this.location.subscribe((e: PopStateEvent) => {
      this.lastPoppedUrl = e.url
    })
    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationStart) {
        if (e.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY)
        }
      }
      else if (e instanceof NavigationEnd) {
        if (e.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined
          window.scrollTo(0, this.yScrollStack.pop())
        }
        else {
          window.scrollTo(0, 0)
        }
      }
    })
  }
}
