import { ThrowStmt } from '@angular/compiler'
import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Subject } from 'rxjs'
import { first } from 'rxjs/operators'

import { User } from '../models'
import { UserService } from '../services/user.service'

@Component({
  selector: 'mb-profile',
  templateUrl: './profile.component.html',
  host: {'class': 'mb-page'}
})
export class ProfileComponent implements OnInit, OnDestroy {
  public account: User
  public loaded = false
  public formGroup: FormGroup
  public error: string
  public success: string
  private unsubscribe: Subject<any> = new Subject()

  public constructor(
    private fb: FormBuilder,
    private userService: UserService
  ) { }

  public ngOnInit() {
    
    this.userService.requestCurrent()
      .pipe(first())
      .subscribe(
        (data: any) => {
            this.setUser(data)
            this.createForm()
        },
        (e: any) => { console.warn('error', e) }
      )
  }

  public setUser(data: any) {
    if (data.success) {
      this.account = new User(data.result)
      this.userService.setCurrent(this.account)
      this.loaded = true
    }
    else { console.warn('error', data.error) }
  }

    /**
   * submit the form
   */
     public submit() {
      const data: any = {
        first_name: this.formGroup.value.first_name,
        last_name: this.formGroup.value.last_name,
        email: this.formGroup.value.email
      }
      this.clearAlerts()
      this.loaded = false
      this.userService.updateSelfUser(data)
        .pipe(first())
        .subscribe(
          (data: any) => {
            this.loaded = true
            this.setUser(data)
            this.setSuccess('Profile updated successfully!')
            
          },
          (e: any) => { 
            this.loaded = true
            console.warn('error', e) 
            if ( e.error ) {
              this.setError(e.error.message + ' ' + Object.values(e.error.errors).map((v: any) => v.join(', ')))
            } else {
              this.setError('An error was encountered while updating profile. Please try again!')
            }
          }
        )
    }
  

  /**
   * unsubscribe
   */
  public ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  private createForm() {
    this.formGroup = this.fb.group({
      first_name: [this.account.userFirstName, Validators.required],
      last_name: [this.account.userLastName, Validators.required],
      // phone: [this.account.phone, Validators.required],
      email: [this.account.userEmail, Validators.required]
    })
  }

  private setError(message: string, obj?: any) {
    this.success = null
    this.error = message
    console.warn('Error: ' + message, obj)
    window.scroll(0, 0)
  }

  private setSuccess(message: string, obj?: any) {
    this.error = null
    this.success = message
    if (obj) console.log('Success', obj)
    window.scroll(0, 0)
  }

  private clearAlerts() {
    this.error = null
    this.success = null
  }
}
