/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./boxscore.component.ngfactory";
import * as i2 from "./boxscore.component";
import * as i3 from "../services/user.service";
import * as i4 from "../services/event-levels.service";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "./event.component";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "../services/mbevent.service";
import * as i10 from "ngx-toastr";
var styles_EventComponent = [];
var RenderType_EventComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EventComponent, data: {} });
export { RenderType_EventComponent as RenderType_EventComponent };
function View_EventComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "mb-event__edit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "Edit"], ["src", "./assets/SVG/UI/Edit.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Edit "]))], null, null); }
function View_EventComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "mb-event__sync"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.export() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "Export"], ["src", "./assets/SVG/UI/Sync.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Export to"])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["Trackwrestling ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.event.twid ? "" : "*"); _ck(_v, 5, 0, currVal_0); }); }
export function View_EventComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "mb-event__head"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "mb-event-boxscore", [["class", "mb-event__boxscore"], ["style", "display: block"]], null, null, null, i1.View_BoxscoreComponent_0, i1.RenderType_BoxscoreComponent)), i0.ɵdid(2, 245760, null, 0, i2.BoxscoreComponent, [i3.UserService, i4.EventLevelsService], { event: [0, "event"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventComponent_1)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventComponent_2)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 6, "div", [["class", "mb-event__matches"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "mb-matches__counter"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", " matches "])), (_l()(), i0.ɵeld(10, 0, null, null, 3, "a", [["class", "btn btn-lg btn-primary"], ["preserveQueryParams", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"], preserveQueryParams: [1, "preserveQueryParams"] }, null), i0.ɵpad(12, 2), (_l()(), i0.ɵted(-1, null, [" Event details "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.user.isMainContact; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.user.isMainContact; _ck(_v, 6, 0, currVal_2); var currVal_6 = _ck(_v, 12, 0, "/event", _co.event.encodedID); var currVal_7 = ""; _ck(_v, 11, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.event.match_count; _ck(_v, 9, 0, currVal_3); var currVal_4 = i0.ɵnov(_v, 11).target; var currVal_5 = i0.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_4, currVal_5); }); }
export function View_EventComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-event", [["class", "mb-event"]], null, null, null, View_EventComponent_0, RenderType_EventComponent)), i0.ɵdid(1, 245760, null, 0, i7.EventComponent, [i3.UserService, i8.NgbModal, i9.MBEventService, i10.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventComponentNgFactory = i0.ɵccf("mb-event", i7.EventComponent, View_EventComponent_Host_0, { event: "event" }, { eventChanged: "eventChanged" }, []);
export { EventComponentNgFactory as EventComponentNgFactory };
