import { Component, OnInit, OnDestroy } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Subject } from 'rxjs'
import { takeUntil, first } from 'rxjs/operators'

import { LibraryItemEditComponent } from './library-item-edit.component'
import { LibraryService, UserService } from '../services'
import { LibrarySection, LibraryItem } from '../models'
import { User } from '../models'

@Component({
  selector: 'mb-library',
  templateUrl: './library.component.html'
})
export class LibraryComponent implements OnInit, OnDestroy {
  public loaded = false
  public user: User
  public sections: LibrarySection[]
  public error: string
  public success: string
  public empty = true
  private unsubscribe: Subject<any> = new Subject()

  constructor(
    private libService: LibraryService,
    private userService: UserService,
    private modalService: NgbModal
  ) { }

  public ngOnInit(): void {
    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        this.user = user
        this.findLibrary()
      })
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  public addItem(): void {
    const modalRef = this.modalService.open(LibraryItemEditComponent, { size: 'sm' })
    let item: LibraryItem = new LibraryItem({})
    modalRef.componentInstance.item = item
    modalRef.componentInstance.teamID = this.user.teamID
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.setSuccess('The new post was saved!')
          this.loaded = false
          this.findLibrary()
        } else {
          this.setError('There was an error saving the post (1).', fulfilledValue)
        }
      },
      (rejectedValue: any) => {
        if (rejectedValue === 'Cross click' || rejectedValue === 0 || rejectedValue === 1) return
        this.setError('There was an error saving the post (2).', rejectedValue)
      }
    )
  }

  public editItem(item: LibraryItem): void {
    const modalRef = this.modalService.open(LibraryItemEditComponent, { size: 'sm' })
    modalRef.componentInstance.item = item
    modalRef.componentInstance.teamID = this.user.teamID
    modalRef.result.then(
      (fulfilledValue) => {
        if (!fulfilledValue.success) {
          this.setError('There was an error saving the post (1).', fulfilledValue)
          return
        }
        if (fulfilledValue.result === 'deleted') {
          this.setSuccess('Post deleted!')
          this.loaded = false
          this.findLibrary()
          return
        }
        let item: LibraryItem = new LibraryItem(fulfilledValue.result)
        this.setSuccess('Post saved!')
        this.loaded = false
        this.findLibrary()
      },
      (rejectedValue) => {
        if (rejectedValue === 'Cross click' || rejectedValue === 0 || rejectedValue === 1) return
        this.setError('There was an error saving the post (2).', rejectedValue)
      }
    )
  }

  private findLibrary(): void {
    this.libService.findAll({ teamID: this.user.teamID })
      .pipe(first())
      .subscribe((data: any) => {
        this.sections = []
        if (!data.success || !data.result || !data.result.sections) {
          console.warn('library error')
          // @todo alert/redirect
          return
        }
        data.result.sections.forEach((s: any[]) => {
          const libSec = new LibrarySection(s)
          if (libSec && libSec.items && libSec.items.length) {
            this.empty = false
            this.sections.push(libSec)
          }
        })

        this.ready()
      })
  }

  private ready(): void {
    this.loaded = true
  }

  private setError(message: string, obj?: any) {
    this.success = null
    this.error = message
    console.warn('Error: ' + message, obj)
    window.scroll(0, 0)
  }

  private setSuccess(message: string, obj?: any) {
    this.error = null
    this.success = message
    if (obj) console.warn('Success', obj)
    window.scroll(0, 0)
  }

  private clearAlerts() {
    this.error = null
    this.success = null
  }
}
