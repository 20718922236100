import { ScoringEvent } from "./scoring-event";

export class MBMatch {
  public ID: number;
  public matchIDHash: string;
  public matchID: string;
  public eventID: string;
  public theDate: string;
  public theWeightClass: string;
  public winner: number;
  public theResult: string;
  public teamPoints: string;
  public ourWrestlerID: string;
  public redWrestler: string;
  public greenWrestler: string;
  public videoFileName: string;
  public videoFileUrl: string;
  public videoInCloud: boolean;
  public isConverting: number;
  public conversionPercent: number;
  public conversionStatus: string;
  public notes: string;
  public doodle: string;
  public highlightInfo: string;
  public scoringEvents: string;
  public numSeconds: number; // returned from matchesbymove only

  public signedThumbUrl: string;
  public signedVideoUrl: string;

  private _scoringEventsArray: ScoringEvent[];
  private _ourColor: string;
  private _theirColor: string;
  private _theirWrestler: string;
  private _theirWrestlerScore: number;
  private _theirWrestlerInitials: string;
  private _ourWrestler: string;
  private _ourWrestlerScore: number;
  private _ourWrestlerInitials: string;

  public opponentName: string;
  public opponentWrestlerLabel: string;
  public opponentSchool: string;
  public ourWrestlerName: string;
  public ourTeam: string;
  public ourWrestlerSchool: string;

  public constructor(data) {
    Object.assign(this, data);
    this.parseMatch();
  }

  public get moveTimestamp(): string {
    if (!this.numSeconds) return "";

    let numSeconds: number = Math.round(this.numSeconds);
    let min: number = numSeconds > 60 ? Math.floor(numSeconds / 60) : 0;
    let sec: number = numSeconds > 60 ? numSeconds % 60 : numSeconds;
    return (min > 9 ? "" : "0") + min + ":" + (sec > 9 ? "" : "0") + sec;
  }

  public get encodedEventID(): string {
    return encodeURIComponent(this.eventID);
  }

  public get accountID(): string {
    return this.eventID ? this.eventID.split(":")[0] : null;
  }

  public get year(): string {
    return this.eventID ? this.eventID.split(":")[1] : null;
  }

  public get ourColor(): string {
    return this._ourColor;
  }

  public get theirColor(): string {
    return this._theirColor;
  }

  public get ourWrestler(): string {
    return this._ourWrestler;
  }

  public get ourWrestlerScore(): number {
    return this._ourWrestlerScore;
  }

  public get ourWrestlerInitials(): string {
    return this._ourWrestlerInitials;
  }

  public get theirWrestler(): string {
    return this._theirWrestler;
  }

  public get theirWrestlerScore(): number {
    return this._theirWrestlerScore;
  }

  public get theirWrestlerInitials(): string {
    return this._theirWrestlerInitials;
  }

  public get ourGreen(): boolean {
    return this.ourColor.toLocaleLowerCase() === "green";
  }

  public get ourRed(): boolean {
    return this.ourColor.toLocaleLowerCase() === "red";
  }

  public get greenWrestlerName(): string {
    return this.ourGreen ? this.ourWrestlerName : this.opponentName;
  }

  public get redWrestlerName(): string {
    return this.ourRed ? this.ourWrestlerName : this.opponentName;
  }

  public get greenWinner(): boolean {
    // return (this.ourGreen && !!this.winner) || (this.ourRed && !this.winner)
    return !this.winner;
  }

  public get redWinner(): boolean {
    // return (this.ourRed && !!this.winner) || (this.ourGreen && !this.winner)
    return !!this.winner;
  }

  public get hasScoringEvents(): boolean {
    return this.scoringEventsArray.length > 0;
  }

  public get ourWin(): string {
    if (this.theResult == "Double Forfeit") {
      return "";
    }
    if (
      (this.redWinner && this.ourRed) ||
      (this.greenWinner && this.ourGreen)
    ) {
      return "Win";
    } else {
      return "Loss";
    }
  }

  public get redScore(): number {
    return this.ourRed ? this.ourWrestlerScore : this.theirWrestlerScore;
  }

  public get greenScore(): number {
    return this.ourGreen ? this.ourWrestlerScore : this.theirWrestlerScore;
  }

  public get scoringEventsArray(): ScoringEvent[] {
    return this._scoringEventsArray;
  }

  public set scoringEventsArray(se: ScoringEvent[]) {
    this._scoringEventsArray = se;
    this.scoringEvents = se.map((e) => ScoringEvent.serialize(e)).join("#");
  }

  public get redScoringEvents(): ScoringEvent[] {
    return this.scoringEventsArray.filter(
      (se) => se.color.toLocaleLowerCase() === "red"
    );
  }

  public get greenScoringEvents(): ScoringEvent[] {
    return this.scoringEventsArray.filter(
      (se) => se.color.toLocaleLowerCase() === "green"
    );
  }

  public get ourScoringEvents(): ScoringEvent[] {
    return this.scoringEventsArray.filter((se) => se.color === this.ourColor);
  }

  public get theirScoringEvents(): ScoringEvent[] {
    return this.scoringEventsArray.filter((se) => se.color === this.theirColor);
  }

  public get fallTime(): string {
    const parts: string[] = (this.theResult || "").split(" ");
    if (parts[0] !== "Fall") return null;
    return parts[1];
  }

  public set fallTime(t: string) {
    const parts: string[] = (t || "").split(" ");
    if (!parts.length) return;
    if (parts[0] === "Fall") t = parts[1];
    else t = parts[0];
    this.theResult = "Fall " + t;
  }

  // this behavior does not match how it actually works in the api
  // the wrestlers switch colors but the scoring symbols do not
  // this switches both wrestlers and scoring symbols
  public switchColors() {
    if (this.ourRed) {
      this._ourColor = "Green";
      this._theirColor = "Red";
    } else {
      this._ourColor = "Red";
      this._theirColor = "Green";
    }
  }

  public parseMatch() {
    this._scoringEventsArray = MBMatch._loadScoringEvents(this.scoringEvents);

    const redWrestlerParts = (this.redWrestler || "").split("#");
    const greenWrestlerParts = (this.greenWrestler || "").split("#");
    if (redWrestlerParts[0] === "(null)" || redWrestlerParts[0] === "") {
      // Opponent is red wrestler
      this._ourColor = "Green";
      this._theirColor = "Red";
      this._theirWrestler = this.redWrestler;
      this._ourWrestler = this.greenWrestler;
    } else {
      this._ourColor = "Red";
      this._theirColor = "Green";
      this._theirWrestler = this.greenWrestler;
      this._ourWrestler = this.redWrestler;
    }

    this._parseWrestlers();
  }

  protected _parseWrestlers() {
    const theirWrestlerParts = (this.theirWrestler || "").split("#");
    this.opponentName = theirWrestlerParts[1] || "";
    const opponentNames = (this.opponentName || "").split(" ");
    this._theirWrestlerInitials = "";
    if (opponentNames.length > 1) {
      if (this.opponentName.lastIndexOf(",") !== -1) {
        this._theirWrestlerInitials =
          opponentNames[1].substr(0, 1) + opponentNames[0].substr(0, 1);
      } else {
        this._theirWrestlerInitials =
          opponentNames[0].substr(0, 1) + opponentNames[1].substr(0, 1);
      }
    } else this._theirWrestlerInitials = this.opponentName.substr(0, 1);
    this.opponentSchool = theirWrestlerParts[3];
    this.opponentWrestlerLabel =
      this.opponentName + " (" + this.opponentSchool + ")";
    this._theirWrestlerScore = MBMatch._calculateScore(
      this.theirColor,
      MBMatch._loadScoringEvents(this.scoringEvents)
    );

    //--------------------------------
    const ourWrestlerParts = (this.ourWrestler || "").split("#");

    const currentVideoWrestlerID = ourWrestlerParts[0];
    this.ourWrestlerName = ourWrestlerParts[1] || "";
    const ourNames = (this.ourWrestlerName || "").split(", ");
    this._ourWrestlerInitials = "";
    if (ourNames.length > 1) {
      this._ourWrestlerInitials =
        ourNames[1].substr(0, 1) + ourNames[0].substr(0, 1);
    } else {
      this._ourWrestlerInitials = this.ourWrestlerName.substr(0, 1);
    }

    this.ourWrestlerSchool = ourWrestlerParts[3];
    this.ourTeam = " (" + this.ourWrestlerSchool + ")";
    this._ourWrestlerScore = MBMatch._calculateScore(
      this.ourColor,
      MBMatch._loadScoringEvents(this.scoringEvents)
    );
  }

  protected static _calculateScore(
    theColor: string,
    scoringEvents: any[]
  ): number {
    let score = 0;
    let evtScore;
    const len = scoringEvents.length;
    for (let i = 0; i < len; i++) {
      if (scoringEvents[i].color === theColor) {
        evtScore = parseInt(scoringEvents[i].symbol.replace(/[^0-9]/g, ""), 10);
        if (!isNaN(evtScore)) score += evtScore;
      }
    }

    return score;
  }

  protected static _loadScoringEvents(
    scoringEventsString: string
  ): ScoringEvent[] {
    let scoringEvents: any[] = [];
    if (!scoringEventsString) return [];
    let stringsArray = (scoringEventsString || "").split("#");
    for (let i = 0; i < stringsArray.length; i++) {
      scoringEvents.push(ScoringEvent.deserialize(stringsArray[i]));
    }

    return scoringEvents;
  }
}
