<header *ngIf="loaded" class="mb-header" [ngClass]="{ active: navOpen }">
  <a
    (click)="toggleNav()"
    class="mb-header__toggle"
    [ngClass]="{ active: navOpen }"
  >
    <span></span>
  </a>
  <div class="mb-header__inner"></div>
  <div class="mb-header__inner--md container">
    <ul class="mb-header__nav--secondary nav justify-content-end">
      <li class="nav-item">
        <a routerLink="/settings" class="nav-link btn">Settings</a>
      </li>
      <li class="nav-item">
        <a href="https://help.matboss.com" target="_blank" class="nav-link btn"
          >Help</a
        >
      </li>
      <li class="nav-item">
        <a (click)="signOut()" class="nav-link btn btn btn-outline-light"
          >Sign Out</a
        >
      </li>
    </ul>
    <div class="mb-header__nav--primary">
      <ul class="nav">
        <li *ngFor="let item of navItems" class="nav-item">
          <a
            *ngIf="!item.external"
            [routerLink]="item.path"
            class="nav-link"
            [ngClass]="{ active: item.active }"
            >{{ item.label }}</a
          >
          <a
            *ngIf="item.external"
            class="nav-link"
            [href]="item.href"
            target="_blank"
            >{{ item.label }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</header>
<div *ngIf="navOpen" class="mb-header__nav--mobile">
  <ul class="nav">
    <li *ngFor="let item of navItems" class="nav-item">
      <a
        *ngIf="!item.external"
        [routerLink]="item.path"
        (click)="mobileNavClick($event)"
        class="nav-link"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ item.label }}
      </a>
      <a
        *ngIf="item.external"
        class="nav-link"
        [href]="item.href"
        target="_blank"
        >{{ item.label }}</a
      >
    </li>
    <li *ngIf="user.isMainContact" class="nav-item">
      <!-- <a routerLink="/account"
        (click)="mobileNavClick($event)"
        class="nav-link">My Account</a> -->
      <a routerLink="/settings" class="nav-link">Settings</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="https://help.matboss.com/" target="_blank"
        >Help</a
      >
    </li>
    <li class="nav-item">
      <a (click)="signOut()" href="#" class="nav-link">Sign Out</a>
    </li>
  </ul>
</div>
