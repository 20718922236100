import { ApiService } from './api.service';
import { ReportParams } from '../models';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class ReportService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    getReport(reportType, reportParams) {
        const path = this.reportPath(reportType);
        if (!path)
            throw new Error('Invalid reportType');
        return this.apiService.get(path, { params: reportParams });
    }
    getReportOutputUrl(reportType, reportParams, outputType) {
        const path = this.reportPath(reportType);
        if (!path)
            throw new Error('Invalid reportType');
        let qs = '?';
        const keys = Object.keys(reportParams);
        const pairs = [`${outputType}=true`];
        keys.forEach((k) => {
            pairs.push(k + '=' + encodeURIComponent(reportParams[k]));
        });
        qs += pairs.join('&');
        return ApiService.API_URL + path + qs;
    }
    // public getReportCSVUrl(reportType: string, reportParams: ReportParams): string {
    //   return this.getReportOutputUrl(reportType, reportParams, 'csv')
    // }
    getReportPDFUrl(reportType, reportParams) {
        return this.getReportOutputUrl(reportType, reportParams, 'pdf');
    }
    emailReport(reportType, reportParams, recipients, message) {
        const path = this.reportPath(reportType);
        if (!path)
            throw new Error('Invalid reportType');
        const params = Object.assign({ pdf: true, email: true, recipients }, reportParams);
        if (message)
            params['message'] = message;
        return this.apiService.post(path, params);
    }
    reportPath(reportType) {
        const paths = {
            // team
            teamduals: 'reports',
            seasonteamanalysis: 'reports/seasonteamanalysis',
            matchcounts: 'reports/matchcounts',
            logins: 'reports/logins',
            matchlistingbyteam: 'reports/matchlistingbyteam',
            categoryleaders: 'reports/categoryleaders',
            advancedtagreportteam: 'reports/advancedtagreportteam',
            highlights: 'reports/highlights',
            // event
            dualmeetsummary: 'reports/dualmeetsummary',
            eventanalysis: 'reports/eventanalysis',
            dualeventanalysis: 'reports/eventanalysis',
            tourneyeventanalysis: 'reports/eventanalysis',
            tournamentsummary: 'reports/tournamentsummary',
            // individual
            individualmatchsummary: 'reports/individualmatchsummary',
            individualanalysis: 'reports/individualanalysis',
            advancedtagreportindividual: 'reports/advancedtagreportindividual',
            individualseasonsummaryillinois: 'reports/individualseasonsummaryillinois',
            // custom
            customreport: 'reports/customreport',
            // cumulative
            categoryleaderscumulative: 'reports/categoryleaderscumulative',
            individualanalysiscareer: 'reports/individualanalysiscareer',
        };
        return paths[reportType];
    }
}
ReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportService_Factory() { return new ReportService(i0.ɵɵinject(i1.ApiService)); }, token: ReportService, providedIn: "root" });
