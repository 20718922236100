import { Component, OnInit, Renderer2, OnDestroy } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { takeUntil, first } from "rxjs/operators";
import { Subject } from "rxjs";

import { UserService } from "../../services/user.service";
import { User } from "../../models";
import { environment } from "../../../environments/environment";

@Component({
  selector: "mb-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  static ANALYTICS: any = {
    path: [],
    href: environment.analyticsUrl,
    label: "Analytics",
    external: true,
  };

  static ALL_NAV_ITEMS: any[] = [
    { path: ["/"], label: "Dashboard", activePaths: [""] },
    { path: ["/roster"], label: "Roster", activePaths: ["roster"] },
    {
      path: ["/scorebook"],
      label: "Scorebook",
      activePaths: ["scorebook", "event"],
    },
    { path: ["/reports"], label: "Reports", activePaths: ["reports"] },
    { path: ["/video"], label: "Video", activePaths: ["video"] },
    { path: ["/library"], label: "Library", activePaths: ["library"] },
    { path: ["/highlights"], label: "Highlights", activePaths: ["highlights"] },
  ];

  static STATS_NAV_ITEMS: any[] = [
    { path: ["/"], label: "Dashboard", activePaths: [""] },
    { path: ["/roster"], label: "Roster", activePaths: ["roster"] },
    {
      path: ["/scorebook"],
      label: "Scorebook",
      activePaths: ["scorebook", "event"],
    },
    { path: ["/reports"], label: "Reports", activePaths: ["reports"] },
  ];

  static LIMITED_NAV_ITEMS: any[] = [
    { path: ["/"], label: "Dashboard", activePaths: [""] },
    {
      path: ["/scorebook"],
      label: "Scorebook",
      activePaths: ["scorebook", "event"],
    },
    { path: ["/reports"], label: "Reports", activePaths: ["reports"] },
    { path: ["/video"], label: "Video", activePaths: ["video"] },
    { path: ["/library"], label: "Library", activePaths: ["library"] },
  ];
  static LIMITED_STATS_NAV_ITEMS: any[] = [
    { path: ["/"], label: "Dashboard", activePaths: [""] },
    {
      path: ["/scorebook"],
      label: "Scorebook",
      activePaths: ["scorebook", "event"],
    },
    { path: ["/reports"], label: "Reports", activePaths: ["reports"] },
  ];

  static NO_NAV_ITEMS: any[] = [
    { path: ["/"], label: "Dashboard", activePaths: [""] },
  ];

  public navOpen = false;
  public activePath = "/";
  private unsubscribe: Subject<any> = new Subject();
  public user: User;
  public navItems: any[];
  public loaded = false;
  private baseUrl: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.initUser();
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public toggleNav() {
    this.navOpen = !this.navOpen;
    if (this.navOpen) {
      this.renderer.addClass(document.body, "mb-nav-open");
    } else {
      this.renderer.removeClass(document.body, "mb-nav-open");
    }
  }

  public mobileNavClick(event) {
    this.navOpen = false;
    this.renderer.removeClass(document.body, "mb-nav-open");
  }

  public signOut() {
    this.mobileNavClick(null);
    this.userService
      .signOut()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.router.navigate(["/login"]);
      });
  }

  private initUser(): void {
    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user) => {
        this.user = user;
        if (this.user.isMainContact) {
          this.navItems = this.user.statsOnly
            ? HeaderComponent.STATS_NAV_ITEMS
            : HeaderComponent.ALL_NAV_ITEMS;
        }
        if (this.user.isWrestler) {
          this.navItems = this.user.statsOnly
            ? HeaderComponent.LIMITED_STATS_NAV_ITEMS
            : HeaderComponent.LIMITED_NAV_ITEMS;
        }
        if (this.user.isCoachManager) {
          // @todo determine what this should be
          this.navItems = this.user.statsOnly
            ? HeaderComponent.STATS_NAV_ITEMS
            : HeaderComponent.LIMITED_NAV_ITEMS;
        }
        if (this.user.isPublicUser) {
          // @todo determine what this should be
          this.navItems = HeaderComponent.NO_NAV_ITEMS;
        }
        // analytics is only available for coaches of HS and College
        if (
          (this.user.isMainContact || this.user.isCoachManager) &&
          (this.user.ageLevel.indexOf("High School") > -1 ||
            this.user.ageLevel.indexOf("College") > -1)
        ) {
          let navItem = { ...HeaderComponent.ANALYTICS };
          navItem.href =
            navItem.href +
            (this.user.ageLevel.indexOf("High School") > -1
              ? "#/hs-dashboard"
              : "#/college-dashboard");
          let found = this.navItems.find(
            (ni) => ni.label === HeaderComponent.ANALYTICS.label
          );
          if (!found) {
            this.navItems.push(navItem);
          }
        }
        this.initRoute();
        this.loaded = true;
      });
  }

  private initRoute(): void {
    this.setRoute(this.router.url);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event && event.url) this.setRoute(event.url);
      }
    });
  }

  private setRoute(r: string): void {
    if (!this.navItems) return;
    this.baseUrl = r.split("/")[1].split("?")[0];
    this.navItems.forEach((n) => {
      n.active = !!(
        n.path[0] === this.baseUrl ||
        (n.activePaths || []).includes(this.baseUrl)
      );
    });
  }
}
