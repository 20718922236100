import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { first } from 'rxjs/operators'
import * as _ from 'lodash'

import { MBEventLevel, EVENT_YEAR_OPTIONS, DEFAULT_TAGS } from '../../util'
import { EventLevelsService } from '../../services/event-levels.service'
import { MBEventService } from '../../services/mbevent.service'
import { RosterService } from '../../services/roster.service'
import { MBEvent, User, Wrestler, Tag } from '../../models'
import { environment } from '../../../environments/environment'

export class VideoListFilters {
  public level: MBEventLevel
  public event: MBEvent
  public wrestler: Wrestler
  public wrestlerOptions: Wrestler[] = []
  public yearOptions: string[]
  public eventOptions: MBEvent[]
  private _move: string
  private _year: string

  constructor(
    public levelOptions: MBEventLevel[],
    public allEventOptions: MBEvent[],
    public allWrestlerOptions: Wrestler[],
    public moveOptions: string[],
    public initialParams: any,
  ) {
    if (initialParams.levelID != undefined) {
      this.levelID = initialParams.levelID
      this.setEventOptions()
    }
    else this.level = this.levelOptions[0]

    this.yearOptions = EVENT_YEAR_OPTIONS
    this._year = initialParams.year || this.yearOptions[0]

    this.resetWrestlerOptions()

    if (initialParams.eventID) this.eventID = initialParams.eventID
    else this.eventID = null

    if (initialParams.wrestlerID) this.wrestlerID = initialParams.wrestlerID
    else this.wrestler = initialParams.wrestler || null

    if (initialParams.move) this.move = initialParams.move
    else this.move = null

    if (!Object.keys(this.initialParams).length) {
      this.levelChange()
    }    
  }

  public get levelID(): string {
    return this.level.levelID
  }

  public set levelID(levelID: string) {
    const opts = this.levelOptions.filter(opt => opt.levelID === levelID)
    this.level = opts && opts.length ? opts[0] : null
    this.move = null
    this.levelChange()
  }

  public get eventID(): string {
    return this.event ? this.event.eventID : null
  }

  public set eventID(eventID: string) {
    const evts = this.allEventOptions.filter(opt => opt.eventID === eventID)
    this.event = evts && evts.length ? evts[0] : null
    this.move = null
  }

  public get wrestlerID(): string {
    return this.wrestler ? this.wrestler.wrestlerID : null
  }

  public set wrestlerID(wrestlerID: string) {
    this.move = null
    for (let i=0; i<this.wrestlerOptions.length; i++) {
      if (this.wrestlerOptions[i].wrestlerID === wrestlerID) {
        this.wrestler = this.wrestlerOptions[i]
        return
      }
    }
    this.wrestler = null
  }

  public get move(): string {
    return this._move || null
  }

  public set move(m: string) {
    this._move = m
  }

  public get year(): string {
    return this._year || null
  }

  public set year(y: string) {
    this._year = y
  }

  public resetWrestlerOptions(): void {
    this.wrestlerOptions = this.allWrestlerOptions.filter(w => w.yearsWrestled.includes(this.year))
  }

  private levelChange(): void {
    this.event = null
    this.move = null
    this.setEventOptions()
  }

  private setEventOptions(): void {
    if (this.level.levelID === '-1') {
      this.eventOptions = this.allEventOptions
      return
    }
    this.eventOptions = this.allEventOptions.filter(opt => opt.levelID === this.level.levelID)
  }
}

//-------------------------------

@Component({
  selector: 'mb-video-list-filters',
  templateUrl: './video-list-filters.component.html'
})
export class VideoListFiltersComponent implements OnInit {
  @Input() user: User
  @Input() params: any

  @Output()
  public submitted: EventEmitter<VideoListFilters> = new EventEmitter<VideoListFilters>()

  public loaded = false
  public eventOptions: MBEvent[]
  public allEventOptions: MBEvent[]
  public levelOptions: MBEventLevel[]
  public filters: VideoListFilters
  public wrestlerOptions: Wrestler[]
  public moveOptions: string[]

  constructor(
    private eventLevelsService: EventLevelsService,
    private eventService: MBEventService,
    private rosterService: RosterService
  ) { }

  /**
   * sync initial filters value on init
   */
  public ngOnInit() {
    this.loadEvents(true)
    this.loadEventLevels()
    this.loadRoster()
    this.loadMoveOptions()
  }

  public submit(): void {
    this.submitted.emit(this.filters)
  }

  public yearChange(): void {
    if (this.filters.eventID) this.filters.eventID = null
    this.loadEvents()
    this.filters.resetWrestlerOptions()
    this.submit()
  }

  public levelChange(): void {
    this.submit()
  }

  public eventChange(): void {
    this.submit()
  }

  private loadEvents(firstRun=false): void {
    const params = {
      teamID: this.user.teamID,
      levelID: (this.filters && this.filters.levelID) || '-1',
      year: (this.filters && this.filters.year) || this.params.year || environment.currentYear,
      perPage: 100
    }
    this.eventService.findRecent(params)
      .pipe(first())
      .subscribe((data: any) => {
        this.eventOptions = []
        this.allEventOptions = []
        if (data.result) {
          data.result.forEach(item => {
            const event: MBEvent = new MBEvent(item)
            this.allEventOptions.push(event)
          })
          this.eventOptions = this.allEventOptions
          if (firstRun) this.ready()
          else {
            this.filters.allEventOptions = this.allEventOptions
            this.filters.eventOptions = this.allEventOptions
          }
        }
        else throw new Error('Invalid response')
      }, (err: any) => {
        throw new Error('Invalid response (2)')
      })
  }

  private loadRoster(): void {
    this.rosterService.getRoster(this.user.teamID, { perPage: 500, status: 'all' })
      .pipe(first())
      .subscribe((data: any) => {
        this.wrestlerOptions = []
        data.result.forEach(item => {
          this.wrestlerOptions.push(new Wrestler(item))
        })
        this.sortWrestlers()
        // this.pagination = data.pagination as Pagination
        this.ready()
      })
  }

  private sortWrestlers(): void {
    this.wrestlerOptions = _.orderBy(
      this.wrestlerOptions,
      w => w['lastName'].toLowerCase(),
      'asc'
    )
      .map((w) => new Wrestler(w))
  }

  private loadEventLevels(): void {
    this.eventLevelsService.current
      .subscribe(levels => {
        this.levelOptions = levels
        this.ready()
      })
  }

  public normalizeTagValue(tag:string): string {
    if ( tag === 'Bottom' ) return 'BOT'
    if ( tag === 'Neutral' ) return 'NEU'
    if ( tag === 'Top' ) return 'TOP'
    return tag
  }

  private loadMoveOptions(): void {
    this.moveOptions = [
      ...DEFAULT_TAGS,
      ...this.user.neutralTags.map((t: Tag) => t.abbrev),
      ...this.user.bottomTags.map((t: Tag) => t.abbrev),
      ...this.user.topTags.map((t: Tag) => t.abbrev),
    ]
    this.moveOptions = this.moveOptions.map((e) => this.normalizeTagValue(e))
  }

  private ready(): void {
    if (this.levelOptions && this.eventOptions && this.wrestlerOptions) {
      this.filters = new VideoListFilters(
        this.levelOptions,
        this.eventOptions,
        this.wrestlerOptions,
        this.moveOptions,
        this.params
      )
      this.loaded = true
      this.submit()
    }
  }
}
