/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./footer.component";
import * as i4 from "../../services/rss.service";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "footer", [["class", "mb-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 12, "div", [["class", "row mb-footer__foot"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "mb-copyright col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["\u00A9 ", " MatBoss."])), (_l()(), i0.ɵeld(6, 0, null, null, 8, "div", [["class", "col-md-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 7, "ul", [["class", "nav justify-content-end"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "a", [["class", "nav-link"], ["routerLink", "/settings"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Settings"])), (_l()(), i0.ɵeld(12, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "a", [["class", "nav-link"], ["href", "https://help.matboss.com/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Help"]))], function (_ck, _v) { var currVal_3 = "/settings"; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentYear; _ck(_v, 5, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 10).target; var currVal_2 = i0.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_1, currVal_2); }); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 114688, null, 0, i3.FooterComponent, [i4.RssService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i0.ɵccf("mb-footer", i3.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
