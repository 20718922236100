import { Wrestler } from "./wrestler";
import { User, UserRoleType, UserRoleList, LimitedUserRoleList } from "./user";

export class UserInvite {
  completed: boolean = false;
  first_name!: string;
  last_name!: string;
  email!: string;
  role!: string;
  token!: string;
  hubspot_id: number | null = null;
  created_at!: string;
  updated_at!: string;
  account: User | null = null;
  wrestler: Wrestler | null = null;
  user: User | null = null;

  public constructor(data: any) {
    if (data.user) {
      this.user = new User(data.user);
    }
    if (data.account) {
      this.account = new User(data.account);
    }
    if (data.wrestler) {
      this.wrestler = new Wrestler(data.wrestler);
    }

    // pluck the props
    const {
      completed,
      first_name,
      last_name,
      email,
      role,
      token,
      hubspot_id,
      created_at,
      updated_at,
    } = data;

    Object.assign(this, {
      completed,
      first_name,
      last_name,
      email,
      role,
      token,
      hubspot_id,
      created_at,
      updated_at,
    });
  }

  public get fullName(): string {
    return [this.first_name, this.last_name].join(" ").trim();
  }

  public get formattedWrestler(): string {
    if (this.wrestler) {
      if (this.wrestler.Level === "Former") {
        return `[Former] ${this.wrestler.firstName} ${this.wrestler.lastName}`;
      }
      return `${this.wrestler.firstName} ${this.wrestler.lastName}`;
    }
    return "";
  }

  public get formattedRole(): string {
    let f = "Unknown";
    switch (this.role) {
      case "admin-super-user":
      case "admin-support-user":
        f = "Site Admin";
        break;
      case "account-owner":
      case "account-admin":
      case "account-manager":
        f = "Coach";
        break;
      default:
        f = "Athlete";
    }
    return f;
  }

  public get isWrestler(): boolean {
    const roles = ["athlete-active", "athlete-limited"];
    return roles.includes(this.role);
    // return (this.userType || '').toLowerCase() === 'wrestler'
  }

  public get roleInfo(): UserRoleType | undefined {
    return UserRoleList.find((r: any) => r.value === this.role);
  }
  public get limitedRoleInfo(): UserRoleType | undefined {
    return LimitedUserRoleList.find((r: any) => r.value === this.role);
  }
}
