import { Component, Input } from '@angular/core'
import { FormGroup, AbstractControl, FormControl } from '@angular/forms'

// @todo allow these to use variable substitution (e.g. minlength)
const DEFAULT_ERROR_MESSAGES = {
  email: 'Please enter a valid email',
  required: 'This field is required',
  pattern: 'Please enter a valid value'
}

@Component({
  selector: 'mb-invalid',
  template: `<label *ngIf="invalid" class="mb-invalid">{{ displayMessage }}</label>`
})
export class InvalidComponent {
  @Input() form?: FormGroup
  @Input() field?: string
  @Input() control?: FormControl
  @Input() message?: string = DEFAULT_ERROR_MESSAGES.required
  @Input() messages: any
  @Input() validate?: boolean = true
  @Input() valid?: boolean

  constructor() { }

  public get invalid(): boolean {
    if (this.valid !== undefined) return !this.valid
    if (!this.formField) return false
    return this.validate && this.formField.invalid
  }

  public get formField(): AbstractControl {
    if (this.control) return this.control
    if (!this.form) return null
    return this.form.get(this.field)
  }

  public get displayMessage(): string {
    if (!this.errorKeys.length) return this.message
    const firstKey = this.errorKeys[0]
    if (this.messages && this.messages[firstKey]) {
      return this.messages[firstKey] || DEFAULT_ERROR_MESSAGES[firstKey] || this.message
    }
    if (!this.formField) return this.message
    return this.formField.errors[firstKey].message || DEFAULT_ERROR_MESSAGES[firstKey] || this.message
  }

  public get errorKeys(): string[] {
    if (!this.formField) return []
    return this.formField.errors ? Object.keys(this.formField.errors) : []
  }
}
