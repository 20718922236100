import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import {
  first,
  takeUntil,
  debounceTime,
  distinctUntilChanged,
  map,
} from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { UserService } from "../../services/user.service";
import { MBMatchService } from "../../services/mbmatch.service";
import { NavService } from "../../services/nav.service";
import { User, MBMatch, MBMatchVideo } from "../../models";
import { VideoListFilters } from "./video-list-filters.component";
import { VideoListShareComponent } from "./video-list-share.component";
import { environment } from "../../../environments/environment";

@Component({
  selector: "mb-video-list",
  templateUrl: "./video-list.component.html",
})
export class VideoListComponent implements OnInit, OnDestroy {
  public error: string;
  public success: string;
  public loaded = false;
  public matches: MBMatch[];
  public year: string;
  public levelID: string;
  public wrestlerID: string;
  public eventID: string;
  public user: User;
  public sharedMatches: any = {};
  public sharedIds: string[] = [];
  public totalMatches = 0;
  public page = 1;
  public perPage = "100";
  public params: any;
  public search: string = "";
  private unsubscribe: Subject<any> = new Subject();
  private filters: VideoListFilters;

  public searchUpdate = new Subject<string>();

  constructor(
    private acRoute: ActivatedRoute,
    private router: Router,
    private matchService: MBMatchService,
    private navService: NavService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.navService.setCurrentFromData({
      name: "video page",
    });
    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        this.user = user;
      });
    this.initQueryParams();

    this.searchUpdate
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.page = 1;
        this.findMatches();
      });
  }

  private initQueryParams(): void {
    this.acRoute.queryParams.pipe(first()).subscribe((params: any) => {
      this.params = params || {};
      if (this.params.search) this.search = this.params.search;
      if (this.params.page) this.page = this.params.page;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public updateFilters(filters: VideoListFilters): void {
    this.filters = filters;
    this.findMatches();
  }

  public updateShared(): void {
    this.sharedIds = Object.keys(this.sharedMatches).filter(
      (k) => !!this.sharedMatches[k]
    );
  }

  public watch(m: MBMatch): void {
    this.acRoute.queryParams.pipe(first()).subscribe((params: any) => {
      let q: any = { ...params };
      q.back = "video";
      if (!!this.filters.move) {
        q.startTime = m.numSeconds;
        q.autoplay = "true";
      }
      window.location.href = `${environment.viewerUrl}#/match/${
        m.matchID
      }?${new URLSearchParams(q).toString()}`;
    });
  }

  public download(m: MBMatch): void {
    window.open(
      `${environment.apiUrl}matches/download/${this.filters.year}/${m.matchIDHash}`,
      "_blank"
    );
  }

  public sort(field: string): void {}

  public share(): void {
    if (!this.sharedIds.length) return;

    const modalRef = this.modalService.open(VideoListShareComponent);
    modalRef.componentInstance.account = this.user;
    modalRef.componentInstance.teamID = this.user.teamID;

    modalRef.result.then(
      (fulfilledValue: any) => {
        this.matchService
          .share({
            matchIDs: this.sharedIds,
            emails: [fulfilledValue.emails],
            message: fulfilledValue.message,
          })
          .pipe(first())
          .subscribe(
            (r: any) => {
              this.toastrService.success("Matches Shared");
            },
            (e: any) => {
              this.toastrService.error(
                "There was an error sharing the Scoring Event"
              );
              console.error("error", e);
            }
          );
      },
      (rejectedValue: any) => {
        console.warn("share rejected", rejectedValue);
      }
    );
  }

  public onPageChange(e: any): void {
    this.page = e;
    this.findMatches();
  }

  private findMatches(): void {
    if (!this.user || !this.filters) return;

    this.loaded = false;
    const params = {
      teamID: this.user.teamID,
      levelID: this.filters.levelID,
      year: this.filters.year,
      eventID: this.filters.eventID,
      wrestlerID: this.filters.wrestlerID,
      search: (this.search || "").trim(),
      uploadedVideoOnly: "1",
      per_page: this.perPage,
      page: this.page.toString(),
    };
    if (this.filters.move && this.filters.move !== "null") {
      params["move"] = this.filters.move;
    }
    const queryParams = Object.keys(params).reduce((c, i) => {
      if (i !== "teamID" && !!params[i] && params[i] !== "null") {
        c[i] = params[i];
      }
      return c;
    }, {});

    this.matchService
      .findAll(params)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.matches = [];
          if (data.result) {
            this.totalMatches = data.pagination
              ? parseInt(data.pagination.total)
              : data.result.length;
            this.matches = data.result.map((item) => new MBMatch(item));
            this.router.navigate([], {
              relativeTo: this.acRoute,
              queryParams,
            });
            this.loaded = true;
          } else throw new Error("Invalid response");
        },
        (err: any) => {
          this.setError("There was an error finding the videos", err);
          this.loaded = true;
        }
      );
  }

  private setError(message: string, obj?: any) {
    this.success = null;
    this.error = message;
    console.warn("Error: " + message, obj);
    window.scroll(0, 0);
  }

  private setSuccess(message: string, obj?: any) {
    this.error = null;
    this.success = message;
    if (obj) console.warn("Success", obj);
    window.scroll(0, 0);
  }
}
