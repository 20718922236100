import { Component, OnInit, OnDestroy } from '@angular/core'
import { DatePipe } from '@angular/common'
import { Subject } from 'rxjs'
import { first } from 'rxjs/operators'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import * as _ from 'lodash'

import { User, UserToken } from '../models'
import { UserService } from '../services/user.service'
import { Pagination } from '../util'
import { DeviceFilters } from './'

@Component({
  selector: 'mb-devices',
  templateUrl: './devices.component.html',
  host: {'class': 'mb-page'}
})
export class DevicesComponent implements OnInit, OnDestroy {
  public account: User
  public loaded = false
  private unsubscribe: Subject<any> = new Subject()
  public error: string
  public success: string

  public devices: UserToken[]
  public filteredDevices: UserToken[]
  public pagination: Pagination
  public sortBy = 'lastUsedAt'
  public status = 'current'
  public search = ''
  private _sortAsc = true

  public constructor(
    private modalService: NgbModal,
    private userService: UserService,
    public router: Router,
    public datepipe: DatePipe
  ) { }

  public ngOnInit() {
    this.userService.requestCurrent()
      .pipe(first())
      .subscribe(
        (data: any) => {
            this.setUser(data)
        },
        (e: any) => { console.warn('error', e) }
      )
  }

  public setUser(data: any) {
    if (data.success) {
      this.account = new User(data.result)
      this.userService.setCurrent(this.account)
      this.findDevices()
    }
    else { console.warn('error', data.error) }
  }

  public revokeDevice(device: UserToken) {
    this.clearAlerts()
    this.userService.deleteDeviceToken(device.id)
      .pipe(first())
      .subscribe(
        (r: any) => {
          if ( r.success ) {
            this.findDevices()
            this.setSuccess('Device login successfully revoked!')
          } else {
            console.log('error encountered revoking device login:', r)
            this.setError('An error was encountered while revoking the device login. Please try again!')
          }
        },
        (e: any) => {
          console.warn('error encountered revoking device login:', e.error.message)
          this.setError('An error was encountered while revoking the device login. Please try again!')
        }
      )
  }

  /**
   * unsubscribe
   */
  public ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

    /**
   * This is a listener for the filters.submitted event
   * which is called from roster-filters.component when
   * filters have changed. This triggers a new search.
   * @param filters
   */
     public updateFilters(filters: DeviceFilters): void {
      if (this.status !== filters.status) {
        this.status = filters.status
      } else if (this.search !== filters.search) {
        this.search = filters.search
      }
      this.applySearch()
    }

    /**
     * roster lookup
     */
    private findDevices(): void {
      this.loaded = false

      this.userService.deviceTokens()
        .pipe(first())
        .subscribe((data: any) => {
          this.devices = []
          data.result.forEach(item => {
            this.devices.push(new UserToken(item))
          })
          this.filteredDevices = this.devices
          this.pagination = data.pagination as Pagination
          this.loaded = true
        })
    }

  
    private applySearch(): void {
      this.filteredDevices = []
      this.filteredDevices = this.devices.filter((t: UserToken) => {
        let valid = true
        valid = t.name.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()) !== -1
        if ( valid && this.status == 'unused' ) {
          valid = t.last_used_at == null
        }
        return valid
      })
    }

  /**
   * the sort direction
   */
  public get sortDir(): _.Many<'asc' | 'desc'> {
    return this._sortAsc ? 'asc' : 'desc'
  }

  /**
   * reverse sort direction if field is same, or default to asc
   * @param field
   */
  private newSortDir(field: string): _.Many<boolean | 'asc' | 'desc'> {
    if (field === this.sortBy) {
      this._sortAsc = !this._sortAsc
    } else {
      this._sortAsc = true
    }
    return this.sortDir
  }

  /**
   * sort wrestlers by a field
   * @param field
   */
  public sort(field): void {
    const dir: _.Many<boolean | 'asc' | 'desc'> = this.newSortDir(field)
    this.sortBy = field
    this.filteredDevices = _.orderBy(this.devices, [w => (w[this.sortBy] ? w[this.sortBy] : '').toLowerCase()], dir)
      .map((w) => new UserToken(w))
  }

  public formatDate(dateString: string) {
    return dateString ? this.datepipe.transform(dateString, 'MM-dd-yyyy') : 'Never'
  }

  private setError(message: string, obj?: any): void {
    this.success = null
    this.error = message
    console.warn('Error: ' + message, obj)
    window.scroll(0, 0)
  }

  private setSuccess(message: string, obj?: any): void {
    this.error = null
    this.success = message
    if (obj) console.warn('Success', obj)
    window.scroll(0, 0)
  }

  private clearAlerts(): void {
    this.error = null
    this.success = null
  }
}
