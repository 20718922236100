import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

/**
 * provides a data model to contain the roster filter options
 * and the selected filters
 */
export class UserFilters {
  public status: string;
  public search: string;

  protected statusOptions: string[] = [
    "managers",
    "athletes",
    "former",
    "all",
    "inactive",
  ];

  constructor() {
    this.status = "all";
    this.search = "";
  }
}

@Component({
  selector: "mb-user-filters",
  templateUrl: "./user-filters.component.html",
})
export class UserFiltersComponent implements OnInit {
  @Input()
  public set search(s: string) {
    if (s === this.filters.search) return;
    this.filters.search = s;
    this.submit();
  }

  @Output()
  submitted: EventEmitter<UserFilters> = new EventEmitter<UserFilters>();

  constructor(public filters: UserFilters = new UserFilters()) {}

  /**
   * sync initial filters value on init
   */
  public ngOnInit() {}

  /**
   * emit the submitted event
   */
  public submit() {
    this.submitted.emit(this.filters);
  }
}
