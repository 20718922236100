<div class="modal-header">
  <h4 class="modal-title">{{ event.eventID ? 'Edit' : 'Add' }} Event</h4>
  <button type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>
  <form [formGroup]="eventForm" (ngSubmit)="submit()" novalidate>
    <div class="form-group">
      <label *ngIf="eventForm.value.eventType === 'Dual Meet'">Opposing Team</label>
      <label *ngIf="eventForm.value.eventType !== 'Dual Meet'">Name of the event</label>
      <input type="text"
        class="form-control"
        formControlName="eventName">
      <mb-invalid [form]="eventForm"
        [validate]="validate"
        [messages]="validationMessages"
        field="eventName"></mb-invalid>
    </div>
    <div class="form-group">
      <label>Type</label>
      <select
        class="form-control"
        formControlName="eventType">
        <option *ngFor="let type of eventTypes"
          [value]="type">{{ type }}</option>
      </select>
      <mb-invalid
        [form]="eventForm"
        [validate]="validate"
        field="eventType"></mb-invalid>
    </div>
    <div class="form-group">
      <label>Event date - YYYY-MM-DD</label>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          formControlName="eventDate"
          ngbDatepicker
          placeholder="YYYY-MM-DD"
          (focus)="d.open()"
          #d="ngbDatepicker">
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="d.toggle()"
            type="button"></button>
        </div>
      </div>
      <mb-invalid
        [form]="eventForm"
        [validate]="validate"
        field="eventDate"></mb-invalid>
    </div>
    <div class="form-group">
      <label>Official - Optional</label>
      <input type="text" class="form-control" formControlName="official">
      <mb-invalid
        [form]="eventForm"
        [validate]="validate"
        [messages]="validationMessages"
        field="official"></mb-invalid>
    </div>
    <div class="form-group">
      <label>Starting Weight</label>
      <input type="text" class="form-control" formControlName="startingWeight">
      <mb-invalid
        [form]="eventForm"
        [validate]="validate"
        [messages]="validationMessages"
        field="startingWeight"></mb-invalid>
    </div>
    <div class="form-group">
      <label>Level (To move this event, choose a different scorebook)</label>
      <select class="form-control" formControlName="levelID" (change)="loadEvents()">
        <option *ngFor="let level of eventLevels" 
          [value]="level.levelID" 
          [disabled]="event.eventID && level.levelID === '-1'">{{ level.label }}</option>
      </select>
      <mb-invalid [form]="eventForm" [validate]="validate" field="levelID"></mb-invalid>
    </div>
	<div class="form-group">
      <label>Event (To merge into another event choose the destination below)</label>
      <select class="form-control" formControlName="eventID">
        <option *ngFor="let event of events" [value]="event.eventID">{{ event.eventName }}</option>
      </select>
      <mb-invalid [form]="eventForm" [validate]="validate" field="eventID"></mb-invalid>
    </div>
  </form>
</div>

<div class="modal-footer d-block">
  <button *ngIf="!submitting"
    type="button"
    class="btn btn-primary btn-block"
    (click)="submit()">
    Save
  </button>
  <mb-ellipsis-loader
    *ngIf="submitting"></mb-ellipsis-loader>

  <div
    *ngIf="event.eventID"
    class="my-5 text-center">
    <a (click)="confirmDelete()"
      class="mb-link">Delete this event</a>
  </div>
</div>
