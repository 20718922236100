import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { first } from "rxjs/operators";

import { RosterService } from "../services";
import { Wrestler } from "../models";

@Component({
  selector: "mb-cumulative-reports",
  templateUrl: "./cumulative-reports.component.html",
  host: { style: "display: block", class: "p-3" },
})
export class CumulativeReportsComponent implements OnInit {
  public loaded = false;
  public wrestlers: Wrestler[];

  @Input() reportType: string;
  @Output() reportTypeChange = new EventEmitter();
  @Input() wrestlerID: string;
  @Output() wrestlerIDChange = new EventEmitter();
  @Input() teamID: string;

  constructor(private rosterService: RosterService) {}

  ngOnInit() {
    this.findRoster();
  }

  private findRoster() {
    this.loaded = false;
    this.rosterService
      .getRoster(this.teamID, { status: "all", perPage: 9999 })
      .pipe(first())
      .subscribe((data: any) => {
        this.wrestlers = [];
        data.result.forEach((item) => {
          this.wrestlers.push(new Wrestler(item));
        });
        this.loaded = true;
      });
  }

  public onReportTypeChange(): void {
    this.reportTypeChange.emit(this.reportType);
  }

  public onWrestlerIDChange(): void {
    this.wrestlerIDChange.emit(this.wrestlerID);
  }
}
