import { Component, OnInit, Input } from '@angular/core'

import { playbackSpeedOptions, leadTimeOptions } from '../video-options'
import { MBMatchVideo, MBMatch, User } from '../../models'
import { Doodle } from '../doodle'

@Component({
  selector: 'mb-video-options',
  templateUrl: './video-options.component.html'
})
export class VideoOptionsComponent implements OnInit {

  private _speed: number
  @Input() doodle: Doodle
  @Input() matchVideo: MBMatchVideo
  @Input() user: User

  public playbackSpeedOptions = playbackSpeedOptions
  public leadTimeOptions = leadTimeOptions

  public get match(): MBMatch {
    return this.matchVideo.match
  }

  constructor() { }

  public ngOnInit() {
    if (!this.matchVideo.leadTime) {
      this.matchVideo.leadTime = this.matchVideo.user.leadTime || 3
    }
    if (!this.matchVideo.teamPoints) {
      this.matchVideo.teamPoints = this.match.teamPoints
    }
    if (!this.matchVideo.fallTime) {
      this.matchVideo.fallTime = this.match.fallTime
    }
  }

  public changePlaybackSpeed(val: string) {
    this._speed = parseFloat(val)
    this.syncSpeed()
  }

  public toggleOverrideTeamPointsFlag() {
    if (!this.matchVideo.overrideTeamPoints) {
      this.matchVideo.teamPoints = this.match.teamPoints
    }
  }

  private syncSpeed(): void {
    if (!this.doodle || !this.doodle.videoEl) return
    if (!this._speed) return
    this.doodle.videoEl.playbackRate = this._speed
  }
}
