import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mb-not-found',
  template: `
    <div class="mb-page">
      <div class="container">
        <h1>404</h1>
        <p>Page not found</p>
      </div>
    </div>
  `
})
export class NotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
