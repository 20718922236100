import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Subject } from 'rxjs'
import { takeUntil, first } from 'rxjs/operators'
import * as _ from 'lodash'

import { Wrestler, User } from '../models'
import { NavService, RosterService, UserService, EventLevelsService } from '../services'
import { Pagination, MBEventLevel } from '../util'
import { DEFAULT_EVENT_LEVELS, EVENT_LEVELS } from '../util/mbevent-options'
import { EmailRosterComponent, RosterFilters, WrestlerEditComponent } from './'

@Component({
  selector: 'mb-roster',
  templateUrl: './roster.component.html',
})
export class RosterComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'mb-page'

  public wrestlers: Wrestler[]
  public loaded = false
  public pagination: Pagination
  public error: string
  public levelOptions: MBEventLevel[]
  public success: string
  public sortBy = 'Level'
  public status = 'current'
  public search = ''
  private _sortAsc = true
  private _wrestlers: Wrestler[]
  private teamID: string
  private teamCode: string
  private unsubscribe: Subject<any> = new Subject()
  private levelsLoaded = false
  private wrestlersLoaded = false

  public levels: any = { '': '' }

  public constructor(
    private eventLevelsService: EventLevelsService,
    private modalService: NgbModal,
    private navService: NavService,
    private rosterService: RosterService,
    private userService: UserService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    EVENT_LEVELS.forEach((level) => {
      if (level.levelID === '-1') return
      const levelID: string = DEFAULT_EVENT_LEVELS[Number(level.levelID)]
      const label: string = level.levelID === '3' || level.levelID === '4' ? levelID : level.label
      this.levels[levelID] = label
    })
  }

  public ngOnInit() {
    this.navService.setCurrentFromData({
      name: 'roster'
    })
    this.route.queryParams.subscribe(params => {
      if (params.search && this.search === '') {
        this.search = params.search
      }
    })
    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        this.teamID = user.teamID
        this.teamCode = user.teamCode
        this.findRoster()
        this.findLevels()
      })
  }

  public ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  /**
   * add a new wrestler to the roster
   */
  public addWrestler(): void {
    const modalRef = this.modalService.open(WrestlerEditComponent, { size: 'sm' })
    const wrestler: Wrestler = new Wrestler({ TeamID: this.teamID })
    modalRef.componentInstance.wrestler = wrestler
    modalRef.componentInstance.title = 'Add Wrestler'
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          const newWrestler: Wrestler = new Wrestler(fulfilledValue.result)
          this.wrestlers.push(newWrestler)
          this.setSuccess(newWrestler.fullName + ' saved!')
          this.search = ''
          this.findRoster()
        } else {
          this.setError('There was an error saving the new wrestler (1).', fulfilledValue)
        }
      },
      (rejectedValue: any) => {
        if (rejectedValue === 'Cross click' || rejectedValue === 0 || rejectedValue === 1) return
        this.setError('There was an error saving the new wrestler (2).', rejectedValue)
      }
    )
  }

  /**
   * edit wrestler
   * @param wrestler
   */
  public editWrestler(wrestler: Wrestler): void {
    const modalRef = this.modalService.open(WrestlerEditComponent, { size: 'sm' })
    modalRef.componentInstance.teamCode = this.teamCode
    modalRef.componentInstance.wrestler = wrestler
    modalRef.result.then(
      (fulfilledValue) => {
        if (fulfilledValue.success) {
          const wr: Wrestler = new Wrestler(fulfilledValue.result)
          this.setSuccess(wr.fullName + ' saved!')
          this.findRoster()
        } else {
          this.setError('There was an error saving the wrestler (1).', fulfilledValue)
        }
      },
      (rejectedValue) => {
        if (rejectedValue === 'Cross click' || rejectedValue === 0 || rejectedValue === 1) return
        this.setError('There was an error saving the wrestler (2).', rejectedValue)
      }
    )
  }

  /**
   * email a roster
   */
  public emailRoster(): void {
    const modalRef = this.modalService.open(EmailRosterComponent, { size: 'sm' })
    modalRef.componentInstance.teamID = this.teamID
  }

  /**
   * sort wrestlers by a field
   * @param field
   */
  public sort(field): void {
    const dir: _.Many<boolean | 'asc' | 'desc'> = this.newSortDir(field)
    this.sortBy = field
    this.wrestlers = _.orderBy(this.wrestlers, [w => w[this.sortBy].toLowerCase()], dir)
      .map((w) => new Wrestler(w))
  }

  /**
   * the sort direction
   */
  public get sortDir(): _.Many<'asc' | 'desc'> {
    return this._sortAsc ? 'asc' : 'desc'
  }

  /**
   * This is a listener for the filters.submitted event
   * which is called from roster-filters.component when
   * filters have changed. This triggers a new search.
   * @param filters
   */
  public updateFilters(filters: RosterFilters): void {
    if (this.status !== filters.status) {
      this.loaded = false
      this.status = filters.status
      this.findRoster()
    } else if (this.search !== filters.search) {
      this.search = filters.search
      this.applySearch()
    }
  }

  private applySearch(): void {
    if (!this._wrestlers) {
      this._wrestlers = [].concat(this.wrestlers)
    }

    this.wrestlers = this._wrestlers.filter((w: Wrestler) => {
      return [w.firstName, w.lastName]
        .join(' ')
        .toLocaleLowerCase()
        .indexOf(this.search.toLocaleLowerCase()) !== -1
    })
  }

  /**
   * reverse sort direction if field is same, or default to asc
   * @param field
   */
  private newSortDir(field: string): _.Many<boolean | 'asc' | 'desc'> {
    if (field === this.sortBy) {
      this._sortAsc = !this._sortAsc
    } else {
      this._sortAsc = true
    }
    return this.sortDir
  }

  /**
   * roster lookup
   */
  private findRoster(): void {
    this.loaded = false

    this.rosterService.getRoster(this.teamID, {status: this.status})
      .pipe(first())
      .subscribe((data: any) => {
        this.wrestlers = []
        this._wrestlers = undefined
        data.result.forEach(item => {
          this.wrestlers.push(new Wrestler(item))
        })
        this.pagination = data.pagination as Pagination
        if (this.levelsLoaded) {
          this.applySearch()
          this.loaded = true
        }
      })
  }

  private findLevels(): void {
    this.eventLevelsService.current.subscribe(levels => {
      this.levelOptions = levels
      this.levelsLoaded = true
      if (this.wrestlersLoaded) this.loaded = true
    })
  }

  private setError(message: string, obj?: any): void {
    this.success = null
    this.error = message
    console.warn('Error: ' + message, obj)
    window.scroll(0, 0)
  }

  private setSuccess(message: string, obj?: any): void {
    this.error = null
    this.success = message
    if (obj) console.warn('Success', obj)
    window.scroll(0, 0)
  }

  private clearAlerts(): void {
    this.error = null
    this.success = null
  }
}
