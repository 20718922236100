import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil, first } from "rxjs/operators";

import { UserService } from "../services";
import { User } from "../models";
import { environment } from "../../environments/environment";

@Component({
  selector: "mb-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public error: string;
  public validate = false;
  public submitting = false;
  public registered = false;
  public invalidInvite = false;
  public invalidInviteMessage =
    "Invalid invite code. Please contact your Coach or MatBoss support.";
  private unsubscribe: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public ngOnInit() {
    this.createForm();
    this.route.queryParams.subscribe((params) => {
      if (params.username && this.form.get("userName").value === "") {
        this.form.patchValue({ userName: params.username });
      }
      if (params.registered) {
        this.registered = params.registered ? true : false;
      }
      if (params.invalidInvite) {
        this.invalidInvite = params.invalidInvite ? true : false;
      }
      if (params.nonce) {
        this.submitWithNonce(params.nonce);
      }
    });

    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        console.log("user", user);

        // check to see if the migrate flag is set!
        // TODO

        if (user.loaded && user.userName) {
          // @todo potentially capture incoming uri and redirect there
          this.destroy();
          if (user.showSetupWizard && user.isMainContact) {
            window.location.href = environment.setupUrl;
          } else this.router.navigate(["/"]);
        }
      });

    this.userService
      .requestCSRFToken()
      .pipe(first())
      .subscribe(
        (data: any) => {
          // ignore result, CSRF token request just sets internal value for subsequent requests
          // console.log(data)
        },
        (err: any) => {
          this.loginError(err);
        }
      );
  }

  public ngOnDestroy() {
    this.destroy();
  }

  private destroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public submitWithNonce(nonce: string) {
    this.error = null;
    this.submitting = true;
    this.userService
      .authenticateWithNonce(nonce)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.success) {
            let props = data.result;
            // if we have a migrate flag on the current user response, set the same flag on the user account
            props.migrate = data.migrate || false;
            this.userService.setCurrentFromData(props);
            let user: User = new User(data);
            // console.log(props);
            // check to see if the migrate flag is set!
            if (props.migrate) {
              this.router.navigate(["/migrate"]);
            } else if (props.account.showSetupWizard && user.isMainContact) {
              window.location.href = environment.setupUrl;
            } else {
              this.router.navigate(["/"]);
            }
          } else {
            this.loginError(data);
            this.submitting = false;
          }
        },
        (err: any) => {
          this.submitting = false;
          this.loginError(err);
        }
      );
  }

  public submit() {
    this.error = null;
    if (!this.form.valid) {
      this.validate = true;
      return false;
    }
    this.submitting = true;
    this.userService
      .authenticate(this.form.value)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.success) {
            let props = data.result;
            // if we have a migrate flag on the current user response, set the same flag on the user account
            props.migrate = data.migrate || false;
            this.userService.setCurrentFromData(props);
            let user: User = new User(data);
            // console.log(props);
            // check to see if the migrate flag is set!
            if (props.migrate) {
              this.router.navigate(["/migrate"]);
            } else if (props.account.showSetupWizard && user.isMainContact) {
              window.location.href = environment.setupUrl;
            } else {
              this.router.navigate(["/"]);
            }
          } else {
            this.loginError(data);
            this.submitting = false;
          }
        },
        (err: any) => {
          this.submitting = false;
          this.loginError(err);
        }
      );
  }

  private loginError(err: any) {
    if (!environment.production) {
      console.warn("login error", err);
    }
    this.error = "Invalid email address or password";
  }

  private createForm() {
    this.form = this.fb.group({
      userName: ["", Validators.required],
      password: ["", Validators.required],
    });
  }
}
