import { environment } from '../../environments/environment'

export interface MBEventLevel {
  levelID: string
  label: string
}

export const DEFAULT_EVENT_LEVELS: string[] = [
  'A-Squad',
  'B-Squad',
  'C-Squad',
  'Former',
  'Inactive',
]

export const EVENT_LEVELS: MBEventLevel[] = [
  { levelID: '-1', label: 'All Levels' },
  { levelID: '0', label: 'A-Squad' },
  { levelID: '1', label: 'B-Squad' },
  { levelID: '2', label: 'C-Squad' },
  { levelID: '3', label: 'Practice' },
  { levelID: '4', label: 'Scout '}
]

export const EVENT_TYPES: string[] = [
  'Dual Meet',
  'Tournament'
]

const _EVENT_YEAR_OPTIONS: string[] = []
let [y1] = environment.currentYear.split('-').map(parseInt)
for (y1; y1 >= 2012; y1--) {
  _EVENT_YEAR_OPTIONS.push(`${y1}-${y1 + 1}`)
}
export const EVENT_YEAR_OPTIONS = _EVENT_YEAR_OPTIONS
