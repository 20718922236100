import { Component, OnInit } from '@angular/core'
import { first } from 'rxjs/operators'

import { RssService } from '../../services'

@Component({
  selector: 'mb-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  public blogs: any[]
  public podcasts: any[]
  public currentYear: number = new Date().getFullYear();

  private feedUrl = 'https://matbossapp.hs-sites.com/blog/rss.xml'
  //private intermatFeedUrl = 'http://intermatwrestle.com/rss'

  constructor(
    private rssService: RssService
  ) { }

  ngOnInit() {
    // this.loadFeed()
  }

  private loadFeed(): void {
    this.rssService.loadFeed(this.feedUrl)
      .pipe(first())
      .subscribe((r: any[]) => {
        r.forEach(item => {
          if (this.itemIsPodcast(item)) this.addPodcast(item)
          else this.addBlog(item)
        })
      })
  }

  private itemIsPodcast(item: any): boolean {
    return item.category && item.category.indexOf('Podcast') !== -1
  }

  private addBlog(b: any): void {
    if (!this.blogs) this.blogs = []
    this.blogs.push(this.rss2item(b))
  }

  private addPodcast(p: any): void {
    if (!this.podcasts) this.podcasts = []
    this.podcasts.push(this.rss2item(p))
  }

  private rss2item(rss: any): {title: string, link: string, description: string} {
    return {
      title: this.stripHtml(rss.title[0]),
      link: rss.link[0],
      description: this.stripHtml(rss.description[0])
    }
  }

  private stripHtml(t: string): string {
    return t.replace(/<[^>]*>/g, '')
  }
}
