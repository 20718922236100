<div class="modal-header">
    <h4 class="modal-title">{{ isNew ? 'Add a new Tag' : 'Edit Tag' }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
      <div class="form-group">
        <label>Tag name</label>
        <input class="form-control" formControlName="name">
        <mb-invalid [form]="form" [validate]="validate" field="name"></mb-invalid>
      </div>
      <div class="form-group">
        <label>Position</label>
        <select class="form-control" formControlName="position">
          <option *ngFor="let pos of positions" [value]="pos">{{ pos }}</option>
        </select>
        <mb-invalid [form]="form" [validate]="validate" field="position"></mb-invalid>
      </div>
      <div class="form-group">
        <label>Abbreviation</label>
        <input class="form-control" formControlName="abbrev">
        <mb-invalid [form]="form" [validate]="validate" field="abbrev"></mb-invalid>
      </div>
    </form>
  </div>

  <div class="modal-footer d-block">
    <button type="button"
      class="btn btn-primary btn-block"
      (click)="submit()">
      Save
    </button>

    <div class="my-4 text-center">
      <a (click)="activeModal.dismiss('Cross click')" class="mb-link">Cancel</a>
    </div>
  </div>
