import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { first } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'

import { RosterService } from '../services'
import { CustomValidators } from '../util/forms/custom-validators'

@Component({
  selector: 'mb-email-roster',
  templateUrl: './email-roster.component.html'
})
export class EmailRosterComponent implements OnInit {
  @Input() teamID: string
  public form: FormGroup
  public validate = false
  public submitting = false

  public constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private rosterService: RosterService,
    private toastrService: ToastrService,
  ) { }

  public ngOnInit() {
    this.createForm()
  }

  public submit() {
    if (!this.form.valid) {
      this.validate = true
      return
    }
    this.submitting = true
    this.rosterService.emailRoster(
      this.teamID,
      this.form.value.recipients,
      this.form.value.message
    ).pipe(first())
      .subscribe(
        (r: any) => {
          this.submitting = false
          if (r.success) this.toastrService.success('Email sent!')
          else this.toastrService.error('Could not send message: ' + r.message)
          this.activeModal.close()
        },
        (e: any) => {
          this.submitting = false
          this.toastrService.error('There was an error sending the message: ' + e.message)
          console.error('error sending email', e)
          this.activeModal.close()
        }
      )
  }

  private createForm() {
    this.form = this.fb.group({
      recipients: ['', [
        Validators.required,
        CustomValidators.emailListValidator()
      ]],
      message: ['']
    })
  }
}
