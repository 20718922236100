<mb-jumbotron
  *ngIf="notificationsLoaded && systemMessages.length"
  [messages]="systemMessages"
></mb-jumbotron>

<div *ngIf="notificationsLoaded" class="container container--inner">
  <div class="d-flex align-items-center py-4 px-4">
    <div class="h1 mb-0 mr-2 font-weight-bold">{{ matchesLoaded }}</div>
    <div class="mr-5">matches uploaded this week</div>
    <div class="h1 mb-0 mr-2 font-weight-bold">
      {{ wrestlerLogins }}
    </div>
    <div>
      wrestler logins
      <a
        class="mb-link--info"
        style="text-decoration: none"
        [routerLink]="['reports/result']"
        [queryParams]="{
          year: year,
          levelID: '0',
          timePeriod: '0',
          reportType: 'logins'
        }"
        >❯❯</a
      >
    </div>
  </div>
</div>

<div class="jumbotron mb-upcoming-matches mb-section" *ngIf="user.isPublicUser">
  <div class="container container--inner">
    <h3 class="mb-section__head">Welcome to MatBoss</h3>
    <div class="row">
      <div class="col-md-12">
        Created by coaches for coaches, MatBoss integrates wrestling stats
        directly into the video you record for each match, completely replacing
        the need for labor-intensive pencil and paper scoring systems. It's the
        wrestling stats app our sport has been waiting for.
      </div>
    </div>
  </div>
</div>

<div class="mb-recent-matches mb-section" *ngIf="user.isPublicUser">
  <div class="container container--inner">
    <h3>
      Settings
      <a routerLink="/scorebook" class="mb-section__head-link"
        >Go To Settings &gt;</a
      >
    </h3>
    <div class="row">
      <div class="col-md-12">
        If you need to make changes to your profile, do so here.
      </div>
    </div>
  </div>
</div>

<div class="mb-recent-matches mb-section" *ngIf="user.isPublicUser">
  <div class="container container--inner">
    <h3>
      Help
      <a routerLink="/scorebook" class="mb-section__head-link"
        >Go To MatBoss University &gt;</a
      >
    </h3>
    <div class="row">
      <div class="col-md-12">
        For information on how to use MatBoss, please check out MatBoss
        University.
      </div>
    </div>
  </div>
</div>

<div
  class="jumbotron mb-upcoming-matches mb-section"
  *ngIf="!user.isPublicUser"
>
  <div class="container container--inner">
    <h3 class="mb-section__head">
      <img src="./assets/SVG/Page/UpcomingMatches.svg" alt="icon" />
      Upcoming Events
    </h3>

    <mb-spinner *ngIf="!upcomingEvents"></mb-spinner>

    <div *ngIf="upcomingEvents && !upcomingEvents.length">
      No Upcoming Events
    </div>
    <div *ngIf="upcomingEvents && upcomingEvents.length" class="row">
      <div *ngFor="let evt of upcomingEvents" class="col-md-">
        <div class="mb-upcoming-match">
          <p class="mb-event__summary">
            {{ evt.eventDate }} <span class="px-2">•</span> {{ evt.level }}
            <span class="px-2">•</span> 
            {{ evt.eventType }}
          </p>
          <h4>
            <a [routerLink]="['/event', evt.encodedID]">
              <span *ngIf="evt.isDualMeet">
                {{ user.schoolName }}
                vs.
                {{ evt.opponentName }}
              </span>
              <span *ngIf="evt.isTournament">
                {{ evt.eventName }}
              </span>
            </a>
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mb-recent-matches mb-section" *ngIf="!user.isPublicUser">
  <div class="container container--inner">
    <h3 class="mb-section__head">
      <img src="./assets/SVG/Page/RecentMatches.svg" alt="Icon" />
      Recent Events
      <a routerLink="/scorebook" class="mb-section__head-link"
        >See all events</a
      >
    </h3>

    <mb-spinner *ngIf="!recentEvents"></mb-spinner>

    <div *ngIf="recentEvents">
      <div *ngIf="!recentEvents.length">No recent events</div>
      <div *ngIf="recentEvents.length">
        <div class="mb-event mt-4" *ngFor="let evt of recentEvents">
          <div class="mb-event__head">
            <mb-event-boxscore [event]="evt"></mb-event-boxscore>
            <div *ngIf="user.isMainContact" class="mb-event__sync">
              <a *ngIf="!exporting[evt.eventID]" (click)="exportEvent(evt)">
                <img src="./assets/SVG/UI/Sync.svg" alt="Export" />
                Export to<br />Trackwrestling
              </a>
              <mb-spinner *ngIf="exporting[evt.eventID]"></mb-spinner>
            </div>
          </div>
          <div class="mb-matches">
            <div class="mb-matches__counter">
              <a
                class="mb-link--info"
                (click)="collapse[evt.eventID] = !collapse[evt.eventID]"
              >
                {{ evt.match_count }} matches ({{
                  collapse[evt.eventID] ? "+" : "-"
                }})
              </a>
            </div>
            <div *ngIf="!!evt.matches && !collapse[evt.eventID]" class="row">
              <div
                *ngFor="let match of evt.matches"
                class="col-md-3 mb-match-container"
              >
                <div class="mb-match">
                  <a [attr.href]="watch(match)">
                    <img
                      class="mb-match__thumbnail"
                      [src]="thumbnailForMatch(match)"
                      (error)="defaultThumbnail($event)"
                      alt="video thumbnail"
                    />
                    <div class="mb-match__weight">
                      {{ match.theWeightClass }}
                    </div>
                    <div class="mb-match__boxscore">
                      <div class="mb-match__boxscore-row">
                        <div
                          class="mb-match__boxscore-wrestler"
                          [ngClass]="{
                            'font-weight-bold': match.ourWin,
                            'mb-wrestler--red': match.ourRed,
                            'mb-wrestler--green': match.ourGreen
                          }"
                        >
                          {{ match.ourWrestlerName }}
                        </div>
                      </div>
                      <div class="mb-match__boxscore-row">
                        <div
                          class="mb-match__boxscore-wrestler mb-wrestler--green"
                          [ngClass]="{
                            'font-weight-bold': !match.ourWin,
                            'mb-wrestler--red': !match.ourRed,
                            'mb-wrestler--green': !match.ourGreen
                          }"
                        >
                          {{ match.opponentName }}
                        </div>
                      </div>
                      <div class="mb-match__boxscore-result">
                        {{ match.ourWin }} {{ match.theResult }}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div class="mb-details-link">
              <a
                [routerLink]="['/event', evt.encodedID]"
                class="btn btn-lg btn-primary"
                >Event Details</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mb-reports mb-section" *ngIf="!user.isPublicUser">
  <div class="container container--inner">
    <h3 class="mb-section__head">
      <img src="./assets/SVG/Page/Reports.svg" alt="Icon" />
      Reports
      <a
        *ngIf="user.isMainContact"
        routerLink="/reports"
        class="mb-section__head-link"
        >Create a report</a
      >
    </h3>

    <div class="row">
      <div class="col-md-6">
        <mb-spinner *ngIf="!report1"></mb-spinner>
        <div *ngIf="report1" class="mb-report-summary">
          <h5 class="mb-report-summary__title">
            {{ report1.title }}
          </h5>
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th
                  *ngFor="
                    let h of report1.sections[0].columnHeadings;
                    index as i
                  "
                  [ngClass]="{ 'text-right pr-4': i === 0 }"
                >
                  {{ h }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of report1.sections[0].rows">
                <td
                  *ngFor="let item of row; index as i"
                  [ngClass]="{ 'text-right pr-4': i === 0 }"
                >
                  {{ item }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-6">
        <mb-spinner *ngIf="!report2"></mb-spinner>
        <div *ngIf="report2" class="mb-report-summary">
          <h5 class="mb-report-summary__title">
            {{ report2.title }}
          </h5>
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th
                  *ngFor="
                    let h of report2.sections[0].columnHeadings;
                    index as i
                  "
                  [ngClass]="{ 'text-right pr-4': i === 0 }"
                >
                  {{ h }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of report2.sections[0].rows">
                <td
                  *ngFor="let item of row; index as i"
                  [ngClass]="{ 'text-right pr-4': i === 0 }"
                >
                  {{ item }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
