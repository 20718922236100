import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { first } from 'rxjs/operators'

import { MBMatch, MBEvent } from '../models'
import { ConfirmComponent } from '../util/confirm'
import { MBMatchService, MBEventService } from '../services'
import { environment } from '../../environments/environment'
import { EVENT_LEVELS } from '../util/mbevent-options'

@Component({
  selector: 'mb-match-edit',
  templateUrl: './match-edit.component.html'
})
export class MatchEditComponent implements OnInit {

  @Input() match: MBMatch
  @Input() teamID: string

  public error: string
  public events: MBEvent[]
  public form: FormGroup
  public submitting = false
  public loadingEvents = false
  public eventLevels = EVENT_LEVELS
  public validate = false

  constructor(
    public activeModal: NgbActiveModal,
    private eventService: MBEventService,
    private matchService: MBMatchService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    this.createForm()
  }

  public submit(): void {
    if (!this.form.valid) {
      this.validate = true
      return
    }
    this.submitting = true
    this.matchService.move(this.match, this.form.value.eventID)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.activeModal.close({
            success: true,
            result: data,
            deleted: false
          })
        },
        (e: any) => {
          this.submitting = false
          this.setError('Oops something went wrong and we could not move the match. Please try again later.')
        })
  }

  public loadEvents(): void {
    this.events = []
    this.loadingEvents = true
    if (!this.form.value.levelID) return
    this.eventService.findAll({
      teamID: this.teamID,
      levelID: this.form.value.levelID,
      year: this.match.year,
    })
      .pipe(first())
      .subscribe((data: any) => {
        this.events = []
        data.result.forEach((r) => { this.events.push(new MBEvent(r)) })
        this.loadingEvents = false
      })
  }

  public confirmDelete(): void {
    const modalRef = this.modalService.open(ConfirmComponent)
    modalRef.componentInstance.title = 'Confirm'
    modalRef.componentInstance.body = 'Are you sure you want to permanently delete this match?'
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) this.delete()
      },
      (rejectedValue: any) => {
        console.log('rejected', rejectedValue)
      }
    )
  }

  public watch(): void {
    this.activeModal.dismiss('View Match')
  }

  private delete(): void {
    this.submitting = true
    this.matchService.delete(this.match)
      .pipe(first())
      .subscribe((r: any) => {
        this.activeModal.close({
          success: true,
          result: null,
          deleted: true
        })
      }, (e: any) => {
        this.submitting = false
        this.setError('Oops something went wrong and we could not delete the match. Please try again later.')
      })
  }

  public confirmClone(): void {
    const modalRef = this.modalService.open(ConfirmComponent)
    modalRef.componentInstance.title = 'Confirm'
    modalRef.componentInstance.body = 'This will create a new copy of the match. This function is to be used for the situation where both wrestlers are from your team. Do you still want to proceed?'
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) this.clone()
      },
      (rejectedValue: any) => {
        console.log('rejected', rejectedValue)
      }
    )
  }

  private clone(): void {
    this.submitting = true
    this.matchService.clone(this.match)
      .pipe(first())
      .subscribe((r: any) => {
        this.activeModal.close({
          success: true,
          result: r,
          deleted: false
        })
      }, (e: any) => {
        this.submitting = false
        this.setError('Oops something went wrong and we could not clone the match. Please try again later.')
      })
  }

  private createForm(): void {
    this.form = this.fb.group({
      levelID: [null, Validators.required],
      eventID: [null, Validators.required]
    })
  }

  private setError(msg: string): void {
    this.error = msg
    window.scroll(0, 0)
  }
}
