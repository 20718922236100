<div class="container container--inner">
  <h3 class="mb-page__head">
    <span class="mb-page__head-title">
      <img src="./assets/SVG/Page/Highlights.svg" alt="Icon" />
      Highlights
    </span>
  </h3>

  <div class="row">
    <div class="col-md-6">
      <p class="mb-text">
        One of the most time-consuming parts of coaching is sifting through
        hours of footage to be used for year-end highlight videos. MatBoss
        offers a video clipping service aimed at saving you time. Best of all,
        this service is FREE with your paid subscription.
      </p>

      <p class="mb-text">
        All you need to do is select the clips you want from the list below and
        click the 'Submit Clip Order' button at the bottom of the page. We will
        then locate the clips you've selected, and deliver the clips in an
        organized archive. We guarantee a turnaround time of 24 hours, but it is
        usually much sooner than that.
      </p>
    </div>
    <div class="col-md-6">
      <img
        src="./assets/Img/Highlights-Desktop.png"
        alt="Wrestler"
        style="width: 100%"
      />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-12">
      <p class="mb-text mb-scorebook__filters">
        Filter By:
        <select [(ngModel)]="year" (change)="yearChange()">
          <option [value]="null">Season</option>
          <option *ngFor="let yr of yearOptions" [value]="yr" [selected]>
            {{ yr }}
          </option>
        </select>
      </p>
    </div>
  </div>
  <!-- <hr class="mb-hr top" /> -->

  <mb-spinner
    *ngIf="!loaded"
    label="Loading Highlights..."
    class="my-5 p-5 text-center"
  ></mb-spinner>
  <div *ngIf="loaded">
    <mb-alert
      *ngIf="error"
      type="danger"
      [message]="error"
      (closed)="error = null"
    ></mb-alert>
    <mb-alert
      *ngIf="success"
      [message]="success"
      (closed)="success = null"
    ></mb-alert>

    <div *ngIf="enableSearch" class="mb-roster-filter" class="p-1">
      <input *ngIf="enableSearch" type="search" placeholder="Search..." />
    </div>

    <div *ngIf="highlights.length > 0">
      <div>
        <div class="mb-details-link top">
          <button
            (click)="placeOrder()"
            [disabled]="submitting || numSelected == 0"
            class="btn btn-med btn-primary"
          >
            Submit Clip Order
          </button>
          <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
        </div>
        <hr class="top" />
      </div>

      <div class="table-responsive">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th class="text-nowrap">
                <input
                  type="checkbox"
                  [(ngModel)]="checkAll"
                  (change)="toggleAll()"
                />
              </th>
              <th class="text-nowrap">Wt</th>
              <th class="text-nowrap">Your Wrestler</th>
              <th class="text-nowrap">Opponent</th>
              <th class="text-nowrap">Result</th>
              <th class="text-nowrap">Description</th>
              <th class="text-nowrap"></th>
              <th class="text-nowrap"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let highlight of highlights"
              (click)="toggleRow(highlight)"
              [ngClass]="{ 'mb-selected-row': selected[highlight.ID] }"
            >
              <td>
                <input type="checkbox" [(ngModel)]="selected[highlight.ID]" />
              </td>
              <td>
                {{ highlight.weightClass }}
              </td>
              <td>
                {{ highlight.ourWrestler }}
              </td>
              <td>
                <div>{{ highlight.opponent }}</div>
                <div>
                  <small>{{ highlight.opposingTeam }}</small>
                </div>
              </td>
              <td>
                {{ highlight.theResult }}
              </td>
              <td>
                {{ highlight.description }}
              </td>
              <td>
                <button
                  id="highlight--{{ highlight.ID }}"
                  class="btn btn-sm btn-outline-secondary"
                  (click)="preview($event, highlight)"
                  style="text-transform: uppercase"
                >
                  <img
                    alt="Icon"
                    src="./assets/SVG/UI/Preview.svg"
                    style="width: 16px; height: 16px"
                  />
                  Preview
                </button>
              </td>
              <td>
                <button
                  class="btn btn-sm btn-outline-secondary"
                  (click)="confirmDelete($event, highlight)"
                  style="margin-left: 20px"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="mb-text">
        Once your request has been processed, we'll send you a link to download
        the archive.
      </p>

      <div>
        <hr />
        <p class="mb-text">{{ numSelected }} selected clips</p>
        <div class="mb-details-link">
          <button
            (click)="placeOrder()"
            [disabled]="submitting || numSelected == 0"
            class="btn btn-med btn-primary"
          >
            Submit Clip Order
          </button>
          <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
        </div>
      </div>
    </div>
    <div *ngIf="highlights.length === 0" class="px-5 text-center">
      <p>
        There are no saved highlight clips in your account for the selected
        season.<br />
        You can submit clips from the match video preview screens.
      </p>
    </div>

    <mb-video-preview
      *ngIf="previewHighlight"
      [matchID]="previewHighlight.matchID"
      [highlight]="previewHighlight"
      [startTime]="previewStartTime"
      (close)="reload()"
    ></mb-video-preview>
  </div>

  <div *ngIf="archive">
    <h3 class="mt-5">Archives</h3>
    <hr class="mb-hr top" />
    <p>
      The table below contains links to all of the clip archives you've ordered
      from us.
    </p>
    <div class="table-responsive">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th class="d-none d-sm-table-cell text-nowrap">Date</th>
            <th class="d-none d-sm-table-cell text-nowrap"># of Clips</th>
            <th class="d-none d-sm-table-cell text-nowrap">Status</th>
            <th class="d-none d-sm-table-cell text-nowrap">Link</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let entry of archive">
            <td class="d-none d-sm-table-cell text-nowrap">
              {{ entry.date }}
            </td>
            <td class="d-none d-sm-table-cell text-nowrap">
              <div *ngIf="entry.status === 'completed'">
                {{ entry.numClips }}
              </div>
              <div *ngIf="entry.status !== 'completed'">
                {{ entry.clipsProcessed }}/{{ entry.numClips }}
              </div>
            </td>
            <td class="d-none d-sm-table-cell text-nowrap">
              {{ entry.status }}
              <div *ngIf="entry.status === 'error'">
                {{ entry.message }}
              </div>
            </td>
            <td class="d-none d-sm-table-cell text-nowrap">
              <a
                [href]="entry.url"
                *ngIf="entry.url && entry.url !== ''"
                class="mb-link"
                >Download</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
