<div class="mb-onboarding__password">

  <h2 class="mb-heading">Recover Password</h2>

  <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>
  <mb-alert *ngIf="success" [message]="success" (closed)="success = null"></mb-alert>

  <p class="mb-text">
    Enter your email address and click the 'Submit' button.
    A link will be sent to your email address which can be used to reset your password.
  </p>

  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>

    <mb-text-field
      formControlName="email"
      label="Enter Email Address"
      [valid]="!validate || form.get('email').valid"
    ></mb-text-field>

    <div class="form-group">
      <button
        *ngIf="!submitting"
        type="submit"
        class="btn btn-primary btn-lg">
          Submit
      </button>
      <mb-ellipsis-loader
        *ngIf="submitting"></mb-ellipsis-loader>
    </div>
  </form>

  <div class="mb-onboarding__links">
    <a routerLink="/login" class="mb-link">Back to Log in</a>
  </div>
</div>
