import { Injectable } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { Observable, of, forkJoin } from 'rxjs'
import { delay } from 'rxjs/operators'

import { ApiService } from './api.service'
import { MBEvent } from '../models'
import { environment } from '../../environments/environment'

export interface MBEventIndexParams {
  teamID: string
  levelID: string
  year: string
  per_page?: number
  page?: number
  sortDir?: string
  filter?: string
  move?: string
  includeDeletedEvents?: string
}

@Injectable()
export class MBEventService {

  constructor(public apiService: ApiService) { }

  /**
   * finds all events by team, levelID and year
   * @param data { teamID: string, levelID: string, year: string }
   */
  public findAll(data: MBEventIndexParams): Observable<any> {
    let params = new HttpParams()
      .set('teamID', data.teamID)
      .set('levelID', data.levelID)
      .set('year', data.year)
      .set('per_page', (data.per_page || 100).toString())
      .set('page', (data.page || 0).toString())
      .set('sortDir', (data.sortDir || null))
      .set('filter', (data.filter || null))
      .set('move', (data.move || null))
      .set('includeDeletedEvents', (data.includeDeletedEvents || null))

    return this.apiService.get('events', { params: params })
  }

  public findRecent(data: {
    teamID: string,
    year: string,
    levelID?: string,
    perPage?: number,
    page?: number,
    sortDir?: string
  }): Observable<any> {
    let params = new HttpParams()
      .set('teamID', data.teamID)
      .set('year', data.year)
      .set('levelID', data.levelID || '-1')
      .set('filter', 'recent')
      .set('per_page', (data.perPage || 1).toString())
      .set('page', (data.page || 0).toString())
      .set('sortDir', (data.sortDir || null))
      .set('include', 'nextDay')

    return this.apiService.get('events', { params: params })
  }

  public findUpcoming(data: { teamID: string, year: string, perPage?: number, page?: number, sortDir?: string }): Observable<any> {
    let params = new HttpParams()
      .set('teamID', data.teamID)
      .set('year', data.year)
      .set('levelID', '-1')
      .set('filter', 'upcoming')
      .set('per_page', (data.perPage || 1).toString())
      .set('page', (data.page || 0).toString())
      .set('sortDir', (data.sortDir || null))

    return this.apiService.get('events', { params: params })
  }

  /**
   * find a single event
   */
  public find(eventID: string): Observable<any> {
    return this.apiService.get('events/' + encodeURIComponent(eventID))
  }

  /**
   * finds all matches for a given event
   * @param data params
   */
  public findMatches(data: { teamID: string, levelID: string, year: string, eventID?: string }): Observable<any> {
    let params = new HttpParams()
      .set('per_page', '200')
      .set('teamID', data.teamID)
      .set('levelID', data.levelID)
      .set('year', data.year)

    if (data.eventID) params = params.set('eventID', data.eventID)

    return this.apiService.get('matches', { params: params })
  }

  public editEvent(eventID: string, data: any): Observable<any> {
    return this.apiService.update('events/' + encodeURIComponent(eventID), data)
  }

  public addEvent(teamID: string, data: any, levelID?: string): Observable<any> {
    let params = data || {}
    params.teamID = teamID
    if (levelID && levelID !== '-1') {
      params.scorebookID = teamID + ':' + this.getYearFromDate(data.year || '') + ':' + (levelID || '0')
      return this.apiService.post('events', params)
    }
    // if levelID === -1
    // we create 3 events, 1 for each of these scorebook levelIDs
    const sbBase = teamID + ':' + this.getYearFromDate(data.year || '') + ':'
    const params0 = Object.assign({}, params, {scorebookID: sbBase + '0'})
    const params1 = Object.assign({}, params, {scorebookID: sbBase + '1'})
    const params2 = Object.assign({}, params, {scorebookID: sbBase + '2'})

    return forkJoin(
      this.apiService.post('events', params0),
      this.apiService.post('events', params1),
      this.apiService.post('events', params2)
    )
  }

  public moveEvent(event: MBEvent, newLevel: string): Observable<any> {
    event.scorebookID = event.teamID + ':' + event.year + ':' + newLevel
    return this.apiService.post('events/' + encodeURIComponent(event.eventID), {
      newScorebookID: event.scorebookID
    })
  }
	
  public mergeEvent(event: MBEvent, newEventID: string): Observable<any> {
    return this.apiService.update('mergeintoevent/' + encodeURIComponent(event.eventID), {
      otherEventID: newEventID
    })
  }

  public export(evt: MBEvent): Observable<any> {
    // @todo
    return of(null).pipe(delay(2500))
  }

  public delete(evt: MBEvent): Observable<any> {
    return this.apiService.delete('events/' + encodeURIComponent(evt.eventID))
  }

  private getYearFromDate(date: string): string {
    const DEFUALT_YEAR: string = environment.currentYear
    const parts: string[] = date.split('-')
    if (parts.length !== 3) return DEFUALT_YEAR
    const yr: number = parseInt(parts[0])
    if (!yr || yr < 2010) return DEFUALT_YEAR
    const nxtYr: number = yr + 1
    return yr + '-' + nxtYr
  }
}
