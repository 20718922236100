import { Component, OnInit } from '@angular/core'
import { UserService } from '../services'
import { User } from '../models'

@Component({
  selector: 'mb-import-events',
  templateUrl: './import-events.component.html'
})
export class ImportEventsComponent implements OnInit {

  public account: User
  public loaded = false

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userService.current.subscribe((user: User) => {
      this.account = user
      this.loaded = true
    })
  }
}
