<mb-spinner *ngIf="!loaded" type="ripple-inverse"></mb-spinner>
<div class="mb-setup--step1" *ngIf="loaded">
  <h2 class="mb-heading">Season Setup Wizard</h2>
  <h2 class="mb-heading">Step 1 of 3</h2>
  <p class="mb-text">
    Please verify your contact info below and make changes if necessary.
  </p>

  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <mb-alert
      *ngIf="error"
      type="danger"
      [message]="error"
      (closed)="error = null"></mb-alert>

    <mb-text-field
      formControlName="mainContact"
      label="Name of main contact"
      [validate]="validate"
      [control]="form.get('mainContact')"
      [messages]="messages"
    ></mb-text-field>

    <mb-text-field
      formControlName="mainContactPhone"
      label="Phone for main contact"
      [validate]="validate"
      [control]="form.get('mainContactPhone')"
    ></mb-text-field>

    <mb-text-field
      formControlName="mainContactEmail"
      label="Email for main contact"
      [validate]="validate"
      [control]="form.get('mainContactEmail')"
    ></mb-text-field>
	  
	<mb-text-field
      formControlName="secondaryContact"
      label="Name of secondary contact"
      [validate]="validate"
      [control]="form.get('secondaryContact')"
    ></mb-text-field>
	  
	<mb-text-field
      formControlName="secondaryContactPhone"
      label="Phone for secondary contact"
      [validate]="validate"
      [control]="form.get('secondaryContactPhone')"
    ></mb-text-field>
	  
	<mb-text-field
      formControlName="secondaryContactEmail"
      label="Email for secondary contact"
      [validate]="validate"
      [control]="form.get('secondaryContactEmail')"
    ></mb-text-field>

    <div class="form-group">
      <button  *ngIf="!submitting"
        type="submit"
        class="btn btn-primary btn-lg">
          Next
      </button>
      <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
    </div>
  </form>
</div>
