<div class="container container--inner">
  <div class="mb-breadcrumbs pb-3">
    <a preserveQueryParams routerLink="/settings">‹ Back To Settings</a>
  </div>

  <h3 class="mb-page__head">
    <span class="mb-page__head-title"> TrackWrestling Settings </span>
  </h3>

  <p>
    Select the team your organization is associated with in TrackWrestling. When
    importing your roster or schedule this will be the team from which it pulls
    the events or wrestlers. The teams listed here are specific to each season
    so this selection needs to happen at least once per season!
  </p>

  <mb-spinner
    *ngIf="!loaded"
    label="Loading Settings..."
    class="my-5 p-5 text-center"
  ></mb-spinner>

  <div *ngIf="loaded">
    <mb-alert
      *ngIf="error"
      type="danger"
      [message]="error"
      (closed)="error = null"
    ></mb-alert>
    <mb-alert
      *ngIf="success"
      [message]="success"
      (closed)="success = null"
    ></mb-alert>

    <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="mb-label">State</label>
            <select
              id="stateDropdown"
              class="form-control"
              (change)="reloadSchools()"
            >
              <option>Choose State</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="mb-label">Age Level</label>
            <select
              id="ageDropdown"
              class="form-control"
              (change)="reloadSchools()"
            >
              <option>Choose Age</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="mb-label">School Name in TW</label>
            <select
              id="schoolNameDropdown"
              class="form-control"
              formControlName="twSchoolName"
            >
              <option>Choose School</option>
            </select>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="mb-label">TrackWrestling Username (optional)</label>
            <mb-text-field
              formControlName="twUserName"
              [valid]="!validate || form.get('twUserName').valid"
            ></mb-text-field>
          </div>
        </div>
      </div> -->

      <hr class="mb-hr" />

      <div>
        <button class="btn btn-lg btn-primary btn-full">Save Changes</button>
      </div>
    </form>
  </div>
</div>
