import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { first } from 'rxjs/operators';
import { TeamPointAdjustment } from '../models/teampointadjustment';
import { TeamPointAdjustmentService } from '../services/teampointadjustment.service';
import { ConfirmComponent } from '../util/confirm'

@Component({
  selector: 'mb-adjust-team-point',
  templateUrl: './adjust-team-point.component.html',
})
export class AdjustTeamPointComponent implements OnInit {

  @Input() eventID: string
  @Input() weightClassList: string[]
  @Input() teamPointAdjustment: TeamPointAdjustment

  public forOrAgainstOptions = [
    {value: 0, label: 'For'},
    {value: 1, label: 'Against'}
  ]
  public typeOfAdjustmentOptions = [
    {type: 'Win By Criteria', teamPointDiff: "+1"},
    {type: 'Unsportsmanlike Conduct', teamPointDiff: "-1"}
  ]
  public form: FormGroup
  public validate = false
  public submitting = false

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private teamPointAdjustmentService: TeamPointAdjustmentService,
    private modalService: NgbModal,) { }

  ngOnInit() {
    this.createForm()
  }

  public onTypeOfAdjustmentSelected(): void {
    if (this.form.get('typeOfAdjustment').value && this.form.get('typeOfAdjustment').value.teamPointDiff === '-1') {
      this.form.get('when').setValidators(Validators.required)
    } else {
      this.form.get('when').clearValidators();
    }
  }

  public create(): void {
    if (!this.form.valid) {
      this.toastrService.warning('Please be sure all required fields are complete')
      this.validate = true
      return
    }

    this.submitting = true;
    const data = {
      eventID: this.eventID,
      forOrAgainst: this.form.get('forOrAgainst').value,
      type: this.form.get('typeOfAdjustment').value.type,
      teamPointDifference: parseInt(this.form.get('typeOfAdjustment').value.teamPointDiff),
      matchWeight: this.form.value.when
    }
    this.teamPointAdjustmentService.create(data).pipe(first()).subscribe(
      (data: any) => {
        this.submitting = false
        if (data.success) {
          this.toastrService.success('Team point adjustment saved')
            this.activeModal.close({
              success: true,
              result: data.result,
            })
        } else {
          this.toastrService.error('There was an error saving the team point adjustment')
        }
      }
    )
  }

  public update(): void {
    if (!this.form.valid) {
      this.toastrService.warning('Please be sure all required fields are complete')
      this.validate = true
      return
    }

    this.submitting = true;
    const data = {
      eventID: this.eventID,
      forOrAgainst: this.form.get('forOrAgainst').value,
      type: this.form.get('typeOfAdjustment').value.type,
      teamPointDifference: parseInt(this.form.get('typeOfAdjustment').value.teamPointDiff),
      matchWeight: this.form.value.when
    }
    this.teamPointAdjustmentService.update(this.teamPointAdjustment.id, data)
    .pipe(first())
    .subscribe(
      (data: any) => {
        this.submitting = false
        if (data.success) {
          this.toastrService.success('Team point adjustment updated')
            this.activeModal.close({
              success: true,
              result: data.result,
            })
        } else {
          this.toastrService.error('There was an error updating the team point adjustment')
        }
      }
    )
  }

  public confirmDelete(): void {
    const modalRef = this.modalService.open(ConfirmComponent)
    modalRef.componentInstance.title = 'Confirm'
    modalRef.componentInstance.body = 'Are you sure you want to delete this adjustment?'
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) this.delete()
      },
      (rejectedValue: any) => {
        console.log('rejected', rejectedValue)
      }
    )
  }

  private delete(): void {
    this.submitting = true
    this.teamPointAdjustmentService.delete(this.teamPointAdjustment.id)
      .pipe(first())
      .subscribe(
        (r: any) => {
          this.toastrService.success('Team point adjustment deleted')
          this.activeModal.close({
            success: true,
            result: null,
            deleted: true
          })
        }, (e: any) => {
          this.submitting = false
          this.toastrService.error('Oops something went wrong and we could not delete the adjustment. Please try again later.')
        })
  }

  private createForm(): void {
    if (this.teamPointAdjustment) {
      this.form = this.fb.group({
        forOrAgainst: [
          this.forOrAgainstOptions.find(({ value }) => value === this.teamPointAdjustment.forOrAgainst).value,
          Validators.required],
        typeOfAdjustment: [
          this.typeOfAdjustmentOptions.find(({ type }) => type === this.teamPointAdjustment.type),
          Validators.required],
        when: [Array.from(this.weightClassList).find(weight => weight === this.teamPointAdjustment.matchWeight)],
      })
      this.onTypeOfAdjustmentSelected()
    } else {
      this.form = this.fb.group({
        forOrAgainst: [null, Validators.required],
        typeOfAdjustment: [null, Validators.required],
        when: [null],
      })
    }
  }
}
