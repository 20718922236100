<!-- STEP 2 -->
<mb-spinner *ngIf="!loaded" type="ripple-inverse"></mb-spinner>
<div *ngIf="loaded">
  <a (click)="goBack()" class="mb-link" style="color:gainsboro">&lt;&lt; Back</a>
  <h2 class="mb-heading">Step 3 of 3</h2>
  <p class="mb-text" style="font-size: 20px">
    Set the titles and weight classes for each of your scorebooks below.
  </p>

  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <mb-alert *ngIf="error"
      type="danger"
      [message]="error"
      (closed)="error = null"></mb-alert>

    <mb-text-field
      formControlName="aSquadTitle"
      label="A-Squad Title"
	  placeholder="Enter A-Squad title"
	  required minlength="1"
      [valid]="!validate || form.get('aSquadTitle').valid"
    ></mb-text-field>
	  
	<mb-text-field
      formControlName="aSquadWeights"
      label="A-Squad Weights"
	  placeholder="Enter A-Squad weight classes"
	  required minlength="1"
      [valid]="!validate || form.get('aSquadWeights').valid"
    ></mb-text-field>

    <mb-text-field
      formControlName="bSquadTitle"
      label="B-Squad Title"
	  placeholder="Enter B-Squad title"
	  required minlength="1"
      [valid]="!validate || form.get('bSquadTitle').valid"
    ></mb-text-field>
	  
	<mb-text-field
      formControlName="bSquadWeights"
      label="B-Squad Weights"
	  placeholder="Enter B-Squad weight classes"
	  required minlength="1"
      [valid]="!validate || form.get('bSquadWeights').valid"
    ></mb-text-field>

    <mb-text-field
      formControlName="cSquadTitle"
      label="C-Squad Title"
	  placeholder="Enter C-Squad title"
	  required minlength="1"
      [valid]="!validate || form.get('cSquadTitle').valid"
    ></mb-text-field>
	  
	<mb-text-field
      formControlName="cSquadWeights"
      label="C-Squad Weights"
	  placeholder="Enter C-Squad weight classes"
	  required minlength="1"
      [valid]="!validate || form.get('cSquadWeights').valid"
    ></mb-text-field>

    <div class="form-group">
		  <button  *ngIf="!submitting"
			type="submit"
			class="btn btn-primary btn-lg">
			  Next
		  </button>
		  <mb-ellipsis-loader
			*ngIf="submitting"></mb-ellipsis-loader>
	   </div>
  </form>
</div>
