import { Component, OnInit } from '@angular/core'

import { NavService, NavItem } from '../services'

@Component({
  selector: 'mb-layout',
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit {

  public title: string

  constructor(
    private navService: NavService
  ) { }

  ngOnInit() {
    this.navService.current.subscribe(
      (n: NavItem) => { this.title = n.name }
    )
  }
}
