import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'mb-onboarding',
  templateUrl: './onboarding.component.html'
})
export class OnboardingComponent implements OnInit {

  public currentYear: number = new Date().getFullYear();

  constructor() { }

  ngOnInit() { }

}
