import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'

import { MBEvent } from '../models/mbevent'
import { MBEventService } from '../services/mbevent.service'
import { EVENT_LEVELS, EVENT_TYPES } from '../util/mbevent-options'
import { CustomValidators } from '../util/forms/custom-validators'
import { ConfirmComponent } from '../util/confirm'

@Component({
  selector: 'mb-event-edit',
  templateUrl: './event-edit.component.html'
})
export class EventEditComponent implements OnInit {
  @Input() event: MBEvent
  @Input() teamID: string
  @Input() levelID: string
  @Input() eventID: string

  public error: string
  public events: MBEvent[]
  public eventForm: FormGroup
  public eventLevels = EVENT_LEVELS
  public eventTypes = EVENT_TYPES
  public submitting = false
  public validate = false
  public loadingEvents = false
  private VALIDATION_PATTERN = /^[a-zA-Z0-9_ -.()]*$/
  public validationMessages = {
    required: 'This field is required',
    pattern: 'Please use numbers, letters, underscores, dashes only'
  }

  constructor(
    public activeModal: NgbActiveModal,
    private eventService: MBEventService,
    private fb: FormBuilder,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
    this.createForm()
  }

  public submit() {
    if (!this.eventForm.valid) {
      this.validate = true
      return
    }
    const data: any = {
      eventDate: this.eventDateValue,
      eventType: this.eventForm.value.eventType,
      eventName: this.eventForm.value.eventName,
      // twid: @todo
      // location: this.eventForm.value.location,
      // ourScore: this.eventForm.value.ourScore,
      // opponentScore: this.eventForm.value.opponentScore,
      // parentEventID: @todo
      official: this.eventForm.value.official,
      startingWeight: this.eventForm.value.startingWeight
    }

    const result: Observable<any> = this.event.ID ?
      this.eventService.editEvent(this.event.eventID, data).pipe(first()) :
      this.eventService.addEvent(
        this.teamID,
        data,
        this.eventForm.value.levelID
      ).pipe(first())

    const moveEvent: boolean = !!this.event.ID && (this.event.levelID !== this.eventForm.value.levelID)
	const mergeEvent: boolean = !!this.event.ID && (this.event.eventID !== this.eventForm.value.eventID)
    this.submitting = true
    this.error = null
    result.subscribe(
      (r: any) => {
        this.event.update(r.result)
        if (moveEvent) return this.moveEvent()
		if (mergeEvent) return this.mergeEvent()
        this.submitting = false
        this.activeModal.close({ success: true, result: this.event })
      },
      (e: any) => {
        this.submitting = false
        this.activeModal.close({ success: false, error: e })
      }
    )
  }

  private moveEvent(): void {
    this.event.ID
    this.event.levelID
    this.eventForm.value.levelID
    this.eventService.moveEvent(this.event, this.eventForm.value.levelID)
      .pipe(first())
      .subscribe(
        (r: any) => {
          this.submitting = false
          this.activeModal.close({ success: true, result: this.event })
        },
        (e: any) => {
          this.submitting = false
          this.activeModal.close({ success: false, error: e })
        }
      )
  }
	
  private mergeEvent(): void {
    this.event.ID
    this.event.levelID
    this.eventForm.value.levelID
    this.eventService.mergeEvent(this.event, this.eventForm.value.eventID)
      .pipe(first())
      .subscribe(
        (r: any) => {
          this.submitting = false
          this.activeModal.close({ success: true, result: this.event })
        },
        (e: any) => {
          this.submitting = false
          this.activeModal.close({ success: false, error: e })
        }
      )
  }

  public invalidField(field: string): boolean {
    return this.validate && this.eventForm.get(field).invalid
  }

  public confirmDelete(): void {
    const modalRef = this.modalService.open(ConfirmComponent, {  })
    modalRef.componentInstance.event = this.event
    modalRef.componentInstance.title = 'Confirm'
    modalRef.componentInstance.body = 'Are you sure you want to permanently delete this event?'
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.delete()
        } else {
          // ignore
        }
      },
      (rejectedValue: any) => {
        console.log('rejected', rejectedValue)
      }
    )
  }
	
  public loadEvents(): void {
	if (!this.event.levelID) return
    this.events = []
    this.loadingEvents = true
    if (!this.eventForm.value.levelID) return
	var eventIDParts = this.event.eventID.split(':')
	this.teamID = eventIDParts[0]
    this.eventService.findAll({
      teamID: this.teamID,
      levelID: this.eventForm.value.levelID,
      year: this.event.year,
    })
      .pipe(first())
      .subscribe((data: any) => {
        this.events = []
        data.result.forEach((r) => { this.events.push(new MBEvent(r)) })
        this.loadingEvents = false
      })
  }

  private delete(): void {
    this.submitting = true
    this.eventService.delete(this.event)
      .pipe(first())
      .subscribe((r: any) => {
        this.activeModal.close({
          success: true,
          result: null,
          deleted: true
        })
      }, (e: any) => {
        this.submitting = false
        this.setError('Oops something went wrong and we could not delete the event. Please try again later.')
      })
  }

  private createForm(): void {
    this.eventForm = this.fb.group({
      eventName: [this.event.eventName, [
        Validators.required,
        Validators.pattern(this.VALIDATION_PATTERN)
      ]],
      eventType: [
        this.event.eventType || 'Dual Meet',
        Validators.required
      ],
      eventDate: [this.getEventDate(), [
        Validators.required,
        CustomValidators.datepickerValidator({
          minDate: new Date(2014, 0, 1),
          maxDate: new Date(2032, 0, 1)
        })
      ]],
      official: [this.event.official, [
        Validators.pattern(this.VALIDATION_PATTERN)
      ]],
      startingWeight: [this.event.startingWeight, [
        Validators.pattern(this.VALIDATION_PATTERN)
      ]],
      levelID: [this.event.levelID || this.levelID],
	  eventID: [this.event.eventID || this.eventID]
    })
	this.loadEvents()
  }

  private getEventDate(): any {
    const d: string = this.event.eventDate || ''
    let pieces: string[] = d.split('-')
    if (pieces.length !== 3) pieces = ['', '', '']
    return {
      year: parseInt(pieces[0]) || null,
      month: parseInt(pieces[1]) || null,
      day: parseInt(pieces[2]) || null
    }
  }

  private get eventDateValue(): string {
    const v: any = this.eventForm.value.eventDate
    if (v && v.year && v.month && v.day) {
      const m: string = v.month >= 10 ? v.month.toString() : '0' + v.month
      const d: string = v.day >= 10 ? v.day.toString() : '0' + v.day
      return v.year + '-' + m + '-' + d
    }
    return null
  }

  private setError(msg: string): void {
    this.error = msg
    window.scroll(0, 0)
  }
}
