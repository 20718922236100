import { Component, HostListener, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { interval } from 'rxjs/internal/observable/interval'
import { first, startWith, switchMap } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'
import { FileUploader } from 'ng2-file-upload'

import * as S3 from 'aws-sdk/clients/s3'
import * as AWS from 'aws-sdk/global'

import { Doodle } from '../doodle'
import { MBMatchVideo, MBMatch, ScoringEvent, User } from '../../models'
import { periodOptions } from '../video-options'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { EditWtClassComponent } from '../edit-wt-class/edit-wt-class.component'
import { ScoringSymbolEditComponent } from '../scoring-symbol-edit/scoring-symbol-edit.component'
import { EditOurWrestlerComponent } from '../edit-our-wrestler/edit-our-wrestler.component'
import { EditOpponentWrestlerComponent } from '../edit-opponent-wrestler/edit-opponent-wrestler.component'
import { MBMatchService } from '../../services'
import { environment } from '../../../environments/environment'

const MAX_UPLOAD_MB = 1024

@Component({
  selector: 'mb-video-playback',
  templateUrl: './video-playback.component.html',
  styleUrls: ['./video-playback.scss']
})
export class VideoPlaybackComponent implements OnInit, OnDestroy {
  private _scrubber: HTMLElement
  private _canvasEl: HTMLCanvasElement
  private _videoEl: HTMLVideoElement
  private _matchVideo: MBMatchVideo
  private _doodle: Doodle
  private _videoProgress = 0
  private uploader: FileUploader
  public periodOptions = periodOptions
  public volume = true
  public hasDropZoneOver = false
  public uploadedFile: any

  public uploading = false
  public uploadPercent: number = 0

  private _timeinterval: Subscription
  public converting = false
  public conversionPercent: number = 0
  public conversionStatus: string = 'unknown'
  
  @Input() public set matchVideo(mv: MBMatchVideo) {
    if (!mv) return
    this._matchVideo = mv
    this.converting = this._matchVideo.match.isConverting === 1
    this.startMonitoringConversionStatus()
  }
  @Input() startTime?: number
  @Input() autoplay = false
  @Input() user: User
  @Input() public set doodle(d: Doodle) {
    if (!d) return
    this._doodle = d
    if (this._canvasEl) this._doodle.canvas = this._canvasEl
    if (this._videoEl) this.doodle.videoEl = this._videoEl
    this.doodle.parseSketchesString(this.match.doodle)
  }

  public get matchVideo(): MBMatchVideo { return this._matchVideo }
  public get doodle(): Doodle { return this._doodle }
  public get canvasEl(): HTMLCanvasElement { return this._canvasEl }
  public get videoEl(): HTMLVideoElement { return this._videoEl }
  public get match(): MBMatch { return this._matchVideo.match }
  public get progressAmount(): number {
    if (!this._scrubber) return 0
    return (this._videoProgress * this._scrubber.offsetWidth) - 5
  }

  @ViewChild('scrubber', {static: false}) public set scrubber(el: ElementRef) {
    if (!el) return
    this._scrubber = el.nativeElement as HTMLElement
  }
  @ViewChild('canvasElem', {static: false}) public set canvasElem(el: ElementRef) {
    if (!el) return
    this._canvasEl = el.nativeElement as HTMLCanvasElement
    if (this.doodle && this._canvasEl) this.doodle.canvas = this._canvasEl
  }
  @ViewChild('videoElem', {static: false}) public set videoElem(el: ElementRef) {
    if (!el) return
    this._videoEl = el.nativeElement as HTMLVideoElement
    if (this.doodle) this.doodle.videoEl = this._videoEl
    const self = this
    this._videoEl.addEventListener('timeupdate', () => {
      if (!self.videoEl || !self.videoEl.duration) return
      self._videoProgress = self.videoEl.currentTime / self.videoEl.duration
    })
    if (this.startTime) {
      const leadTime = this._matchVideo.leadTime || this._matchVideo.user.leadTime || 3
      let startTime = this.startTime - leadTime
      if (startTime <= 1) startTime = this.startTime
      this.videoEl.currentTime = startTime
    }
    if (this.autoplay) this.videoEl.play()
  }

  @HostListener('window:keypress', ['$event'])
  private keypressEvent(event: KeyboardEvent) {
    const t: HTMLElement = event.target as HTMLElement
    if (t.nodeName === 'INPUT' || t.nodeName === 'TEXTAREA') return

    // space bar
    if (event.charCode === 32) {
      event.stopPropagation() // prevent page scroll
      this.toggleVideoPlayback()
    }
    // N (normal speed)
    else if (event.charCode === 110) this.doodle.videoEl.playbackRate = 1
    // V (very fast)
    else if (event.charCode === 118) this.doodle.videoEl.playbackRate = 3
    // F (fast)
    else if (event.charCode === 102) this.doodle.videoEl.playbackRate = 2
    // S (slow)
    else if (event.charCode === 115) this.doodle.videoEl.playbackRate = 0.5
    // U (ultra-slow)
    else if (event.charCode === 117) this.doodle.videoEl.playbackRate = 0.25
  }

  @HostListener('window:keydown', ['$event'])
  private keydownEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowRight') {
      // @todo
    }
    else if (event.key === 'ArrowLeft') {
      // @todo
    }
  }

  constructor(
    private modalService: NgbModal,
    private matchService: MBMatchService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.createUploader()
    this.setupFullscreenListeners()
  }

  ngOnDestroy(): void {
    if ( this._timeinterval ) {
      this._timeinterval.unsubscribe()
    }
    this.doodle.active = false
  }

  public stopVideo(): void {
    // this is triggered by videoEl.onended event
  }

  public get playbackTime(): string {
    if (!this.videoEl || !this.videoEl.duration) return ''
    const currentSec = Math.round(this.videoEl.currentTime)
    const total = Math.round(this.videoEl.duration)
    const currMin = currentSec > 60 ? Math.floor(currentSec / 60) : 0
    const currSec = currentSec > 60 ? currentSec % 60 : currentSec
    const totMin = total > 60 ? Math.floor(total / 60) : 0
    const totSec = total > 60 ? total % 60 : total
    const curr = (currMin > 9 ? '' : '0') + currMin + ':' + (currSec > 9 ? '' : '0') + currSec
    const tot = (totMin > 9 ? '' : '0') + totMin + ':' + (totSec > 9 ? '' : '0') + totSec
    return curr + ' / ' + tot
  }

  public toggleVolume(): void {
    this.volume = !this.volume
    this.videoEl.volume = this.volume ? 1 : 0
  }

  public toggleFullscreen(): void {
    if (!this.isFullScreen()) this.enterFullScreen()
    else this.exitFullScreen()
  }

  private isFullScreen(): boolean {
    const doc = (document as any)
    return doc.fullscreenElement || doc.webkitFullscreenElement || doc.webkitCurrentFullScreenElement ||
      doc.mozFullScreenElement || doc.msFullscreenElement
  }

  private enterFullScreen(): void {
    // const el = document.getElementById('mb-video-playback')
    const el = this.videoEl
    const fns = ['requestFullscreen', 'webkitRequestFullscreen', 'webkitRequestFullScreen', 'mozRequestFullScreen', 'msRequestFullscreen']
    for (const k in fns) {
      if (!!el[fns[k]]) {
        el[fns[k]]()
        break
      }
    }
  }

  private exitFullScreen(): void {
    const fns = ['exitFullscreen', 'webkitExitFullscreen', 'webkitCancelFullScreen', 'mozCancelFullScreen', 'msExitFullscreen']
    for (const k in fns) {
      if (!!document[fns[k]]) {
        document[fns[k]]()
        break
      }
    }
  }

  public addNewScoringSymbol(): boolean {
    if (!this.user || this.user.isWrestler) return

    if (this.videoEl) this.videoEl.pause()
    const modalRef = this.modalService.open(ScoringSymbolEditComponent)
    modalRef.componentInstance.scoringEvent = new ScoringEvent({
      minutes: this.videoEl ? Math.floor((this.videoEl.currentTime || 0) / 60) + '' : '0',
      seconds: this.videoEl ? Math.round((this.videoEl.currentTime || 0) % 60) + '' : '0'
    })
    modalRef.componentInstance.account = this.user
    modalRef.result.then(
      (fulfilledValue: any) => {
        this.match.scoringEventsArray.push(fulfilledValue.result)
        // trigger reserialization
        this.match.scoringEventsArray = this.match.scoringEventsArray
        this.matchService.editMatch(this.match.matchID, {
          scoringEvents: this.match.scoringEvents
        })
        .pipe(first())
        .subscribe(
          (r: any) => {
            this.toastrService.success('Scoring Event Saved')
            this.match.parseMatch()
          },
          (e: any) => {
            this.toastrService.error('There was an error saving the Scoring Event')
            console.error('error', e)
          }
        )
      },
      (rejectedValue: any) => {
        console.warn('edit scoring symbol rejected', rejectedValue)
      }
    )
    // prevents default events from mouse event
    return false
  }

  public editScoringSymbol(scoringEvent: ScoringEvent, e: Event = null) {
    if (!this.user || this.user.isWrestler) return

    if (e) e.stopImmediatePropagation()
    const numSeconds = (parseInt(scoringEvent.minutes, 10) * 60) + parseInt(scoringEvent.seconds, 10)
    const leadTime = this._matchVideo.leadTime || this._matchVideo.user.leadTime || 3
    if (this.videoEl) {
      this.videoEl.currentTime = numSeconds - leadTime
      this.videoEl.pause()
    }

    const modalRef = this.modalService.open(ScoringSymbolEditComponent)
    modalRef.componentInstance.scoringEvent = scoringEvent
    modalRef.componentInstance.account = this.user
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          // trigger reserialization
          this.match.scoringEventsArray = fulfilledValue.result === 'delete' ?
            this.match.scoringEventsArray.filter((se) => se !== scoringEvent) :
            this.match.scoringEventsArray
          this.matchService.editMatch(this.match.matchID, {
            scoringEvents: this.match.scoringEvents
          })
          .pipe(first())
          .subscribe(
            (r: any) => {
              const msg: string = fulfilledValue.result === 'delete' ?
                'Scoring Event Deleted' :
                'Scoring Event Saved'
              this.toastrService.success(msg)
              this.match.parseMatch()
            },
            (err: any) => {
              this.toastrService.error('There was an error saving the Scoring Event')
              console.error('error', err)
            }
          )
        } else {
          this.toastrService.error('There was an error saving the Scoring Event')
          console.warn('edit scoring symbol failed', fulfilledValue.error)
        }
      }
    )
    // prevents default events from mouse event
    return false
  }

  public movePlayheadToSeconds(numSeconds: number, e: Event = null): void {
    if (e) e.stopImmediatePropagation()
    const leadTime = this._matchVideo.leadTime || this._matchVideo.user.leadTime || 3
    if (this.videoEl) {
      this.videoEl.currentTime = numSeconds - leadTime
      this.videoEl.play()
    }
  }

  public movePlayhead(se: ScoringEvent, e: Event = null): void {
    const numSeconds = (parseInt(se.minutes, 10) * 60) + parseInt(se.seconds, 10)
    this.movePlayheadToSeconds(numSeconds, e)
  }

  public editWeightClass(): boolean {
    if (!this.user || this.user.isWrestler) return

    const modalRef = this.modalService.open(EditWtClassComponent)
    modalRef.componentInstance.match = this.match
    return false
  }

  public editOurWrestler(e: Event = null): boolean {
    if (!this.user || this.user.isWrestler) return

    if (e) e.stopImmediatePropagation()
    const modalRef = this.modalService.open(EditOurWrestlerComponent)
    modalRef.componentInstance.match = this.match
    modalRef.componentInstance.wrestler = 'ours'
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.matchService.editOurWrestler(
            this.match,
            this.match.ourWrestlerID,
            this.match.ourWrestlerName
          )
          .pipe(first())
          .subscribe(
            (data: any) => { this.match.parseMatch() },
            (err: any) => { console.warn('error', err) }
          )
        } else {
          // @todo show alert error
          console.warn('edit wrestler failed', fulfilledValue.error)
        }
      },
      (rejectedValue: any) => {
        // @todo show alert error
        console.warn('edit wrestler failed', rejectedValue)
      }
    )
    // prevents default events from mouse event
    return false
  }

  public editOpponentWrestler(e: Event = null): boolean {
    if (!this.user || this.user.isWrestler) return

    if (e) e.stopImmediatePropagation()
    const modalRef = this.modalService.open(EditOpponentWrestlerComponent)
    modalRef.componentInstance.match = this.match
    modalRef.componentInstance.wrestler = 'ours'
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.matchService.editTheirWrestler(
            this.match,
            this.match.opponentName,
            this.match.opponentSchool
          )
          .pipe(first())
          .subscribe(
            (data: any) => { this.match.parseMatch() },
            (err: any) => { console.warn('error', err) }
          )
        } else {
          // @todo show alert error
          console.warn('edit wrestler failed', fulfilledValue.error)
        }
      },
      (rejectedValue: any) => {
        // @todo show alert error
        console.warn('edit wrestler failed', rejectedValue)
      }
    )
    // prevents default events from mouse event
    return false
  }

  public progressbarClick(e: MouseEvent): void {
    if (!this.videoEl) return

    const elem: HTMLElement = e.currentTarget as HTMLElement
    const bounds = elem.getBoundingClientRect()
    const pos = e.x || e.pageX || e.clientX
    const offset = pos - bounds.left
    const ratio: number = offset / (this._scrubber.offsetWidth || 1)
    this.videoEl.currentTime = Math.round(this.videoEl.duration * ratio)
  }

  public knobDragStart(e: MouseEvent): void {
    const elem: HTMLElement = e.currentTarget as HTMLElement
    const start = e.x || e.pageX || e.clientX
    const offset = elem.offsetLeft
    const self = this
    document.body.onmousemove = function(moveEvt) {
      const end = moveEvt.pageX || moveEvt.clientX
      let pos = (end - start) + offset
      if (pos < 0) pos = 0
      elem.style.left = pos + 'px'
    }
    document.body.onmouseup = function() {
      const ratio: number = elem.offsetLeft / (self._scrubber.offsetWidth || 1)
      self.videoEl.currentTime = Math.round(self.videoEl.duration * ratio)
      document.body.onmousemove = document.body.onmouseup = null
    }
  }

  public toggleVideoPlayback(): void {
    this.doodle.toggleVideoPlayback()
  }

  public get isPaused(): boolean {
    if (!this.videoEl) return true
    return this.videoEl.paused
  }

  public fileDropOver(over: boolean): void {
    this.hasDropZoneOver = over
  }

  public fileDropped(files: any[]): void {
    this.setFile(files)
  }

  public fileSelected(files: any[]): void {
    this.setFile(files)
  }

  public submitUpload(): void {
    //if (this.uploader.queue.length < 1) return
    //this.uploader.uploadItem(this.uploader.queue[0])
    if (!this.uploadedFile) return;
    this.uploading = true
    this.uploadPercent = 0
    this.uploadToS3(this.uploadedFile)
  }

  private setFile(files: any[]): void {
    const file = files && files[0] || null
    if (!file) return

    // if (file.type !== 'video/mp4' && file.type !== 'video/quicktime') {
    //   return this.setFileError('Please select an mp4 or mov video file')
    // }
    // if (file.size > (MAX_UPLOAD_MB * 1024 * 1024)) {
    //   return this.setFileError(`This file exceeds the maximum size of ${MAX_UPLOAD_MB} MB`)
    // }
    //if (this.uploader.queue.length > 1) this.uploader.queue = [this.uploader.queue.pop()]
    this.uploadedFile = file
  }

  private setFileError(msg: string): void {
    this.toastrService.error('Please select an mp4 or mov video file')
    this.uploadedFile = undefined
    //this.uploader.queue.pop()
  }

  public clearUploads(): void {
    this.uploadedFile = undefined
    //this.uploader.clearQueue()
  }

  public get formattedUploadProgress(): string {
    return `${this.uploadPercent.toFixed(2)}%`
  }
  public get formattedConversionProgress(): string {
    return `${this.conversionPercent.toFixed(2)}%`
  }
  public get formattedConversionStatus(): string {
    // TODO, convert terse status to more descriptive text
    return this.conversionStatus
  }

  private startMonitoringConversionStatus(): void {
    if ( this.converting ) {
      this._timeinterval = interval(1000)
      .pipe(
        startWith(0),
        switchMap(() => this.matchService.matchUploadStatus(this.match.matchID))
      ).subscribe((r: any) => {
        const response = r.body
        // console.log('polling response: ', response)
        if ( response.success && response.result ) {
          // this.converting = response.result.isConverting === 1
          this.conversionPercent = response.result.conversionPercent * 100
          this.conversionStatus = response.result.conversionStatus
          if ( !(this.conversionStatus === 'queued' || this.conversionStatus === 'converting') ) {
            this._timeinterval.unsubscribe()
          }
          if (this.conversionStatus === 'complete') {
            this.toastrService.success('The video has completed processing');
            window.location.reload()
          }
        }
      },
      (err: any) => {
        console.log('polling error: ', err)
      })
    }
  }

  private uploadToS3(file: any) {
    const contentType = file.type;
    const extension = contentType !== "video/mp4" ? "." + file.name.split(".").pop() : ".mp4";
    const matchYear = this.match.matchID.split(':')[1];
    const schoolName = this.user.schoolName.replace(/\s/g, "").toLowerCase();

    const ourWrestlerName = this.match.ourWrestlerName.split(",")[0].trim().toLowerCase().replace("-", "_");
    const oppWrestlerName = this.match.opponentName.split(",")[0].trim().toLowerCase().replace("-", "_");

    const videoFileName =
      this.getRandomInt(100000, 999999)
      + '_' + schoolName
      + '_' + ourWrestlerName
      + '_' + oppWrestlerName
      + '_' + new Date().toISOString().split('T')[0]
      + extension;

    let fileName =
      schoolName
      + '.' + this.user.state.toLowerCase()
      + '/' + matchYear
      + '/' + videoFileName;

    let bucketName = this.user.bucketName + (environment.env !== "production" ? "-stage" : "");
    fileName = bucketName + "/" + fileName;
    bucketName = "matboss-upload" + (environment.env !== "production" ? "-stage" : "")

    AWS.config.update({
      region: environment.awsRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: environment.awsIdentityPoolId,
      })
    });

    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      ContentType: contentType
    };

    const upload = new S3.ManagedUpload({
      params: params
    });

    upload.on('httpUploadProgress', (progress:any) => {
      this.uploadPercent = (progress.loaded * 100) / progress.total
    })

    upload.promise().then(
      (data: any) => {
        // console.log('Successfully uploaded the video', data);
        // update uploaded video infomation in database
        this.matchService.matchUploaded(this.match.matchID, {
          videoFileName: videoFileName,
        })
        .pipe(first())
        .subscribe(
          (r: any) => {

            if ( r.success && r.result ) {
              // pull the isConverting flag
              // console.log('match uploaded response: ', r)
              this.converting = r.result.isConverting === 1
              this.conversionPercent = r.result.conversionPercent * 100
              this.conversionStatus = r.result.conversionStatus

              this.uploading = false

              // start polling the upload status endpoint
              this.toastrService.success('The video was uploaded and is waiting to be processed');

              this.startMonitoringConversionStatus()
            }
          },
          (e: any) => {
            this.uploading = false;
            this.toastrService.error('There was an error updating the database')
            console.log(e);
          }
        )
        return true;
      },
      (err: any) => {
        this.uploading = false;
        console.log('Error uploading the video', err);
        this.toastrService.error('There was an error uploading the video')
        return false;
      },
    );
  }

  private getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  private createUploader(): void {
    this.uploader = new FileUploader({
      url: `${environment.apiUrl}matches/video/${encodeURIComponent(this.match.matchID)}`,
      itemAlias: 'videoFile',
      allowedMimeType: ['video/mp4', 'video/quicktime'],
      maxFileSize: 1024 * 1024 * MAX_UPLOAD_MB,
      method: 'post',
    })

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      console.error('error adding file field', item)
    }

    this.uploader.response.subscribe(res => {
      // console.log('Upload response', res)
    })

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      console.log('onSuccessItem', { item, response, status, headers })
      this.toastrService.success('The video was saved')
      window.location.reload()
    }

    this.uploader.onErrorItem = (item, response, status, headers) => {
      // console.log('onErrorItem', { item, response, status, headers })
      this.clearUploads()
      this.toastrService.error('There was a problem uploading the video. Please refresh the page and try again.')
      this.uploading = false
    }
  }

  private setupFullscreenListeners(): void {
    document.addEventListener('fullscreenchange', this.exitFsHandler.bind(this), false)
    document.addEventListener('mozfullscreenchange', this.exitFsHandler.bind(this), false)
    document.addEventListener('MSFullscreenChange', this.exitFsHandler.bind(this), false)
    document.addEventListener('webkitfullscreenchange', this.exitFsHandler.bind(this), false)
  }

  private exitFsHandler(): void {
    if (this.isFullScreen()) this.videoEl.controls = true
    else this.videoEl.controls = false
  }
}
