
export class UserToken {

    id!: number
    tokenable_type!: string
    tokenable_id!: number
    name!: string
    token!: string
    abilities!: string
    last_used_at: string|null = null
    created_at: string|null = null
    updated_at: string|null = null
  
    public constructor(data: any) {
      Object.assign(this, data)
    }
  
  }
  