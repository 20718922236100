<div class="modal-header">
  <h4 class="modal-title">Edit Weight Class</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <p>
    Enter weight class
  </p>

  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Weight Class</label>
          <input type="text"
            size="10"
            class="form-control"
            style="max-width: 120px;"
            formControlName="weightClass">
          <mb-invalid [form]="form"
            [validate]="validate"
            field="weightClass"
            message="A valid (alpha numeric) weight class is required"></mb-invalid>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer d-block">
  <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>
  <button type="button"
    class="btn btn-primary"
    (click)="submit()"
    *ngIf="!submitting">
    Save
  </button>
  <button type="button"
    class="btn"
    (click)="cancel()"
    *ngIf="!submitting">
    Cancel
  </button>
</div>
