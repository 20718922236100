<div class="mb-video-options">
  <div class="row no-gutters">
    <div class="col-6">
      <label
        for="playbackSpeedMenu"
        class="label">
        Playback speed:
      </label>
    </div>
    <div class="col-6">
      <select id="playbackSpeedMenu"
        (change)="changePlaybackSpeed($event.target.value)">
        <option *ngFor="let opt of playbackSpeedOptions"
          [value]="opt.value">{{ opt.label }}</option>
      </select>
    </div>
    <div class="col-6">
      <label for="leadTimeMenu"
        class="label">Lead time:</label>
    </div>
    <div class="col-6">
      <select id="leadTimeMenu"
        [(ngModel)]="matchVideo.leadTime">
        <option *ngFor="let opt of leadTimeOptions"
          [value]="opt.value">{{ opt.label }}</option>
      </select>
    </div>
    <div *ngIf="user.isMainContact" class="col-9">
      <label class="label d-inline-block">
        <input type="checkbox"
          (change)="toggleOverrideTeamPointsFlag()"
          [(ngModel)]="matchVideo.overrideTeamPoints">
        Override Team Points
      </label>
    </div>
    <div *ngIf="user.isMainContact" class="col-3">
      <input type="text"
        [(ngModel)]="matchVideo.teamPoints"
        [disabled]="!matchVideo.overrideTeamPoints"
        size="5">
    </div>
    <div *ngIf="user.isMainContact" class="col-9 text-right mt-2">
      <label class="label d-inline-block pr-2">
        Fall Time
      </label>
    </div>
    <div *ngIf="user.isMainContact" class="col-3 mt-2">
      <input type="text"
        [(ngModel)]="matchVideo.fallTime"
        placeholder="00:00"
        size="5">
    </div>
  </div><!-- .row -->
</div><!-- mb-video-option-controls -->
