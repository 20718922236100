import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "@angular/common/http";
export class HighlightsService {
    constructor(apiService, http) {
        this.apiService = apiService;
        this.http = http;
    }
    findAll(data) {
        let params = new HttpParams()
            .set('teamID', data.teamID)
            .set('year', data.year)
            .set('per_page', (data.per_page || 100).toString())
            .set('page', (data.page || 0).toString());
        return this.apiService.get('highlights', { params: params });
    }
    findArchived(data) {
        return this.apiService.get('highlightarchives', { params: data });
    }
    /**
     * add Highlight
     * required: matchID weightClass theDate wrestlerID description
     */
    addHighlight(data) {
        let params = data || {};
        return this.apiService.post('highlights', params);
    }
    /**
     * edit Highlight
     */
    editHighlight(highlightID, data) {
        let params = data || {};
        params.rowID = highlightID;
        return this.apiService.update('highlights', params);
    }
    /**
     * delete Highlight
     */
    deleteHighlight(highlightID, year) {
        return this.apiService.delete('highlights/' + encodeURIComponent(highlightID) + '?year=' + encodeURIComponent(year));
    }
    /**
     * "order" Highlights
     */
    orderHighlights(body) {
        return this.apiService.post('submitHighlightOrder', body);
    }
}
HighlightsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HighlightsService_Factory() { return new HighlightsService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.HttpClient)); }, token: HighlightsService, providedIn: "root" });
