import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil, first } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { environment } from "../../environments/environment";
import {
  MBEventService,
  NavService,
  UserService,
  NotificationService,
  ReportService,
} from "../services";
import {
  MBEvent,
  MBMatch,
  MBMatchVideo,
  User,
  Report,
  ReportParams,
} from "../models";
import { TwLoginComponent } from "../trackwrestling/tw-login/tw-login.component";

@Component({
  selector: "mb-dashboard",
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnInit, OnDestroy {
  public recentEvents: MBEvent[] = null;
  public upcomingEvents: MBEvent[] = null;
  public collapse: any = {};
  public user: User;
  public notificationsLoaded = false;
  public matchesLoaded = 0;
  public report1: Report;
  public report2: Report;
  public systemMessages: string[] = [];
  public wrestlerLogins = 0;
  public exporting: any = {};
  private unsubscribe: Subject<any> = new Subject();
  private teamID: string;
  private year: string = environment.currentYear;
  private thumbnails: any = {};

  constructor(
    private eventService: MBEventService,
    private modalService: NgbModal,
    private navService: NavService,
    private notificationService: NotificationService,
    private reportService: ReportService,
    private userService: UserService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.navService.setCurrentFromData({
      name: "dashboard",
    });
    this.initUser();
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public exportEvent(evt: MBEvent): void {
    this.exporting[evt.eventID] = true;
    const modalRef = this.modalService.open(TwLoginComponent, { size: "sm" });
    modalRef.componentInstance.event = evt;
    modalRef.componentInstance.user = this.user;
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.toastrService.success("The event has been exported");
        } else this.handleExportError(fulfilledValue);
      },
      (rejectedValue: any) => {
        if (
          rejectedValue === "Cross click" ||
          rejectedValue === 0 ||
          rejectedValue === 1
        )
          return;
        this.handleExportError(rejectedValue);
      }
    );
  }

  // @todo DRY this function into module
  private handleExportError(r: any): void {
    let msg: string = "";
    if (r && r.result) {
      if (r.result.error && r.result.error.message)
        msg = r.result.error.message;
      else if (r.result.message) msg = r.result.message;
      else if (r.result.statusText) msg = r.result.statusText;
    } else if (r && r.message) msg = r.message;

    this.toastrService.error("There was an error exporting the event: " + msg);
  }

  private initUser(): void {
    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        // console.log(user)
        this.user = user;
        this.teamID = user.teamID;
        if (!user.isPublicUser) {
          this.findEvents();
          this.findNotifications();
          this.findReports();
        }
      });
  }

  private findEvents(): void {
    let params = {
      teamID: this.teamID,
      year: this.year,
      perPage: 8,
    };

    this.eventService
      .findRecent(params)
      .pipe(first())
      .subscribe((data: any) => {
        this.recentEvents = [];
        if (data.result) {
          data.result.forEach((item) => {
            // collect recent events within the past 7 days
            const event: MBEvent = new MBEvent(item);
            const ed: Date = new Date(event.eventDate);
            const nd: Date = new Date();
            nd.setDate(nd.getDate() - 7);
            if (ed > nd) {
              this.findEventMatches(event);
              this.recentEvents.push(event);
            }
          });
        } else throw new Error("Invalid response");
      });
    this.eventService
      .findUpcoming(params)
      .pipe(first())
      .subscribe((data: any) => {
        this.upcomingEvents = [];
        if (data.result) {
          data.result.forEach((item) => {
            // collect upcoming events within the next 7 days
            const event: MBEvent = new MBEvent(item);
            const ed: Date = new Date(event.eventDate);
            const nd: Date = new Date();
            nd.setDate(nd.getDate() + 7);
            if (ed < nd) {
              this.upcomingEvents.push(event);
            }
          });
        } else throw new Error("Invalid response");
      });
  }

  public watch(m: MBMatch): string {
    let canWatch = this.user.canWatch(m);
    if (canWatch) {
      return `${environment.viewerUrl}#/match/${m.matchID}`;
    }
    return "#";
  }

  private findEventMatches(event: MBEvent): void {
    this.eventService
      .findMatches({
        teamID: this.teamID,
        year: event.year,
        levelID: "-1",
        eventID: event.eventID,
      })
      .pipe(first())
      .subscribe((data: any) => {
        event.matches = [];
        data.result.forEach((r) => {
          event.matches.push(new MBMatch(r));
        });
      });
  }

  private findNotifications(): void {
    if (!this.user.isMainContact) return;

    const self: DashboardComponent = this;
    this.notificationService
      .getNotifications(this.user.teamID)
      .pipe(first())
      .subscribe((data: any) => {
        const result: any = (data && data.result) || {};
        const sections: any[] = result.sections || [];

        const logins = sections.filter((s) => s.title === "Wrestler Logins");
        const matches = sections.filter((s) => s.title === "Matches Uploaded");
        const sysMsgs = sections.filter((s) => s.title === "System Messages");
        self.systemMessages = (sysMsgs[0] || {}).rows || [];
        self.wrestlerLogins = parseInt(((logins[0] || {}).rows || [])[0], 10);
        self.matchesLoaded = parseInt(((matches[0] || {}).rows || [])[0], 10);
        self.notificationsLoaded = true;
      });
  }

  private findReports(): void {
    const params: ReportParams = new ReportParams({
      teamID: this.user.teamID,
      levelID: 0,
      year: this.year,
    });
    const self: DashboardComponent = this;
    this.reportService
      .getReport("seasonteamanalysis", params)
      .pipe(first())
      .subscribe((data: any) => {
        self.report1 = new Report(data.result);
      });
    this.reportService
      .getReport("categoryleaders", params)
      .pipe(first())
      .subscribe(
        (data: any) => {
          self.report2 = new Report(data.result);
        },
        (err: any) => {
          self.report2 = null;
        }
      );
  }

  private setError(msg: string, obj?: any): void {}

  public thumbnailForMatch(match: MBMatch): string {
    if (!this.thumbnails[match.matchID]) {
      this.thumbnails[match.matchID] = new MBMatchVideo(
        match,
        this.user
      ).thumbnail;
    }
    return this.thumbnails[match.matchID];
  }

  public defaultThumbnail(event: Event): void {
    (event.target as HTMLImageElement).src = `./assets/Img/mb-video.png`;
  }
}
