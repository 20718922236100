import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'

import { ApiService } from './api.service'

@Injectable()
export class TeamPointAdjustmentService {
  url: string = 'teampointadjustment'

  constructor(public apiService: ApiService) { }

  public findTeamPointAdjustmentForEvent(eventID: string): Observable<any> {
    const params: any = { eventID: eventID}
    return this.apiService.get(this.url, { params: params })
  }

  public create(data: {
    eventID: string,
    forOrAgainst: number, // 0 = for, 1 = against
    type: string,
    teamPointDifference: number,
    matchWeight?: string
  }): Observable<any> {
    return this.apiService.post(this.url, data)
  }

  public delete(rowID: number): Observable<any> {
    console.log(rowID)
    return this.apiService.delete(this.url + '/' + rowID)
  }

  public update(rowID: number, data: {
    eventID: string,
    forOrAgainst?: number, // 0 = for, 1 = against
    type?: string,
    teamPointDifference?: number,
    matchWeight?: string
  }): Observable<any> {
    return this.apiService.update(this.url + '/' + rowID, data)
  }

}
