<mb-spinner *ngIf="!loaded"></mb-spinner>
<div *ngIf="loaded" class="row">
  <div class="col-6">
    <h6>Category Leaders</h6>
    <div><small>(Select Squad Level Above)</small></div>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange()"
        value="categoryleaderscumulative"
        class="form-check-input"
        id="cumul-cat">
      <label
        class="form-check-label"
        for="cumul-cat">Top 15 in Various Categories (All Years)</label>
    </div>
  </div>
  <div class="col-6">
    <h6>Individual Career Reports</h6>
    <div class="form-check my-1">
      <input
        type="radio"
        name="reportType"
        [(ngModel)]="reportType"
        (ngModelChange)="onReportTypeChange()"
        value="individualanalysiscareer"
        class="form-check-input"
        id="cumul-indi">
      <label
        class="form-check-label"
        for="cumul-indi">Individual Match Summary</label>
    </div>
    <div *ngIf="reportType === 'individualanalysiscareer'">
      <select
        [(ngModel)]="wrestlerID"
        (ngModelChange)="onWrestlerIDChange()"
        class="form-control">
        <option
          [ngValue]="undefined"
          disabled  selected> - Choose Wrestler - </option>
        <option
          *ngFor="let wrestler of wrestlers"
          [value]="wrestler.wrestlerID">{{ wrestler.fullName }}</option>
      </select>
    </div>
  </div>
</div>
