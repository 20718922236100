import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { MBEventLevel, EVENT_YEAR_OPTIONS, DEFAULT_TAGS } from '../util'
import { User, Tag } from '../models'
import { EventLevelsService } from '../services'

/**
 * provides a data model to contain the filter options
 * and the selected filters
 */
export class ScorebookFilters {
  public level: MBEventLevel
  public levelOptions: MBEventLevel[]
  public yearOptions: string[]
  private _move: string
  public year: string
  public timeRangeOptions = [
    { value: 'all', label: 'Show all events' },
    { value: 'upcoming', label: 'Show upcoming events' },
    { value: 'past', label: 'Show past events' }
  ]
  public timeRange = 'all'
  public sortOptions = [
    { value: 'asc', label: 'Sort Ascending' },
    { value: 'desc', label: 'Sort Descending' }
  ]
  public sort = 'asc'

  constructor(
    levelOptions: MBEventLevel[],
    public moveOptions: string[],
    initialParams: any,
    yearOptions?: string[]
  ) {
    if (initialParams.move) this.move = initialParams.move
    else this.move = null

    this.levelOptions = levelOptions
    if (initialParams && initialParams.levelID != undefined) {
      this.levelID = initialParams.levelID
    }
    else this.level = this.levelOptions[0]

    if (initialParams.filter != undefined) {
      this.timeRange = initialParams.filter;
    }
    else this.timeRange = 'all'

    if (initialParams.sortDir != undefined) {
      this.sort = initialParams.sortDir;
    }
    else this.sort = 'asc'

    this.yearOptions = yearOptions ? yearOptions : EVENT_YEAR_OPTIONS;
    if (yearOptions) {
      this.year = this.yearOptions[0]
    } else {  
      this.year = (initialParams && initialParams.year) ? initialParams.year : this.yearOptions[0]
    }
  }

  /**
   * getter for selected levelID
   */
  public get levelID(): string {
    return this.level.levelID
  }

  /**
   * set the selected MBEventLevel by levelID value
   */
  public set levelID(levelID: string) {
    for (let i=0; i<this.levelOptions.length; i++) {
      if (this.levelOptions[i].levelID === levelID) {
        this.level = this.levelOptions[i]
      }
    }
  }

  public get move(): string {
    return this._move || null
  }

  public set move(m: string) {
    this._move = m
  }
}

/**
 * Component
 */
@Component({
  selector: 'mb-scorebook-filters',
  templateUrl: './filters.component.html'
})
export class FiltersComponent implements OnInit {
  @Input() user: User
  @Input() params: any
  @Input() yearOptions?: string[]

  @Output()
  public submitted: EventEmitter<ScorebookFilters> = new EventEmitter<ScorebookFilters>()

  public loaded = false
  public filters: ScorebookFilters
  public moveOptions: string[]

  constructor(
    private eventLevelsService: EventLevelsService
  ) { }

  /**
   * sync initial filters value on init
   */
  public ngOnInit() {
    this.loadMoveOptions()
    this.eventLevelsService.current
      .subscribe(levels => {
        // const levelOptions = levels.filter(opt => opt.levelID !== '-1')
        this.filters = new ScorebookFilters(
          // levelOptions, 
          levels,
          this.moveOptions, 
          this.params,
          this.yearOptions)
        // @todo only submit if selected level changes
        this.submit()
        this.loaded = true
      })
  }

  private loadMoveOptions(): void {
    this.moveOptions = [
      ...DEFAULT_TAGS,
      ...this.user.neutralTags.map((t: Tag) => t.abbrev),
      ...this.user.bottomTags.map((t: Tag) => t.abbrev),
      ...this.user.topTags.map((t: Tag) => t.abbrev),
    ]
  }

  /**
   * emit the submitted event
   */
  public submit() {
    this.submitted.emit(this.filters)
  }
}
