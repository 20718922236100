<div class="container container--inner">
  <h3 class="mb-page__head">
    <span class="mb-page__head-title">
      <img src="./assets/SVG/Page/Messages.svg" alt="Icon">
      Library
    </span>
    <span class="mb-page__head-links">
      <a *ngIf="user && user.isMainContact"
        (click)="addItem()"
        class="mx-4 mb-page__head-link">Add a post</a>
    </span>
  </h3>

  <mb-spinner *ngIf="!loaded"
    label="Loading Library..."
    class="my-5 p-5 text-center"></mb-spinner>

  <div *ngIf="loaded">

    <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>
    <mb-alert *ngIf="success" [message]="success" (closed)="success = null"></mb-alert>

    <div *ngIf="!empty">
      <div class="row">
        <div class="col-md-4"
          *ngFor="let i of [1,3,0]">
          <div *ngIf="sections[i] && sections[i].items && sections[i].items.length"
            class="mb-report-summary">
            <h5 class="mb-report-summary__title">
              {{ sections[i].title }}
            </h5>
            <ul class="list-group">
              <li *ngFor="let item of sections[i].items"
                class="list-group-item d-flex justify-content-between">
                <a *ngIf="item.isExternal"
                  [href]="item.url"
                  class="mb-link"
                  target="_blank">
                  {{ item.description }}
                </a>
                <a *ngIf="!item.isExternal"
                  [href]="item.internalUrl(user)"
                  class="mb-link"
                  target="_blank">
                  {{ item.description }}
                </a>
                <a *ngIf="user && user.isMainContact" (click)="editItem(item)" class="mb-link">
                  <img src="./assets/SVG/UI/Edit.svg" alt="Edit" title="Edit" style="width: 24px;">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div><!-- row-->
      <div class="row mt-4" *ngIf="sections[2] && sections[2].items && sections[2].items.length">
        <div class="col-md-12">
          <div class="mb-report-summary">
            <h5 class="mb-report-summary__title">
              {{ sections[2].title }}
            </h5>
            <ul class="list-group">
              <li *ngFor="let item of sections[2].items" class="list-group-item d-flex justify-content-between">
                <a *ngIf="item.isExternal" [href]="item.url" class="mb-link" target="_blank">
                  {{ item.description }}
                </a>
                <a *ngIf="!item.isExternal" [href]="item.internalUrl(user)" class="mb-link" target="_blank">
                  {{ item.description }}
                </a>
                <a *ngIf="user && user.isMainContact" (click)="editItem(item)" class="mb-link">
                  <img src="./assets/SVG/UI/Edit.svg" alt="Edit" title="Edit" style="width: 24px;">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="empty">
      <p>There are no items in the Library yet.</p>
    </div>
  </div><!-- loaded -->
</div>
