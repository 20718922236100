<!-- STEP 2 -->
<mb-spinner *ngIf="!loaded" type="ripple-inverse"></mb-spinner>
<div *ngIf="loaded">
  <a (click)="goBack()" class="mb-link" style="color:gainsboro">&lt;&lt; Back</a>
  <h2 class="mb-heading">Step 2 of 3</h2>
  <p class="mb-text">
    Please review your TrackWrestling info below.
    If a change is needed, use the dropdowns to select the correct information.
  </p>

  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <mb-alert *ngIf="error"
      type="danger"
      [message]="error"
      (closed)="error = null"></mb-alert>

	<p class="mb-text">
      State
    </p>
	<div class="select-wrapper medium inline-block" style="margin-top:-20px;">
		<select id="stateDropdown" class="form-control" (change)="reloadSchools()">
			<option>Choose State</option>
		</select>
	</div>
	  
	<p class="mb-text" style="margin-top:20px;">
      Age Level
    </p>
	<div class="select-wrapper medium inline-block" style="margin-top:-20px;">
		<select id="ageDropdown" class="form-control" (change)="reloadSchools()">
			<option>Choose Age</option>
		</select>
	</div>
	
	<p class="mb-text" style="margin-top:20px;">
      School Name in TW
    </p>
	<div class="select-wrapper medium inline-block" style="margin-top:-20px;">
		<select id="schoolNameDropdown" class="form-control" formControlName="twSchoolName" >
			<option>Choose School</option>
		</select>
	</div>

	<p class="mb-text" style="margin-top:20px;">
      TrackWrestling Username (optional)
    </p>
    <mb-text-field
      formControlName="twUserName"
      style="margin-top:-25px;"
      [valid]="!validate || form.get('twUserName').valid"
    ></mb-text-field>

    <div class="form-group">
		  <button
			(click)="submitAnswer('no_school_found')"
			type="button"
			style="margin-bottom:20px; margin-top:20px;"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg">
			  My school name does not appear
		  </button>
		  <button
			(click)="submitAnswer('all_good')"
			type="button"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg">
			  The information is correct
		  </button>
		  <mb-ellipsis-loader
			*ngIf="submitting"></mb-ellipsis-loader>
	   </div>
  </form>
</div>
