<!-- STEP 2 -->
<mb-spinner *ngIf="!loaded" type="ripple-inverse"></mb-spinner>
<div *ngIf="loaded">
  <a (click)="goBack()" class="mb-link" style="color:gainsboro">&lt;&lt; Back</a>
  <h2 class="mb-heading">Welcome to MatBoss</h2>
  <p class="mb-text">
    Your account is now set up. Some of your account details are listed below.
  </p>
  <br>
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <mb-alert *ngIf="error"
      type="danger"
      [message]="error"
      (closed)="error = null"></mb-alert>
	<p class="mb-text" style="font-size: 20px;">
		Main contact: <span style="font-size: 20px; color: gainsboro">{{ this.account.contact }}</span>
  	</p>
	<p class="mb-text" style="font-size: 20px;">
		Main contact phone: <span style="font-size: 20px; color: gainsboro">{{ this.account.contactPhone }}</span>
  	</p>
	<p class="mb-text" style="font-size: 20px;">
		Main contact email: <span style="font-size: 20px; color: gainsboro">{{ this.account.contactEmail }}</span>
  	</p>
    <!-- <p class="mb-text" style="font-size: 20px;">
		Username: <span style="font-size: 20px; color: gainsboro">{{ this.account.userName }}</span>
  	</p> -->
	<p class="mb-text" style="font-size: 20px;">
		Secondary contact: <span style="font-size: 20px; color: gainsboro">{{ this.account.secondaryContact }}</span>
  	</p>
	<p class="mb-text" style="font-size: 20px;">
		Secondary contact phone: <span style="font-size: 20px; color: gainsboro">{{ this.account.secondaryContactPhone }}</span>
  	</p>
	<p class="mb-text" style="font-size: 20px;">
		Secondary contact email: <span style="font-size: 20px; color: gainsboro">{{ this.account.secondaryContactEmail }}</span>
  	</p>
	<p class="mb-text" style="font-size: 20px;">
    	School name: <span style="font-size: 20px; color: gainsboro">{{ this.account.schoolName }}</span>
  	</p>
	<!-- <p class="mb-text" style="font-size: 20px;">
    	Team code: <span style="font-size: 20px; color: gainsboro">{{ this.account.teamCode }}</span>
  	</p>
	<p class="mb-text" style="font-size: 14px; padding-left: 25px; margin-top: -10px;">
    	Note: Your wrestlers will need to know your team code when creating their accounts.
  	</p> -->
  </form>
  <br>
  <form [formGroup]="form">
	  <p class="mb-text">
		Click the button below to get started!
	  </p>
	  <div class="form-group">
		  <button
			(click)="submitAnswer('next')"
			type="button"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg">
			  Enter MatBoss
		  </button>
		  <mb-ellipsis-loader
			*ngIf="submitting"></mb-ellipsis-loader>
	   </div>
  </form>

</div>
