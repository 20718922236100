<!-- STEP 2 -->
<mb-spinner *ngIf="!loaded" type="ripple-inverse"></mb-spinner>
<div *ngIf="loaded">
  <a (click)="goBack()" class="mb-link"  style="color:gainsboro">&lt;&lt; Back</a>
  <h2 class="mb-heading">Step 2 of 3</h2>
  <p class="mb-text">
    Do you want to import that info?
  </p>
  <form [formGroup]="form">
	  <div class="form-group">
		  <button
			(click)="submitAnswer('yes')"
			type="button"
			style="margin-right:20px;"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg">
			  Yes, import now
		  </button>
		  <button
			(click)="submitAnswer('no')"
			type="button"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg">
			  Skip for now
		  </button>
		  <mb-ellipsis-loader
			*ngIf="submitting"></mb-ellipsis-loader>
	   </div>
  </form>

</div>
