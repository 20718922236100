/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../util/ellipsis-loader.ngfactory";
import * as i2 from "../util/ellipsis-loader";
import * as i3 from "./boxscore.component.ngfactory";
import * as i4 from "./boxscore.component";
import * as i5 from "../services/user.service";
import * as i6 from "../services/event-levels.service";
import * as i7 from "@angular/common";
import * as i8 from "./event-trashed.component";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
import * as i10 from "../services/mbevent.service";
var styles_EventTrashedComponent = [];
var RenderType_EventTrashedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EventTrashedComponent, data: {} });
export { RenderType_EventTrashedComponent as RenderType_EventTrashedComponent };
function View_EventTrashedComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-lg btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmRestore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Restore "]))], null, null); }
function View_EventTrashedComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-ellipsis-loader", [["style", "display: block; text-align: center;"]], null, null, null, i1.View_EllipsisLoaderComponent_0, i1.RenderType_EllipsisLoaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.EllipsisLoaderComponent, [], null, null)], null, null); }
export function View_EventTrashedComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "mb-event__head"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "mb-event-boxscore", [["class", "mb-event__boxscore"], ["style", "display: block"]], null, null, null, i3.View_BoxscoreComponent_0, i3.RenderType_BoxscoreComponent)), i0.ɵdid(2, 245760, null, 0, i4.BoxscoreComponent, [i5.UserService, i6.EventLevelsService], { event: [0, "event"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "mb-event__sync"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventTrashedComponent_1)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EventTrashedComponent_2)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "mb-event__matches"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "mb-matches__counter"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, [" ", " matches "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isRestoring; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.isRestoring; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.event.match_count; _ck(_v, 10, 0, currVal_3); }); }
export function View_EventTrashedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-event-trashed", [["class", "mb-event"]], null, null, null, View_EventTrashedComponent_0, RenderType_EventTrashedComponent)), i0.ɵdid(1, 114688, null, 0, i8.EventTrashedComponent, [i9.NgbModal, i10.MBEventService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventTrashedComponentNgFactory = i0.ɵccf("mb-event-trashed", i8.EventTrashedComponent, View_EventTrashedComponent_Host_0, { event: "event" }, { eventRestored: "eventRestored" }, []);
export { EventTrashedComponentNgFactory as EventTrashedComponentNgFactory };
