export class ScoringEvent {
  public minutes: string
  public seconds: string
  public period: string
  public symbol: string
  public color: string

  public constructor(data: any = {}) {
    Object.assign(this, data)
  }

  public get totalSeconds(): number {
    return (parseInt(this.minutes) * 60) + parseInt(this.seconds)
  }

  public get time(): string {
    let sec: string = this.seconds
    let min: string = this.minutes
    if (!min) min = '0'
    if (!sec) sec = '0'
    if (sec.length < 2) sec = '0' + sec
    if (min.length < 2) min = '0' + min
    return min + ':' + sec
  }

  public static serialize(evt: ScoringEvent): string {
    return [
      evt.minutes,
      evt.seconds,
      evt.period,
      evt.symbol,
      evt.color
    ].join('*')
  }

  public static deserialize(evt: string): ScoringEvent {
    const parts = evt.split('*')
    return new ScoringEvent({
      minutes: parts[0],
      seconds: parts[1],
      period: parts[2],
      symbol: parts[3],
      color: parts[4]
    })
  }
}
