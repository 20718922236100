<div class="mb-onboarding">
    <div class="container">
        <div class="row">
            <div class="mb-onboarding__logo">
                <img src="./assets/Img/GrayedMBLogo.png"
                    class="logo"
                    alt="Logo Alt Text">
            </div>
        </div>
    </div>

    <router-outlet></router-outlet>

    <!-- Begin Footer -->
    <footer class="mb-onboarding__footer" role="contentinfo">
        <p class="mb-onboarding__copyright">
            &copy; {{ currentYear }}. All rights reserved.
        </p>
    </footer>
</div>
