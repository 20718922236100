import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Subject } from 'rxjs'
import { first, takeUntil } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'

import { MBEvent, Wrestler, User, ScoringEvent, MBMatch } from '../models'
import { MBMatchService, RosterService, UserService } from '../services'
import { EVENT_LEVELS } from '../util/mbevent-options'

@Component({
  selector: 'mb-match-add',
  templateUrl: './match-add.component.html',
  styleUrls: ['./match-add.component.scss']
})
export class MatchAddComponent implements OnInit {

  @Input() teamID: string
  @Input() levelID: string
  @Input() event: MBEvent

  public error: string
  public events: MBEvent[]
  public form: FormGroup
  public submitting = false
  public loadingEvents = false
  public eventLevels = EVENT_LEVELS
  public validate = false
  public wrestlers: Wrestler[]
  public ourRed = true
  public user: User
  private unsubscribe: Subject<any> = new Subject()

  constructor(
    public activeModal: NgbActiveModal,
    private matchService: MBMatchService,
    private fb: FormBuilder,
    private rosterService: RosterService,
    private toastrService: ToastrService,
	private userService: UserService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.createForm()
    this.findRoster()
    this.initUser()
  }

  public submit(): void {
    if (!this.form.valid) {
      this.toastrService.warning('Please be sure all required fields are complete')
      this.validate = true
      return
    }

    const dt = this.form.value.theDate
    const matchDate = dt.year + '-' + this.lpad(dt.month, '0', 2) + '-' + this.lpad(dt.day, '0', 2)

    const ourWrestlerID = this.form.value.ourWrestlerID
    const ourWrestler = this.wrestlers.find(w => w.wrestlerID === ourWrestlerID)
    var temp = this.form.value.opponent
    if (temp == null || temp == '') {
      temp = 'Doe, John (Unknown)'
    }
    var theirWrestler = temp.trim()
    // need this just in case they entered a bunch of spaces only
    if (theirWrestler == '') {
        theirWrestler = 'Doe, John (Unknown)'
      }
      var theirWrestlerParts = theirWrestler.split(" (")
      var theirWrestlerName = ''
      var theirSchoolName = ''
      if (theirWrestlerParts.length < 2) {
        theirWrestlerName = theirWrestler
        theirSchoolName = 'Unknown'
      } else {
        theirWrestlerName = theirWrestlerParts[0]
        theirSchoolName = theirWrestlerParts[1].substring(0, theirWrestlerParts[1].length - 1)
      }
    if (theirWrestlerName.includes(",")) {
      // Do nothing, name is in correct format
    } else {
      var firstName = '';
      var lastName = '';
      var theirNameParts = theirWrestlerName.split(" ")
      if (theirNameParts.length > 1) {
        firstName = theirWrestlerName.substr(0,theirWrestlerName.indexOf(' ')).trim()
        lastName = theirWrestlerName.substr(theirWrestlerName.indexOf(' ')+1).trim()
        theirWrestlerName = lastName + ", " + firstName
      }
    }

    // normalize hand-entered "forfeit"
    if ( theirWrestlerName.toLowerCase() === 'forfiet' 
      || theirWrestlerName.toLowerCase() === 'forfeit' ) {
      theirWrestlerName = 'Forfeit'
    }

    const ourSchoolName = this.user.schoolName

    let redWrestler: string
    let greenWrestler: string

    let containsForfeit = false
    let forfeitColor: string = ''
    if ( this.containsForfeit(ourWrestler.lastName) || this.containsForfeit(ourWrestler.lastName) ) {
      containsForfeit = true
      forfeitColor = this.ourRed ? 'Red' : 'Green'
    }
    else if ( this.containsForfeit(theirWrestlerName) ) {
      containsForfeit = true
      forfeitColor = this.ourRed ? 'Green' : 'Red'
    }

    // @todo what do the nulls mean?
    const ourWrestlerString = `${this.form.value.ourWrestlerID}#${ourWrestler.lastName}, ${ourWrestler.firstName}#(null)#${ourSchoolName}#(null)`
    const theirWrestlerString = `(null)#${theirWrestlerName}#(null)#${theirSchoolName}#(null)#`
    if (this.ourRed) {
      redWrestler = ourWrestlerString
      greenWrestler = theirWrestlerString
    }
    else {
      greenWrestler = ourWrestlerString
      redWrestler = theirWrestlerString
    }

    const data = {
      ourWrestlerID,
      redWrestler,
      greenWrestler,
      eventID: this.event.eventID,
      theWeightClass: this.form.value.theWeightClass,
      theDate: matchDate,
    }
    this.matchService.create(data).pipe(first())
      .subscribe((data: any) => {
        if (data.success) {

          if ( containsForfeit ) {
            let match: MBMatch = new MBMatch(data.result)
            // if forfeit, add a forfeit scoring event
            let scoringEvent: ScoringEvent = new ScoringEvent({
              seconds: '00',
              minutes: '00',
              period: '0',
              symbol: 'Forfeit',
              color: forfeitColor
            })
            match.scoringEventsArray.push(scoringEvent)
            // trigger reserialization
            match.scoringEventsArray = match.scoringEventsArray
            this.matchService.editMatch(match.matchID, {
              scoringEvents: match.scoringEvents
            })
            .pipe(first())
            .subscribe(
              (r: any) => {
                this.toastrService.success('Match saved')
                this.activeModal.close({
                  success: true,
                  result: data.result,
                  deleted: false
                })
              },
              (e: any) => {
                this.toastrService.error('There was an error saving the forfeit scoring event')
                console.error('error', e)
              }
            )
          }
          else
          {
            this.toastrService.success('Match saved')
            this.activeModal.close({
              success: true,
              result: data.result,
              deleted: false
            })
          }
        }
        else {
          this.toastrService.error('There was an error saving the match')
        }
      })
  }

  private initUser(): void {
    this.userService.current
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => {
        this.user = user
      })
  }

  public switchColors(): boolean {
    this.ourRed = !this.ourRed
    return false
  }

  private findRoster(): void {
    this.rosterService.getRoster(this.teamID, {
      status: 'current',
      // year: this.event.year,
      // perPage: 9999
    })
      .pipe(first())
      .subscribe((data: any) => {
        this.wrestlers = []
        data.result.forEach(item => {
          this.wrestlers.push(new Wrestler(item))
        })
      },
      error => {
        // @todo
        this.wrestlers = []
      })
  }

  private containsForfeit(val:string):boolean {
    let s = ''
    if ( val ) {
      s = val.toLowerCase()
    }
    return ( s.indexOf('forfeit') > -1 || s.indexOf('forfiet') > -1 )
  }

  private createForm(): void {
    const dtStr = this.event.eventDate ? this.event.eventDate.split('-') : null
    const dt = dtStr ? {
      year: parseInt(dtStr[0], 10),
      month: parseInt(dtStr[1], 10),
      day: parseInt(dtStr[2], 10)
    } : dtStr
    this.form = this.fb.group({
      theDate: [dt, Validators.required],
      theWeightClass: [null, Validators.required],
      ourWrestlerID: [null, Validators.required],
      opponent: [null],
    })
  }

  private setError(msg: string): void {
    this.error = msg
    window.scroll(0, 0)
  }

  private lpad(_str: string | number, padString: string, length: number): string {
    let str = _str + ''
    while (str.length < length) str = padString + str
    return str
  }
}
