/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./spinner";
var styles_SpinnerComponent = [];
var RenderType_SpinnerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SpinnerComponent, data: {} });
export { RenderType_SpinnerComponent as RenderType_SpinnerComponent };
function View_SpinnerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "loading"], ["src", "./assets/loaders/spinner-gray.gif"]], null, null, null, null, null))], null, null); }
function View_SpinnerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "mb-ripple"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "inverse": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "mb-ripple"; var currVal_1 = _ck(_v, 3, 0, _co.isInverse); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SpinnerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [["class", "text-muted mt-5"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
export function View_SpinnerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSpinner; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isRipple; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.label; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_SpinnerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-spinner", [["style", "display: block; text-align: center;"]], null, null, null, View_SpinnerComponent_0, RenderType_SpinnerComponent)), i0.ɵdid(1, 49152, null, 0, i2.SpinnerComponent, [], null, null)], null, null); }
var SpinnerComponentNgFactory = i0.ɵccf("mb-spinner", i2.SpinnerComponent, View_SpinnerComponent_Host_0, { label: "label", type: "type" }, {}, []);
export { SpinnerComponentNgFactory as SpinnerComponentNgFactory };
