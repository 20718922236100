<!-- STEP 5 -->
<mb-spinner *ngIf="!loaded" type="ripple-inverse"></mb-spinner>
<div *ngIf="loaded">
  <a (click)="goBack()" class="mb-link" style="color:gainsboro">&lt;&lt; Back</a>
  <h2 class="mb-heading">Step 3 of 3</h2>
  <p class="mb-text" style="font-size: 20px">
    App/device preparation: At the beginning of each season, you MUST disconnect/sign out of your account inside the MatBoss app and then sign back in again. This will generate new scorebooks for this season and clear your device's memory. Failure to do this will create problems. This needs to be done on EACH of your devices. Have you performed this task on each of your tablets?
  </p>
  <form [formGroup]="form">
	  <div class="form-group">
		  <button
			(click)="submitAnswer('yes')"
			type="button"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg"
			style="margin-right: 20px">
			  Yes
		  </button>
		  <button
			(click)="submitAnswer('no')"
			type="button"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg">
			  No
		  </button>
		  <mb-ellipsis-loader
			*ngIf="submitting"></mb-ellipsis-loader>
	   </div>
  </form>
</div>
