<div class="container container--inner">
  <h3 class="mb-page__head">
    <span class="mb-page__head-title">
      <img src="./assets/SVG/Page/Roster.svg" alt="Icon" />
      Scorebook
    </span>
    <span *ngIf="user && user.isMainContact" class="mb-page__head-links">
      <a
        *ngIf="loaded && event.isDualMeet && matches.length > 0"
        (click)="adjustTeamPoint()"
        class="mx-4 mb-page__head-link"
        >Adjust Team Point</a
      >
      <a (click)="addMatch()" class="mx-4 mb-page__head-link">Add a match</a>
    </span>
  </h3>

  <div class="mb-breadcrumbs p-3">
    <a preserveQueryParams routerLink="/scorebook">‹ Back</a>
  </div>

  <mb-alert
    *ngIf="error"
    type="danger"
    [message]="error"
    (closed)="error = null"
  ></mb-alert>
  <mb-alert
    *ngIf="success"
    [message]="success"
    (closed)="success = null"
  ></mb-alert>

  <mb-spinner
    *ngIf="!loaded"
    label="Loading Event..."
    class="my-5 p-5 text-center"
  ></mb-spinner>

  <div *ngIf="loaded" class="mb-scorebook__content">
    <div class="mb-event__head">
      <mb-event-boxscore [event]="event"></mb-event-boxscore>

      <div *ngIf="user.isMainContact" class="mb-event__edit">
        <a (click)="edit()">
          <img src="./assets/SVG/UI/Edit.svg" alt="Edit" />
          Edit
        </a>
      </div>
      <div *ngIf="user.isMainContact" class="mb-event__sync">
        <a (click)="export()">
          <img src="./assets/SVG/UI/Sync.svg" alt="Sync" />
          Export to<br />Trackwrestling
        </a>
      </div>
    </div>

    <div
      *ngIf="
        event.isDualMeet &&
        teamPointAdjustments &&
        teamPointAdjustments.length > 0
      "
    >
      <div
        class="team-point-adjustment"
        *ngFor="let teamPoint of teamPointAdjustments"
      >
        <p *ngIf="teamPoint.type == 'Unsportsmanlike Conduct'">
          {{ teamPoint.forOrAgainst ? "The opposing team" : "Our team" }} lost a
          team point for Unsportsmanlike Conduct during the
          {{ teamPoint.matchWeight }} match.
        </p>
        <p *ngIf="teamPoint.type == 'Win By Criteria'">
          {{ teamPoint.forOrAgainst ? "The opposing team" : "Our team" }} was
          awarded a team point for a Win By Criteria.
        </p>
        <button
          class="btn btn-sm btn-outline-dark"
          (click)="editTeamPoint(teamPoint)"
        >
          Edit
        </button>
      </div>
    </div>

    <div class="mb-matches">
      <div class="mb-matches__counter">{{ matches.length }} matches</div>
      <div class="row">
        <div class="col-md-3 mb-match-container" *ngFor="let match of matches">
          <div class="mb-match">
            <a [attr.href]="watch(match)">
              <img
                class="mb-match__thumbnail"
                [src]="thumbnailForMatch(match)"
                (error)="defaultThumbnail($event)"
                alt="video thumbnail"
              />
              <div class="mb-match__weight">
                {{ match.theWeightClass }}
              </div>
              <div class="mb-match__boxscore">
                <div class="mb-match__boxscore-row">
                  <div
                    class="mb-match__boxscore-wrestler"
                    [ngClass]="{
                      'font-weight-bold': match.ourWin,
                      'mb-wrestler--red': match.ourRed,
                      'mb-wrestler--green': match.ourGreen
                    }"
                  >
                    {{ match.ourWrestlerName }}
                  </div>
                </div>
                <div class="mb-match__boxscore-row">
                  <div
                    class="mb-match__boxscore-wrestler"
                    [ngClass]="{
                      'font-weight-bold': !match.ourWin,
                      'mb-wrestler--red': !match.ourRed,
                      'mb-wrestler--green': !match.ourGreen
                    }"
                  >
                    {{ match.opponentName }}
                    <div class="mb-match__boxscore-wrestler-school">
                      {{ match.opponentSchool }}
                    </div>
                  </div>
                </div>
                <div class="mb-match__boxscore-result">
                  {{ match.ourWin }} {{ match.theResult }}
                  <span *ngIf="match.isExhibition">(Exhibition)</span>
                </div>
              </div>
            </a>
            <div *ngIf="user.isMainContact" class="mb-match__edit">
              <a (click)="editMatch(match)">
                <img src="./assets/SVG/UI/Edit.svg" alt="Edit" />
                Edit
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <a routerLink="/scorebook" class="btn btn-lg btn-outline-dark btn-full">
          Back to the scorebook
        </a>
      </div>
    </div>
  </div>
</div>
