<div class="mb-video-highlights">
  <form [formGroup]="form"
    (ngSubmit)="submit()"
    novalidate>
    <p class="mb-text">
      To mark this video for use in highlights,
      follow the numbered steps below.
    </p>
    <div class="form-group">
      <label>1. Description (Name/Opponent)</label>
      <input
        type="text"
        class="form-control"
        placeholder="J. Garcia vs Doe (Lincoln)"
        formControlName="description">
      <mb-invalid
        [form]="form"
        [validate]="validate"
        field="description"></mb-invalid>
    </div>
    <div class="form-group">
      <label>2. Move</label>
      <input
        type="text"
        class="form-control"
        placeholder="Enter name of move"
        formControlName="move">
      <mb-invalid
        [form]="form"
        [validate]="validate"
        field="move"></mb-invalid>
    </div>
    <div class="form-group">
      <label>3. Start/Stop Points</label>
      <div class="d-flex">
        <input size="5"
          type="text"
          class="form-control mr-1"
          placeholder="00:00"
          maxlength="5"
          formControlName="start">
        <input size="5"
          type="text"
          class="form-control"
          placeholder="00:00"
          maxlength="5"
          formControlName="stop">
      </div>
      <div class="d-flex">
        <mb-invalid
          [form]="form"
          [validate]="validate"
          field="start"></mb-invalid>
        <mb-invalid
          [form]="form"
          [validate]="validate"
          field="stop"></mb-invalid>
      </div>
    </div>
    <div class="form-group">
      <input type="submit"
        class="btn btn-full btn-primary"
        value="Save Highlight">
      <mb-ellipsis-loader
        *ngIf="submitting"></mb-ellipsis-loader>
    </div>
  </form>
</div><!-- highlights -->
