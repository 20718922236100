import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'mb-custom-reports',
  templateUrl: './custom-reports.component.html'
})
export class CustomReportsComponent implements OnInit {
  public selectedOptions: any = {}
  public error: string

  @Input()
  public set catIndexArray(s: string[]) {
    this.selectedOptions = {}
    if (s && s.length > 0) {
      s.forEach(k => this.selectedOptions[k] = true)
    }
  }
  @Output() catIndexArrayChange = new EventEmitter()

  public options: {label: string, value: string}[][] = [
    [
      {label: 'Win-Loss', value: '3'},
      {label: 'Major Decisions', value: '2'},
      {label: 'Tech Falls', value: '1'},
      {label: 'Falls', value: '0'},
      {label: 'Fall Percentage', value: '24'},
      {label: '6-Pt Wins', value: '25'},
      {label: '6-Pt Percentage', value: '26'},
    ],
    [
      {label: 'Bonus Point Percentage', value: '27'},
      {label: 'Team Points', value: '4'},
      {label: 'First Points Scored', value: '5'},
      {label: 'Takedowns', value: '6'},
      {label: 'Escapes', value: '7'},
      {label: 'Reversals', value: '8'},
    ],
    [
      {label: 'Near Fall 2', value: '9'},
      {label: 'Near Fall 3', value: '10'},
      {label: 'Near Fall 4', value: '11'},
      {label: 'Total Near Fall', value: '28'},
      {label: 'Cautions', value: '12'},
      {label: 'Penalty Points', value: '13'},
    ],
    [
      {label: 'Stalling Warnings', value: '14'},
      {label: 'Stalling Points', value: '15'},
      {label: 'Period 1 Points', value: '17'},
      {label: 'Period 2 Points', value: '18'},
      {label: 'Period 3 Points', value: '19'},
      {label: 'Overtime Points', value: '29'},
      {label: 'Total Match Points', value: '16'},
    ]
  ]

  constructor() { }

  ngOnInit() { }

  public onOptionChange(): void {
    const selected: string[] = Object.keys(this.selectedOptions)
      .filter(k => k && this.selectedOptions[k])
    if (selected.length > 7) {
      this.error = "Too many options selected. Please choose up to 7 categories."
    } else {
      this.error = null
    }
    this.catIndexArrayChange.emit(selected)
  }
}
