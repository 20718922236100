import { Component, OnInit, Input } from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs/operators";

import { MBEvent, User, TwEvent } from "../../models";
import { TwService, MBEventService } from "../../services";
import { states } from "../../util/states";

@Component({
  selector: "mb-tw-login",
  templateUrl: "./tw-login.component.html",
})
export class TwLoginComponent implements OnInit {
  @Input() event: MBEvent;
  @Input() user: User;

  public form: FormGroup;
  public validate = false;
  public submitting = false;
  public error: string;
  public states: string[] = states;
  public twEvents: TwEvent[];
  public loadingTwEvents = false;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public twService: TwService,
    public mbEventService: MBEventService
  ) {}

  ngOnInit() {
    this.createForm();
    this.findEvents();
  }

  public submit(): void {
    this.error = null;
    if (!this.form.valid) {
      this.validate = true;
      return;
    }
    this.submitting = true;
    this.mbEventService
      .editEvent(this.event.eventID, {
        twid: this.form.value.twid,
        location: this.form.value.location,
      })
      .pipe(first())
      .subscribe(
        (r: any) => {
          if (!r || !r.success) return this.handleError(r);
          this.event.twid = this.form.value.twid;
          this.event.location = this.form.value.location;
          this.submitExport();
        },
        (err: any) => {
          this.handleError(err);
          this.submitting = false;
        }
      );
  }

  private submitExport(): void {
    this.submitting = true;
    this.twService
      .export(
        this.event,
        "username",
        "password",
        this.form.value.opponentState,
        this.form.value.exportExhibitions
      )
      .pipe(first())
      .subscribe(
        (r: any) => {
          if (!r || !r.success) return this.handleError(r);
          this.activeModal.close({
            success: true,
            result: r,
          });
        },
        (err: any) => {
          this.handleError(err);
        }
      );
  }

  private handleError(r: any): void {
    let msg: string = "";
    if (r && r.error && r.error.message)
      msg = r.error.message.split("<br>")[0] || r.error.message || "Unknown";
    else if (r && r.result) {
      if (r.result.error && r.result.error.message)
        msg =
          r.result.error.message.split("<br>")[0] ||
          r.result.error.message ||
          "Unknown";
      else if (r.result.message) msg = r.result.message;
      else if (r.result.statusText) msg = r.result.statusText;
    } else if (r && r.message) msg = r.message;
    this.setError("Export failed: " + msg);
    // this.activeModal.close({
    //   success: false,
    //   result: r
    // })
  }

  private createForm(): void {
    this.form = this.fb.group({
      location: ["", Validators.required],
      exportExhibitions: [true],
    });
    if (this.event.isDualMeet) {
      this.form.addControl(
        "opponentState",
        new FormControl("", Validators.required)
      );
    }
    this.form.addControl("twid", new FormControl("", Validators.required));
  }

  private setError(e: string): void {
    this.error = e;
    this.submitting = false;
  }

  /**
   * find events from tw
   */
  private findEvents(): void {
    this.loadingTwEvents = true;
    this.twService
      .getSchedule(this.event.teamID)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.twEvents = [];
          if (data.success && data.result) {
            data.result.forEach((item) => {
              const evt = new TwEvent(item.event);
              this.twEvents.push(evt);
            });
          }
          this.loadingTwEvents = false;
        },
        (err) => {
          this.setError(err.message);
          this.loadingTwEvents = false;
        }
      );
  }
}
