import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class LibraryService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    findAll(data) {
        let params = new HttpParams()
            .set('teamID', data.teamID)
            .set('per_page', (data.per_page || 100).toString())
            .set('page', (data.page || 0).toString());
        return this.apiService.get('library', { params: params });
    }
    addItem(data) {
        return this.apiService.post('library', data);
    }
    editItem(id, data) {
        data.rowID = id;
        return this.apiService.update('library', data);
    }
    deleteItem(id) {
        return this.apiService.delete('library?rowID=' + encodeURIComponent(id));
    }
}
LibraryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LibraryService_Factory() { return new LibraryService(i0.ɵɵinject(i1.ApiService)); }, token: LibraryService, providedIn: "root" });
