<div class="container">
  <div class="media">
    <img src="./assets/SVG/UI/Alert.svg"
      alt="Alert"
      class="mr-3 d-none d-md-block"
      style="width: 100px;">

    <div class="media-body">
      <ng-container *ngFor="let message of messages">
        <h4 [innerHtml]="message"></h4>
      </ng-container>
    </div>
  </div>
</div>
