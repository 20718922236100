/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "../util/alert.ngfactory";
import * as i4 from "../util/alert";
import * as i5 from "./custom-reports.component";
var styles_CustomReportsComponent = [];
var RenderType_CustomReportsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomReportsComponent, data: {} });
export { RenderType_CustomReportsComponent as RenderType_CustomReportsComponent };
function View_CustomReportsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "form-check my-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "input", [["class", "form-check-input"], ["type", "checkbox"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.selectedOptions[_v.context.$implicit.value] = $event) !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = (_co.onOptionChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.CheckboxControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.CheckboxControlValueAccessor]), i0.ɵdid(4, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(6, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "label", [["class", "form-check-label"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.selectedOptions[_v.context.$implicit.value]; _ck(_v, 4, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "cust-", _v.context.$implicit.value, ""); var currVal_1 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 6).ngClassValid; var currVal_6 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i0.ɵinlineInterpolate(1, "cust-", _v.context.$implicit.value, ""); _ck(_v, 7, 0, currVal_9); var currVal_10 = _v.context.$implicit.label; _ck(_v, 8, 0, currVal_10); }); }
function View_CustomReportsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomReportsComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CustomReportsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-alert", [["type", "danger"]], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = ((_co.error = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertComponent_0, i3.RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i4.AlertComponent, [], { message: [0, "message"], type: [1, "type"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; var currVal_1 = "danger"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CustomReportsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h6", [["class", "my-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Choose up to seven (7) categories below. Report will print in columns, sorted by wrestler."])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomReportsComponent_1)), i0.ɵdid(4, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomReportsComponent_3)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.error; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_CustomReportsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mb-custom-reports", [], null, null, null, View_CustomReportsComponent_0, RenderType_CustomReportsComponent)), i0.ɵdid(1, 114688, null, 0, i5.CustomReportsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomReportsComponentNgFactory = i0.ɵccf("mb-custom-reports", i5.CustomReportsComponent, View_CustomReportsComponent_Host_0, { catIndexArray: "catIndexArray" }, { catIndexArrayChange: "catIndexArrayChange" }, []);
export { CustomReportsComponentNgFactory as CustomReportsComponentNgFactory };
