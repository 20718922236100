import { Injectable } from '@angular/core'
import { HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'

import { ApiService } from './api.service'

@Injectable()
export class AdministrativeService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * emailSomeone
   * @param emailAddress
   * @param message
   * @param subject
   */
  public emailSomeone(theEmailAddress: string, theMessage: string, theSubject: string): Observable<any> {
    return this.apiService.post('emailsomeone', {
      emailAddress: theEmailAddress,
      message: theMessage,
      subject: theSubject
    })
  }

  /**
   * setwizardcompleted
   * @param emailAddress
   */
  public setwizardcompleted(theEmailAddress: string): Observable<any> {
    return this.apiService.post('setstartupwizardcompletedinhubspot', {
      emailAddress: theEmailAddress
    })
  }

  /**
   * @todo is this Needed?
   * create HttpParams from js object
   * @param data
   */
  private makeParams(data: any): HttpParams {
    let params = new HttpParams()
    for (const k of data) {
      params = params.set(k, data[k])
    }
    return params
  }

  private transform(data: any): any {
    data = data || {}
    if (data.firstName) data.firstName = this.transformName(data.firstName)
    if (data.lastName) data.lastName = this.transformName(data.lastName)
    return data
  }

  private transformName(name: string): string {
    return name.replace("'", '`').trim()
  }
}
