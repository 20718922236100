<div class="modal-header">
  <h4 class="modal-title">Edit Match</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <mb-alert
    *ngIf="error"
    type="danger"
    [message]="error"
    (closed)="error = null"
  ></mb-alert>
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <div class="form-group">
      <label>Step 1 - Choose scorebook</label>
      <select
        class="form-control"
        formControlName="levelID"
        (change)="loadEvents()"
      >
        <option
          *ngFor="let level of eventLevels"
          [value]="level.levelID"
          [disabled]="level.levelID === '-1'"
        >
          {{ level.label }}
        </option>
      </select>
      <mb-invalid
        [form]="form"
        [validate]="validate"
        field="levelID"
      ></mb-invalid>
    </div>
    <div class="form-group">
      <label>Step 2 - Choose event</label>
      <select
        class="form-control"
        [disabled]="loadingEvents"
        formControlName="eventID"
      >
        <option *ngIf="loadingEvents">Loading...</option>
        <option *ngIf="events && events.length">- Choose -</option>
        <option *ngFor="let evt of events" [value]="evt.eventID">
          {{ evt.eventName }}
        </option>
      </select>
      <mb-invalid
        [form]="form"
        [validate]="validate"
        field="eventID"
      ></mb-invalid>
    </div>
  </form>
</div>

<div class="modal-footer d-block">
  <button
    *ngIf="!submitting"
    type="button"
    class="btn btn-primary btn-block"
    (click)="submit()"
  >
    Move
  </button>
  <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>

  <div class="mt-4 text-center">
    <span>- or -</span>
  </div>

  <div
    class="mt-4 d-flex"
    [ngClass]="{
      'justify-content-between':
        match.opponentSchool === match.ourWrestlerSchool,
      'justify-content-center': match.opponentSchool !== match.ourWrestlerSchool
    }"
  >
    <button
      *ngIf="match.opponentSchool === match.ourWrestlerSchool"
      (click)="confirmClone()"
      class="btn btn-outline-secondary"
    >
      Clone match
    </button>

    <button (click)="confirmDelete()" class="btn btn-outline-danger">
      Delete match
    </button>
  </div>
</div>
