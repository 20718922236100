import { Injectable } from '@angular/core'

import { ApiService } from './api.service'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Get notifications
   * @param teamID
   */
  public getNotifications(teamID: string): Observable<any> {
    const params: any = { teamID: teamID }
    return this.apiService.get('notifications', { params: params })
  }
}
