<mb-spinner
  *ngIf="!loaded"
  label="Loading..."
  class="my-5 p-5 text-center"
></mb-spinner>

<div class="container container--inner mb-settings" *ngIf="loaded">
  <h3 class="mb-page__head">
    <span class="mb-page__head-title"> Settings </span>
  </h3>

  <h3 class="mb-subhead my-3" *ngIf="!account.isPublicUser">My Organization</h3>

  <div class="row" *ngIf="!account.isPublicUser">
    <div class="col-md-6">
      <div class="form-group">
        <label class="mb-label">School/Organization</label>
        <p>{{ account.schoolName }}</p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="mb-label">State/Level</label>
        <p>{{ account.state }}, {{ account.ageLevel }}</p>
      </div>
    </div>
  </div>

  <h3 class="mb-subhead my-3" *ngIf="account.isMainContact && paymentInfo">
    Payment Status
  </h3>
  <div class="row" *ngIf="account.isMainContact && paymentInfo">
    <div class="col-md-6">
      <div class="form-group">
        <label class="mb-label">{{ paymentInfo.previous.title }}</label>
        <p>
          {{ paymentInfo.previous.status || "N/A" }}
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="mb-label">{{ paymentInfo.current.title }}</label>
        <p>
          {{
            paymentInfo.current.status || "Unknown, please check back later!"
          }}
        </p>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="account.isMainContact && paymentInfo">
    <div class="col-md-6">
      <div class="form-group">
        <a
          *ngIf="paymentInfo.previous.invoice_url"
          href="{{ paymentInfo.previous.invoice_url }}"
          class="btn btn-lg btn-primary"
          target="_new"
        >
          Access Invoice
        </a>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <a
          *ngIf="paymentInfo.current.invoice_url"
          href="{{ paymentInfo.current.invoice_url }}"
          class="btn btn-lg btn-primary"
          target="_new"
        >
          Access Invoice
        </a>
      </div>
    </div>
  </div>

  <div *ngIf="account.isMainContact">
    <h3 class="mb-subhead my-3">Tools</h3>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          Looking to change your school or organization's global settings such
          as TrackWrestling integration, weight classes, scorebook titles or
          custom tags? Do so here:
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          View and manage all member accounts (Coaches, Managers, Atheletes,
          etc.) linked to your organization. If you are looking to invite a
          manager or athelete, this is the place to do it!
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <a routerLink="/settings/organization" class="btn btn-lg btn-primary"
            >Organization Settings</a
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <a routerLink="/settings/users" class="btn btn-lg btn-primary"
            >Organization Members</a
          >
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          Need to change your TrackWrestling school, username or season
          settings?
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          Now you can use MatBoss to track your school records! Clicking the
          button below will take you to a page where you can enter your existing
          records.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <a
            routerLink="/settings/track-wrestling"
            class="btn btn-lg btn-primary"
            >TrackWrestling Settings</a
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <a routerLink="/school-records" class="btn btn-lg btn-primary"
            >School Records</a
          >
        </div>
      </div>
    </div>
  </div>
  <hr class="mb-hr" *ngIf="!account.isPublicUser" />

  <h3 class="mb-subhead my-3">My Profile</h3>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="mb-label">Name</label>
        <p>{{ account.userFullName }}</p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="mb-label">Email</label>
        <p>{{ account.userEmail }}</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        Looking to change your profile settings such as email, or change your
        password? Do so here:
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        Each mobile device (iOS or Android) that you log into your profile with
        has a unique device login, view and manage these logins here:
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <a routerLink="/settings/profile" class="btn btn-lg btn-primary"
          >Profile Settings</a
        >
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <a routerLink="/settings/devices" class="btn btn-lg btn-primary"
          >Device Logins</a
        >
      </div>
    </div>
  </div>
  <!-- <hr class="mb-hr"/> -->
</div>
