<div class="container">
  <h3 class="mb-page__head">
    <span class="mb-page__head-title">
      <img src="./assets/SVG/Page/Roster.svg" alt="" />
      Video
    </span>
  </h3>

  <mb-video-list-filters
    *ngIf="params"
    [params]="params"
    [user]="user"
    (submitted)="updateFilters($event)"
  >
  </mb-video-list-filters>

  <div *ngIf="matches">
    <div class="d-flex flex-row justify-content-between">
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div class="pb-2">
        <input
          type="text"
          (ngModelChange)="this.searchUpdate.next($event)"
          [(ngModel)]="search"
          placeholder="Search"
          class="mb-video-list__search"
        />
      </div>
    </div>
  </div>

  <mb-alert
    *ngIf="error"
    type="danger"
    [message]="error"
    (closed)="error = null"
  ></mb-alert>
  <mb-alert
    *ngIf="success"
    [message]="success"
    (closed)="success = null"
  ></mb-alert>

  <mb-spinner
    *ngIf="!loaded"
    label="Loading Video..."
    class="my-5 p-5 text-center"
    style="min-height: 400px"
  ></mb-spinner>

  <div *ngIf="loaded" class="mb-video-list__content">
    <div class="d-flex flex-row justify-content-between" *ngIf="matches.length">
      <div class="pb-2" *ngIf="matches.length">
        <button
          *ngIf="!user.isWrestler"
          class="btn btn-primary"
          (click)="share()"
          [disabled]="!sharedIds.length"
        >
          Share
        </button>
      </div>
      <div *ngIf="matches.length < totalMatches">
        <ngb-pagination
          [(page)]="page"
          (pageChange)="onPageChange($event)"
          [pageSize]="perPage"
          [maxSize]="5"
          [boundaryLinks]="true"
          [rotate]="true"
          [collectionSize]="totalMatches"
        ></ngb-pagination>
      </div>
    </div>
    <div>
      <div class="table-responsive" *ngIf="matches.length">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th *ngIf="!user.isWrestler">Share</th>
              <th class="text-nowrap">
                <a (click)="sort('WeightClass')" class="mb-th__link"
                  >Wt class</a
                >
              </th>
              <th class="text-nowrap">
                <a (click)="sort('ourWrestler')" class="mb-th__link"
                  >Your wrestler</a
                >
              </th>
              <th class="text-nowrap">
                <a (click)="sort('theirWrestler')" class="mb-th__link"
                  >Opponent</a
                >
              </th>
              <th class="text-nowrap">
                <a
                  *ngIf="!filters.move"
                  (click)="sort('Result')"
                  class="mb-th__link"
                  >Result</a
                >
                <span *ngIf="!!filters.move"
                  >{{ filters.move }} for Timestamp</span
                >
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let match of matches">
              <td *ngIf="!user.isWrestler">
                <input
                  type="checkbox"
                  [(ngModel)]="sharedMatches[match.matchID]"
                  (ngModelChange)="updateShared()"
                />
              </td>
              <td>{{ match.theWeightClass }}</td>
              <td>{{ match.ourWrestlerName }}</td>
              <td>{{ match.opponentWrestlerLabel }}</td>
              <td>
                <span *ngIf="!filters.move"
                  >{{ match.ourWin }} {{ match.theResult }}</span
                >
                <span *ngIf="!filters.move && match.isExhibition">
                  (Exhibition)</span
                >
                <span *ngIf="!!filters.move">{{ match.moveTimestamp }}</span>
              </td>
              <td style="white-space: nowrap">
                <button
                  *ngIf="user.canWatch(match)"
                  (click)="watch(match)"
                  class="btn btn-sm btn-primary mr-2"
                  style="text-transform: uppercase"
                >
                  Watch / Edit
                </button>
                <button
                  *ngIf="user.canWatch(match)"
                  (click)="download(match)"
                  class="btn btn-sm btn-outline-secondary"
                  style="text-transform: uppercase"
                >
                  Download
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="d-flex flex-row justify-content-between"
        *ngIf="matches.length"
      >
        <div class="pb-2">
          <button
            class="btn btn-primary"
            (click)="share()"
            [disabled]="!sharedIds.length"
          >
            Share
          </button>
        </div>
        <div *ngIf="matches.length < totalMatches">
          <ngb-pagination
            [(page)]="page"
            (pageChange)="onPageChange($event)"
            [pageSize]="perPage"
            [maxSize]="5"
            [boundaryLinks]="true"
            [rotate]="true"
            [collectionSize]="totalMatches"
          ></ngb-pagination>
        </div>
      </div>
    </div>
    <div *ngIf="!matches.length" class="p-5 text-center">
      There were no videos found.
    </div>
  </div>
</div>
