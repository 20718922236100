import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment'

@Injectable()
export class ApiService {
  public static API_URL = environment.apiUrl

  constructor(public http: HttpClient) { }

  public get(path: string, options?: object): Observable<any> {
    return this.http.get(ApiService.API_URL + path, this.getOptions(options))
  }

  public post(path: string, body: any, options?: any): Observable<any> {
    return this.http.post(ApiService.API_URL + path, body, this.getOptions(options))
  }

  public delete(path: string, options?: any) {
    return this.http.delete(ApiService.API_URL + path, this.getOptions(options))
  }

  public update(path: string, body?: any, options?: any) {
    return this.http.put(ApiService.API_URL + path, body, this.getOptions(options))
  }

  private getOptions(options?: any): any {
    options = options || {}
    options.withCredentials = true
    options.headers = options.headers || {}
    options.headers.Accept = 'application/json'
    return options
  }
}
