import { Observable, interval } from 'rxjs'
import { takeWhile } from 'rxjs/operators'

export class Doodle {

  static DEFAULT_SKETCH_LENGTH: number = 3 // default length in seconds that a sketch will last on screen
  static ARROW_MODE: number = 0
  static ELLIPSE_MODE: number = 10
  static FREEFORM_MODE: number = 20
  static TEXT_MODE: number = 30
  static NO_DRAW_MODE: number = 40

  private modes: string[] = [
    'DRAW', 'ELLIPSE', 'ARROW', 'TEXT'
  ]
  private ui: string = 'DEFAULT'
  private uis: string[] = [
    'DEFAULT', 'INVISIBLE'
  ]

  public mode = Doodle.NO_DRAW_MODE
  public sketches: any[] = [] // array of all sketches for the current video
  public newSketch: any = null  // the sketch a user is currently creating (if any)
  private _canvas: HTMLCanvasElement
  public context: CanvasRenderingContext2D
  public videoEl: HTMLVideoElement
  public colorEl: HTMLInputElement
  public drag = false // indicates the mouse is dragging (i.e. clicked down and moving)
  public recentMouseDownX: number // x-coordinate of the last mouse click
  public recentMouseDownY: number  // y-coordinate of the last mouse click
  public videoAlreadyPausedForThisSketch = false
  public active = true
  public committed = false

  private timer: Observable<any>

  constructor() { }

  public set canvas(c: HTMLCanvasElement) {
    this._canvas = c
    this.canvas.width = this.canvas.offsetWidth
    this.canvas.height = this.canvas.offsetHeight
    this.context = this.canvas.getContext('2d')
    this.initContext()
    this.initTimer()
    this.initCanvas()
  }

  public get canvas(): HTMLCanvasElement {
    return this._canvas
  }

  private initContext() {
    this.context.lineJoin = 'round'
    this.context.lineCap = 'round'
    this.context.lineWidth = 5
  }

  private initTimer(): void {
    this.timer = interval(10)
    this.timer
      .pipe(takeWhile(() => this.active))
      .subscribe(() => this.tick())
  }

  private tick(): void {
    if (this.inInvisibleUI) return this.clearCanvas()
    if (this.drag) return
    if (this.mode !== Doodle.NO_DRAW_MODE) return
    this.clearCanvas()
    this.drawLiveSketches()
    this.drawSketch(this.newSketch)
  }

  private clearCanvas(): void {
    if (!this.context) return
    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height)
  }

  private initCanvas(): void {
    const c = this.canvas
    c.addEventListener('mousedown', this.onMouseDown.bind(this))
    c.addEventListener('mousemove', this.onMouseMove.bind(this))
    c.addEventListener('mouseup', this.onMouseUp.bind(this))
    c.addEventListener('mouseleave', this.onMouseLeave.bind(this))

    c.addEventListener('touchstart', this.onTouchStart.bind(this))
    c.addEventListener('touchmove', this.onTouchMove.bind(this))
    c.addEventListener('touchend', this.onTouchEnd.bind(this))

    // Prevent scrolling when touching the canvas
    document.body.addEventListener('touchstart', (e) => {
      if (e.target === c) e.preventDefault()
    }, false)
    document.body.addEventListener('touchend', (e) => {
      if (e.target === c) e.preventDefault()
    }, false)
    document.body.addEventListener('touchmove', (e) => {
      if (e.target === c) e.preventDefault()
    }, false)
  }

  public get videoPlaying(): boolean {
    return !!(
      this.videoEl.currentTime > 0 &&
      !this.videoEl.paused &&
      !this.videoEl.ended &&
      this.videoEl.readyState > 2
    )
  }

  public get videoPlayable(): boolean {
    return !!(
      this.videoEl.paused && !this.videoEl.ended && this.videoEl.readyState > 0
    )
  }

  public toggleVideoPlayback(): void {
    if (this.videoPlaying) {
      this.videoEl.pause()
    }
    else if (this.videoPlayable) {
      this.videoEl.play()
    }
  }

  private onTouchStart(e: TouchEvent): void {
    if (this.mode === Doodle.NO_DRAW_MODE) return

    var rect = this.canvas.getBoundingClientRect()
    const mousePos: any = {
      x: e.touches[0].clientX - rect.left,
      y: e.touches[0].clientY - rect.top
    }
    var touch = e.touches[0]
    var mouseEvent = new MouseEvent('mousedown', {
      clientX: touch.clientX,
      clientY: touch.clientY
    })
    this.canvas.dispatchEvent(mouseEvent)
  }

  private onTouchMove(e: TouchEvent): void {
    var touch = e.touches[0]
    var mouseEvent = new MouseEvent('mousemove', {
      clientX: touch.clientX,
      clientY: touch.clientY
    })
    this.canvas.dispatchEvent(mouseEvent);
  }

  private onTouchEnd(e: TouchEvent): void {
    var mouseEvent = new MouseEvent('mouseup', {})
    this.canvas.dispatchEvent(mouseEvent)
  }

  private onMouseDown(e: MouseEvent): void {
    // i.e. user is not editing sketches
    // @todo handle play/pause here?
    if (this.mode == Doodle.NO_DRAW_MODE) {
      this.toggleVideoPlayback()
      return
    }

    this.videoEl.pause()

    this.drag = true
    switch(this.mode) {
      case Doodle.ARROW_MODE:
      case Doodle.ELLIPSE_MODE:
        this.recentMouseDownX = e.offsetX / this.canvas.width
        this.recentMouseDownY = e.offsetY / this.canvas.height
        break
      case Doodle.FREEFORM_MODE:
        const newFreeform = { xcoors: [], ycoors: [] }
        this.newSketch.freeforms.push(newFreeform)
        break
    }
  }

  private onMouseMove(e: MouseEvent) {
    //i.e. user is not in the middle of creating an arrow/ellipse/freeform
    if (!this.drag) return

    this.clearCanvas()
    let mouseX = e.offsetX / this.canvas.width
    let mouseY = e.offsetY / this.canvas.height

    this.changeColor()
    switch(this.mode) {
      case Doodle.FREEFORM_MODE:
        this.newSketch.freeforms[this.newSketch.freeforms.length - 1].xcoors.push(mouseX)
        this.newSketch.freeforms[this.newSketch.freeforms.length - 1].ycoors.push(mouseY)
        break
      case Doodle.ARROW_MODE:
        this.changeColor()
        this.drawArrow(this.recentMouseDownX, this.recentMouseDownY, mouseX, mouseY)
        break
      case Doodle.ELLIPSE_MODE:
        var cenX = (mouseX + this.recentMouseDownX) / 2
        var cenY = (mouseY + this.recentMouseDownY) / 2
        var a = Math.abs((mouseX - this.recentMouseDownX) / 2)
        var b = Math.abs((mouseY - this.recentMouseDownY) / 2)
        this.drawEllipse(cenX, cenY, a, b)
    }
    this.drawSketch(this.newSketch)
  }

  private onMouseUp(e: MouseEvent) {
    if (this.mode === Doodle.NO_DRAW_MODE || !this.drag) return
    this.drag = false
    let currentMouseX = e.offsetX / this.canvas.width
    let currentMouseY = e.offsetY / this.canvas.height

    switch(this.mode) {
      case Doodle.ARROW_MODE:
        let newArrow = {
          x1: this.recentMouseDownX,
          y1: this.recentMouseDownY,
          x2: currentMouseX,
          y2: currentMouseY
        }
        this.newSketch.arrows.push(newArrow)
        break
      case Doodle.ELLIPSE_MODE:
        let newEllipse = {
          x: ((currentMouseX + this.recentMouseDownX) / 2),
          y: ((currentMouseY + this.recentMouseDownY) / 2),
          a: (Math.abs(currentMouseX - this.recentMouseDownX) / 2),
          b: (Math.abs(currentMouseY - this.recentMouseDownY) / 2)
        }
        this.newSketch.ellipses.push(newEllipse)
        break
      case Doodle.TEXT_MODE:
      var message = prompt("Enter a short message you want to display on the screen. \nFor longer messages, use the notepad on the left side of the screen.");
        if (message === null) break
        // remove characters that will make  the string parsing buggy
        message = message.replace(/[,@*$%<>'"]/g, '').replace(/&/g, 'and')
        var newText = {
          text: message,
          x: currentMouseX,
          y: currentMouseY
        }
        this.changeColor()
        this.newSketch.texts.push(newText)
    }

    this.mode = Doodle.FREEFORM_MODE
  }

  private onMouseLeave(e: MouseEvent) {
    this.drag = false
    this.clearCanvas()
    this.drawLiveSketches()
    this.drawSketch(this.newSketch)
  }

  /***************************************************************************
    FUNCTIONS TO DRAW ON THE CANVAS
  ***************************************************************************/

  public drawFreeForm(freeform) {
    this.initContext()
    for (var i = 0; i < freeform.xcoors.length - 1; i++) {
      this.context.beginPath()
      this.context.moveTo(freeform.xcoors[i] * this.canvas.width, freeform.ycoors[i] * this.canvas.height)
      this.context.lineTo(freeform.xcoors[i+1] * this.canvas.width, freeform.ycoors[i + 1] * this.canvas.height)
      this.context.closePath()
      this.context.stroke()
    }
  }

  public drawArrow(x1, y1, x2, y2) {
    this.initContext()
    x1 = x1 * this.canvas.width
    y1 = y1 * this.canvas.height
    x2 = x2 * this.canvas.width
    y2 = y2 * this.canvas.height
    let triHeight = 30
    let triWidth = 2 * 20

    // find the vector (x2,y2) - (x1,y1) and normalize it
    if ((x1 == x2) && (y1 == y1)) return
    let xPrime = x2  - x1
    let yPrime = y2 - y1
    let mag = Math.sqrt(xPrime * xPrime + yPrime * yPrime)
    xPrime = xPrime / mag
    yPrime = yPrime / mag

    // find the angle of vector normal to (xPrime, yPrime) w.r.t positive x-axis
    let theta
    if (xPrime == 0) {
      if (yPrime > 0) theta = Math.PI / 2
      else theta = - Math.PI / 2
    }
    else {
      theta = Math.atan(yPrime / xPrime)
      if (xPrime < 0) theta += Math.PI
    }
    // since it is normal to (xPrime,yPrime)
    theta = theta + Math.PI / 2

    // draw line first
    this.context.beginPath()
    this.context.moveTo(x1, y1)
    this.context.lineTo(x2 - triHeight * xPrime, y2 - triHeight * yPrime)
    this.context.stroke()

    // draw triangular point
    // from arrow point, add vectors normal to and parallel to the arrow with the desired magnitute to draw the triangle
    this.context.beginPath()
    this.context.moveTo(x2, y2)
    this.context.lineTo(x2 - triHeight * xPrime + triWidth / 2 * Math.cos(theta), y2 - triHeight * yPrime + triWidth/2 * Math.sin(theta))
    this.context.lineTo(x2 - triHeight * xPrime - triWidth / 2 * Math.cos(theta), y2 - triHeight * yPrime - triWidth/2 * Math.sin(theta))
    this.context.closePath()
    this.context.fill()
  }

  // draws an ellipse on the canvas. (x,y) is the center-point of the ellipse.
  // The ellipse has a vertical radius of length a and horizontal radius of length b
  // Recall algebraic definition of an ellipse is all points (X,Y) that satisfy:
  //  [(X - x)/(a)]^2 + [(Y - y)/(b)]^2  = 1
  private drawEllipse(x, y, a, b) {
    this.initContext()
    a = a * this.canvas.width
    b = b * this.canvas.height
    x = x * this.canvas.width
    y = y * this.canvas.height

    this.context.beginPath()
    this.context.moveTo(x, y - b) // A1
    this.context.bezierCurveTo(
      x + a, y - b, // C1
      x + a, y + b, // C2
      x, y + b      // A2
    )

    this.context.bezierCurveTo(
      x - a, y + b, // C3
      x - a, y - b, // C4
      x, y - b      // A1
    )

    this.context.stroke()
  }

  private drawText(message, x, y) {
    // Format message into multiple lines if necessary, four words at a time on a line
    var nextLineAddY = 0
    var messageWords = message.split(' ')
    for (var i = 0; i < messageWords.length; i += 4) {
      var printPartsMessage = messageWords[i]
      if (i + 1 < messageWords.length) {
        printPartsMessage = printPartsMessage + ' ' + messageWords[i + 1]
      }
      if (i + 2 < messageWords.length) {
        printPartsMessage = printPartsMessage + ' ' + messageWords[i + 2]
      }
      if (i + 3 < messageWords.length) {
        printPartsMessage = printPartsMessage + ' ' + messageWords[i + 3]
      }
      this.context.font = '24px Georgia'
      // context.fillText(message, x * canvas.width, y * canvas.height)
      this.context.fillText(
        printPartsMessage,
        x * this.canvas.width,
        y * this.canvas.height + nextLineAddY
      )
      nextLineAddY += 30
    }
  }

  // draw all sketches that show up at the current video time
  private drawLiveSketches() {
    for (var k = 0; k < this.sketches.length; k++) {
      if (
        (this.sketches[k].startTime <= this.videoEl.currentTime) &&
        (this.sketches[k].endTime > this.videoEl.currentTime)
      ) {
        if (
          (Math.round(this.sketches[k].startTime) === Math.round(this.videoEl.currentTime)) &&
          !this.videoEl.paused &&
          !this.videoAlreadyPausedForThisSketch
        ) {
          this.videoEl.pause()
          this.videoAlreadyPausedForThisSketch = true
          setTimeout(() => {
            this.videoEl.play()
            setTimeout(() => {
              this.videoAlreadyPausedForThisSketch = false
            }, 2000)
          }, 2000)
        }
        this.drawSketch(this.sketches[k])
      }
    }
  }

  // draws sketch on the canvas. See top of page for info on sketch abstraction.
  private drawSketch(sketch) {
    if (sketch == null) return
    this.context.strokeStyle = sketch.color
    this.context.fillStyle = sketch.color
    for (var i = 0; i < sketch.ellipses.length; i++) {
      this.drawEllipse(
        sketch.ellipses[i].x,
        sketch.ellipses[i].y,
        sketch.ellipses[i].a,
        sketch.ellipses[i].b
      )
    }
    for (var j = 0; j < sketch.arrows.length; j++) {
      this.drawArrow(
        sketch.arrows[j].x1,
        sketch.arrows[j].y1,
        sketch.arrows[j].x2,
        sketch.arrows[j].y2
      )
    }
    for (var k = 0; k < sketch.freeforms.length; k++) {
      this.drawFreeForm(sketch.freeforms[k])
    }
    for (var n = 0; n < sketch.texts.length; n++) {
      this.drawText(sketch.texts[n].text, sketch.texts[n].x, sketch.texts[n].y)
    }
  }

  /***************************************************************************
    FUNCTIONS TO HANDLE SAVING AND LOADING STORED SKETCHES
  ***************************************************************************/

  /*  Here is how sketches are represented as strings:

    Create a string representation of sketches. Sketches are separated by the
    delimiter @
    Within a sketch, each element is separated by the element *, in the following
    order: color, startTime, endTime, ellipses, arrows, freeforms, texts
    Within ellipses, arrows, and freforms, each ellipse, arrow, or freeform is
    separated by the delimiter $.
    Within each ellipse and arrow, coordinates are separated by the delimter %
    Coordinates are in the order (x,y,a,b) and (x1,y1,x2,y2) respectively
    Within each freeform, parts of cartesian coordinates are also separated by
    the delimter %. The coordinates themselves separate x and y by a comma.
  */

  // create string representation of sketches
  public createSketchesString() {
    this.commitSketch()
    let record = ''
    for(let k = 0; k < this.sketches.length; k++) {
      record += this.sketches[k].color + '*'
      record += this.sketches[k].startTime + '*'
      record += this.sketches[k].endTime + '*'
      for (var m = 0; m < this.sketches[k].ellipses.length; m++) {
        record += this.sketches[k].ellipses[m].x + '%'
        record += this.sketches[k].ellipses[m].y + '%'
        record += this.sketches[k].ellipses[m].a + '%'
        record += this.sketches[k].ellipses[m].b + '%'
        record += '$'
      }
      record += '*'
      for (var n = 0; n < this.sketches[k].arrows.length; n++) {
        record += this.sketches[k].arrows[n].x1 + '%'
        record += this.sketches[k].arrows[n].y1 + '%'
        record += this.sketches[k].arrows[n].x2 + '%'
        record += this.sketches[k].arrows[n].y2 + '%'
        record += '$'
      }
      record += '*'
      for (var p = 0; p < this.sketches[k].freeforms.length; p++) {
        for (var subp = 0; subp < this.sketches[k].freeforms[p].xcoors.length; subp++) {
          record += this.sketches[k].freeforms[p].xcoors[subp] + ','
          record += this.sketches[k].freeforms[p].ycoors[subp] + '%'
        }
        record += '$'
      }
      record += '*'
      for (var t = 0; t < this.sketches[k].texts.length; t++) {
        record += this.sketches[k].texts[t].text + '%'
        record += this.sketches[k].texts[t].x + '%'
        record += this.sketches[k].texts[t].y + '%'
        record += '$'
      }
      record += '@'
    }
    return record
  }

  // datastring is a string representation of a sketch (see notes above)
  // parses datastring, and re-creates sketches based on the sketches
  // reprsented by dataString
  public parseSketchesString(dataString) {
    // Clears the canvas
    this.clearCanvas()
    this.sketches = []
    this.newSketch = null
    if (dataString == null || dataString.length == 0) return
    let sketchStrings = dataString.split('@')
    for (let i = 0; i < sketchStrings.length - 1; i++) {
      let sketchParts = sketchStrings[i].split('*')
      let ellipseSet = []
      let ellipseStrings = sketchParts[3].split('$')
      for (let j = 0; j < ellipseStrings.length - 1; j++) {
        let coordinates = ellipseStrings[j].split('%')
        let nextEllipse = {
          x: coordinates[0],
          y: coordinates[1],
          a: coordinates[2],
          b: coordinates[3]
        }
        ellipseSet.push(nextEllipse)
      }

      let arrowSet = []
      let arrowStrings = sketchParts[4].split('$')
      for (let k = 0; k < arrowStrings.length - 1; k++) {
        let coordinates = arrowStrings[k].split('%')
        let nextArrow = {
          x1:coordinates[0],
          y1:coordinates[1],
          x2:coordinates[2],
          y2:coordinates[3]
        }
        arrowSet.push(nextArrow)
      }

      let freeformSet = []
      let freeformStrings = sketchParts[5].split('$')
      for (let m = 0; m < freeformStrings.length - 1; m++) {
        let coordinatePairs = freeformStrings[m].split('%')
        let xCoordinates = []
        let yCoordinates = []
        for (let subm = 0; subm < coordinatePairs.length - 1; subm++) {
          let coordinates = coordinatePairs[subm].split(',')
          xCoordinates.push(coordinates[0])
          yCoordinates.push(coordinates[1])
        }
        var nextFreeform = {
          xcoors: xCoordinates,
          ycoors: yCoordinates
        }
        freeformSet.push(nextFreeform)
      }

      var textSet = []
      var textStrings = sketchParts[6].split('$')
      for (var p = 0; p < textStrings.length - 1; p++) {
        var parts = textStrings[p].split('%')
        var nextText = {
          text: parts[0],
          x: parts[1],
          y: parts[2]
        }
        textSet.push(nextText)
      }

      var nextSketch = {
        color: sketchParts[0],
        startTime: sketchParts[1],
        endTime: sketchParts[2],
        ellipses: ellipseSet,
        arrows: arrowSet,
        freeforms: freeformSet,
        texts: textSet
      }
      this.sketches.push(nextSketch)
    }
  }

  /***************************************************************************
    FUNCTIONS TO HANDLE USER BUTTONS
  ***************************************************************************/
  // permanently delete any sketch that is currently showing on the video screen from sketches
  public deleteLiveSketches() {
    if (!confirm("Are you sure you want to permanently delete all sketches now showing on the screen?")) return
    for (let k = 0; k < this.sketches.length; k++) {
      if (
        (this.sketches[k].startTime <= this.videoEl.currentTime) &&
        (this.sketches[k].endTime > this.videoEl.currentTime)
      ) {
        this.sketches.splice(k--, 1)
      }
    }
    // @TODO
    // see MBvideo.js
    // setEditFlag()
  }

  // the user has chosen a new color. Change the color for newSketch
  public changeColor() {
    // Clears the canvas
    this.clearCanvas()
    // this.drawLiveSketches()
    let color = (this.colorEl && this.colorEl.value) || '#FF0000'
    this.context.strokeStyle = color
    this.context.fillStyle = color
    this.newSketch.color = color
    this.drawSketch(this.newSketch)
  }

  //the user has clicked on "add sketch" button. Prep a new sketch to add.
  public startNewSketch() {
    this.canvas.width = this.canvas.offsetWidth
    this.canvas.height = this.canvas.offsetHeight
    let color = (this.colorEl && this.colorEl.value) || '#FF0000'
    this.videoEl.pause()
    this.newSketch = {
      startTime: this.videoEl.currentTime,
      endTime: (this.videoEl.currentTime + Doodle.DEFAULT_SKETCH_LENGTH),
      color: color,
      ellipses: [],
      arrows: [],
      freeforms: [],
      texts: []
    }
    this.mode = Doodle.FREEFORM_MODE
    // @TODO
    // see MBvideo.js
    // setEditFlag()
  }

  // user is finished creating newSketch. Add newSketch to sketches
  public commitSketch() {
    this.mode = Doodle.NO_DRAW_MODE
    if (!this.newSketch) return
    this.newSketch.startTime = this.videoEl.currentTime
    this.newSketch.endTime = this.videoEl.currentTime + Doodle.DEFAULT_SKETCH_LENGTH
    this.sketches.push(this.newSketch)
    this.newSketch = null
    this.committed = true
    this.videoAlreadyPausedForThisSketch = true
    setTimeout(() => {
      this.videoAlreadyPausedForThisSketch = false
    }, 4000)
    this.loadDefaultUI()
  }

  // User clicked "cancel sketch". Destroy newSketch
  public cancelSketch() {
    this.mode = Doodle.NO_DRAW_MODE
    this.loadDefaultUI()
    this.newSketch = null
  }

  /***************************************************************************
    public mode methods
  ***************************************************************************/
  // CREATE SKETCH MODE
  public get inCreateSketchMode(): boolean {
    return this.mode !== Doodle.NO_DRAW_MODE
  }

  // ELLIPSE MODE
  public setEllipseMode() {
    if (!this.inDefaultUI) return
    this.mode = Doodle.ELLIPSE_MODE
  }
  public get inEllipseMode(): boolean {
    return this.mode === Doodle.ELLIPSE_MODE
  }

  // ARROW MODE
  public setArrowMode() {
    if (!this.inDefaultUI) return
    this.mode = Doodle.ARROW_MODE
  }
  public get inArrowMode(): boolean {
    return this.mode === Doodle.ARROW_MODE
  }

  // DRAW MODE
  public setDrawMode() {
    if (!this.inDefaultUI) return
    this.startNewSketch()
    this.mode = Doodle.FREEFORM_MODE
  }
  public get inDrawMode(): boolean {
    return this.mode === Doodle.FREEFORM_MODE
  }

  // TEXT MODE
  public setTextMode() {
    if (!this.inDefaultUI) return
    this.mode = Doodle.TEXT_MODE
  }
  public get inTextMode(): boolean {
    return this.mode === Doodle.TEXT_MODE
  }

  //
  public createSketch() {
    if (!this.inDefaultUI || this.inCreateSketchMode) return
    this.setDrawMode()
  }

  // UI
  private setUI(ui: string) {
    if (this.uis.indexOf(ui) === -1) return
    this.ui = ui
  }

  public loadDefaultUI() {
    this.setUI('DEFAULT')
  }
  public get inDefaultUI(): boolean {
    return this.ui === 'DEFAULT'
  }

  public loadInvisibleUI() {
    if (this.inCreateSketchMode) return
    this.setUI('INVISIBLE')
    this.clearCanvas()
  }
  public get inInvisibleUI(): boolean {
    return this.ui === 'INVISIBLE'
  }

  // @todo determine when this might need to be true
  public get inNoDoodleUI(): boolean {
    return false
  }

  public noDoodleMode(): void {
    // ???
  }

  public setEditFlag(): void {
    // @todo make this go away
  }
}
