<div class="mb-event__head">
    <mb-event-boxscore [event]="event"></mb-event-boxscore>
    <div class="mb-event__sync">
        <a *ngIf="!isRestoring" class="btn btn-lg btn-primary" 
        (click)="confirmRestore()">
          Restore
        </a>
        <mb-ellipsis-loader *ngIf="isRestoring"></mb-ellipsis-loader>
    </div>
</div>
<div class="mb-event__matches">
    <div class="mb-matches__counter">
      {{ event.match_count }} matches
    </div>
</div>
