<!-- STEP 2 -->
<mb-spinner *ngIf="!loaded" type="ripple-inverse"></mb-spinner>
<div *ngIf="loaded">
  <a (click)="goBack()" class="mb-link" style="color:gainsboro">&lt;&lt; Back</a>
  <h2 class="mb-heading">Step 2 of 3</h2>
  <p class="mb-text">
    Below you will see your current MatBoss roster.  For each wrestler who is no longer with the team, set the dropdown next to his/her name to 'Former'. NOTE: You will be able to add new wrestlers to your team after you go through this setup wizard by going to the Roster page inside your online account.
  </p>
  <form [formGroup]="form">
	  <table class="table">
      <thead>
        <tr>
          <th>Last Name</th>
          <th>First Name</th>
		  <th>Status</th>
        </tr>
      </thead>
      <tbody>
		<ng-container *ngFor="let w of wrestlers">
		  <tr *ngIf="!w.lastName.toLowerCase().includes('forfeit') && !w.lastName.toLowerCase().includes('wrestler')">
			  <td>{{ w.lastName }}</td>
			  <td>{{ w.firstName }}</td>
			  <td>
				<select
				  class="form-control"
				  >
				  <option [value]="w.wrestlerID.toString() + '#' + w.yearsWrestled.toString()" selected>Current</option>
				  <option [value]="w.wrestlerID.toString()">Former</option>
				</select>
			  </td>
          </tr>
		</ng-container>
      </tbody>
    </table>
	  <div class="form-group">
		  <button
			(click)="submitForm()"
			type="button"
			style="margin-right: 20px;"
			*ngIf="!submitting"
			class="btn btn-primary btn-lg">
			  Done
		  </button>
		  <mb-ellipsis-loader
			*ngIf="submitting"></mb-ellipsis-loader>
	   </div>
  </form>

</div>
