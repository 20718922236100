import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class NotificationService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    /**
     * Get notifications
     * @param teamID
     */
    getNotifications(teamID) {
        const params = { teamID: teamID };
        return this.apiService.get('notifications', { params: params });
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.ApiService)); }, token: NotificationService, providedIn: "root" });
