import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { environment } from "../../environments/environment";

import { User, UserInvite, Wrestler } from "../models";
import { UserService, RosterService } from "../services";
import { ConfirmComponent } from "../util/confirm";

@Component({
  selector: "mb-user-invite-edit",
  templateUrl: "./user-invite-edit.component.html",
})
export class UserInviteEditComponent implements OnInit {
  @Input() teamID?: string;
  @Input() invite: UserInvite;
  @Input() title?: string = "Edit Pending Invite";

  public error: string;
  public resent: string;
  public copied: string;
  public userForm: FormGroup;

  public wrestlers: Wrestler[];

  public submitting = false;
  public validate = false;
  public loaded = false;
  private NAME_PATTERN = /^[a-zA-Z0-9'` -]+$/;

  public constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private userService: UserService,
    private rosterService: RosterService,
    public modalService: NgbModal
  ) {}

  public ngOnInit() {
    this.createForm();
    this.findRoster();
  }

  private findRoster() {
    this.loaded = false;
    this.rosterService
      .getRoster(this.teamID, { status: "current" })
      .pipe(first())
      .subscribe((data: any) => {
        this.wrestlers = [];
        data.result.forEach((item) => {
          this.wrestlers.push(new Wrestler(item));
        });
        this.sortWrestlers();
        this.createForm();
      });
  }

  private sortWrestlers(): void {
    this.wrestlers = _.orderBy(
      this.wrestlers,
      (w) => w["lastName"].toLowerCase(),
      "asc"
    ).map((w) => new Wrestler(w));
  }

  public isWrestler() {
    return this.invite.role === "athlete-active";
  }

  public canResend(invite: UserInvite) {
    return true;
  }
  public canCopy(invite: UserInvite) {
    return true;
  }

  public submit() {
    if (!this.userForm.valid) {
      this.validate = true;
      return;
    }
    const data: any = {
      first_name: this.userForm.value.first_name,
      last_name: this.userForm.value.last_name,
      email: this.userForm.value.email,
      wrestlerID:
        this.invite.role === "athlete-active"
          ? this.userForm.value.wrestlerID
          : null,
    };
    const result: Observable<any> = this.userService.updateTeamInvite(
      this.invite.token,
      data
    );
    this.submitting = true;
    result.pipe(first()).subscribe(
      (r: any) => {
        this.invite = new UserInvite(r.result);
        this.activeModal.close({
          success: true,
          result: this.invite,
        });
      },
      (e: any) => {
        this.submitting = false;
        console.warn("user invite edit error", e.error.message);
        this.setError("Error saving invite: " + e.error.message);
        // this.activeModal.close({
        //   success: false,
        //   error: e,
        // });
      }
    );
  }

  public confirmDelete(): void {
    const modalRef = this.modalService.open(ConfirmComponent, {});
    modalRef.componentInstance.event = this.invite;
    modalRef.componentInstance.title = "Confirm";
    modalRef.componentInstance.body =
      "Are you sure you want to permanently delete this Invite?";
    modalRef.result.then(
      (fulfilledValue: any) => {
        if (fulfilledValue.success) {
          this.delete();
        } else {
          // ignore
        }
      },
      (rejectedValue: any) => {
        console.log("rejected", rejectedValue);
      }
    );
  }

  private delete(): void {
    this.submitting = true;
    this.userService
      .deleteTeamInvite(this.invite.token)
      .pipe(first())
      .subscribe(
        (r: any) => {
          this.activeModal.close({
            success: true,
            result: null,
            deleted: true,
          });
        },
        (e: any) => {
          this.submitting = false;
          this.setError(
            "Oops something went wrong and we could not delete the invite. Please try again later."
          );
        }
      );
  }

  public resendInvite(invite: UserInvite): void {
    this.userService
      .resendTeamInvite({ token: invite.token })
      .pipe(first())
      .subscribe((data: any) => {
        console.log(data);
        this.resent = "Invite email sent to " + invite.email + "!";
      });
  }
  public copyInvite(invite: UserInvite): void {
    const url = environment.frontendUrl + "#/register?invite=" + invite.token;
    navigator.clipboard.writeText(url);
    this.copied =
      "Invite URL <a href='" +
      url +
      "' target='_new'>" +
      url +
      "</a> has been copied to your clipboard!";
  }

  private setError(msg: string): void {
    this.error = msg;
    // window.scroll(0, 0);
  }

  private createForm() {
    this.userForm = this.fb.group({
      first_name: [
        this.invite.first_name,
        [Validators.required, Validators.pattern(this.NAME_PATTERN)],
      ],
      last_name: [
        this.invite.last_name,
        [Validators.required, Validators.pattern(this.NAME_PATTERN)],
      ],
      email: [this.invite.email, [Validators.required, Validators.email]],
      wrestlerID: [
        this.invite.role === "athlete-active" && this.invite.wrestler
          ? this.invite.wrestler.wrestlerID
          : null,
      ],
    });
  }
}
