import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { MBMatch } from '../../models'

@Component({
  selector: 'mb-edit-opponent-wrestler',
  templateUrl: './edit-opponent-wrestler.component.html'
})
export class EditOpponentWrestlerComponent implements OnInit {

  @Input() match: MBMatch
  @Input() teamID: string

  public form: FormGroup
  public validate = false
  public loaded = false

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.createForm()
  }

  public submit(): void {
    if (!this.form.valid) {
      this.validate = true
      return
    }
    this.match.opponentName = this.form.value.opponentName.trim()
	if (this.match.opponentName == null || this.match.opponentName == '') {
      this.match.opponentName = 'Doe, John'
    }
	if (this.match.opponentName.includes(",")) {
		// Do nothing, name is in correct format
	} else {
		var firstName = '';
		var lastName = '';
		var theirNameParts = this.match.opponentName.split(" ")
		if (theirNameParts.length > 1) {
			firstName = this.match.opponentName.substr(0,this.match.opponentName.indexOf(' ')).trim()
			lastName = this.match.opponentName.substr(this.match.opponentName.indexOf(' ')+1).trim()
			this.match.opponentName = lastName + ", " + firstName
		}
	}
    this.match.opponentSchool = this.form.value.opponentSchool
    this.activeModal.close({
      success: true,
      result: this.match
    })
  }

  public cancel(): void {
    this.activeModal.dismiss('Cross click')
  }

  // PRIVATE

  private createForm(): void {
    this.form = this.fb.group({
      opponentName: [this.match.opponentName, Validators.required],
      opponentSchool: [this.match.opponentSchool, Validators.required]
    })
    this.loaded = true
  }
}
