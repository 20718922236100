import { Component, Input } from '@angular/core'

@Component({
  selector: 'mb-ellipsis-loader',
  host: { 'style': 'display: block; text-align: center;' },
  template: `
    <div class="mb-ellipsis" [ngClass]="{ 'inverse': isInverse }"><div></div><div></div><div></div><div></div></div>
  `
})
export class EllipsisLoaderComponent {
  @Input() label: string
  @Input() type: string

  constructor() { }

  public get isInverse(): boolean {
    return this.type === 'inverse'
  }
}
