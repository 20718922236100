import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { first } from 'rxjs/operators'

import { User } from '../../models'
import { UserService } from '../../services';

@Component({
  selector: 'mb-setup31',
  templateUrl: './setup31.component.html'
})
export class Setup31Component implements OnInit {

  @Input() account: User

  public form: FormGroup
  public loaded = false
  public submitting = false
  public validate = false

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.createForm()
  }

  public submit(): void {
    if (!this.form.valid) {
      this.validate = true
      return
    }
    this.submitting = true
    // if (this.form.pristine) {
    //   this.router.navigate(['/setup/2'])
    //   return
    // }
    const data: any = {
      level0Title: this.form.value.aSquadTitle,
	  level1Title: this.form.value.bSquadTitle,
	  level2Title: this.form.value.cSquadTitle,
	  level0Weights: this.form.value.aSquadWeights,
      level1Weights: this.form.value.bSquadWeights,
      level2Weights: this.form.value.cSquadWeights
    }
    this.userService.update(
      this.account.userName,
      this.account.userType,
      data
    )
    .pipe(first())
    .subscribe(
      (data: any): void => {
        this.router.navigate(['/setup/15'])
        // this.submitting = false
      }
    )
	this.router.navigate(['/setup/15'])
  }

  public goBack() {
    window.history.back()
  }

  // private

  private createForm(): void {
    this.form = this.fb.group({
	  aSquadTitle: [this.account.level0Title],
	  bSquadTitle: [this.account.level1Title],
	  cSquadTitle: [this.account.level2Title],
	  aSquadWeights: this.getDefaultWeights(),
	  bSquadWeights: this.getDefaultWeights(),
	  cSquadWeights: this.getDefaultWeights(),
    })
    this.loaded = true
  }

  // private

  private getDefaultWeights(): string {
	  const ageLevel = this.account.ageLevel
	  const state = this.account.state
	  switch(ageLevel) {
	    case 'College Men':
		  return '125, 133, 141, 149, 157, 165, 174, 184, 197, 285'
		  break;

		case 'College Women':
		  return '101, 109, 116, 123, 130, 136, 143, 155, 170, 191'
		  break;

	    case 'High School Boys':
		  switch(state) {
			  case 'MT':
				  return '103, 113, 120, 126, 132, 138, 145, 152, 160, 170, 182, 205, 285'
				  break;

			  case 'MI':
				  return '103, 112, 119, 125, 130, 135, 140, 145, 152, 160, 171, 189, 215, 285'
				  break;

			  case 'NY':
				  return '99, 106, 113, 120, 126, 132, 138, 145, 152, 160, 170, 182, 195, 220, 285'
				  break;

			  case 'ID':
				  return '98, 106, 113, 120, 126, 132, 138, 145, 152, 160, 170, 182, 195, 220, 285'
				  break;

			  default:
				  return '106, 113, 120, 126, 132, 138, 145, 152, 160, 170, 182, 195, 220, 285'

		  }
		  break;

		case 'High School Girls':
		  switch(state) {
			  case 'TX':
				  return '95, 102, 110, 119, 128, 138, 148, 165, 185, 215'
				  break;

			  case 'MO':
				  return '103, 110, 116, 121, 126, 131, 136, 143, 152, 167, 187, 235'
				  break;

			  default:
				  return '100, 105, 110, 115, 120, 125, 130, 135, 140, 155, 170, 190, 235'

		  }
		  break;

	    default:
		  return ''
	  }
  }
}
