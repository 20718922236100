import { MBMatch, User } from "./";
import { environment } from "../../environments/environment";

export class MBMatchVideo {
  public match: MBMatch;
  public user: User;
  public leadTime: number;
  public teamPoints: string;
  public overrideTeamPoints = false;
  public fallTime: string;
  public notes: string;
  private _src: string;
  private _thumbnail: string;

  public constructor(match: MBMatch, user: User) {
    this.match = match;
    this.user = user;
    // this._parse();
    this._parseNewWithSignedUrls();
  }

  public get src(): string {
    return this._src;
  }

  public get thumbnail(): string {
    return this._thumbnail;
  }

  protected _parseNewWithSignedUrls(): void {
    // if this user's account is stats only, this must be a stats-only match
    if (this.user.accountType === "Stats") {
      this._src = "";
      this._thumbnail = "./assets/Img/mb-stats-only.png";
      return;
    }

    // if this is a stats-only match
    const statsOnly =
      (this.match.scoringEvents !== null ||
        this.match.scoringEvents.length > 0) &&
      (this.match.videoFileUrl === null || this.match.videoFileUrl === "");
    if (statsOnly) {
      this._src = "";
      this._thumbnail = "./assets/Img/mb-stats-only.png";
      return;
    }

    // otherwise if this is a video match, but no video has been uploaded yet, show the "no video uploaded" message
    if (
      this.match.videoFileUrl !== null &&
      this.match.videoFileUrl !== "" &&
      !this.match.videoInCloud
    ) {
      this._src = "---";
      this._thumbnail = "./assets/Img/mb-needs-upload.png";
      return;
    }
    // otherwise use the signed URLs!
    this._src = this.match.signedVideoUrl;
    this._thumbnail = this.match.signedThumbUrl;
  }

  // protected _parse(): void {
  //   const videoFileName = this.match.videoFileName || "";
  //   if (!videoFileName || videoFileName === "(null)" || videoFileName === "0") {
  //     this._src = "";
  //     this._thumbnail =
  //       "https://s3.us-east-2.amazonaws.com/frontend.matbossapp.com/assets/Img/mb-no-video.png";
  //     return;
  //   }
  //   if (!this.match.videoInCloud) {
  //     this._src = "---";
  //     this._thumbnail =
  //       "https://s3.us-east-2.amazonaws.com/frontend.matbossapp.com/assets/Img/mb-video-not-uploaded.png";
  //     return;
  //   }
  //   let amazonFileName = videoFileName
  //     .replace(/:/g, "%3A")
  //     .replace(/ /g, "+")
  //     .replace("++", "+%2B");

  //   // jpchaos at start of filename means video is "cloned" -
  //   // instead of duplicating, this is a reference to original file
  //   // so we are looking at original filename
  //   if (videoFileName.substr(0, 7) === "jpchaos") {
  //     this._src = "https://s3-us-west-2.amazonaws.com/" + amazonFileName;
  //   } else {
  //     // rather than serving from s3 directly,
  //     // cloudfront caches videos that are played frequently
  //     let domain = "https://s3-us-west-2.amazonaws.com/";
  //     if (this.user.bucketName === "jpchaos-wrestling-college") {
  //       if (environment.env === "production") {
  //         domain = "https://d11b87skmzy3rw.cloudfront.net/"; // prod
  //       } else {
  //         domain = "https://d2f3gyu19rir65.cloudfront.net/"; // stage
  //       }
  //     } else if (this.user.bucketName === "jpchaos-wrestling-highschool") {
  //       if (environment.env === "production") {
  //         domain = "https://d34vfsqnu5f6fg.cloudfront.net/"; // prod
  //       } else {
  //         domain = "https://d34mpqhug5wfgl.cloudfront.net/"; // stage
  //       }
  //     } else if (this.user.bucketName === "jpchaos-wrestling-juniorhigh") {
  //       if (environment.env === "production") {
  //         domain = "https://d3n6cxsyltjnx0.cloudfront.net/"; // prod
  //       } else {
  //         domain = "https://d1o1qndboa181u.cloudfront.net/"; // stage
  //       }
  //     } else if (this.user.bucketName === "jpchaos-wrestling-youth") {
  //       if (environment.env === "production") {
  //         domain = "https://d2ep8v35elhq87.cloudfront.net/"; // prod
  //       } else {
  //         domain = "https://d2i3afhc9vqfit.cloudfront.net/"; // stage
  //       }
  //     }

  //     // @todo make this dynamic
  //     let yearFolderString: string = this.match.year;
  //     if (!yearFolderString) yearFolderString = environment.currentYear;

  //     // Set video file source to amazon file name
  //     const subfolderName = (this.user.schoolName || "")
  //       .toLowerCase()
  //       .replace(/ /g, "");
  //     this._src =
  //       domain +
  //       subfolderName +
  //       "." +
  //       (this.user.state || "").toLowerCase() +
  //       "/" +
  //       yearFolderString +
  //       "/" +
  //       amazonFileName;
  //   }
  //   const parts: string[] = this._src.split(".");
  //   parts.pop();
  //   this._thumbnail = parts.join(".") + ".png";
  // }
}
