import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { first } from 'rxjs/operators'
import { User } from '../../models'
import { UserService, TwService, AdministrativeService } from '../../services'

@Component({
  selector: 'mb-setup21',
  templateUrl: './setup21.component.html'
})
export class Setup21Component implements OnInit {

  @Input() account: User

  public form: FormGroup
  public loaded = false
  public submitting = false
  public validate = false
  private states: String[]
  private stateIDs: string[]
  private seasons: string[]
  private seasonTitles: String[]
  private schools: String[]
  private schoolIDs: string[]
  private statesLoaded = false
  private seasonsLoaded = false
  private schoolsLoaded = false

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private administrativeService: AdministrativeService,
    private twService: TwService
  ) { }

  ngOnInit() {
    this.createForm()
    this.loadStates()
    this.loadSeasons()
    //this.loadSchools() this needs to be executed after other dropdowns load, so it is called at condlusion of loadSeasons()
  }

  public submitAnswer(theAnswer: string) {
    this.submitting = true
    const data: any = {}
    let update: boolean = false
    // tw username field
    if (this.form.value.twUserName !== this.account.twUserName) {
      data.twUserName = this.form.value.twUserName
      update = true
    }
    // state dropdown
    var statedropdown = document.getElementById("stateDropdown") as HTMLSelectElement
    const stateLongName = statedropdown.options[statedropdown.selectedIndex].innerHTML
    if (stateLongName === 'Choose State') {
      // return and give error message
    } else {
      const stateAbbrev = this.getStateConversion(stateLongName, 0)
      if (stateAbbrev !== this.account.state) {
        data.state = stateAbbrev
        update = true
      }
    }

    // season dropdown
    var seasondropdown = document.getElementById("ageDropdown") as HTMLSelectElement
    const seasonID = seasondropdown.value
    const seasonName = seasondropdown.options[seasondropdown.selectedIndex].innerHTML
    if (seasonName === 'Choose Age') {
      // return and give error message
    } else {
      if (seasonID !== this.account.twSeason) {
        data.twSeason = seasonID
        update = true
      }
    }
    // random
    if (theAnswer !== 'no_school_found') {
      // school dropdown
      var schooldropdown = document.getElementById("schoolNameDropdown") as HTMLSelectElement
      var schoolID = schooldropdown.value
	  var schoolNameTW = ''
	  if (schoolID !== '') {
      	schoolNameTW = schooldropdown.options[schooldropdown.selectedIndex].innerHTML
	  }
      // what if they hit 'The information is correct', but value of school dropdown is still 'Choose School'???
      if (schoolID === 'Choose School' || schoolID === '') {
        data.twTeamID = 0
        update = true
        theAnswer = 'no_school_found'
      } else {
       if (schoolID !== this.account.twTeamID) {
         data.twTeamID = schoolID
         update = true
       }
       if (schoolNameTW !== this.account.twSchoolName) {
         data.twSchoolName = schoolNameTW
         const partone = 'When going through the setup wizard, '
         const parttwo = ' the school name in TW for '
         const parttwob = ' in '
         const partthree = ' in the ageLevel: '
         const partfour = ' was changed by the main contact to: '
         const message = partone.concat(parttwo, this.account.schoolName, parttwob, this.account.state, partthree, this.account.ageLevel, partfour, schoolNameTW)
         this.administrativeService.emailSomeone(
           'john@matbossapp.com',
           message,
           'School name issue'
           )
           .pipe(first())
           .subscribe((r) => null)
         update = true
       }
      }
    }

    if (update) {
      // update changed account info before proceeding
      this.userService.update(
      this.account.userName,
      this.account.userType,
      data
      )
      .pipe(first())
      .subscribe((r) => this._submit(theAnswer))
    }
    else this._submit(theAnswer)
  }

  private _submit(theAnswer: string): void {
    if (theAnswer === 'no_school_found' || theAnswer === '') {
    const partone = 'When going through the setup wizard, '
    const parttwo = ' was not found in the TW system in the ageLevel: '
    const message = partone.concat(this.account.schoolName, parttwo, this.account.ageLevel)
    this.administrativeService.emailSomeone(
      'john@matbossapp.com',
      message,
      'School name not found'
      )
      .pipe(first())
      .subscribe((r) => null)
      this.router.navigate(['/setup/5'])
    }
    else {
      this.router.navigate(['/setup/6'])
    }
  }

  public goBack() {
    window.history.back()
  }

  // private

  private createForm(): void {
    this.form = this.fb.group({
      twUserName: [this.account.twUserName],
	  twSchoolName: [this.account.twSchoolName],
    })
    this.loaded = true
  }

  private loadStates(): void {
    this.twService.getTWStates()
      .pipe(first())
      .subscribe((data: any) => {
        if (!data.success) return
        this.states = []
    this.stateIDs = []
    for (var item of data.result) {
      var items = item.state.split("~")
      if (items.length > 1) {
        this.stateIDs.push(items[0])
        this.states.push(items[1])
        var selector = document.getElementById("stateDropdown") as HTMLSelectElement
        selector.options[selector.options.length] = new Option(items[1], items[0])
      }
    }
    this.statesLoaded = true;
    // Set the dropdown to this team's state
    const statelongname = this.getStateConversion(this.account.state, 1)
    const stateID = this.getStateID(statelongname)
    var statedropdown = document.getElementById("stateDropdown") as HTMLSelectElement
    statedropdown.value = stateID
        this.ready()
      })
  }

  private getStateConversion(stateString: String, whichDirection: number): String {
    var abbreviations: String[] = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]
    var stateLongNames: String[] = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Washington DC", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"]

    // whichDirection = 0 => convert long name to abbreviation
    // whichDirection = 1 => convert abbreviatiopn to longName
    if (whichDirection == 0) {
      for (var i = 0; i < stateLongNames.length; i++) {
        if (stateLongNames[i] === stateString) {
          return abbreviations[i]
        }
      }
    } else {
      for (var i = 0; i < abbreviations.length; i++) {
        if (abbreviations[i] === stateString) {
          return stateLongNames[i]
        }
      }
    }
    return "";
  }

  private getStateID(statelongname: String): string {
    for (var i = 0; i < this.states.length; i++) {
      if (this.states[i] === statelongname) {
        return this.stateIDs[i]
      }
    }
  }

  private loadSeasons(): void {
    this.twService.getTWSeasons()
      .pipe(first())
      .subscribe((data: any) => {
        if (!data.success) return
        this.seasons = []
    this.seasonTitles = []
    for (var item of data.result) {
      var items = item.season.split("~")
      if (items.length > 1) {
        this.seasons.push(items[0])
        this.seasonTitles.push(items[1])
        var selector = document.getElementById("ageDropdown") as HTMLSelectElement;
        selector.options[selector.options.length] = new Option(items[1], items[0])
      }
    }
    this.seasonsLoaded = true;
    // Set the dropdown to this team's age level
    const seasonID = this.getSeason(this.account.ageLevel)
    var seasondropdown = document.getElementById("ageDropdown") as HTMLSelectElement
    seasondropdown.value = seasonID
        this.ready()
      })
  }

  private getSeason(seasontitle: String): string {
    for (var i = 0; i < this.seasonTitles.length; i++) {
      if (this.seasonTitles[i] === seasontitle) {
        return this.seasons[i]
      }
    }
  }

  private loadSchools(): void {
    if (this.schoolsLoaded) return
    var seasondropdown = document.getElementById("ageDropdown") as HTMLSelectElement
    const seasonID = seasondropdown.value
    var statedropdown = document.getElementById("stateDropdown") as HTMLSelectElement
    const stateID = statedropdown.value
    this.twService.getTWTeamList(seasonID, stateID)
    .pipe(first())
      .subscribe((data: any) => {
        if (!data.success) return
        this.schools = []
    this.schoolIDs = []
    for (var item of data.result) {
      var items = item.team.split("~")
      if (items.length > 1) {
        this.schoolIDs.push(items[0])
        this.schools.push(items[1])
        var selector = document.getElementById("schoolNameDropdown") as HTMLSelectElement
        selector.options[selector.options.length] = new Option(items[1], items[0])
      }
    }
    this.schoolsLoaded = true;
    const schoolID = this.getSchoolID()
    var schoolNameDropdown = document.getElementById("schoolNameDropdown") as HTMLSelectElement
    if (schoolID) {
      schoolNameDropdown.value = schoolID
    } else {

    }
    this.ready()
    })
  }

  private reloadSchools() {
    var selector = document.getElementById("schoolNameDropdown") as HTMLSelectElement
    var i
      for(i = selector.options.length - 1 ; i >= 0 ; i--)
      {
          selector.remove(i)
      }
    selector.options[selector.options.length] = new Option('Choose School', '0')
    this.schoolsLoaded = false
    this.loadSchools()
  }

  private getSchoolID(): string {
    for (var i = 0; i < this.schools.length; i++) {
      if (this.schools[i] === this.account.twSchoolName) {
        return this.schoolIDs[i]
      }
    }
  }

  private ready(): void {
    if (!this.statesLoaded) return
  if (!this.seasonsLoaded) return
  this.loadSchools()
  if (!this.schoolsLoaded) return
    this.loaded = true
  }
}
