import { Injectable } from '@angular/core'
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError, first, map } from 'rxjs/operators'
import { UserService } from './user.service'

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  /**
   * this is the inverse of AuthGuard#canActivate
   */
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|boolean {
    return this.userService.requestCurrent()
      .pipe(
        first(),
        map(
          (data: any) => {
            this.userService.setCurrentFromData(data.result)
            // @todo find a better way to validate user
            const validUser = data.result && data.result.active && data.result.role
            if (validUser) this.router.navigate(['/'])
            return !validUser
          }
        )
      )
  }
}
