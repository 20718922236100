<div class="modal-header">
    <h4 class="modal-title">Adjust Team Point</h4>
    <button type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="create()" novalidate>
        <div class="form-group">
            <label>For or Against</label>
            <select class="form-control" formControlName="forOrAgainst">
              <option *ngFor="let opt of forOrAgainstOptions" [value]='opt.value'>
                {{ opt.label }}
              </option>
            </select>
        </div>
        <div class="form-group">
            <label>Type of Adjustment</label>
            <select class="form-control" formControlName="typeOfAdjustment"
            (change)="onTypeOfAdjustmentSelected()">
              <option *ngFor="let option of typeOfAdjustmentOptions" [ngValue]="option">
                {{ option.type }}
              </option>
            </select>
        </div>
        <div class="form-group">
            <label>Team Point Difference</label>
            <p>
              {{ form.get('typeOfAdjustment').value ? form.get('typeOfAdjustment').value.teamPointDiff : '' }}
            </p>
        </div>
        <div class="form-group"
        *ngIf="form.get('typeOfAdjustment').value ? form.get('typeOfAdjustment').value.teamPointDiff==='-1' : false">
            <label>When?</label>
            <select class="form-control" formControlName="when">
                <option *ngFor="let weight of weightClassList" [value]="weight">
                  {{ weight }}
                </option>
            </select>
        </div>
    </form>
</div>

<div class="modal-footer d-block">
  <button *ngIf="!submitting && !teamPointAdjustment"
    type="button"
    class="btn btn-primary btn-block"
    (click)="create()">
    Save
  </button>

  <button *ngIf="!submitting && teamPointAdjustment"
  type="button"
  class="btn btn-primary btn-block"
  (click)="update()">
    Update
  </button>

  <mb-ellipsis-loader *ngIf="submitting"></mb-ellipsis-loader>

  <div *ngIf="teamPointAdjustment && !submitting" class="mt-4 text-center">
    <span>- or -</span>
  </div>

  <div *ngIf="teamPointAdjustment && !submitting" class="mt-4 d-flex justify-content-center">
    <button  (click)="confirmDelete()"
      class="btn btn-outline-danger">
      Delete Adjustment
    </button>
  </div>
</div>
