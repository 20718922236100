<mb-spinner *ngIf="!loaded"
      label="Loading..."
      class="my-5 p-5 text-center"></mb-spinner>

<div class="container container--inner mb-settings" *ngIf="loaded">

    <div class="mb-breadcrumbs pb-3">
        <a preserveQueryParams routerLink="/settings">‹ Back To Settings</a>
    </div>

    <mb-alert *ngIf="error" type="danger" [message]="error" (closed)="error = null"></mb-alert>
    <mb-alert *ngIf="success" [message]="success" (closed)="success = null"></mb-alert>
    
    <h3 class="mb-page__head">
      <span class="mb-page__head-title">
        Profile Settings
      </span>
    </h3>

    <form [formGroup]="formGroup" (ngSubmit)="submit()" novalidate>

      <h3 class="mb-subhead my-3" *ngIf="!account.isWrestler">Profile Information</h3>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label">First Name</label>
            <input type="text"
              class="form-control"
              formControlName="first_name">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label">Last Name</label>
            <input type="text"
              class="form-control"
              formControlName="last_name">
          </div>
        </div>
        <!-- <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label">Phone</label>
            <input type="text"
              class="form-control"
              formControlName="phone">
          </div>
        </div> -->
        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-label">Email</label>
            <input type="text"
              class="form-control"
              formControlName="email">
          </div>
        </div>
      </div>

      <hr class="mb-hr" />

      <div>
        <button class="btn btn-lg btn-primary btn-full">Save changes</button>
      </div>
    </form>
</div>