import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { first } from 'rxjs/operators'

import { User } from '../../models'
import { UserService } from '../../services';

@Component({
  selector: 'mb-setup20',
  templateUrl: './setup20.component.html'
})
export class Setup20Component implements OnInit {

  @Input() account: User

  public form: FormGroup
  public loaded = false
  public submitting = false
  public validate = false

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.createForm()
  }

  public submitAnswer(theAnswer: string) {
	  this.submitting = true
	  const data: any = {}
	  let update: boolean = false
	  // By default, set reports to true
	  let reports: boolean = true
	  if (theAnswer == 'no') {
		  reports = false
	  }
	  if (reports !== undefined) {
      	data.reportsToTw = reports ? 1 : 0
      	update = true
      }
	  if (update) {
		  // update account twUserName before proceeding
		  this.userService.verify(
			this.account.userName,
			this.account.userType,
			data
		  )
		  .pipe(first())
		  .subscribe((r) => this._submit(reports))
		}
		else this._submit(reports)
  }

  private _submit(reports: boolean): void {
    if (reports) {
      this.router.navigate(['/setup/4'])
    }
    else {
      this.router.navigate(['/setup/11'])
    }
  }

  public goBack() {
    window.history.back()
  }

  // private

  private createForm(): void {
    this.form = this.fb.group({

	})
    this.loaded = true
  }
}
